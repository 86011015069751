<div class="main-chart-outer" (scroll)=" divScroll($event)">
    <div class="main-chart">
        <div class="main-chart-inner">
            <!-- MAin Head -->
            <div class="main-chart-head">
                <!-- <div class="main-chart-tool-toggle">
                    <div class="mc-tt-icon mc-tt-show"><i-feather name="Maximize-2"></i-feather></div>
                    <div class="mc-tt-icon mc-tt-hide"><i-feather name="Minimize-2"></i-feather></div>
                </div> -->
                <div class="main-chart-head-inner">
                    <div class="mchi-outer">
                        <div class="mchi-main">
                            <div class="mchi-inner">
                                <div class="mchi-head">
                                    <div class="mchi-head-title">
                                        <h2>Filter</h2>
                                    </div>
                                    <div class="mchi-head-item"
                                        *ngIf="filter_1 || filter_2 || filter_3 || filter_4 || filter_5 || filter_6"
                                        (click)="filter_all_hide_func()" style="color:#f00">
                                        <i-feather name="chevron-up" title="Close All"></i-feather>
                                    </div>
                                    <div class="mchi-head-item"
                                        *ngIf="show_all_chart && showtoabbas"
                                        (click)="show_all_charts()" style="color:#8BC34A;">
                                        <i-feather name="Trending-Down" title="Select All Charts"></i-feather>
                                    </div>
                                    <div class="mchi-head-item"
                                        *ngIf="!filter_1 || !filter_2 || !filter_3 || !filter_4 || !filter_5 || !filter_6"
                                        (click)="filter_all_show_func()" style="color:#8BC34A; display: none;">
                                        <i-feather name="chevron-down" title="Show All"></i-feather>
                                    </div>
                                    <div class="mchi-head-item" (click)="filter_1_func()"
                                        [ngClass]="{'active':filter_1 == true }">
                                        <i-feather name="bar-chart-2" title="Candle Sticks"></i-feather>
                                    </div>
                                    <div class="mchi-head-item" (click)="filter_2_func()"
                                        [ngClass]="{'active':filter_2 == true }" style="display: none;">
                                        <i-feather name="Trending-Down" title="Filter 2"></i-feather>
                                    </div>
                                    <div class="mchi-head-item" (click)="filter_3_func()"
                                        [ngClass]="{'active':filter_3 == true }" style="display: none;">
                                        <i-feather name="Trending-Up" title="Filter 3"></i-feather>
                                    </div>
                                    <div class="mchi-head-item" (click)="filter_4_func()"
                                        [ngClass]="{'active':filter_4 == true }" style="display: none;">
                                        <i-feather name="Cpu" title="Filter 4"></i-feather>
                                    </div>
                                    <div class="mchi-head-item" (click)="filter_5_func()"
                                        [ngClass]="{'active':filter_5 == true }" style="display: none;">
                                        <i-feather name="Maximize-2" title="Filter 5"></i-feather>
                                    </div>
                                    <div class="mchi-head-item" (click)="filter_6_func()"
                                        [ngClass]="{'active':filter_6 == true }" style="display: none;">
                                        <i-feather name="Maximize" title="Filter 6"></i-feather>
                                    </div>
                                </div>
                                <div class="mchi-body"
                                    *ngIf="filter_1 || filter_2 || filter_3 || filter_4 || filter_5 || filter_6 || filter_7">
                                    <div class="mchi-item" *ngIf="filter_1">
                                        <div class="mchi-item-head">
                                            <h2>Select Charts</h2>
                                        </div>
                                        <div class="mchi-item-body">
                                            <div class="row">
                                                <div class="col-12 pl-4 pr-4">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showCandleSticks" (change)="setcomponentstate()">
                                                                    <span>Candle Sticks</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"*ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showTrueLevels" (change)="setcomponentstate()">
                                                                    <span>True Levels</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showBoxes" (change)="setcomponentstate()">
                                                                    <span>DK Boxes</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showDemandSupply" (change)="setcomponentstate()">
                                                                    <span>Demand Supply</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showRejection" (change)="setcomponentstate()">
                                                                    <span>Rejection</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showMove" (change)="setcomponentstate()">
                                                                    <span>Move</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showBodyMove" (change)="setcomponentstate()">
                                                                    <span>Body Move</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTopWickSignal" (change)="setcomponentstate()">
                                                                    <span>Top Wick Signal</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showCandelTrends" (change)="setcomponentstate()">
                                                                  <span>Candel Trends</span>
                                                              </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showDPUPTrendDirection" (change)="setcomponentstate()">
                                                                  <span>DPUP Trend Direction</span>
                                                              </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showNextClose" (change)="setcomponentstate()">
                                                                    <span>Next Close</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showIncreasingVolume" (change)="setcomponentstate()">
                                                                    <span>BRD</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBlackDotUpPressure" (change)="setcomponentstate()">
                                                                    <span>Black Dot Up Pressure</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showDP" (change)="setcomponentstate()">
                                                                    <span>UP & DP</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBlackDotPressureU10" (change)="setcomponentstate()">
                                                                    <span>BDPU10</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showSevenLevelPerc" (change)="setcomponentstate()">
                                                                    <span>Seven Level Perc</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showSevenLevelMinVSTPerc"
                                                                        (change)="setcomponentstate()">
                                                                    <span>Seven Level Min VST Perc</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showSevenLevel" (change)="setcomponentstate()">
                                                                    <span>Seven Level Average</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showSevenLevelST" (change)="setcomponentstate()">
                                                                    <span>Seven Level ST </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showSevenLevelVST" (change)="setcomponentstate()">
                                                                    <span>Seven Level VST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showSevenLevelMinMax" (change)="setcomponentstate()">
                                                                    <span>Seven Level MinMax</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showAskBidVolume" (change)="setcomponentstate()">
                                                                    <span>Ask Bid Volume</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showAskBidVolumeB" (change)="setcomponentstate()">
                                                                    <span>Ask Bid Volume B</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showAskBidDelta" (change)="setcomponentstate()">
                                                                    <span>Ask Bid Delta</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showAskBidDeltaB" (change)="setcomponentstate()">
                                                                    <span>Ask Bid Delta B</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showBidDelta" (change)="setcomponentstate()">
                                                                    <span>Ask Bid Division</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showAskBidDivisionB" (change)="setcomponentstate()">
                                                                    <span>Ask Bid Division B</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showAskBidCount" (change)="setcomponentstate()">
                                                                    <span>Ask Bid Count</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showVolumeComparison" (change)="setcomponentstate()">
                                                                    <span>Volume Comparison</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showScriptTime" (change)="setcomponentstate()">
                                                                    <span>Script Time</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngIf="showtoabbas || showtouser">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showCoinMetaCount" (change)="setcomponentstate()">
                                                                    <span>Coin Meta Count</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBlackWallPerc" (change)="setcomponentstate()">
                                                                    <span>Black Wall Perc</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigContractsPerc" (change)="setcomponentstate()">
                                                                    <span>Big Contracts Perc</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerContractsPercVST" (change)="setcomponentstate()">
                                                                    <span>LBBCTLT</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerContractsPercST" (change)="setcomponentstate()">
                                                                    <span>LBBCTST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigSellerContractsPercVST" (change)="setcomponentstate()">
                                                                    <span>LBSCTLT</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigSellerContractsPercST" (change)="setcomponentstate()">
                                                                    <span>LBSCTST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerSellerContractsCount" (change)="setcomponentstate()">
                                                                    <span>LBBSCT Count</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerSellerContractsVol" (change)="setcomponentstate()">
                                                                    <span>LBBSCT Vol</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerSellerContractsCount" (change)="setcomponentstate()">
                                                                    <span>Hourly Big Contractor Count</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerSellerContractsVol" (change)="setcomponentstate()">
                                                                    <span>Hourly Big Contractor  Vol</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa', 'Doug' ,'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerSellerDelta" (change)="setcomponentstate()">
                                                                    <span>Hourly Big Contractor Delta</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa', 'Doug' ,'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigBuyerSellerDiff" (change)="setcomponentstate()">
                                                                    <span>Hourly Big Contractor Diff</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showCandlePerc" (change)="setcomponentstate()">
                                                                    <span>Candle Perc</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showCandleSTPerc"
                                                                        (change)="setcomponentstate()">
                                                                    <span>Candle ST Perc</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showCandleVSTPerc"
                                                                        (change)="setcomponentstate()">
                                                                    <span>Candle VST Perc</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showTwoWayBar" (change)="setcomponentstate()">
                                                                    <span>Two Way Bars</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBlackWallAverg" (change)="setcomponentstate()">
                                                                    <span>Black Wall Averg</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigContractsAverg" (change)="setcomponentstate()">
                                                                    <span>Big Contracts Averg</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showUpperWick" (change)="setcomponentstate()">
                                                                    <span>Upper Wick</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showUpperWickST"
                                                                        (change)="setcomponentstate()">
                                                                    <span>Upper Wick ST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showUpperWickVST"
                                                                        (change)="setcomponentstate()">
                                                                    <span>Upper Wick VST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showLowerWick" (change)="setcomponentstate()">
                                                                    <span>Lower Wick</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showLowerWickST"
                                                                        (change)="setcomponentstate()">
                                                                    <span>Lower Wick ST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showLowerWickVST"
                                                                        (change)="setcomponentstate()">
                                                                    <span>Lower Wick VST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBodyPercentile" (change)="setcomponentstate()">
                                                                    <span>Body Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBlackWallMinMax" (change)="setcomponentstate()">
                                                                    <span>Black Wall MinMax</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showBigContracts" (change)="setcomponentstate()">
                                                                    <span>Big Contracts</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <!-- <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showBigDelta" (change)="setcomponentstate()">
                                                                    <span>Big Delta</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTelegramSentiment" (change)="setcomponentstate()">
                                                                    <span>Telegram Sentiment</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTelegramITPercentile" (change)="setcomponentstate()">
                                                                    <span>Telegram IT Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTelegramSTPercentile" (change)="setcomponentstate()">
                                                                    <span>Telegram ST Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTelegramVSTPercentile" (change)="setcomponentstate()">
                                                                    <span>Telegram VST Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTwitterSentiment" (change)="setcomponentstate()">
                                                                    <span>Twitter Sentiment</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTwitterITPercentile" (change)="setcomponentstate()">
                                                                    <span>Twitter IT Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTwitterSTPercentile" (change)="setcomponentstate()">
                                                                    <span>Twitter ST Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showTwitterVSTPercentile" (change)="setcomponentstate()">
                                                                    <span>Twitter VST Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showNewsSentiment" (change)="setcomponentstate()">
                                                                    <span>News Sentiment</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showNewsITPercentile" (change)="setcomponentstate()">
                                                                    <span>News IT Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showNewsSTPercentile" (change)="setcomponentstate()">
                                                                    <span>News ST Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showNewsVSTPercentile" (change)="setcomponentstate()">
                                                                    <span>News VST Percentile</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showT3MinMax" (change)="setcomponentstate()">
                                                                    <span>T1COT</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showT2MinMax" (change)="setcomponentstate()">
                                                                    <span>T2COT</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showT4MinMax" (change)="setcomponentstate()">
                                                                    <span>T4COT</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox">
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox" [(ngModel)]="showT2BCP" (change)="setcomponentstate()">
                                                                    <span>T2BCP Ask Bid Vol</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   <div class="mchi-foot">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label>PLine {{price_length}}</label>
                                                    <input type="range" class="form-control" min="50" max="200"
                                                        [(ngModel)]="price_length" (change)="changearrayValue()">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label>TLine {{time_length}}</label>
                                                    <input type="range" class="form-control" min="25" max="168"
                                                        [(ngModel)]="time_length" (change)="changearrayValue()"
                                                        (mousedown)="onKeyDown($event)" (mouseup)="onKeyUp($event)" (keydown.arrowup)= "onKeyDown($event)" (keyup.arrowup)= "onKeyUp($event)" (keydown.arrowdown)= "onKeyDown($event)" (keyup.arrowdown)= "onKeyUp($event)" (keydown.arrowleft)= "onKeyDown($event)" (keyup.arrowleft)= "onKeyUp($event)" (keydown.arrowright)= "onKeyDown($event)" (keyup.arrowright)= "onKeyUp($event)">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-2 col-lg-2 pr-1 pl-1">
                                                <div class="btnchecbox">
                                                    <div class="btn-test-checked">
                                                        <input type="checkbox" [(ngModel)]="candleScroller" (change)="setcomponentstate()">
                                                        <span>Enable Scroller</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-2 col-lg-2 pr-1 pl-1">
                                                <div class="btnchecbox">
                                                    <div class="btn-test-checked">
                                                        <input type="checkbox" [(ngModel)]="popUpOnCandleStick" (change)="setcomponentstate()">
                                                        <span>CandleStick Tooltip</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-2 col-lg-2 pr-1 pl-1">
                                                <div class="btnchecbox">
                                                    <div class="btn-test-checked">
                                                        <input type="checkbox" [(ngModel)]="popUpOnCandle" (change)="setcomponentstate()">
                                                        <span>Other Charts Tooltip</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                                <div class="form-group">
                                                    <label>Candle By Date:</label>
                                                    <input type="date" [(ngModel)]="search_date" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <div class="form-group">
                                                    <label>&nbsp;</label>
                                                    <button (click)="date_search_candle()"
                                                        class="btn btn-block btn-primary">Search</button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <div class="form-group">
                                                    <label>&nbsp;</label>
                                                    <button class="btn btn-block btn-primary" *ngIf="show_backward"
                                                        (click)="show_backward_c()">Backward</button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <div class="form-group">
                                                    <label>&nbsp;</label>
                                                    <button class="btn btn-block btn-primary" *ngIf="show_forward"
                                                        (click)="show_forward_f()">Forward</button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <label>&nbsp;</label>
                                                <button class="btn btn-block btn-danger" (click)="RemovesettingLocalStorage()">Refresh</button>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                                <label>&nbsp;</label>
                                                <a href="#" class="btn btn-block btn-success">Save Setting</a>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                                <label>&nbsp;</label>
                                                <button class="btn btn-block btn-danger" style="color: #FDFCFA;"
                                                        (click)="removeAllLines()">Lines Remove</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Main Head End -->
            <!-- Main Body Start-->
            <div class="main-chart-body">

                <div class="loaderiner loaderlight" *ngIf="dataLoaderWaiting">
                    <img src="../../assets/images/loaderx.gif">
                </div>
                <div class="main-chart-body-panel" id="main-chart-body-panel" (mousemove)="mouseMove($event)"
                    (mouseenter)="mouseenter($event)" (mousedown)="mousedown($event)" (mouseup)="mouseup($event)"
                    (mouseleave)="mouseleave($event)" (dblclick)="dblclick()">
                    <!-- Charts Start -->
                    <div *ngIf="!candleScroller">
                        <div  class="candlesticks-selector" *ngIf="showCandleSticks" (mousemove) = "mouseMoveOnCandels($event)" (mouseover) = "mouseOverOnCandels($event)" (mouseenter)="mouseEnterComponent($event)" >
                            <app-candlesticks ></app-candlesticks>
                        </div>
                    </div>
                    <div *ngIf="candleScroller">
                        <div  mouseWheel (mouseWheelUp)="mouseWheelUpFunc($event)" (mouseWheelDown)="mouseWheelDownFunc($event)" class="candlesticks-selector" *ngIf="showCandleSticks" (mousemove) = "mouseMoveOnCandels($event)" (mouseover) = "mouseOverOnCandels($event)" (mouseenter)="mouseEnterComponent($event)" >
                            <app-candlesticks ></app-candlesticks>
                        </div>
                    </div>


                    <!-- <div class="volumetext-selector" *ngIf="showVolumeText" (mousemove) = "mouseMoveOnVolumeText($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-volumetext></app-volumetext>
                    </div> -->
                    <!-- <div class="truelevels-selector" id="tlevels" *ngIf="showTrueLevels" (mousemove) = "mouseMoveOnTruelevels($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-truelevels></app-truelevels>
                    </div>
                    <div class="boxes-selector" *ngIf="showBoxes" id="box" (mousemove) = "mouseMoveOnBoxes($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-boxes></app-boxes>
                    </div> -->
                    <div class="demandsupply-selector" *ngIf="showDemandSupply" id="dsupply" (mousemove) = "mouseMoveOnDemandSupply($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-demand-supply></app-demand-supply>
                    </div>
                    <div class="rejection-selector" *ngIf="showRejection" (mousemove) = "mouseMoveOnRejection($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-rejection></app-rejection>
                    </div>
                    <div class="move-selector" *ngIf="showMove" (mousemove) = "mouseMoveOnMove($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-move></app-move>
                    </div>
                    <div class="bodymove-selector" *ngIf="showBodyMove" (mousemove) = "mouseMoveOnBodyMove($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-body-move></app-body-move>
                    </div>
                    <!-- <div class="topwicksignal-selector" *ngIf="showTopWickSignal" (mousemove) = "mouseMoveOnTopWickSignal($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-top-wick-signal></app-top-wick-signal>
                    </div> -->
                    <div class="candeltrends-selector" *ngIf="showCandelTrends" (mousemove)="mouseMoveOnCandelTrends($event)" (mouseenter)="mouseEnterComponent($event)">
                        <app-candel-trends></app-candel-trends>
                    </div>
                    <div class="dpuptrenddirection-selector" *ngIf="showDPUPTrendDirection" (mousemove)="mouseMoveOnDPUPTrendDirection($event)" (mouseenter)="mouseEnterComponent($event)">
                        <app-dp-up-trend-direction></app-dp-up-trend-direction>
                    </div>
                    <div class="next_close-selector" *ngIf="showNextClose" (mousemove)="mouseMoveOnNextClose($event)" (mouseenter)="mouseEnterComponent($event)">
                        <app-next-close></app-next-close>
                    </div>
                    <!-- <div class="increasingvolume-selector" *ngIf="showIncreasingVolume" (mousemove) = "mouseMoveOnIncreasingVolume($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-increasing-volume></app-increasing-volume>
                    </div>
                    <div class="blackdotuppressure-selector" *ngIf="showBlackDotUpPressure" (mousemove) = "mouseMoveOnBlackDotUpPressure($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-black-dot-up-pressure></app-black-dot-up-pressure>
                    </div> -->
                    <div class="dp1-selector" *ngIf="showDP" (mousemove) = "mouseMoveOnDP1($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-dp1></app-dp1>
                    </div>
                    <div class="dp2-selector" *ngIf="showDP" (mousemove) = "mouseMoveOnDP2($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-dp2></app-dp2>
                    </div>
                    <div class="dp3-selector" *ngIf="showDP" (mousemove) = "mouseMoveOnDP3($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-dp3></app-dp3>
                    </div>
                    <div class="sevenlevelpercentile-selector" *ngIf="showSevenLevelPerc" (mousemove) = "mouseMoveOnSevenLevelPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-sevenlevelpercentile></app-sevenlevelpercentile>
                    </div>
                    <!-- <div class="sevenlevelminvstpercentile-selector" *ngIf="showSevenLevelMinVSTPerc" (mousemove)="mouseMoveOnSevenLevelMinVSTPercentile($event)" (mouseenter)="mouseEnterComponent($event)">
                        <app-seven-level-min-very-short-term-percentile></app-seven-level-min-very-short-term-percentile>
                    </div> -->
                    <div class="sevenlevel-selector" *ngIf="showSevenLevel" (mousemove) = "mouseMoveOnSevenLevel($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-sevenlevel></app-sevenlevel>
                    </div>
                    <!-- <div class="sevenlevelst-selector" *ngIf="showSevenLevelST" (mousemove) = "mouseMoveOnSevenLevelST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-seven-level-avg-st></app-seven-level-avg-st>
                    </div>
                    <div class="sevenlevelvst-selector" *ngIf="showSevenLevelVST" (mousemove) = "mouseMoveOnSevenLevelVST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-seven-level-avg-vst></app-seven-level-avg-vst>
                    </div>
                    <div class="sevenlevelminmax-selector" *ngIf="showSevenLevelMinMax" (mousemove) = "mouseMoveOnSevenLevelMinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-seven-level-min-max></app-seven-level-min-max>
                    </div>
                    <div class="blackdotpressureu10-selector" *ngIf="showBlackDotPressureU10" (mousemove) = "mouseMoveOnBlackDotPressureU10($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-black-dot-pressure-u10></app-black-dot-pressure-u10>
                    </div> -->
                    <div class="askbidvolume-selector" *ngIf="showAskBidVolume" (mousemove) = "mouseMoveOnAskBidVolume($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-ask-bid-volume></app-ask-bid-volume>
                    </div>
                    <div class="askbidvolumeb-selector" *ngIf="showAskBidVolumeB" (mousemove) = "mouseMoveOnAskBidVolumeB($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-ask-bid-volume-b></app-ask-bid-volume-b>
                    </div>
                    <div class="askbiddelta-selector" *ngIf="showAskBidDelta" (mousemove) = "mouseMoveOnAskBidDelta($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-ask-bid-delta></app-ask-bid-delta>
                    </div>
                    <div class="askbiddeltab-selector" *ngIf="showAskBidDeltaB" (mousemove) = "mouseMoveOnAskBidDeltaB($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-ask-bid-delta-b></app-ask-bid-delta-b>
                    </div>
                    <div class="biddelta-selector" *ngIf="showBidDelta" (mousemove) = "mouseMoveOnBidDelta($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-bid-delta></app-bid-delta>
                    </div>
                    <div class="askbiddivisionb-selector" *ngIf="showAskBidDivisionB" (mousemove) = "mouseMoveOnAskBidDivisionB($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-ask-bid-division-b></app-ask-bid-division-b>
                    </div>
                    <div class="askbidcount-selector" *ngIf="showAskBidCount" (mousemove) = "mouseMoveOnAskBidCount($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-ask-bid-count></app-ask-bid-count>
                    </div>
                    <div class="volumecomparison-selector" *ngIf="showVolumeComparison" (mousemove) = "mouseMoveOnVolumeComparison($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-volume-comparison></app-volume-comparison>
                    </div>
                    <!-- <div class="scripttime-selector" *ngIf="showScriptTime" (mousemove) = "mouseMoveOnScriptTime($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-scripttime></app-scripttime>
                    </div> -->
                    <!-- <div class="coinmetacount-selector" *ngIf="showCoinMetaCount" (mousemove) = "mouseMoveOnCoinMetaCount($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-coin-meta-count></app-coin-meta-count>
                    </div>
                    <div class="blackwallpercentile-selector" *ngIf="showBlackWallPerc" (mousemove) = "mouseMoveOnBlackWallPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-blackwallpercentile></app-blackwallpercentile>
                    </div>
                    <div class="bigcontractspercentile-selector" *ngIf="showBigContractsPerc" (mousemove) = "mouseMoveOnBigContractsPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-bigcontractspercentile></app-bigcontractspercentile>
                    </div>
                    <div class="bigbuyercontractspercentilest-selector" *ngIf="showBigBuyerContractsPercST" (mousemove) = "mouseMoveOnBigBuyerContractsPercentileST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyers-contracts1-perc-st></app-big-buyers-contracts1-perc-st>
                    </div>
                    <div class="bigbuyercontractspercentilevst-selector" *ngIf="showBigBuyerContractsPercVST" (mousemove) = "mouseMoveOnBigBuyerContractsPercentileVST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyers-contracts1-perc></app-big-buyers-contracts1-perc>
                    </div>
                    <div class="bigsellercontractspercentilest-selector" *ngIf="showBigSellerContractsPercST" (mousemove) = "mouseMoveOnBigSellerContractsPercentileST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-sellers-contracts1-perc-st></app-big-sellers-contracts1-perc-st>
                    </div>
                    <div class="bigsellercontractspercentilevst-selector" *ngIf="showBigSellerContractsPercVST" (mousemove) = "mouseMoveOnBigSellerContractsPercentileVST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-sellers-contracts1-perc></app-big-sellers-contracts1-perc>
                    </div>
                    <div class="bigbuyersellercontractscount-selector" *ngIf="showBigBuyerSellerContractsCount" (mousemove) = "mouseMoveOnBigBuyerSellerContractsCount($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyers-sellers-contracts-count></app-big-buyers-sellers-contracts-count>
                    </div>
                    <div class="bigbuyersellercontractsvol-selector" *ngIf="showBigBuyerSellerContractsVol" (mousemove) = "mouseMoveOnBigBuyerSellerContractsVol($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyers-sellers-contracts-vol></app-big-buyers-sellers-contracts-vol>
                     </div> -->
                     <div class="bigbuyersellercontractscount-selector" *ngIf="showBigBuyerSellerContractsCount" (mousemove) = "mouseMoveOnBigBuyerSellerContractsCount($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyers-sellers-contracts-count></app-big-buyers-sellers-contracts-count>
                    </div>
                    <div class="bigbuyersellercontractsvol-selector" *ngIf="showBigBuyerSellerContractsVol" (mousemove) = "mouseMoveOnBigBuyerSellerContractsVol($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyers-sellers-contracts-vol></app-big-buyers-sellers-contracts-vol>
                    </div>
                    <div class="bigbuyersellerdelta-selector" *ngIf="showBigBuyerSellerDelta" (mousemove) = "mouseMoveOnBigBuyerSellerDelta($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyer-seller-delta></app-big-buyer-seller-delta>
                    </div>
                    <div class="bigbuyersellerdiff-selector" *ngIf="showBigBuyerSellerDiff" (mousemove) = "mouseMoveOnBigBuyerSellerDiff($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-buyer-seller-diff></app-big-buyer-seller-diff>
                    </div>
                    <div class="candlepercentile-selector" *ngIf="showCandlePerc" (mousemove) = "mouseMoveOnCandlePercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-candlepercentile></app-candlepercentile>
                    </div>
                    <!-- <div class="candlestpercentile-selector" *ngIf="showCandleSTPerc" (mousemove) = "mouseMoveOnCandleSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-candle-short-term-percentile></app-candle-short-term-percentile>
                    </div>
                    <div class="candlevstpercentile-selector" *ngIf="showCandleVSTPerc" (mousemove) = "mouseMoveOnCandleVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-candle-vstpercentile></app-candle-vstpercentile>
                    </div>
                    <div class="twowaybar-selector" *ngIf="showTwoWayBar" (mousemove) = "mouseMoveOnTwoWayBar($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-two-way-bar></app-two-way-bar>
                    </div> -->
                    <div class="blackwallavg-selector" *ngIf="showBlackWallAverg" (mousemove) = "mouseMoveOnBlackWallAverage($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-black-wall-averg></app-black-wall-averg>
                    </div>
                    <!-- <div class="bigcontractsavg-selector" *ngIf="showBigContractsAverg" (mousemove) = "mouseMoveOnBigContractsAverage($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-contracts-averg></app-big-contracts-averg>
                    </div>
                    <div class="upperwick-selector" *ngIf="showUpperWick" (mousemove) = "mouseMoveOnUpperwick($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-upper-wick-percentile></app-upper-wick-percentile>
                    </div>
                    <div class="upperwickst-selector" *ngIf="showUpperWickST" (mousemove) = "mouseMoveOnUpperwickST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-upper-wick-short-term-percentile></app-upper-wick-short-term-percentile>
                    </div>
                    <div class="upperwickvst-selector" *ngIf="showUpperWickVST" (mousemove) = "mouseMoveOnUpperwickVST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-upper-wick-vstpercentile></app-upper-wick-vstpercentile>
                    </div>
                    <div class="lowerwick-selector" *ngIf="showLowerWick" (mousemove) = "mouseMoveOnLowerwick($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-lower-wick-percentile></app-lower-wick-percentile>
                    </div>
                    <div class="lowerwickst-selector" *ngIf="showLowerWickST" (mousemove) = "mouseMoveOnLowerwickST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-lower-wick-short-term-percentile></app-lower-wick-short-term-percentile>
                    </div>
                    <div class="lowerwickvst-selector" *ngIf="showLowerWickVST" (mousemove) = "mouseMoveOnLowerwickVST($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-lower-wick-vstpercentile></app-lower-wick-vstpercentile>
                    </div>
                    <div class="bodypercentile-selector" *ngIf="showBodyPercentile" (mousemove) = "mouseMoveOnBodyPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-body-percentile></app-body-percentile>
                    </div>
                    <div class="blackwallminmax-selector" *ngIf="showBlackWallMinMax" (mousemove) = "mouseMoveOnBlackWallMinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-black-wall-min-max></app-black-wall-min-max>
                    </div>
                    <div class="bigcontracts-selector" *ngIf="showBigContracts" (mousemove) = "mouseMoveOnBigContracts($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-contracts></app-big-contracts>
                    </div> -->
                    <!-- <div class="bigdelta-selector" *ngIf="showBigDelta" (mousemove) = "mouseMoveOnBigDelta($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-big-delta></app-big-delta>
                    </div> -->
                    <!-- <div class="telegram-selector" *ngIf="showTelegramSentiment" (mousemove) = "mouseMoveOnTelegram($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-telegram-sentiment></app-telegram-sentiment>
                    </div>
                    <div class="telegramitperc-selector" *ngIf="showTelegramITPercentile" (mousemove) = "mouseMoveOnTelegramITPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-telegram-perc-it></app-telegram-perc-it>
                    </div>
                    <div class="telegramstperc-selector" *ngIf="showTelegramSTPercentile" (mousemove) = "mouseMoveOnTelegramSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-telegram-perc-st></app-telegram-perc-st>
                    </div>
                    <div class="telegramvstperc-selector" *ngIf="showTelegramVSTPercentile" (mousemove) = "mouseMoveOnTelegramVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-telegram-perc-vst></app-telegram-perc-vst>
                    </div>
                    <div class="twitter-selector" *ngIf="showTwitterSentiment" (mousemove) = "mouseMoveOnTwitter($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-twitter-sentiment></app-twitter-sentiment>
                    </div>
                    <div class="twitteritperc-selector" *ngIf="showTwitterITPercentile" (mousemove) = "mouseMoveOnTwitterITPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-twitter-perc-it></app-twitter-perc-it>
                    </div>
                    <div class="twitterstperc-selector" *ngIf="showTwitterSTPercentile" (mousemove) = "mouseMoveOnTwitterSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-twitter-perc-st></app-twitter-perc-st>
                    </div>
                    <div class="twittervstperc-selector" *ngIf="showTwitterVSTPercentile" (mousemove) = "mouseMoveOnTwitterVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-twitter-perc-vst></app-twitter-perc-vst>
                    </div>
                    <div class="news-selector" *ngIf="showNewsSentiment" (mousemove) = "mouseMoveOnNews($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-news-sentiment></app-news-sentiment>
                    </div>
                    <div class="newsitperc-selector" *ngIf="showNewsITPercentile" (mousemove) = "mouseMoveOnNewsITPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-news-perc-it></app-news-perc-it>
                    </div>
                    <div class="newsstperc-selector" *ngIf="showNewsSTPercentile" (mousemove) = "mouseMoveOnNewsSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-news-perc-st></app-news-perc-st>
                    </div>
                    <div class="newsvstperc-selector" *ngIf="showNewsVSTPercentile" (mousemove) = "mouseMoveOnNewsVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-news-perc-vst></app-news-perc-vst>
                    </div>
                    <div class="t3minmax-selector" *ngIf="showT3MinMax" (mousemove) = "mouseMoveOnT3($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-t3-cotmin-max></app-t3-cotmin-max>
                    </div>
                    <div class="t2minmax-selector" *ngIf="showT2MinMax" (mousemove) = "mouseMoveOnT2($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-t2-cotmin-max></app-t2-cotmin-max>
                    </div>
                    <div class="t4minmax-selector" *ngIf="showT4MinMax" (mousemove) = "mouseMoveOnT4($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-t4-cotmin-max></app-t4-cotmin-max>
                    </div>
                    <div class="t2bcp-selector" *ngIf="showT2BCP" (mousemove) = "mouseMoveOnT2BCP($event)"(mouseenter)="mouseEnterComponent($event)">
                        <app-t2-bcp15-ask-bid-vol></app-t2-bcp15-ask-bid-vol>
                    </div> -->
                    <!-- Charts End -->
                </div>
                <div class="main-chart-codinate_y" [style.left.px]="left_coord" *ngIf="left_coord > 0">
                    <span class="time" style="color: #FDFCFA;">{{openTime_human_readible_}}</span>
                </div>

                <div class="main-chart-codinate_x" [style.top.px]="top_coord" *ngIf="top_coord > 0">
                    <span class="price" *ngIf="showPriceLine">{{price}}</span>
                </div>


                <div *ngFor="let item of lineArray; let i = index">
                    <div class="main-chart-liney-codinate" [style.left.px]="item.fromleft">
                        <span class="remove_line" (click)="removeline(i)">x</span>
                        <span class="time">{{item.time}}</span>
                    </div>
                    <div class="main-chart-linex-codinate" [style.top.px]="item.fromtop " >
                        <span class="remove_line" (click)="removeline(i)">x</span>
                        <span class="price" *ngIf="showPriceLine">{{item.price}}</span>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTooltip && popUpOnCandleStick">
                    <div class="tootip-item">
                        <p>
                            <strong>High :</strong> {{high_}}
                        </p>
                        <p>
                            <strong>Low :</strong> {{low_}}
                        </p>
                        <p>
                            <strong>Open :</strong> {{open_}}
                        </p>
                        <p>
                            <strong>Close :</strong> {{close_}}
                        </p>
                        <p>
                            <strong>Volume :</strong> {{volume_}}
                        </p>
                        <p>
                            <strong>Ask :</strong> {{ask_}}
                        </p>
                        <p>
                            <strong>Bid :</strong> {{bid_}}
                        </p>
                        <p>
                            <strong>Ask_B :</strong> {{ask_b_}}
                        </p>
                        <p>
                            <strong>Bid_B :</strong> {{bid_b_}}
                        </p>
                        <p>
                            <strong>Total Volume :</strong> {{total_volume_}}
                        </p>
                        <p>
                            <strong>Down Barrier :</strong> {{down_barrier_value_}}
                        </p>
                        <p>
                            <strong>Up Barrier :</strong> {{up_barrier_value_}}
                        </p>
                        <p>
                            <strong>Ask Bid Volume :</strong>{{total_volume_}}
                        </p>
                        <p>
                            <strong>Demand Base :</strong> {{demandbasecandel_}}
                        </p>
                        <p>
                            <strong>Supply Base :</strong> {{supplybasecandel_}}
                        </p>
                        <p>
                            <strong>Swing :</strong> {{swing_}}
                        </p>
                        <p>
                            <strong>Open Time :</strong> {{openTime_human_readible_}}
                        </p>
                        <p>
                            <strong>Close Time :</strong>{{closeTime_human_readible_}}
                        </p>
                        <p>
                            <strong>Top Wick :</strong>{{top_wick_prices_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTruelevelTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong style="font-size: 15px;">L1 = </strong><span style="font-size: 12px;">Buy: </span>{{L1_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L1_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L2 </strong><span style="font-size: 12px;">Buy: </span>{{L2_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L2_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L3 </strong><span style="font-size: 12px;">Buy: </span>{{L3_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L3_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L4 </strong><span style="font-size: 12px;">Buy: </span>{{L4_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L4_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L5 </strong><span style="font-size: 12px;">Buy: </span>{{L5_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L5_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L6 </strong><span style="font-size: 12px;">Buy: </span>{{L6_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L6_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L7 </strong><span style="font-size: 12px;">Buy: </span>{{L7_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L7_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L8 </strong><span style="font-size: 12px;">Buy: </span>{{L8_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L8_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L9 </strong><span style="font-size: 12px;">Buy: </span>{{L9_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L9_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L10 </strong><span style="font-size: 12px;">Buy: </span>{{L10_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L10_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L11 </strong><span style="font-size: 12px;">Buy: </span>{{L11_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L11_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L12 </strong><span style="font-size: 12px;">Buy: </span>{{L12_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L12_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L13 </strong><span style="font-size: 12px;">Buy: </span>{{L13_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L13_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L14 </strong><span style="font-size: 12px;">Buy: </span>{{L14_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L14_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L15 </strong><span style="font-size: 12px;">Buy: </span>{{L15_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L15_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L16 </strong><span style="font-size: 12px;">Buy: </span>{{L16_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L16_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L17 </strong><span style="font-size: 12px;">Buy: </span>{{L17_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L17_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L18 </strong><span style="font-size: 12px;">Buy: </span>{{L18_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L18_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L19 </strong><span style="font-size: 12px;">Buy: </span>{{L19_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L19_sell_price_}}
                        </p>
                        <p>
                            <strong style="font-size: 15px;">L20 </strong><span style="font-size: 12px;">Buy: </span>{{L20_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L20_sell_price_}}
                        </p>
                    </div>
                </div>
                <!-- <div class="tooltip-box" [style.left.px]="left_coord + 20" [style.top.px]="tooltip_coord"
                    *ngIf="showDKBoxeTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Rinkos RM: </strong>{{rinkos_rm_}}
                        </p>
                        <p>
                            <strong>Rinkos HK: </strong>{{rinkos_hk_}}
                        </p>
                        <p>
                            <strong>Spike Buy: </strong>{{rinkos_spike_buy_}}
                        </p>
                        <p>
                            <strong>Spike Sell: </strong>{{rinkos_spike_sell_}}
                        </p>
                        <p>
                            <strong>Rinkos Supply: </strong>{{rinkos_supply_}}
                        </p>
                    </div>
                </div> -->
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showDemandSupplyTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Candle Type :</strong> {{demand_supply_candel_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showRejectionTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Rejected Candle Type :</strong> {{rejected_candle_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showMoveTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Move Value :</strong> {{move_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBodyMoveTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Body Move:</strong> {{body_move_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showCandelTrendsTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Candel Trends :</strong> {{candel_trends_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showDPUPTrendDirectionTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>DPUP Trend Direction :</strong> {{dpup_trend_direction_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showNextCloseTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Next Close :</strong> {{next_close_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTopWickSignalTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Top Wick Aggregate :</strong> {{top_wick_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigRaiseDropTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Big Drop :</strong> {{big_drop_}}
                        </p>
                        <p>
                            <strong>Big Raise :</strong> {{big_raise_}}
                        </p>
                        <p>
                            <strong>Big Drop Price:</strong> {{big_drop_price_}}
                        </p>
                        <p>
                            <strong>Big Raise Price:</strong> {{big_raise_price_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBlackDotUpPressureTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Black Dot Up Pressure :</strong> {{black_dot_up_ressure_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBlackDotPressureU10Tooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Black Dot Pressure U10 :</strong> {{black_dot_pressure_u10_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showAskBidVolumeTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Bid Volume :</strong> {{ask_bid_volume_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showAskBidVolumeBTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Bid Volume B :</strong> {{ask_bid_volume_b_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showAskBidCountTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Count :</strong> {{ask_count_}}
                        </p>
                        <p>
                            <strong>Bid Count :</strong> {{bid_count_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showScriptTimeTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Time Taken:</strong> {{script_time_}} mins
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showDP1Tooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p *ngIf="DP1_ > 0 || DP1_ < 0">
                            <strong>DP1 :</strong> {{DP1_}} %
                        </p>
                        <p *ngIf="UP1_ > 0 || UP1_ < 0">
                            <strong>UP1 :</strong> {{UP1_}} %
                        </p>
                        <p *ngIf="DP1_ > 0 || DP1_ < 0">
                            <strong>DP1 Perc :</strong> {{DP1_perc_}}
                        </p>
                        <p *ngIf="UP1_ > 0 || UP1_ < 0">
                            <strong>UP1 Perc :</strong> {{UP1_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showDP2Tooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p *ngIf="DP2_ > 0 || DP2_ < 0">
                            <strong>DP2 :</strong> {{DP2_}} %
                        </p>
                        <p *ngIf="UP2_ > 0 || UP2_ < 0">
                            <strong>UP2 :</strong> {{UP2_}} %
                        </p>
                        <p *ngIf="DP2_ > 0 || DP2_ < 0">
                            <strong>DP2 Perc :</strong> {{DP2_perc_}}
                        </p>
                        <p *ngIf="UP2_ > 0 || UP2_ < 0">
                            <strong>UP2 Perc :</strong> {{UP2_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showDP3Tooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p *ngIf="DP3_ > 0 || DP3_ < 0">
                            <strong>DP3 :</strong> {{DP3_}} %
                        </p>
                        <p *ngIf="UP3_ > 0 || UP3_ < 0">
                            <strong>UP3 :</strong> {{UP3_}} %
                        </p>
                        <p *ngIf="DP3_ > 0 || DP3_ < 0">
                            <strong>DP3 Perc :</strong> {{DP3_perc_}}
                        </p>
                        <p *ngIf="UP3_ > 0 || UP3_ < 0">
                            <strong>UP3 Perc :</strong> {{UP3_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showCoinMetaCountTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Coin Meta Count:</strong> {{coin_meta_count_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showVolumeComparisonTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Volume Comparison:</strong> {{volume_comparison_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBlackwallPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>BlackWall Perc :</strong> {{black_wall_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showSevenlevelPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>SevenLevel Perc :</strong> {{seven_level_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showSevenlevelMinVSTPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>SevenLevelMin VST Perc :</strong> {{seven_level_min_vst_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigcontractsPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Big Contracts Perc :</strong> {{big_contracts_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigBuyercontractsPercSTTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>LBBCTST:</strong> {{big_buyer_contracts_perc_st_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigBuyercontractsPercVSTTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>LBBCTLT:</strong> {{big_buyer_contracts_perc_vst_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigSellercontractsPercSTTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>LBSCTST:</strong> {{big_seller_contracts_perc_st_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigSellercontractsPercVSTTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>LBSCTLT:</strong> {{big_seller_contracts_perc_vst_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigBuyerSellercontractsCountTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Buyers Count:</strong> {{big_buyer_contracts_count_}}
                        </p>
                        <p>
                            <strong>Sellers Count:</strong> {{big_seller_contracts_count_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigBuyerSellercontractsVolTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Buyers Vol:</strong> {{big_buyer_contracts_vol_}}
                        </p>
                        <p>
                            <strong>Sellers Vol:</strong> {{big_seller_contracts_vol_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigBuyerSellerDeltaTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Hourly Big Contractor Delta:</strong> {{big_buyer_seller_delta_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigBuyerSellerDiffTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Hourly Big Contractor Diff:</strong> {{big_buyer_seller_diff_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showCandlePercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Candle Perc :</strong> {{candle_perc_}}
                        </p>
                    </div>
                </div>
                 <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                     *ngIf="showCandleSTPercTooltip && popUpOnCandle">
                     <div class="tootip-item">
                         <p>
                             <strong>Candle ST Perc :</strong> {{candle_st_perc_}}
                         </p>
                     </div>
                 </div>
                 <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                     *ngIf="showCandleVSTPercTooltip && popUpOnCandle">
                     <div class="tootip-item">
                         <p>
                             <strong>Candle VST Perc :</strong> {{candle_vst_perc_}}
                         </p>
                     </div>
                 </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTwoWayBarTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Volume :</strong> {{ask_volume_}}
                        </p>
                        <p>
                            <strong>Bid Volume :</strong> {{bid_volume_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showUpperwickPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>UpperWick Perc :</strong> {{upper_wick_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showUpperwickSTPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>UpperWick ST Perc :</strong> {{upper_wick_st_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showUpperwickVSTPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>UpperWick VST Perc :</strong> {{upper_wick_vst_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showLowerwickPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>LowerWick Perc :</strong> {{lower_wick_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showLowerwickSTPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>LowerWick ST Perc :</strong> {{lower_wick_st_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showLowerwickVSTPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>LowerWick VST Perc :</strong> {{lower_wick_vst_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBodyPercTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Body Perc :</strong> {{body_perc_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showSevenlevelMinMaxTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Seven Level Max :</strong> {{sevenlevel_max_}}
                        </p>
                        <p>
                            <strong>Seven Level Min :</strong> {{sevenlevel_min_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBlackwallMinMaxTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Black Wall Max :</strong> {{blackwall_max_}}
                        </p>
                        <p>
                            <strong>Black Wall Min :</strong> {{blackwall_min_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBlackwallAvgTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Black Wall Avg :</strong> {{black_wall_avg_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showSevenlevelAvgTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Seven Level Avg :</strong> {{seven_level_avg_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showSevenlevelAvgVSTTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Seven Level VST :</strong> {{seven_level_avg_vst_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showSevenlevelAvgSTTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Seven Level ST :</strong> {{seven_level_avg_st_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigcontractsAvgTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Big Contracts Avg :</strong> {{big_contracts_avg_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBigDeltaTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Big Delta :</strong> {{big_delta_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTelegramTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Positive Sentiment :</strong> {{tele_positive_sen_}}
                        </p>
                        <p>
                            <strong>Negative Sentiment :</strong> {{tele_negative_sen_}}
                        </p>
                        <p>
                            <strong>Neutral Sentiment :</strong> {{tele_neutral_sen_}}
                        </p>
                        <p>
                            <strong>Total Sentiment :</strong> {{tele_total_sen_}}
                        </p>
                    </div>
                </div>


                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTelegramITPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Telegram IT Perc :</strong> {{telegram_perc_it_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTelegramSTPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Telegram ST Perc :</strong> {{telegram_perc_st_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTelegramVSTPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Telegram VST Perc :</strong> {{telegram_perc_vst_}}
                        </p>
                    </div>
                </div>


                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTwitterTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Positive Sentiment :</strong> {{twit_positive_sen_}}
                        </p>
                        <p>
                            <strong>Negative Sentiment :</strong> {{twit_negative_sen_}}
                        </p>
                        <p>
                            <strong>Neutral Sentiment :</strong> {{twit_neutral_sen_}}
                        </p>
                        <p>
                            <strong>Total Sentiment :</strong> {{twit_total_sen_}}
                        </p>
                    </div>
                </div>



                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTwitterITPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Twitter IT Perc :</strong> {{twitter_perc_it_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTwitterSTPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Twitter ST Perc :</strong> {{twitter_perc_st_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showTwitterVSTPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Twitter VST Perc :</strong> {{twitter_perc_vst_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showNewsTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Positive Sentiment :</strong> {{news_positive_sen_}}
                        </p>
                        <p>
                            <strong>Negative Sentiment :</strong> {{news_negative_sen_}}
                        </p>
                        <p>
                            <strong>Neutral Sentiment :</strong> {{news_neutral_sen_}}
                        </p>
                        <p>
                            <strong>Total Sentiment :</strong> {{news_total_sen_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showNewsITPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>News IT Perc :</strong> {{news_perc_it_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showNewsSTPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>News ST Perc :</strong> {{news_perc_st_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showNewsVSTPerctooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>News VST Perc :</strong> {{news_perc_vst_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showT1Tooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>T1 Max:</strong> {{t1_max_}}
                        </p>
                        <p>
                            <strong>T1 Min:</strong> {{t1_min_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showT2Tooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>T2 Max:</strong> {{t2_max_}}
                        </p>
                        <p>
                            <strong>T2 Min:</strong> {{t2_min_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showT4Tooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>T4 Max:</strong> {{t4_max_}}
                        </p>
                        <p>
                            <strong>T4 Min:</strong> {{t4_min_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showAskBidDeltaTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Bid Delta:</strong> {{ask_bid_delta_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showAskBidDeltaBTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Bid Delta B :</strong> {{ask_bid_delta_b_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showBidDeltaTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Bid Division:</strong> {{bid_delta_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showAskBidDivisionBTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>Ask Bid Division B:</strong> {{ask_bid_division_b_}}
                        </p>
                    </div>
                </div>
                <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                    *ngIf="showT2BCPTooltip && popUpOnCandle">
                    <div class="tootip-item">
                        <p>
                            <strong>T2BCP Max :</strong> {{t2bcp_max_}}
                        </p>
                        <p>
                            <strong>T2BCP Min :</strong> {{t2bcp_min_}}
                        </p>
                    </div>
                </div>

            </div>
            <!-- Main Body End -->
        </div>
    </div>
</div>
