import { Role } from "./role";

export class User {
    id: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: Role;
    token?: string;
    email_address: string;
    timezone:string;
    application_mode:string;
    special_role:string;
}