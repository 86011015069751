import { Injectable } from '@angular/core';
import { Router, CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      // private adminRoutes: any = ['add-manual-order', 'add-auto-order'],
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authenticationService.currentUserValue;
      if (currentUser) {
          // check if route is restricted by role
          if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
              // role not authorised so redirect to home page
              this.router.navigate(['/']);
              return false;
          }

          // //Check User Role and Access rights //Umer Abbas [20-11-19]
          // let allowedRoles: any = ((typeof route.data.roles != 'undefined' && route.data.roles instanceof Array) ? route.data.roles : []);
          // let userRole = currentUser.role;
          // let specialRole = currentUser.special_role;
          let accessRights = ((localStorage.user_access_rights) ? localStorage.user_access_rights : []);

          let curr_path = state.url;
          let tempArr = curr_path.split('/');
          curr_path = '/'+tempArr[1];
          // // if(currentUser.username == 'umer.abbas' && allowedRoles.includes(userRole)){
          // if(accessRights.includes(curr_path) && allowedRoles.includes(userRole)){
          if(accessRights.includes(curr_path)){
            // alert('umer is authorized to '+curr_path);
            // authorized so return true
            return true;
          }else{
            // unauthorized
            return false;
          }

          // return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
  }
}
