
    <!-- Candle Sticks Start-->
    <div class="chartuix-outer"[ngClass]="{
        FullWidthPanel: maximiz
    }">
        <div class="chartuix">
            <div class="chartuix-tool-toggle">
                <div class="cuix-tt-icon cuix-tt-show" *ngIf="!chartTool" (click)="chartTool_func()">
                    <i-feather name="Maximize-2" title="Show"></i-feather>
                </div>
                <div class="cuix-tt-icon cuix-tt-hide" *ngIf="chartTool" (click)="chartTool_func()">
                    <i-feather name="Minimize-2" title="Hide"></i-feather>
                </div>
            </div>
            <div class="chartuix-inner">
                <div class="chartuix-head" *ngIf="chartTool">
                    <div class="chartuix-head-inner">
                      <div class="chartuix-tool-title">
                          <h2>CandleStick</h2>
                      </div>
                      <ng-container *ngFor="let coin of userCoinsArr">
                        <ng-container *ngIf="coin.symbol == currentCoin">
                          <!-- <div class="chartuix-tool-item">
                              <i-feather name="Maximize" title="Maximize" (click)="maximize()"></i-feather>
                          </div>
                          <div class="chartuix-tool-item">
                              <i-feather name="Minimize" title="Minimize" (click)="minimize()"></i-feather>
                          </div> -->

                          <!-- <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))"> -->
                            <div class="chartuix-tool-item" (click)="changeBackgroundcolor()" [ngClass]="{'active3':showWhitebackground == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Tehmina', 'Hassan', 'Wasiq']" title="Color Changed to White">
                                <i-feather name="battery-charging" ></i-feather>
                            </div>
                          <!-- </ng-container> -->
                          <!-- <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))"> -->
                            <div class="chartuix-tool-item" (click)="changeBackgroundcolor1()" [ngClass]="{'active3':showTradingViewbackground == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Tehmina', 'Hassan', 'Wasiq']" title="Color Changed to White">
                                <i-feather name="battery" ></i-feather>
                            </div>
                          <!-- </ng-container> -->
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" (click)="showCircle_func_buy()" [ngClass]="{'active1':showBuyCircle == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="Buy Percentile Circle">
                                <i-feather name="Circle" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item"  (click)="showCircle_func_sell()" [ngClass]="{'active2':showSellCircle == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="Sell Percentile Circle">
                                <i-feather name="Circle" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item"  (click)="showCircle_func_big()" [ngClass]="{'active':showBigCircle == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="Big Contract Circle">
                                <i-feather name="Circle" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item"  (click)="showCircle_func_buy_level()" [ngClass]="{'active3':showBuyPriceCircle == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="Buy Level Circle">
                                <i-feather name="Circle" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item"  (click)="showCircle_func_sell_level()" [ngClass]="{'active4':showSellPriceCircle == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="Sell Level Circle">
                                <i-feather name="Circle" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item"  (click)="showCircle_func_stop_loss()" [ngClass]="{'active5':showStopLossCircle == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="Stop Loss Circle">
                                <i-feather name="Circle" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                            <div class="chartuix-tool-item" (click)="showSwingLines_func()" [ngClass]="{'active6':showSwingLines == true }" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']" title="Swing Lines">
                                <i-feather name="Minus" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" (click)="showWallLines_func()" [ngClass]="{'active7':showWallLines == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="Wall Lines">
                                <i-feather name="Minus" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" (click)="showRangeLines_func()" [ngClass]="{'active8':showRangesLines == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Rabi', 'Ali', 'Wasiq']" title="Ranges">
                                <i-feather name="Minus" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" (click)="showT1Lines_func()" [ngClass]="{'active9':showT1Lines == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="T1 COT">
                                <i-feather name="Minus" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" (click)="showT2Lines_func()" [ngClass]="{'active10':showT2Lines == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="T2 COT">
                                <i-feather name="Minus" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" (click)="showT4Lines_func()" [ngClass]="{'active11':showT4Lines == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="T4 COT">
                                <i-feather name="Minus" ></i-feather>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" (click)="showT2BCP15Lines_func()" [ngClass]="{'active12':showT2BCP15Lines == true }" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" title="T2BCP15">
                                <i-feather name="Minus" ></i-feather>
                            </div>
                          </ng-container>
                          <!-- <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))"> -->
                            <div class="chartuix-tool-item">
                                <h6 style="font-size: 11px; padding-top: 6px; margin: 0; color: #ffc800;">From <span style="color: #ffffff; margin: 0 5px;">{{startdate}} </span>To<span style="color: #ffffff; margin: 0 5px;"> {{enddate}}</span></h6>
                            </div>
                          <!-- </ng-container> -->
                          <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                            <div class="chartuix-tool-item" *ngIf="showBuyCircle || showSellCircle">
                                <label class="mr-1" style="color: #F6F6F6; margin: -3px;">B/S Radius: {{Factor}}</label>
                                <input type="range" min="1" max="10" [(ngModel)]="Factor" (change)="changearrayValue()">
                            </div>
                          </ng-container>
                            <!-- <div class="chartuix-tool-item" *ngIf="showRangesLines">
                                <label class="mr-1" style="color: #F6F6F6; margin: -3px;">Base: {{Base}}</label>
                                <input type="range" min="1" max="5000" [(ngModel)]="Base" (change)="changearrayValue()">
                            </div> -->
                        </ng-container>
                      </ng-container>
                    </div>
                </div>
                <div class="chartuix-body">
                    <div class="chartuix-body-panel">
                        <div class="canvas_candle">
                            <!-- <canvas id="myCanvas" #canvas style=" background-color: rgba(0, 0,0, 0.5);" [ngStyle]="{'background-color':color === 'black' ? 'orange' : 'red' }"></canvas> -->
                            <canvas id="myCanvas" #canvas [ngStyle]="{'background-color':color === 'black' ? 'rgba(0, 0,0, 0.5)' : color === 'trading' ? '#222B39'  : 'rgba(255, 255, 255)' }"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Candle Sticks End-->

