import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { MainChartService } from '../../main-chart.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-candlepercentile',
  templateUrl: './candlepercentile.component.html',
  styleUrls: []
})
export class CandlepercentileComponent implements OnInit {
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private c: CanvasRenderingContext2D;
  cnd_array;
  general_array;
  total_cnd;
  first_cnd;
  starting_index;
  ending_index;
  increment;
  decrement;

  coordinateArray;
  candleperc_start_point;
  candleperc_end_point;
  candleperc_initial_point;
  candleperc_final_point;
  Height;
  Width;
  c_Height;
  c_Width;
  graph_height;
  graph_width;
  pline_difference;
  price_length;
  tline_difference;
  time_length;
  y_from_top = 5;
  y_from_bottom = 5;
  x_from_left = 100;
  x_from_right = 20;
  selectedDate;
  number;


  constructor(
    private service: MainChartService,
    private router: Router
  ) { }


  counter = 0;
  chartTool: boolean = false;
  ngOnInit() {

    var url = window.location.href;
    var location = decodeURI(url);
    var new_url = new URL(location);
    var date_param = new_url.searchParams.get("date");
    var tradetime_param = new_url.searchParams.get("tradetime");
    var tradeprice_param = new_url.searchParams.get("tradeprice");

    this.number = (this.router.url).includes("date");
    if (this.number == true) {
      this.selectedDate = date_param;
    }


    var url = this.router.url
    this.c = this.canvas.nativeElement.getContext('2d');
    var n = url.includes("dailychart");
    if(n == true){
      this.service.dailyChartData.subscribe(data => {
        this.cnd_array = data;
      })
    }
    else{
      this.service.chartData.subscribe(data => {
        this.cnd_array = data
      });
    }

    this.service.candleperccoordData.subscribe(data => {
      this.coordinateArray = data
    });
    this.total_cnd = this.cnd_array.length;
    this.first_cnd = this.cnd_array[0];

    this.service.canvasData.subscribe(data => {
      this.general_array = data;
      if (this.counter > 0) {
        this.rander()
      }
    });
    if (this.counter == 0) {
      this.rander();
      this.sidebar_visibility_f();
    }
  }
  chartTool_func() {
    this.chartTool = !this.chartTool;
  }
  rander() {
    this.getWidth();
    this.getHeight();
    this.c_Height = this.Height;
    this.c_Width = this.Width
    this.price_length = this.general_array[0].price_length;
    this.time_length = this.general_array[0].time_length;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;
    this.renderCharts();
  }
  getHeight() {
    this.Height = $(".canvas_candlepercentile").height();
  }
  getWidth() {
    this.Width = $(".canvas_candlepercentile").width();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.rander();
    });
  }






  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations       //////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////


  renderCharts() {
    setTimeout(() => {
      this.setCanvasHeightWidth();
      this.priceLineDifference(this.c_Height);
      this.timeLineDifference(this.c_Width);
      this.drawGraph();
      this.counter = 1;
    }, 1);
  }
  priceLineDifference(can_h) {
    var total_graph_height = can_h - this.y_from_bottom - this.y_from_top;
    this.graph_height = total_graph_height;
    this.pline_difference = (total_graph_height / this.price_length);
  }
  timeLineDifference(can_w) {
    var total_graph_width = can_w - this.x_from_left - this.x_from_right;
    this.graph_width = total_graph_width;
    this.tline_difference = total_graph_width / this.time_length;
  }
  setCanvasHeightWidth() {
    this.canvas.nativeElement.height = this.Height;
    this.canvas.nativeElement.width = this.Width;
  }


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations End       //////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////






  ////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  ////////////      Graph      ///////////////////
  /////////////////////////////////////////////////////////
  //////////////////////////////////////////////////
  ////////////////////////////////////////////

  drawGraph() {
    this.drawPriceLine_Tag(this.c_Height);
    this.drawCandle(this.c_Height);
  }
  //All Bellow Functions are Call in Function drawGraph()

  drawPriceLine_Tag(can_h) {
    var y = can_h - this.y_from_bottom;
    var x = this.x_from_left;
    var t_graph_height = this.graph_height;// Total Graph Height
    var point_y_perc = t_graph_height / 100;
    var point_y = point_y_perc * 50;// calculation for Label Point Y
    var point_x_perc = this.x_from_left / 100;
    var point_x = point_x_perc * 75;
    var tag = 'Candle';
    var tag2 = 'Percentile';
    this.c.fillStyle = 'white';
    this.c.fillText(tag, x - (point_x), y - point_y);
    this.c.fillText(tag2, x - (point_x), y - point_y + 15);
  }

  //Draw Candle
  drawCandle(can_h) {
    var _w = 0;
    _w = this.tline_difference;
    var candle_width_perc = this.tline_difference / 100;
    var candle_l_off = 5 * candle_width_perc;
    var candle_r_off = 5 * candle_width_perc;
    var x = (this.x_from_left) + (+ (candle_l_off));
    var y = (can_h - this.y_from_bottom);
    var cnd_w = _w - (candle_l_off + candle_r_off);
    if ((this.time_length > (this.ending_index - this.starting_index)) && (this.time_length > this.cnd_array.length)) {
      this.ending_index = this.cnd_array.length;
    }
    if (this.ending_index > this.cnd_array.length) {
      this.ending_index = this.cnd_array.length;
    }
    this.coordinateArray = [];
    for (var i = this.starting_index; i < this.ending_index; i++) {
      let blackwallpercentile = this.cnd_array[i].total_volume_percentile;

      var val_one2 = parseInt(blackwallpercentile);
      var val_one = 0;
      var color = "#00f";


      if (val_one2 == 1) {
        val_one = 100;
        color = "#00f";
      } else if (val_one2 == 2) {
        val_one = 90;
        color = "#00f";
      } else if (val_one2 == 3) {
        val_one = 80;
        color = "#00f";
      } else if (val_one2 == 4) {
        val_one = 70;
        color = "#00f";
      }
      else if (val_one2 == 5) {
        val_one = 60;
        color = "#00f";
      } else if (val_one2 == 10) {
        val_one = 50;
        color = "#00f";
      }
      else if (val_one2 == 15) {
        val_one = 45;
        color = "#00f";
      }
      else if (val_one2 == 20) {
        val_one = 40;
        color = "#00f";
      }
      else if (val_one2 == 25) {
        val_one = 35;
        color = "#00f";
      }
      else if (val_one2 == 50) {
        val_one = 30;
        color = "#00f";
      }
      else if (val_one2 == 75) {
        val_one = 20;
        color = "#00f";
      }
      else if (val_one2 == 100) {
        val_one = 10;
        color = "#00f";
      }

       // const Datefromlocalstorage =(localStorage.getItem('Highlighted Candle'));
       var openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
       if(openTime_human_readible_ == this.selectedDate){
         this.c.fillStyle = 'rgb(47,79,79,0.3)';
         this.c.fillRect(x - candle_l_off, 0, cnd_w + candle_r_off, this.graph_height + this.y_from_bottom + this.y_from_top);
       }
      this.c.fillStyle = color;


      this.candleperc_start_point = x;
      this.candleperc_end_point = this.candleperc_start_point + cnd_w;
      this.candleperc_initial_point = y;
      this.candleperc_final_point = y - val_one;

      let candleperc_array_object = new Object();
      candleperc_array_object['x1'] = this.candleperc_start_point;
      candleperc_array_object['x2'] = this.candleperc_end_point;
      candleperc_array_object['y1'] = this.candleperc_initial_point;
      candleperc_array_object['y2'] = this.candleperc_final_point;

      this.coordinateArray.push(candleperc_array_object);

      this.c.fillRect(x, y - val_one, cnd_w, val_one);
      x += this.tline_difference;
    }
    this.changeValue();
  }
  //Draw Candle End
  changeValue() {
    var ArrayCandlePerc = this.coordinateArray
    this.service.new_candlepercData(ArrayCandlePerc);
  }
}
