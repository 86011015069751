import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { MainChartService } from '../main-chart.service';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from "../window.service";
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-daily-chart',
  templateUrl: './daily-chart.component.html',
  styleUrls: ['./daily-chart.component.css']
})
export class DailyChartComponent implements OnInit, OnDestroy {
  public subject:Subject<any> = new Subject();
  ngOnDestroy() {
    this.subject.next();
    this.subject.complete();
  }

  GLOBALCOIN: string = localStorage.getItem('GLOBALCOIN') != null ? localStorage.getItem('GLOBALCOIN') : 'BTCUSDT';
  // currentCoin: string = this.route.snapshot.paramMap.get('coin');
  currentCoin: string = 'BTCUSDT'
  dataLoaderWaiting: boolean = false;

  general_array;
  cnd_array;
  cnd_array_length;
  setting: any = [];
  setting_arr = new Object();
  DP1_
  DP2_
  DP3_
  UP1_
  UP2_
  UP3_
  DP1_perc_
  DP2_perc_
  DP3_perc_
  UP1_perc_
  UP2_perc_
  UP3_perc_
  open_;
  close_;
  high_;
  low_;
  time;
  volume_;
  openTime_human_readible_;
  closeTime_human_readible_;
  ask_;
  bid_;
  ask_b_;
  bid_b_;
  ask_count_;
  bid_count_;
  buy_;
  sell_;
  total_volume_;
  down_barrier_value_;
  up_barrier_value_;
  demandbasecandel_;
  supplybasecandel_;
  swing_;
  L1_buy_price_;
  L2_buy_price_;
  L3_buy_price_;
  L4_buy_price_;
  L5_buy_price_;
  L6_buy_price_;
  L7_buy_price_;
  L8_buy_price_;
  L9_buy_price_;
  L10_buy_price_;
  L11_buy_price_;
  L12_buy_price_;
  L13_buy_price_;
  L14_buy_price_;
  L15_buy_price_;
  L16_buy_price_;
  L17_buy_price_;
  L18_buy_price_;
  L19_buy_price_;
  L20_buy_price_;
  L1_sell_price_;
  L2_sell_price_;
  L3_sell_price_;
  L4_sell_price_;
  L5_sell_price_;
  L6_sell_price_;
  L7_sell_price_;
  L8_sell_price_;
  L9_sell_price_;
  L10_sell_price_;
  L11_sell_price_;
  L12_sell_price_;
  L13_sell_price_;
  L14_sell_price_;
  L15_sell_price_;
  L16_sell_price_;
  L17_sell_price_;
  L18_sell_price_;
  L19_sell_price_;
  L20_sell_price_;
  seven_level_perc_;
  seven_level_min_vst_perc_;
  black_wall_perc_;
  big_contracts_perc_;
  big_buyer_contracts_perc_st_;
  big_buyer_contracts_perc_vst_;
  big_seller_contracts_perc_st_;
  big_seller_contracts_perc_vst_;
  big_buyer_contracts_count_;
  big_seller_contracts_count_;
  big_buyer_contracts_vol_;
  big_buyer_seller_delta_;
  big_buyer_seller_diff_;
  big_seller_contracts_vol_;
  candle_perc_;
  candle_st_perc_;
  candle_vst_perc_;
  upper_wick_perc_;
  upper_wick_st_perc_;
  upper_wick_vst_perc_;
  lower_wick_perc_;
  lower_wick_st_perc_;
  lower_wick_vst_perc_;
  body_perc_;
  black_wall_avg_;
  seven_level_avg_;
  seven_level_avg_vst_;
  seven_level_avg_st_;
  big_contracts_avg_;
  big_delta_;
  demand_supply_candel_;
  rejected_candle_;
  move_;
  body_move_;
  top_wick_;
  candel_trends_;
  dpup_trend_direction_;
  next_close_;
  big_drop_;
  big_raise_;
  big_drop_price_;
  big_raise_price_;
  black_dot_up_ressure_;
  black_dot_pressure_u10_;
  ask_bid_volume_;
  ask_bid_volume_b_;
  ask_bid_delta_b_;
  ask_bid_division_b_;
  coin_meta_count_;
  volume_comparison_;
  tele_positive_sen_;
  tele_negative_sen_;
  tele_neutral_sen_;
  tele_total_sen_;
  telegram_perc_it_;
  telegram_perc_st_;
  telegram_perc_vst_;
  twit_positive_sen_;
  twit_negative_sen_;
  twit_neutral_sen_;
  twit_total_sen_;
  twitter_perc_it_;
  twitter_perc_st_;
  twitter_perc_vst_;
  news_positive_sen_;
  news_negative_sen_;
  news_neutral_sen_;
  news_total_sen_;
  news_perc_it_;
  news_perc_st_;
  news_perc_vst_;
  ask_volume_;
  bid_volume_;
  sevenlevel_max_;
  sevenlevel_min_;
  blackwall_max_;
  blackwall_min_;
  script_time_;
  t1_max_;
  t1_min_;
  t2_max_;
  t2_min_;
  t4_max_;
  t4_min_;
  rinkos_rm_;
  rinkos_hk_;
  rinkos_spike_buy_;
  rinkos_spike_sell_;
  rinkos_supply_;
  top_wick_prices_;
  ask_bid_delta_;
  bid_delta_;
  t2bcp_max_;
  t2bcp_min_;
  time_length;
  price_length;
  starting_index;
  ending_index;
  increment: number;
  decrement: number;
  search_date: string;
  candleCoordArray = [];
  truelevelCoordArray = [];
  dkboxCoordArray = [];
  demandsupplyCoordArray = [];
  rejectionCoordArray = [];
  moveCoordArray = [];
  bodymoveCoordArray = [];
  topwicksignalCoordArray = [];
  candeltrendsCoordArray = [];
  dpuptrenddirectionCoordArray = [];
  next_closeCoordArray = [];
  dp1CoordArray = [];
  dp2CoordArray = [];
  dp3CoordArray = [];
  increasingvolumeCoordArray = [];
  blackdotuppressureCoordArray = [];
  blackdotpressureu10CoordArray = [];
  askbidvolumeCoordArray = [];
  askbidvolumebCoordArray = [];
  askbidcountCoordArray = [];
  coinmetacountCoordArray = [];
  volumecomparisonCoordArray = [];
  sevenlevelPercCoordArray = [];
  sevenlevelMinVSTPercCoordArray = [];
  blackwallPercCoordArray = [];
  bigcontractsPercCoordArray = [];
  bigBuyercontractsPercSTCoordArray = [];
  bigBuyercontractsPercVSTCoordArray = [];
  bigSellercontractsPercSTCoordArray = [];
  bigSellercontractsPercVSTCoordArray = [];
  bigBuyerSellercontractsCountCoordArray = [];
  bigBuyerSellercontractsVolCoordArray = [];
  bigBuyerSellerDeltaCoordArray = [];
  bigBuyerSellerDiffCoordArray = [];
  scripttimeCoordArray = [];
  candlePercCoordArray = [];
  candleSTPercCoordArray = [];
  candleVSTPercCoordArray = [];
  twowaybarCoordArray = [];
  blackwallAverageCoordArray = [];
  sevenlevelAverageCoordArray = [];
  sevenlevelAverageSTCoordArray = [];
  sevenlevelAverageVSTCoordArray = [];
  bigcontractsAverageCoordArray = [];
  upperwickPercCoordArray = [];
  upperwickSTPercCoordArray = [];
  upperwickVSTPercCoordArray = [];
  lowerwickPercentileCoordArray = [];
  lowerwickSTPercentileCoordArray = [];
  lowerwickVSTPercentileCoordArray = [];
  bodyPercentileCoordArray = [];
  sevenlevelMinMaxCoordArray = [];
  blackwallMinMaxCoordArray = [];
  bigdeltaCoordArray = [];
  telegramsentimentCoordArray = [];
  telegramITPercentileCoordArray = [];
  telegramSTPercentileCoordArray = [];
  telegramVSTPercentileCoordArray = [];
  twittersentimentCoordArray = [];
  twitterITPercentileCoordArray = [];
  twitterSTPercentileCoordArray = [];
  twitterVSTPercentileCoordArray = [];
  newssentimentCoordArray = [];
  newsITPercentileCoordArray = [];
  newsSTPercentileCoordArray = [];
  newsVSTPercentileCoordArray = [];
  t1CoordArray = [];
  t2CoordArray = [];
  t4CoordArray = [];
  askBidDeltaCoordArray = [];
  askBidDeltabCoordArray = [];
  bidDeltaCoordArray = [];
  askbiddivisionbCoordArray = [];
  t2bcpCoordArray = [];
  lineArray = [];
  pricelineArray = [];
  s_width: number = 0;
  topheader_height;
  maincharthead_height;
  componenthead_height;
  tooltip_height;
  candlestick_height = 0;
  truelevel_height = 0;
  dkboxes_height = 0;
  demandsupply_height = 0;
  rejection_height = 0;
  move_height = 0;
  top_wick_height = 0;
  big_raise_drop_height = 0;
  black_dot_up_pressure_height = 0;
  black_dot_pressure_u10_height = 0;
  ask_bid_volume_height = 0;
  coin_meta_count_height = 0;
  volume_comparison_height = 0;
  blackwallperc_height = 0;
  sevenlevelperc_height = 0;
  sevenlevelminvstperc_height = 0;
  bigcontractsperc_height = 0;
  bigbuyercontractspercst_height = 0;
  bigbuyercontractspercvst_height = 0;
  bigsellercontractspercst_height = 0;
  bigsellercontractspercvst_height = 0;
  bigbuyersellercontractscount_height = 0;
  bigbuyersellercontractsvol_height = 0;
  candleperc_height = 0;
  candlestperc_height = 0;
  candlevstperc_height = 0;
  twowaybars_height = 0;
  blackwallavg_height = 0;
  sevenlevelavg_height = 0;
  sevenlevelavgvst_height = 0;
  sevenlevelavgst_height = 0;
  bigcontractsavg_height = 0;
  upperwick_height = 0;
  upperwickst_height = 0;
  upperwickvst_height = 0;
  lowerwick_height = 0;
  lowerwickst_height = 0;
  lowerwickvst_height = 0;
  bodyperc_height = 0;
  sevenlevelminmax_height = 0;
  blackwallminmax_height = 0;
  bigdelta_height = 0;
  telegram_height = 0;
  telegramit_height = 0;
  telegramst_height = 0;
  telegramvst_height = 0;
  twitter_height = 0;
  twitterit_height = 0;
  twitterst_height = 0;
  twittervst_height = 0;
  news_height = 0;
  newsit_height = 0;
  newsst_height = 0;
  newsvst_height = 0;
  t1cot_height = 0;
  t2cot_height = 0;
  t4cot_height = 0;
  askbiddelta_height = 0;
  biddelta_height = 0;
  t2bcp_height = 0;
  ondown_x = 0;
  onup_x = 0;
  x_diff;
  top_coord;
  left_coord;
  tooltip_coord;
  scrollTop = 0;
  scroll;
  before_length: any = null;
  after_length: any = null;
  before_candle_width: any = null;
  after_candle_width: any = null;
  ondown_starting;
  ondown_ending;
  ondown_maxprice;
  onup_maxprice;
  ondown_minprice;
  onup_minprice;
  price;
  onenter_maxprice;
  onenter_minprice;
  onkeyup_maxprice;
  onkeyup_minprice;
  onkeydown_maxprice;
  onkeydown_minprice;
  onovercand_maxprice;
  onovercand_minprice;
  showTooltip: boolean = false;
  showDemandSupplyTooltip: boolean = false;
  showRejectionTooltip: boolean = false;
  showMoveTooltip: boolean = false;
  showBodyMoveTooltip: boolean = false;
  showTopWickSignalTooltip: boolean = false;
  showCandelTrendsTooltip: boolean = false;
  showDPUPTrendDirectionTooltip: boolean = false;
  showNextCloseTooltip: boolean = false;
  showBigRaiseDropTooltip: boolean = false;
  showBlackDotUpPressureTooltip: boolean = false;
  showBlackDotPressureU10Tooltip: boolean = false;
  showDP1Tooltip: boolean = false;
  showDP2Tooltip: boolean = false;
  showDP3Tooltip: boolean = false;
  showAskBidVolumeTooltip: boolean = false;
  showAskBidVolumeBTooltip: boolean = false;
  showAskBidCountTooltip: boolean = false;
  showCoinMetaCountTooltip: boolean = false;
  showVolumeComparisonTooltip: boolean = false;
  showScriptTimeTooltip: boolean = false;
  showTruelevelTooltip: boolean = false;
  showDKBoxeTooltip: boolean = false;
  showSevenlevelPercTooltip: boolean = false;
  showSevenlevelMinVSTPercTooltip: boolean = false;
  showBlackwallPercTooltip: boolean = false;
  showBigcontractsPercTooltip: boolean = false;
  showBigBuyercontractsPercSTTooltip: boolean = false;
  showBigBuyercontractsPercVSTTooltip: boolean = false;
  showBigSellercontractsPercSTTooltip: boolean = false;
  showBigSellercontractsPercVSTTooltip: boolean = false;
  showBigBuyerSellercontractsCountTooltip: boolean = false;
  showBigBuyerSellercontractsVolTooltip: boolean = false;
  showBigBuyerSellerDiffTooltip: boolean = false;
  showBigBuyerSellerDeltaTooltip: boolean = false;
  showCandlePercTooltip: boolean = false;
  showCandleSTPercTooltip: boolean = false;
  showCandleVSTPercTooltip: boolean = false;
  showTwoWayBarTooltip: boolean = false;
  showBlackwallAvgTooltip: boolean = false;
  showSevenlevelAvgTooltip: boolean = false;
  showSevenlevelAvgSTTooltip: boolean = false;
  showSevenlevelAvgVSTTooltip: boolean = false;
  showBigcontractsAvgTooltip: boolean = false;
  showUpperwickPercTooltip: boolean = false;
  showUpperwickSTPercTooltip: boolean = false;
  showUpperwickVSTPercTooltip: boolean = false;
  showLowerwickPercTooltip: boolean = false;
  showLowerwickSTPercTooltip: boolean = false;
  showLowerwickVSTPercTooltip: boolean = false;
  showBodyPercTooltip: boolean = false;
  showSevenlevelMinMaxTooltip: boolean = false;
  showBlackwallMinMaxTooltip: boolean = false;
  showBigDeltaTooltip: boolean = false;
  showTelegramTooltip: boolean = false;
  showTelegramITPerctooltip: boolean = false;
  showTelegramSTPerctooltip: boolean = false;
  showTelegramVSTPerctooltip: boolean = false;
  showTwitterTooltip: boolean = false;
  showTwitterITPerctooltip: boolean = false;
  showTwitterSTPerctooltip: boolean = false;
  showTwitterVSTPerctooltip: boolean = false;
  showNewsTooltip: boolean = false;
  showNewsITPerctooltip: boolean = false;
  showNewsSTPerctooltip: boolean = false;
  showNewsVSTPerctooltip: boolean = false;
  showT1Tooltip: boolean = false;
  showT2Tooltip: boolean = false;
  showT4Tooltip: boolean = false;
  showAskBidDeltaTooltip: boolean = false;
  showAskBidDeltaBTooltip: boolean = false;
  showBidDeltaTooltip: boolean = false;
  showAskBidDivisionBTooltip: boolean = false;
  showT2BCPTooltip: boolean = false;
  show_forward: boolean = true;
  show_backward: boolean = true;
  showCandleSticks: boolean = true;
  showVolumeText: boolean = false;
  showTrueLevels: boolean = false;
  showBlackWallPerc: boolean = false;
  showSevenLevelPerc: boolean = false;
  showSevenLevelMinVSTPerc: boolean = false;
  showBigContractsPerc: boolean = false;
  showBigBuyerContractsPercST: boolean = false;
  showBigBuyerContractsPercVST: boolean = false;
  showBigSellerContractsPercST: boolean = false;
  showBigSellerContractsPercVST: boolean = false;
  showBigBuyerSellerContractsCount: boolean = false;
  showBigBuyerSellerContractsVol: boolean = false;
  showBigBuyerSellerDelta: boolean = false;
  showBigBuyerSellerDiff: boolean = false;
  showTwoWayBar: boolean = false;
  showCandlePerc: boolean = false;
  showCandleSTPerc: boolean = false;
  showCandleVSTPerc: boolean = false;
  showScore: boolean = false;
  showSevenLevel: boolean = false;
  showSevenLevelST: boolean = false;
  showSevenLevelVST: boolean = false;
  showBottomChart: boolean = false;
  showCandleTwo: boolean = false
  showBlackWallAverg: boolean = false;
  showBigContractsAverg: boolean = false;
  showUpperWick: boolean = false;
  showUpperWickST: boolean = false;
  showUpperWickVST: boolean = false;
  showLowerWick: boolean = false;
  showLowerWickST: boolean = false;
  showLowerWickVST: boolean = false;
  showBodyPercentile: boolean = false;
  showDemandSupply: boolean = false;
  showRejection: boolean = false;
  showMove: boolean = false;
  showBodyMove: boolean = false;
  showTopWickSignal: boolean = false;
  showCandelTrends: boolean = false;
  showDPUPTrendDirection: boolean = false;
  showNextClose: boolean = false;
  showIncreasingVolume: boolean = false;
  showBlackDotUpPressure: boolean = false;
  showBlackDotPressureU10: boolean = false;
  showDP: boolean = false;
  showAskBidVolume: boolean = false;
  showAskBidVolumeB: boolean = false;
  showAskBidCount: boolean = false;
  showCoinMetaCount: boolean = false;
  showVolumeComparison: boolean = false;
  showScriptTime: boolean = false;
  showSevenLevelMinMax: boolean = false;
  showBlackWallMinMax: boolean = false;
  showT3MinMax: boolean = false;
  showT2MinMax: boolean = false;
  showT4MinMax: boolean = false;
  showBigContracts: boolean = false;
  showBigDelta: boolean = false;
  showTelegramSentiment: boolean = false;
  showTelegramITPercentile: boolean = false;
  showTelegramSTPercentile: boolean = false;
  showTelegramVSTPercentile: boolean = false;
  showTwitterSentiment: boolean = false;
  showTwitterITPercentile: boolean = false;
  showTwitterSTPercentile: boolean = false;
  showTwitterVSTPercentile: boolean = false;
  showNewsSentiment: boolean = false;
  showNewsITPercentile: boolean = false;
  showNewsSTPercentile: boolean = false;
  showNewsVSTPercentile: boolean = false;
  showBoxes: boolean = false;
  showAskBidDelta: boolean = false;
  showAskBidDeltaB: boolean = false;
  showBidDelta: boolean = false;
  showAskBidDivisionB: boolean = false;
  showT2BCP: boolean = false;
  popUpOnCandle: boolean = false;
  popUpOnCandleStick: boolean = false;
  candleScroller: boolean = false;
  filter_all: boolean = false;
  filter_1: boolean = false;
  show_all_chart: boolean = false;
  showSevellevelChart: boolean = false;
  filter_2: boolean = false;
  filter_3: boolean = false;
  filter_4: boolean = false;
  filter_5: boolean = false;
  filter_6: boolean = false;
  filter_7: boolean = false;
  showPriceLine: boolean = false;
  ChartMenuData = [];
  x_c_gap = 20;
  y_c_gap = 20;
  deduction = 20;
  coord = 0;
  role;
  adminrole = 'admin';
  userrole = 'user';
  developerrole = 'developer';
  number;
  href;
  showtoadmin: boolean = false;
  showtoabbas: boolean = false;
  showtouser: boolean = false;
  showtodeveloper: boolean = false;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////              Variables End           ///////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private service: MainChartService,
    private route: ActivatedRoute,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {
    this.route.params.subscribe(val => {
      this.ngOnInit();
    });
  }


  //ng OnInit Start
  mouseWheelUpFunc() {
    // console.log('upward')
    if (this.candleScroller) {
      if (this.time_length < 169) {
        this.time_length = this.time_length + 4;
        this.lineArray = [];
        if (this.time_length > 168) {
          this.time_length = 168;
          this.starting_index = 0;
          this.ending_index = 168;
        }
        this.changearrayValue();
      }
    }
  }

  mouseWheelDownFunc() {
    // console.log('downward')
    if (this.candleScroller) {
      if (this.time_length >= 27) {
        this.time_length = this.time_length - 4;
        this.lineArray = [];
        this.changearrayValue();
      }
    }
  }
  async ngOnInit() {
    const perm = JSON.parse(localStorage.getItem('loggedUserName'));
    this.permissionsService.loadPermissions([perm]);




    var url = window.location.href;
    var location = decodeURI(url);
    var new_url = new URL(location);
    var date_param = new_url.searchParams.get("date");
    this.number = (this.router.url).includes("date");
    if (this.number == true) {
      var date = date_param;
      const selecteddate = date;
      var utcDate2 = new Date(selecteddate);
      utcDate2.setDate(utcDate2.getDate()-3);
      this.href = (utcDate2.getFullYear() + '-' + (utcDate2.getMonth() + 1) + '-' + utcDate2.getDate() + ' ' + utcDate2.getHours() +':'+'00'+':'+ '00');
    } else {
      this.href = "";
      if(localStorage.getItem('fwd1_date')){
        this.href = localStorage.getItem('fwd1_date')
      }
    }



    /* Current Coin */
    if (!localStorage.getItem('loggedUserRole')) {
      localStorage.removeItem('currentUserNew');
      window.location.reload();
    } else {
      this.role = JSON.parse(localStorage.getItem('loggedUserRole'));
      // console.log(this.role);
    }


    if (this.role == this.adminrole) {
      this.showtoadmin = true;
      this.showtouser = false;
      this.showtodeveloper = false;
    } else if (this.role == this.userrole) {
      this.showtoadmin = false;
      this.showtouser = true;
      this.showtodeveloper = false;
    } else if (this.role == this.developerrole) {
      this.showtoadmin = false;
      this.showtouser = false;
      this.showtodeveloper = true;
    }
    this.currentCoin = this.route.snapshot.paramMap.get('coin');
    if (this.currentCoin == null) {
      this.currentCoin = "BTCUSDT";
    }
    /* Reload Page... */
    if (!localStorage.getItem('refresh_flag')) {
      localStorage.setItem('refresh_flag', 'true');
      localStorage.setItem('currentCoin', this.currentCoin);
      window.location.reload();
    }

    this.dataLoaderWaiting = true
    this.showCandleSticks = false;
    this.hide_all_filters();
    this.service.canvasData.subscribe(data => {
      this.general_array = data;
    });
    let back_date = "";
    let fwd_date = this.href;
    if(this.href == ''){
      if(localStorage.getItem('back1_date')){
        back_date = localStorage.getItem('back1_date')
      }
    }
    // await this.service.getDailyChartData(this.currentCoin, back_date, fwd_date).subscribe(data => {
      var data =  await this.service.getDailyChartData(this.currentCoin, back_date, fwd_date);
      this.cnd_array = data;
      console.log('Array', this.cnd_array)
      this.time_length = this.general_array[0].time_length;
      this.price_length = this.general_array[0].price_length;
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.cnd_array_length = this.cnd_array.length;
      this.increment = this.general_array[0].increment;
      this.decrement = this.general_array[0].decrement;

      this.dataLoaderWaiting = false
      if (localStorage.getItem('setting_arrr_daily_chart')) {
        this.getcomponentstate();
      } else {
        this.popUpOnCandle = true;
        this.popUpOnCandleStick = true;
        this.candleScroller = false;
        this.showCandleSticks = true;
        this.showTrueLevels = true;
        this.hide_all_filters();
        this.setcomponentstate();
      }
    // });
    // service for candlesticks
    this.service.candlestickcoordData.subscribe(data => {
      this.candleCoordArray = data;
    });
    // service for truelevels
    this.service.truelevelcoordData.subscribe(data => {
      this.truelevelCoordArray = data;
    });
    // service for dkboxes
    this.service.dkboxcoordData.subscribe(data => {
      this.dkboxCoordArray = data;
    });
    //service for demand supply
    this.service.demandsupplycoordData.subscribe(data => {
      this.demandsupplyCoordArray = data;
    });
    //service for rejection
    this.service.rejectioncoordData.subscribe(data => {
      this.rejectionCoordArray = data;
    });
    //service for move
    this.service.movecoordData.subscribe(data => {
      this.moveCoordArray = data;
    });
    //service for body move
    this.service.bodymovecoordData.subscribe(data => {
      this.bodymoveCoordArray = data;
    });
    //service for topwick
    this.service.topwicksignalcoordData.subscribe(data => {
      this.topwicksignalCoordArray = data;
    });
    //service for candel trends
    this.service.candeltrendscoordData.subscribe(data => {
      this.candeltrendsCoordArray = data;
    });
    //service for dpuptrenddirection
    this.service.dpuptrenddirectioncoordData.subscribe(data => {
      this.dpuptrenddirectionCoordArray = data;
    });
    //service for next_close
    this.service.next_closecoordData.subscribe(data => {
      this.next_closeCoordArray = data;
    });
    //service for dp1
    this.service.dp_up1coordData.subscribe(data => {
      this.dp1CoordArray = data
    });
    //service for dp2
    this.service.dp_up2coordData.subscribe(data => {
      this.dp2CoordArray = data;
    });
    //service for dp3
    this.service.dp_up3coordData.subscribe(data => {
      this.dp3CoordArray = data;
    });
    //service for raise drop
    this.service.increasingcoordData.subscribe(data => {
      this.increasingvolumeCoordArray = data;
    });
    //service for black dot up pressure
    this.service.blackdotupcoordData.subscribe(data => {
      this.blackdotuppressureCoordArray = data;
    });
    //service for black dot pressure u10
    this.service.blackdotpressure_u10coordData.subscribe(data => {
      this.blackdotpressureu10CoordArray = data;
    });
    //service for ask bid volume
    this.service.askbidvolumecoordData.subscribe(data => {
      this.askbidvolumeCoordArray = data;
    });
    //service for ask bid volumeb
    this.service.askbidvolumebcoordData.subscribe(data => {
      this.askbidvolumebCoordArray = data;
    });
    //service for ask bid count
    this.service.askbidcountcoordData.subscribe(data => {
      this.askbidcountCoordArray = data;
    });
    //service for coinmetacount
    this.service.coinmetacountcoordData.subscribe(data => {
      this.coinmetacountCoordArray = data;
    });
    //service for volume comparison
    this.service.volumecomparisoncoordData.subscribe(data => {
      this.volumecomparisonCoordArray = data;
    });
    //service for script time
    this.service.scripttimecoordData.subscribe(data => {
      this.scripttimeCoordArray = data;
    });
    // service for black wall percentile
    this.service.blackwallperccoordData.subscribe(data => {
      this.blackwallPercCoordArray = data;
    });
    //service for seven level Percentile
    this.service.sevenlevelperccoordData.subscribe(data => {
      this.sevenlevelPercCoordArray = data;
    });
    //service for seven level min VST Percentile
    this.service.sevenlevelminvstperccoordData.subscribe(data => {
      this.sevenlevelMinVSTPercCoordArray = data;
    });
    //service for bigcontracts percentile
    this.service.bigcontractsperccoordData.subscribe(data => {
      this.bigcontractsPercCoordArray = data;
    });
    //service for bigbuyercontracts percentile vst
    this.service.bigbuyercontractspercvstcoordData.subscribe(data => {
      this.bigBuyercontractsPercVSTCoordArray = data;
    });
    //service for bigbuyercontracts percentile st
    this.service.bigbuyercontractspercstcoordData.subscribe(data => {
      this.bigBuyercontractsPercSTCoordArray = data;
    });
    //service for bigsellercontracts percentile vst
    this.service.bigsellercontractspercvstcoordData.subscribe(data => {
      this.bigSellercontractsPercVSTCoordArray = data;
    });
    //service for bigsellercontracts percentile st
    this.service.bigsellercontractspercstcoordData.subscribe(data => {
      this.bigSellercontractsPercSTCoordArray = data;
    });
    //service for bigsellercontracts Count
    this.service.bigbuyersellercontractscountcoordData.subscribe(data => {
      this.bigBuyerSellercontractsCountCoordArray = data;
    });
    //service for bigsellercontracts Vol
    this.service.bigbuyersellercontractsvolcoordData.subscribe(data => {
      this.bigBuyerSellercontractsVolCoordArray = data;
    });
    //service for bigsellercontracts Delta
    this.service.bigbuyersellerdeltacoordData.subscribe(data => {
      this.bigBuyerSellerDeltaCoordArray = data;
    });
    //service for bigsellercontracts Diff
    this.service.bigbuyersellerdiffcoordData.subscribe(data => {
      this.bigBuyerSellerDiffCoordArray = data;
    });
    //service for candle percentile
    this.service.candleperccoordData.subscribe(data => {
      this.candlePercCoordArray = data;
    });
    //service for candle ST percentile
    this.service.candlestperccoordData.subscribe(data => {
      this.candleSTPercCoordArray = data;
    });
    //service for candle VST percentile
    this.service.candlevstperccoordData.subscribe(data => {
      this.candleVSTPercCoordArray = data;
    });
    //service for Two Way Bar
    this.service.twowaybarccoordData.subscribe(data => {
      this.twowaybarCoordArray = data;
    });
    //service for Blackwall average
    this.service.blackwallavgcoordData.subscribe(data => {
      this.blackwallAverageCoordArray = data;
    });
    //service for sevenlevel average
    this.service.sevenlevelavgcoordData.subscribe(data => {
      this.sevenlevelAverageCoordArray = data;
    });
    //service for sevenlevel average vst
    this.service.sevenlevelavgvstcoordData.subscribe(data => {
      this.sevenlevelAverageVSTCoordArray = data;
    });
    //service for sevenlevel average st
    this.service.sevenlevelavgstcoordData.subscribe(data => {
      this.sevenlevelAverageSTCoordArray = data;
    });
    //service for bigcontracts average
    this.service.bigcontractsavgcoordData.subscribe(data => {
      this.bigcontractsAverageCoordArray = data;
    });
    //service for upperwick percentile
    this.service.upperwickperccoordData.subscribe(data => {
      this.upperwickPercCoordArray = data;
    });
    //service for upperwick st percentile
    this.service.upperwickstperccoordData.subscribe(data => {
      this.upperwickSTPercCoordArray = data;
    });
    //service for upperwick vst percentile
    this.service.upperwickvstperccoordData.subscribe(data => {
      this.upperwickVSTPercCoordArray = data;
    });
    //service for lowerwick percentile
    this.service.lowerwickperccoordData.subscribe(data => {
      this.lowerwickPercentileCoordArray = data;
    });
    //service for lowerwick st percentile
    this.service.lowerwickstperccoordData.subscribe(data => {
      this.lowerwickSTPercentileCoordArray = data;
    });
    //service for lowerwick vst percentile
    this.service.lowerwickvstperccoordData.subscribe(data => {
      this.lowerwickVSTPercentileCoordArray = data;
    });
    //service for Body percentile
    this.service.bodyperccoordData.subscribe(data => {
      this.bodyPercentileCoordArray = data;
    });
    //service for SevenLevel MinMax
    this.service.sevenlevelminmaxcoordData.subscribe(data => {
      this.sevenlevelMinMaxCoordArray = data;
    });
    //service for Blackwall MinMax
    this.service.blackwallminmaxcoordData.subscribe(data => {
      this.blackwallMinMaxCoordArray = data;
    });
    //service for bigdelta
    this.service.bigdeltacoordData.subscribe(data => {
      this.bigdeltaCoordArray = data;
    });
    //service for telegram
    this.service.telegramcoordData.subscribe(data => {
      this.telegramsentimentCoordArray = data;
    });
    //service for telegramit
    this.service.telegramitperccoordData.subscribe(data => {
      this.telegramITPercentileCoordArray = data;
    });
    //service for telegramst
    this.service.telegramstperccoordData.subscribe(data => {
      this.telegramSTPercentileCoordArray = data;
    });
    //service for telegramvst
    this.service.telegramvstperccoordData.subscribe(data => {
      this.telegramVSTPercentileCoordArray = data;
    });
    //service for twitter
    this.service.twittercoordData.subscribe(data => {
      this.twittersentimentCoordArray = data;
    });
    //service for twitterit
    this.service.twitteritperccoordData.subscribe(data => {
      this.twitterITPercentileCoordArray = data;
    });
    //service for twitterst
    this.service.twitterstperccoordData.subscribe(data => {
      this.twitterSTPercentileCoordArray = data;
    });
    //service for twittervst
    this.service.twittervstperccoordData.subscribe(data => {
      this.twitterVSTPercentileCoordArray = data;
    });
    //service for news
    this.service.newscoordData.subscribe(data => {
      this.newssentimentCoordArray = data;
    });
    //service for newsit
    this.service.newsitperccoordData.subscribe(data => {
      this.newsITPercentileCoordArray = data;
    });
    //service for newsst
    this.service.newsstperccoordData.subscribe(data => {
      this.newsSTPercentileCoordArray = data;
    });
    //service for newsvst
    this.service.newsvstperccoordData.subscribe(data => {
      this.newsVSTPercentileCoordArray = data;
    });
    //service for t1
    this.service.t1coordData.subscribe(data => {
      this.t1CoordArray = data;
    });
    //service for t2
    this.service.t2coordData.subscribe(data => {
      this.t2CoordArray = data;
    });
    //service for t4
    this.service.t4coordData.subscribe(data => {
      this.t4CoordArray = data;
    });
    //service for askbiddelta
    this.service.askbiddeltacoordData.subscribe(data => {
      this.askBidDeltaCoordArray = data;
    });
    //service for askbiddeltab
    this.service.askbiddeltabcoordData.subscribe(data => {
      this.askBidDeltabCoordArray = data;
    });
    //service for biddelta
    this.service.biddeltacoordData.subscribe(data => {
      this.bidDeltaCoordArray = data;
    });
    //service for askbiddivisionb
    this.service.askbiddivisionbcoordData.subscribe(data => {
      this.askbiddivisionbCoordArray = data;
    });
    //service for t2bcpaskbidvol
    this.service.t2bcpaskbidvolcoordData.subscribe(data => {
      this.t2bcpCoordArray = data;
    });
  }//OnInit End here




  async show_backward_c() {
    this.setcomponentstate();
    this.dataLoaderWaiting = true
    this.hide_all_filters();
    this.showCandleSticks = false;
    this.service.canvasData.subscribe(data => {
      this.general_array = data;
    });
    let back_date = this.cnd_array[0].openTime_human_readible;
    let fwd_date = "";
    localStorage.setItem('back1_date',back_date)
    var data = this.service.getDailyChartData(this.currentCoin, back_date, fwd_date);
      this.cnd_array = data;
      this.time_length = this.general_array[0].time_length;
      this.price_length = this.general_array[0].price_length;
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.cnd_array_length = this.cnd_array.length;
      this.increment = this.general_array[0].increment;
      this.decrement = this.general_array[0].decrement;

      this.dataLoaderWaiting = false;
      if (localStorage.getItem('setting_arrr_daily_chart')) {

        this.getcomponentstate();
      } else {
        this.showCandleSticks = true;
        this.hide_all_filters();
      }
  }
  async show_forward_f() {
    this.setcomponentstate();
    this.dataLoaderWaiting = true
    this.hide_all_filters();
    this.showCandleSticks = false;
    this.service.canvasData.subscribe(data => {
      this.general_array = data;
    });
    let back_date = "";
    let fwd_date = this.cnd_array[this.cnd_array.length - 1].openTime_human_readible;
    localStorage.setItem('fwd1_date',fwd_date)
    var data = await this.service.getDailyChartData(this.currentCoin, back_date, fwd_date);
      this.cnd_array = data;
      this.time_length = this.general_array[0].time_length;
      this.price_length = this.general_array[0].price_length;
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.cnd_array_length = this.cnd_array.length;
      this.increment = this.general_array[0].increment;
      this.decrement = this.general_array[0].decrement;


      this.dataLoaderWaiting = false

      if (localStorage.getItem('setting_arrr_daily_chart')) {

        this.getcomponentstate();
      } else {
        this.showCandleSticks = true;
        this.hide_all_filters();
      }
  }
  async date_search_candle() {
    this.setcomponentstate();
    this.dataLoaderWaiting = true
    this.hide_all_filters();
    this.showCandleSticks = false;
    this.service.canvasData.subscribe(data => {
      this.general_array = data;
    });
    let back_date = "";
    let fwd_date = this.search_date;
    localStorage.setItem('fwd1_date',fwd_date);
    var data = await this.service.getDailyChartData(this.currentCoin, back_date, fwd_date);
      this.cnd_array = data;
      this.time_length = this.general_array[0].time_length;
      this.price_length = this.general_array[0].price_length;
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.cnd_array_length = this.cnd_array.length;
      this.increment = this.general_array[0].increment;
      this.decrement = this.general_array[0].decrement;

      this.dataLoaderWaiting = false

      if (localStorage.getItem('setting_arrr_daily_chart')) {

        this.getcomponentstate();
      } else {
        this.showCandleSticks = true;
        this.hide_all_filters();
      }
  }

  hide_all_filters() {
    this.showTrueLevels = false;
    this.showBlackWallPerc = false;
    this.showSevenLevelPerc = false;
    this.showSevenLevelMinVSTPerc = false;
    this.showBigContractsPerc = false;
    this.showBigBuyerContractsPercST = false;
    this.showBigBuyerContractsPercVST = false;
    this.showBigSellerContractsPercST = false;
    this.showBigSellerContractsPercVST = false;
    this.showBigBuyerSellerContractsCount = false;
    this.showBigBuyerSellerContractsVol = false;
    this.showBigBuyerSellerDelta = false;
    this.showBigBuyerSellerDiff = false;
    this.showTwoWayBar = false;
    this.showCandlePerc = false;
    this.showCandleSTPerc = false;
    this.showCandleVSTPerc = false;
    this.showScore = false;
    this.showSevenLevel = false;
    this.showSevenLevelST = false;
    this.showSevenLevelVST = false;
    this.showBottomChart = false;
    this.showCandleTwo = false
    this.showBlackWallAverg = false;
    this.showBigContractsAverg = false;
    this.showUpperWick = false;
    this.showUpperWickST = false;
    this.showUpperWickVST = false;
    this.showLowerWick = false;
    this.showLowerWickST = false;
    this.showLowerWickVST = false;
    this.showBodyPercentile = false;
    this.showDemandSupply = false;
    this.showRejection = false;
    this.showMove = false;
    this.showBodyMove = false;
    this.showTopWickSignal = false;
    this.showCandelTrends = false;
    this.showDPUPTrendDirection = false;
    this.showIncreasingVolume = false;
    this.showScriptTime = false;
    this.showNextClose = false;
    this.showAskBidCount = false;
    this.showBlackDotUpPressure = false;
    this.showBlackDotPressureU10 = false;
    this.showAskBidVolume = false;
    this.showAskBidVolumeB = false;
    this.showCoinMetaCount = false;
    this.showVolumeComparison = false;
    this.showSevenLevelMinMax = false;
    this.showBlackWallMinMax = false;
    this.showT3MinMax = false;
    this.showT2MinMax = false;
    this.showT4MinMax = false;
    this.showBigContracts = false;
    this.showBigDelta = false;
    this.showTelegramSentiment = false;
    this.showTelegramITPercentile = false;
    this.showTelegramSTPercentile = false;
    this.showTelegramVSTPercentile = false;
    this.showTwitterSentiment = false;
    this.showTwitterITPercentile = false;
    this.showTwitterSTPercentile = false;
    this.showTwitterVSTPercentile = false;
    this.showNewsSentiment = false;
    this.showNewsITPercentile = false;
    this.showNewsSTPercentile = false;
    this.showNewsVSTPercentile = false;
    this.showBoxes = false;
    this.showAskBidDelta = false;
    this.showAskBidDeltaB = false;
    this.showBidDelta = false;
    this.showAskBidDivisionB = false;
    this.showT2BCP = false;
    this.showDP = false;

  }
  show_all_charts() {
    this.showCandleSticks = true;
    // this.showVolumeText = true;
    this.showTrueLevels = true;
    this.showBlackWallPerc = true;
    this.showSevenLevelPerc = true;
    this.showSevenLevelMinVSTPerc = true;
    this.showBigContractsPerc = true;
    this.showBigBuyerContractsPercST = true;
    this.showBigBuyerContractsPercVST = true;
    this.showBigSellerContractsPercST = true;
    this.showBigSellerContractsPercVST = true;
    this.showBigBuyerSellerContractsCount = true;
    this.showBigBuyerSellerContractsVol = true;
    this.showBigBuyerSellerDelta = true;
    this.showBigBuyerSellerDiff = true;
    this.showTwoWayBar = true;
    this.showCandlePerc = true;
    this.showCandleSTPerc = true;
    this.showCandleVSTPerc = true;
    // this.showScore = true;
    this.showSevenLevel = true;
    this.showSevenLevelST = true;
    this.showSevenLevelVST = true;
    // this.showBottomChart = true;
    // this.showCandleTwo = true
    this.showBlackWallAverg = true;
    this.showBigContractsAverg = true;
    this.showUpperWick = true;
    this.showUpperWickST = true;
    this.showUpperWickVST = true;
    this.showLowerWick = true;
    this.showLowerWickST = true;
    this.showLowerWickVST = true;
    this.showBodyPercentile = true;
    this.showDemandSupply = true;
    this.showRejection = true;
    this.showMove = true
    this.showTopWickSignal = true;
    this.showCandelTrends = true;
    this.showDPUPTrendDirection = true;
    this.showIncreasingVolume = true;
    this.showBlackDotUpPressure = true;
    this.showBlackDotPressureU10 = true;
    this.showAskBidVolume = true;
    this.showAskBidVolumeB = true;
    this.showCoinMetaCount = true;
    this.showVolumeComparison = true;
    this.showSevenLevelMinMax = true;
    this.showBlackWallMinMax = true;
    this.showT3MinMax = true;
    this.showT2MinMax = true;
    this.showT4MinMax = true;
    // this.showBigContracts = true;
    this.showBigDelta = true;
    this.showTelegramSentiment = true;
    this.showTelegramITPercentile = true;
    this.showTelegramSTPercentile = true;
    this.showTelegramVSTPercentile = true;
    this.showTwitterSentiment = true;
    this.showTwitterITPercentile = true;
    this.showTwitterSTPercentile = true;
    this.showTwitterVSTPercentile = true;
    this.showNewsSentiment = true;
    this.showNewsITPercentile = true;
    this.showNewsSTPercentile = true;
    this.showNewsVSTPercentile = true;
    this.showBoxes = true;
    this.showBidDelta = true;
    this.showAskBidDivisionB = true;
    this.showAskBidDelta = true;
    this.showAskBidDeltaB = true;
    this.showT2BCP = true;
    this.showDP = true;
    this.setcomponentstate();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////              Update Value in General Array           //////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  changearrayValue() {
    if (this.general_array[0].time_length != this.time_length) {
      if (this.time_length > this.ending_index) {
        this.ending_index = this.time_length;
      } else {
        this.ending_index = this.ending_index;
      }
      this.starting_index = this.ending_index - this.time_length;
      if (this.starting_index < 0) {
        this.starting_index = 0;
      }
      this.general_array[0].start_index = this.starting_index;
      this.general_array[0].end_index = this.ending_index;
      this.general_array[0].time_length = this.time_length;
    }
    if (this.general_array[0].price_length != this.price_length) {
      this.general_array[0].price_length = this.price_length;
    }
    if (this.general_array[0].start_index != this.starting_index) {
      this.general_array[0].start_index = this.starting_index;
    }
    if (this.general_array[0].end_index != this.ending_index) {
      this.general_array[0].end_index = this.ending_index;
    }
    this.setcomponentstate();
    this.service.new_OthArray(this.general_array);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////           Update Value in General Array End          //////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /* ********************************** */
  /*   Set Component Filter States              */
  /* ********************************** */
  setcomponentstate() {

    var setting = [];
    this.setting_arr = new Object();
    this.setting_arr['showtimelength'] = this.time_length;
    this.setting_arr['showendindex'] = this.ending_index;
    this.setting_arr['showstartindex'] = this.starting_index;
    this.setting_arr['showCandleSticks'] = this.showCandleSticks;
    // this.setting_arr['showVolumeText'] = this.showVolumeText;
    this.setting_arr['showTrueLevels'] = this.showTrueLevels;
    this.setting_arr['showBlackWallPerc'] = this.showBlackWallPerc;
    this.setting_arr['showSevenLevelPerc'] = this.showSevenLevelPerc;
    this.setting_arr['showSevenLevelMinVSTPerc'] = this.showSevenLevelMinVSTPerc;
    this.setting_arr['showBigContractsPerc'] = this.showBigContractsPerc;
    this.setting_arr['showBigBuyerContractsPercVST'] = this.showBigBuyerContractsPercVST;
    this.setting_arr['showBigSellerContractsPercVST'] = this.showBigSellerContractsPercVST;
    this.setting_arr['showBigBuyerSellerContractsCount'] = this.showBigBuyerSellerContractsCount;
    this.setting_arr['showBigBuyerSellerContractsVol'] = this.showBigBuyerSellerContractsVol;
    this.setting_arr['showBigBuyerSellerDelta'] = this.showBigBuyerSellerDelta;
    this.setting_arr['showBigBuyerSellerDiff'] = this.showBigBuyerSellerDiff;
    this.setting_arr['showBigBuyerContractsPercST'] = this.showBigBuyerContractsPercST;
    this.setting_arr['showBigSellerContractsPercST'] = this.showBigSellerContractsPercST;
    this.setting_arr['showTwoWayBar'] = this.showTwoWayBar;
    this.setting_arr['showCandlePerc'] = this.showCandlePerc;
    this.setting_arr['showCandleSTPerc'] = this.showCandleSTPerc;
    this.setting_arr['showCandleVSTPerc'] = this.showCandleVSTPerc;
    this.setting_arr['showScore'] = this.showScore;
    this.setting_arr['showSevenLevel'] = this.showSevenLevel;
    this.setting_arr['showSevenLevelVST'] = this.showSevenLevelVST;
    this.setting_arr['showSevenLevelST'] = this.showSevenLevelST;
    this.setting_arr['showBottomChart'] = this.showBottomChart;
    this.setting_arr['showCandleTwo'] = this.showCandleTwo
    this.setting_arr['showBlackWallAverg'] = this.showBlackWallAverg;
    this.setting_arr['showBigContractsAverg'] = this.showBigContractsAverg;
    this.setting_arr['showUpperWick'] = this.showUpperWick;
    this.setting_arr['showUpperWickST'] = this.showUpperWickST;
    this.setting_arr['showUpperWickVST'] = this.showUpperWickVST;
    this.setting_arr['showLowerWick'] = this.showLowerWick;
    this.setting_arr['showLowerWickST'] = this.showLowerWickST;
    this.setting_arr['showLowerWickVST'] = this.showLowerWickVST;
    this.setting_arr['showBodyPercentile'] = this.showBodyPercentile;
    this.setting_arr['showDemandSupply'] = this.showDemandSupply;
    this.setting_arr['showRejection'] = this.showRejection;
    this.setting_arr['showMove'] = this.showMove;
    this.setting_arr['showBodyMove'] = this.showBodyMove;
    this.setting_arr['showTopWickSignal'] = this.showTopWickSignal;
    this.setting_arr['showCandelTrends'] = this.showCandelTrends;
    this.setting_arr['showDPUPTrendDirection'] = this.showDPUPTrendDirection;
    this.setting_arr['showNextClose'] = this.showNextClose;
    this.setting_arr['showIncreasingVolume'] = this.showIncreasingVolume;
    this.setting_arr['showBlackDotUpPressure'] = this.showBlackDotUpPressure;
    this.setting_arr['showBlackDotPressureU10'] = this.showBlackDotPressureU10;
    this.setting_arr['showAskBidVolume'] = this.showAskBidVolume;
    this.setting_arr['showAskBidVolumeB'] = this.showAskBidVolumeB;
    this.setting_arr['showAskBidCount'] = this.showAskBidCount;
    this.setting_arr['showCoinMetaCount'] = this.showCoinMetaCount;
    this.setting_arr['showVolumeComparison'] = this.showVolumeComparison;
    this.setting_arr['showScriptTime'] = this.showScriptTime;
    this.setting_arr['showSevenLevelMinMax'] = this.showSevenLevelMinMax;
    this.setting_arr['showBlackWallMinMax'] = this.showBlackWallMinMax;
    this.setting_arr['showT3MinMax'] = this.showT3MinMax;
    this.setting_arr['showT2MinMax'] = this.showT2MinMax;
    this.setting_arr['showT4MinMax'] = this.showT4MinMax;
    this.setting_arr['showBigContracts'] = this.showBigContracts;
    this.setting_arr['showBigDelta'] = this.showBigDelta;
    this.setting_arr['showTelegramSentiment'] = this.showTelegramSentiment;
    this.setting_arr['showTwitterSentiment'] = this.showTwitterSentiment;
    this.setting_arr['showNewsSentiment'] = this.showNewsSentiment;
    this.setting_arr['showTelegramIT'] = this.showTelegramITPercentile;
    this.setting_arr['showTelegramST'] = this.showTelegramSTPercentile;
    this.setting_arr['showTelegramVST'] = this.showTelegramVSTPercentile;
    this.setting_arr['showTwitterIT'] = this.showTwitterITPercentile;
    this.setting_arr['showTwitterST'] = this.showTwitterSTPercentile;
    this.setting_arr['showTwitterVST'] = this.showTwitterVSTPercentile;
    this.setting_arr['showNewsIT'] = this.showNewsITPercentile;
    this.setting_arr['showNewsST'] = this.showNewsSTPercentile;
    this.setting_arr['showNewsVST'] = this.showNewsVSTPercentile;
    this.setting_arr['showBoxes'] = this.showBoxes;
    this.setting_arr['showAskBidDelta'] = this.showAskBidDelta;
    this.setting_arr['showAskBidDeltaB'] = this.showAskBidDeltaB;
    this.setting_arr['showBidDelta'] = this.showBidDelta;
    this.setting_arr['showAskBidDivisionB'] = this.showAskBidDivisionB;
    this.setting_arr['showT2BCP'] = this.showT2BCP;
    this.setting_arr['PopUpOnCandle'] = this.popUpOnCandle;
    this.setting_arr['popUpOnCandleStick'] = this.popUpOnCandleStick;
    this.setting_arr['candleScroller'] = this.candleScroller;
    this.setting_arr['showDP'] = this.showDP;
    this.setting_arr['filter_1'] = this.filter_1;
    setting[0] = this.setting_arr;
    if (typeof Storage != 'undefined') {
      localStorage.removeItem('setting_arrr_daily_chart');
      localStorage.setItem('setting_arrr_daily_chart', JSON.stringify(setting));
    }
  }
  /* ********************************** */
  /*   Get Component Filter States              */
  /* ********************************** */
  getcomponentstate() {
    this.setting_arr = JSON.parse(localStorage.getItem('setting_arrr_daily_chart'));
    // this.showCandleSticks= this.setting_arr[0].showCandleSticks;
    if ((this.setting_arr[0].showendindex > 0) && (this.setting_arr[0].showstartindex >= 0)) {
      this.time_length = this.setting_arr[0].showtimelength;
      this.ending_index = this.setting_arr[0].showendindex;
      this.starting_index = this.setting_arr[0].showstartindex;
    }
    this.showCandleSticks = true;
    // this.showVolumeText= this.setting_arr[0].showVolumeText ;
    this.showTrueLevels = this.setting_arr[0].showTrueLevels;
    this.showBlackWallPerc = this.setting_arr[0].showBlackWallPerc;
    this.showSevenLevelPerc = this.setting_arr[0].showSevenLevelPerc;
    this.showSevenLevelMinVSTPerc = this.setting_arr[0].showSevenLevelMinVSTPerc;
    this.showBigBuyerContractsPercVST = this.setting_arr[0].showBigBuyerContractsPercVST;
    this.showBigBuyerContractsPercST = this.setting_arr[0].showBigBuyerContractsPercST;
    this.showBigSellerContractsPercST = this.setting_arr[0].showBigSellerContractsPercST;
    this.showBigSellerContractsPercVST = this.setting_arr[0].showBigSellerContractsPercVST;
    this.showBigBuyerSellerContractsCount = this.setting_arr[0].showBigBuyerSellerContractsCount;
    this.showBigBuyerSellerContractsVol = this.setting_arr[0].showBigBuyerSellerContractsVol;
    this.showBigBuyerSellerDelta = this.setting_arr[0].showBigBuyerSellerDelta;
    this.showBigBuyerSellerDiff = this.setting_arr[0].showBigBuyerSellerDiff;
    this.showBigContractsPerc = this.setting_arr[0].showBigContractsPerc;
    this.showTwoWayBar = this.setting_arr[0].showTwoWayBar;
    this.showCandlePerc = this.setting_arr[0].showCandlePerc;
    this.showCandleSTPerc = this.setting_arr[0].showCandleSTPerc;
    this.showCandleVSTPerc = this.setting_arr[0].showCandleVSTPerc;
    this.showScore = this.setting_arr[0].showScore;
    this.showSevenLevel = this.setting_arr[0].showSevenLevel;
    this.showSevenLevelST = this.setting_arr[0].showSevenLevelST;
    this.showSevenLevelVST = this.setting_arr[0].showSevenLevelVST;
    this.showBottomChart = this.setting_arr[0].showBottomChart;
    this.showCandleTwo = this.setting_arr[0].showCandleTwo
    this.showBlackWallAverg = this.setting_arr[0].showBlackWallAverg;
    this.showBigContractsAverg = this.setting_arr[0].showBigContractsAverg;
    this.showUpperWick = this.setting_arr[0].showUpperWick;
    this.showUpperWickST = this.setting_arr[0].showUpperWickST;
    this.showUpperWickVST = this.setting_arr[0].showUpperWickVST;
    this.showLowerWick = this.setting_arr[0].showLowerWick;
    this.showLowerWickST = this.setting_arr[0].showLowerWickST;
    this.showLowerWickVST = this.setting_arr[0].showLowerWickVST;
    this.showBodyPercentile = this.setting_arr[0].showBodyPercentile;
    this.showDemandSupply = this.setting_arr[0].showDemandSupply;
    this.showRejection = this.setting_arr[0].showRejection;
    this.showMove = this.setting_arr[0].showMove;
    this.showBodyMove = this.setting_arr[0].showBodyMove;
    this.showNextClose = this.setting_arr[0].showNextClose;
    this.showTopWickSignal = this.setting_arr[0].showTopWickSignal;
    this.showCandelTrends = this.setting_arr[0].showCandelTrends;
    this.showDPUPTrendDirection = this.setting_arr[0].showDPUPTrendDirection;
    this.showIncreasingVolume = this.setting_arr[0].showIncreasingVolume;
    this.showBlackDotUpPressure = this.setting_arr[0].showBlackDotUpPressure;
    this.showBlackDotPressureU10 = this.setting_arr[0].showBlackDotPressureU10;
    this.showAskBidVolume = this.setting_arr[0].showAskBidVolume;
    this.showAskBidVolumeB = this.setting_arr[0].showAskBidVolumeB;
    this.showAskBidCount = this.setting_arr[0].showAskBidCount;
    this.showCoinMetaCount = this.setting_arr[0].showCoinMetaCount;
    this.showVolumeComparison = this.setting_arr[0].showVolumeComparison;
    this.showScriptTime = this.setting_arr[0].showScriptTime;
    this.showSevenLevelMinMax = this.setting_arr[0].showSevenLevelMinMax;
    this.showBlackWallMinMax = this.setting_arr[0].showBlackWallMinMax;
    this.showT3MinMax = this.setting_arr[0].showT3MinMax;
    this.showT2MinMax = this.setting_arr[0].showT2MinMax;
    this.showT4MinMax = this.setting_arr[0].showT4MinMax;
    this.showBigContracts = this.setting_arr[0].showBigContracts;
    this.showBigDelta = this.setting_arr[0].showBigDelta;
    this.showTelegramSentiment = this.setting_arr[0].showTelegramSentiment;
    this.showTwitterSentiment = this.setting_arr[0].showTwitterSentiment;
    this.showNewsSentiment = this.setting_arr[0].showNewsSentiment;





    this.showTelegramITPercentile = this.setting_arr[0].showTelegramIT;
    this.showTelegramSTPercentile = this.setting_arr[0].showTelegramST;
    this.showTelegramVSTPercentile = this.setting_arr[0].showTelegramVST;
    this.showTwitterITPercentile = this.setting_arr[0].showTwitterIT;
    this.showTwitterSTPercentile = this.setting_arr[0].showTwitterST;
    this.showTwitterVSTPercentile = this.setting_arr[0].showTwitterVST;
    this.showNewsITPercentile = this.setting_arr[0].showNewsIT;
    this.showNewsSTPercentile = this.setting_arr[0].showNewsST;
    this.showNewsVSTPercentile = this.setting_arr[0].showNewsVST;
    this.showAskBidDelta = this.setting_arr[0].showAskBidDelta;
    this.showAskBidDeltaB = this.setting_arr[0].showAskBidDeltaB;
    this.showBidDelta = this.setting_arr[0].showBidDelta;
    this.showAskBidDivisionB = this.setting_arr[0].showAskBidDivisionB;
    this.showT2BCP = this.setting_arr[0].showT2BCP;
    this.showBoxes = this.setting_arr[0].showBoxes;
    this.popUpOnCandle = this.setting_arr[0].PopUpOnCandle;
    this.popUpOnCandleStick = this.setting_arr[0].popUpOnCandleStick;
    this.candleScroller = this.setting_arr[0].candleScroller;
    this.showDP = this.setting_arr[0].showDP;
    this.filter_1 = typeof this.setting_arr[0].filter_1 == 'undefined' ? false : this.setting_arr[0].filter_1;
    this.changearrayValue();
  }
  RemovesettingLocalStorage() {
    localStorage.removeItem('setting_arrr_daily_chart');
    localStorage.removeItem('fwd1_date');
    localStorage.removeItem('back1_date');
    this.window.location.reload();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
  }
  divScroll(e) {
    this.scrollTop = e.srcElement.scrollTop;
  }
  onMouseScroll(e) {
    // console.log('scroll')

  }
  gettopHeight() {
    this.topheader_height = $(".top-header").height();
  }
  getHeight() {
    this.maincharthead_height = $(".main-chart-head").height();
  }
  gettooltipHeight() {
    this.tooltip_height = $(".tooltip-box").height();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.getWidth();
    });
  }
  getWidth() {
    this.s_width = 0;
    var sideBar: number = ($(".sidebar").width());
    if ($("body").hasClass('remove-sidebar')) {
      sideBar = 0;
    }
    this.s_width = this.s_width + sideBar;
  }
  clickcandlestickToogle() {
    $("body").on('click', '.cuix-tt-show', () => {
      this.getCandleStickHeadHeight();
    });
  }//when head shown
  clickcandlestickToogle1() {
    $("body").on('click', '.cuix-tt-icon', () => {
      this.getCandleStickHeadHeight();
    });
  }//when head not shown
  getCandleStickHeadHeight() {
    this.componenthead_height = 0;
    var height = $(".chartuix-head").height();
    var head_height = (typeof height == 'undefined') ? 0 : height;
    this.componenthead_height = this.componenthead_height + head_height;
  }



  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////   Filter Checks          ////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  filter_all_show_func() {
    this.show_all_chart = true;
    this.showSevellevelChart = true;
    this.filter_1 = true;
    this.filter_2 = true;
    this.filter_3 = true;
    this.filter_4 = true;
    this.filter_5 = true;
    this.filter_6 = true;
  }
  filter_all_hide_func() {
    this.filter_1 = false;
    this.show_all_chart = false;
    this.showSevellevelChart = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.setcomponentstate();
  }
  filter_1_func() {
    this.filter_1 = true;
    this.show_all_chart = true;
    this.showSevellevelChart = true;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.setcomponentstate();
  }
  filter_2_func() {
    this.filter_2 = true;
    this.filter_1 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
  }
  filter_3_func() {
    this.filter_3 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
  }
  filter_4_func() {
    this.filter_4 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_5 = false;
    this.filter_6 = false;
  }
  filter_5_func() {
    this.filter_5 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_6 = false;
  }
  filter_6_func() {
    this.filter_6 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////   Filter Checks  End        ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////   Un-Used Code Must be removed after some time         ////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  mouseEnterComponent(e) {
    this.showTooltip = false;
    this.showDemandSupplyTooltip = false;
    this.showRejectionTooltip = false;
    this.showMoveTooltip = false;
    this.showBodyMoveTooltip = false;
    this.showTopWickSignalTooltip = false;
    this.showCandelTrendsTooltip = false;
    this.showDPUPTrendDirectionTooltip = false;
    this.showNextCloseTooltip = false;
    this.showBigRaiseDropTooltip = false;
    this.showBlackDotUpPressureTooltip = false;
    this.showBlackDotPressureU10Tooltip = false;
    this.showAskBidVolumeTooltip = false;
    this.showAskBidVolumeBTooltip = false;
    this.showAskBidCountTooltip = false;
    this.showCoinMetaCountTooltip = false;
    this.showVolumeComparisonTooltip = false;
    this.showScriptTimeTooltip = false;
    this.showTruelevelTooltip = false;
    this.showDKBoxeTooltip = false;
    this.showSevenlevelPercTooltip = false;
    this.showSevenlevelMinVSTPercTooltip = false;
    this.showBlackwallPercTooltip = false;
    this.showBigcontractsPercTooltip = false;
    this.showBigBuyercontractsPercSTTooltip = false;
    this.showBigBuyercontractsPercVSTTooltip = false;
    this.showBigSellercontractsPercSTTooltip = false;
    this.showBigSellercontractsPercVSTTooltip = false;
    this.showBigBuyerSellercontractsCountTooltip = false;
    this.showBigBuyerSellercontractsVolTooltip = false;
    this.showBigBuyerSellerDiffTooltip = false;
    this.showBigBuyerSellerDeltaTooltip = false;
    this.showCandlePercTooltip = false;
    this.showCandleSTPercTooltip = false;
    this.showCandleVSTPercTooltip = false;
    this.showTwoWayBarTooltip = false;
    this.showBlackwallAvgTooltip = false;
    this.showSevenlevelAvgTooltip = false;
    this.showSevenlevelAvgSTTooltip = false;
    this.showSevenlevelAvgVSTTooltip = false;
    this.showBigcontractsAvgTooltip = false;
    this.showUpperwickPercTooltip = false;
    this.showUpperwickSTPercTooltip = false;
    this.showUpperwickVSTPercTooltip = false;
    this.showLowerwickPercTooltip = false;
    this.showLowerwickSTPercTooltip = false;
    this.showLowerwickVSTPercTooltip = false;
    this.showBodyPercTooltip = false;
    this.showSevenlevelMinMaxTooltip = false;
    this.showBlackwallMinMaxTooltip = false;
    this.showBigDeltaTooltip = false;
    this.showTelegramTooltip = false;
    this.showTelegramITPerctooltip = false;
    this.showTelegramSTPerctooltip = false;
    this.showTelegramVSTPerctooltip = false;
    this.showTwitterTooltip = false;
    this.showTwitterITPerctooltip = false;
    this.showTwitterSTPerctooltip = false;
    this.showTwitterVSTPerctooltip = false;
    this.showNewsTooltip = false;
    this.showNewsITPerctooltip = false;
    this.showNewsSTPerctooltip = false;
    this.showNewsVSTPerctooltip = false;
    this.showT1Tooltip = false;
    this.showT2Tooltip = false;
    this.showT4Tooltip = false;
    this.showAskBidDeltaTooltip = false;
    this.showAskBidDeltaBTooltip = false;
    this.showBidDeltaTooltip = false;
    this.showAskBidDivisionBTooltip = false;
    this.showT2BCPTooltip = false;
    this.showDP1Tooltip = false;
    this.showDP2Tooltip = false;
    this.showDP3Tooltip = false;
  }
  mouseMoveOnCandels(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".candlesticks-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    // Check only when candles are less than 168
    this.showPriceLine = true;

    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if(this.ending_index > this.cnd_array_length){
        this.ending_index = this.cnd_array_length;
      }
      if (this.candleCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.candleCoordArray[a].x1 && this.left_coord <= this.candleCoordArray[a].x2)) {
            if ((this.top_coord <= (this.candleCoordArray[a].y1 + (this.componenthead_height + 30)) && this.top_coord > (this.candleCoordArray[a].y2 + (this.componenthead_height + 30)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.close_ = parseFloat(this.cnd_array[i].close).toFixed(8);
              this.open_ = parseFloat(this.cnd_array[i].open).toFixed(8);
              this.high_ = parseFloat(this.cnd_array[i].high).toFixed(8);
              this.low_ = parseFloat(this.cnd_array[i].low).toFixed(8);
              this.volume_ = this.cnd_array[i].volume;
              this.ask_ = this.cnd_array[i].ask_volume;
              this.bid_ = this.cnd_array[i].bid_volume;
              this.total_volume_ = this.cnd_array[i].total_volume;
              this.down_barrier_value_ = this.cnd_array[i].down_barrier_value;
              this.up_barrier_value_ = this.cnd_array[i].up_barrier_value;
              this.ask_b_ = this.cnd_array[i].ask_volume_b;
              this.bid_b_ = this.cnd_array[i].bid_volume_b;
              // this.buy_ = this.cnd_array[i].buy_volume;
              // this.sell_ = this.cnd_array[i].sell_volume;
              var demandbase = this.cnd_array[i].demand_base_candel;
              if (demandbase == null) {
                this.demandbasecandel_ = "null";
              } else {
                this.demandbasecandel_ = demandbase;
              }
              var supplybase = this.cnd_array[i].supply_base_candel;
              if (supplybase == null) {
                this.supplybasecandel_ = "null";
              } else {
                this.supplybasecandel_ = supplybase;
              }
              this.swing_ = this.cnd_array[i].global_swing_status;
              // this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.closeTime_human_readible_ = this.cnd_array[i].closeTime_human_readible;
              this.top_wick_prices_ = this.cnd_array[i].top_wick_prices;
              this.showTooltip = true;
              // break;
            } else {
              this.showTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          } else {
            // console.log(this.showTooltip);
            this.showTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.candleCoordArray[a].x1 && this.left_coord <= this.candleCoordArray[a].x2)) {
            if ((this.top_coord <= this.candleCoordArray[a].y1 && this.top_coord > this.candleCoordArray[a].y2)) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.close_ = this.cnd_array[i].close;
              this.open_ = this.cnd_array[i].open;
              this.high_ = this.cnd_array[i].high;
              this.low_ = this.cnd_array[i].low;
              this.volume_ = this.cnd_array[i].volume;
              this.ask_ = this.cnd_array[i].ask_volume;
              this.bid_ = this.cnd_array[i].bid_volume;
              this.total_volume_ = this.cnd_array[i].total_volume;
              this.down_barrier_value_ = this.cnd_array[i].down_barrier_value;
              this.up_barrier_value_ = this.cnd_array[i].up_barrier_value;
              // this.buy_ = this.cnd_array[i].buy_volume;
              // this.sell_ = this.cnd_array[i].sell_volume;
              var demandbase = this.cnd_array[i].demand_base_candel;
              if (demandbase == null) {
                this.demandbasecandel_ = "null";
              } else {
                this.demandbasecandel_ = demandbase;
              }
              var supplybase = this.cnd_array[i].supply_base_candel;
              if (supplybase == null) {
                this.supplybasecandel_ = "null";
              } else {
                this.supplybasecandel_ = supplybase;
              }
              this.swing_ = this.cnd_array[i].global_swing_status;
              // this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.closeTime_human_readible_ = this.cnd_array[i].closeTime_human_readible;
              this.top_wick_prices_ = this.cnd_array[i].top_wick_prices;
              this.showTooltip = true;
              break;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          } else {
            this.showTooltip = false;
          }
        }
      }
    }
  // }
  }
  mouseMoveOnCandlePercentile(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".candlepercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var candlepercentiletop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.candlePercCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.candlePercCoordArray[a].x1 && this.left_coord <= this.candlePercCoordArray[a].x2) && (candlepercentiletop <= ((this.candlePercCoordArray[a].y1 + 4)) && candlepercentiletop > ((this.candlePercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.candle_perc_ = this.cnd_array[i].total_volume_percentile;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showCandlePercTooltip = true;
            break;
          } else {
            this.showCandlePercTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.candlePercCoordArray[a].x1 && this.left_coord <= this.candlePercCoordArray[a].x2) && (candlepercentiletop <= ((this.candlePercCoordArray[a].y1 + 4)) && candlepercentiletop > ((this.candlePercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.candle_perc_ = this.cnd_array[i].total_volume_percentile;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showCandlePercTooltip = true;
            break;
          } else {
            this.showCandlePercTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnSevenLevel(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".sevenlevel-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelavgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelAverageCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.sevenlevelAverageCoordArray[a].x1 && this.left_coord <= this.sevenlevelAverageCoordArray[a].x2) && (sevenlevelavgtop <= ((this.sevenlevelAverageCoordArray[a].y1 + 4)) && sevenlevelavgtop > ((this.sevenlevelAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_avg_ = parseFloat(this.cnd_array[i].seven_level_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelAvgTooltip = true;
            break;
          } else {
            this.showSevenlevelAvgTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.sevenlevelAverageCoordArray[a].x1 && this.left_coord <= this.sevenlevelAverageCoordArray[a].x2) && (sevenlevelavgtop <= ((this.sevenlevelAverageCoordArray[a].y1 + 4)) && sevenlevelavgtop > ((this.sevenlevelAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_avg_ = parseFloat(this.cnd_array[i].seven_level_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelAvgTooltip = true;
            break;
          } else {
            this.showSevenlevelAvgTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnSevenLevelPercentile(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".sevenlevelpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelPercCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.sevenlevelPercCoordArray[a].x1 && this.left_coord <= this.sevenlevelPercCoordArray[a].x2) && (sevenlevelpercentiletop <= ((this.sevenlevelPercCoordArray[a].y1 + 4)) && sevenlevelpercentiletop > ((this.sevenlevelPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_perc_ = this.cnd_array[i].seven_level_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelPercTooltip = true;
            break;
          } else {
            this.showSevenlevelPercTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.sevenlevelPercCoordArray[a].x1 && this.left_coord <= this.sevenlevelPercCoordArray[a].x2) && (sevenlevelpercentiletop <= ((this.sevenlevelPercCoordArray[a].y1 + 4)) && sevenlevelpercentiletop > ((this.sevenlevelPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_perc_ = this.cnd_array[i].seven_level_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelPercTooltip = true;
            break;
          } else {
            this.showSevenlevelPercTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnDemandSupply(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".demandsupply-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var demandsupplytop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.demandsupplyCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.demandsupplyCoordArray[a].x1 && this.left_coord <= this.demandsupplyCoordArray[a].x2) && (demandsupplytop <= ((this.demandsupplyCoordArray[a].y1 + 6)) && demandsupplytop > ((this.demandsupplyCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.demand_supply_candel_ = this.cnd_array[i].candle_type;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDemandSupplyTooltip = true;
            break;
          } else {
            this.showDemandSupplyTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.demandsupplyCoordArray[a].x1 && this.left_coord <= this.demandsupplyCoordArray[a].x2) && (demandsupplytop <= ((this.demandsupplyCoordArray[a].y1 + 6)) && demandsupplytop > ((this.demandsupplyCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.demand_supply_candel_ = this.cnd_array[i].candle_type;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDemandSupplyTooltip = true;
            break;
          } else {
            this.showDemandSupplyTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBlackWallAverage(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".blackwallavg-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var blackwallavgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.blackwallAverageCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.blackwallAverageCoordArray[a].x1 && this.left_coord <= this.blackwallAverageCoordArray[a].x2) && (blackwallavgtop <= ((this.blackwallAverageCoordArray[a].y1 + 4)) && blackwallavgtop > ((this.blackwallAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.black_wall_avg_ = parseFloat(this.cnd_array[i].black_wall_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBlackwallAvgTooltip = true;
            break;
          } else {
            this.showBlackwallAvgTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.blackwallAverageCoordArray[a].x1 && this.left_coord <= this.blackwallAverageCoordArray[a].x2) && (blackwallavgtop <= ((this.blackwallAverageCoordArray[a].y1 + 4)) && blackwallavgtop > ((this.blackwallAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.black_wall_avg_ = parseFloat(this.cnd_array[i].black_wall_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBlackwallAvgTooltip = true;
            break;
          } else {
            this.showBlackwallAvgTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnMove(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".move-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var movetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.moveCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.moveCoordArray[a].x1 && this.left_coord <= this.moveCoordArray[a].x2) && (movetop <= ((this.moveCoordArray[a].y1 + 6)) && movetop > ((this.moveCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.move_ = this.cnd_array[i].per_move;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showMoveTooltip = true;
            break;
          } else {
            this.showMoveTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.moveCoordArray[a].x1 && this.left_coord <= this.moveCoordArray[a].x2) && (movetop <= ((this.moveCoordArray[a].y1 + 6)) && movetop > ((this.moveCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.move_ = this.cnd_array[i].per_move;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showMoveTooltip = true;
            break;
          } else {
            this.showMoveTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnRejection(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".rejection-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var rejectiontop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.rejectionCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.rejectionCoordArray[a].x1 && this.left_coord <= this.rejectionCoordArray[a].x2) && (rejectiontop <= ((this.rejectionCoordArray[a].y1 + 6)) && rejectiontop > ((this.rejectionCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.rejected_candle_ = this.cnd_array[i].rejected_candle;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showRejectionTooltip = true;
            break;
          } else {
            this.showRejectionTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.rejectionCoordArray[a].x1 && this.left_coord <= this.rejectionCoordArray[a].x2) && (rejectiontop <= ((this.rejectionCoordArray[a].y1 + 6)) && rejectiontop > ((this.rejectionCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.rejected_candle_ = this.cnd_array[i].rejected_candle;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showRejectionTooltip = true;
            break;
          } else {
            this.showRejectionTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnVolumeComparison(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".volumecomparison-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var volumecomparisontop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.volumecomparisonCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.volumecomparisonCoordArray[a].x1 && this.left_coord <= this.volumecomparisonCoordArray[a].x2) && (volumecomparisontop <= ((this.volumecomparisonCoordArray[a].y1 + 6)) && volumecomparisontop > ((this.volumecomparisonCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.volume_comparison_ = ((parseFloat(this.cnd_array[i].total_volume) / parseFloat(this.cnd_array[i].volume)) * 100).toFixed(2);
            this.total_volume_ = this.cnd_array[i].total_volume;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showVolumeComparisonTooltip = true;
            break;
          } else {
            this.showVolumeComparisonTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.volumecomparisonCoordArray[a].x1 && this.left_coord <= this.volumecomparisonCoordArray[a].x2) && (volumecomparisontop <= ((this.volumecomparisonCoordArray[a].y1 + 6)) && volumecomparisontop > ((this.volumecomparisonCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.volume_comparison_ = ((parseFloat(this.cnd_array[i].total_volume) / parseFloat(this.cnd_array[i].volume)) * 100).toFixed(2);
            this.total_volume_ = this.cnd_array[i].total_volume;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showVolumeComparisonTooltip = true;
            break;
          } else {
            this.showVolumeComparisonTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBodyMove(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".bodymove-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bodymovetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bodymoveCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.bodymoveCoordArray[a].x1 && this.left_coord <= this.bodymoveCoordArray[a].x2)) {
            if ((bodymovetop <= ((this.bodymoveCoordArray[a].y1 + 6)) && bodymovetop > ((this.bodymoveCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.body_move_ = this.cnd_array[i].body_per_move;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBodyMoveTooltip = true;
              // break;
            } else {
              this.showBodyMoveTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.bodymoveCoordArray[a].x1 && this.left_coord <= this.bodymoveCoordArray[a].x2)) {
            if ((bodymovetop <= ((this.bodymoveCoordArray[a].y1 + 6)) && bodymovetop > ((this.bodymoveCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.body_move_ = this.cnd_array[i].body_per_move;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBodyMoveTooltip = true;
              // break;
            } else {
              this.showBodyMoveTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnNextClose(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".next_close-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var next_closetop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.next_closeCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.next_closeCoordArray[a].x1 && this.left_coord <= this.next_closeCoordArray[a].x2)) {
            if ((next_closetop <= ((this.next_closeCoordArray[a].y1 + 6)) && next_closetop > ((this.next_closeCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.next_close_ = this.cnd_array[i].next_close;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showNextCloseTooltip = true;
              // break;
            } else {
              this.showNextCloseTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.next_closeCoordArray[a].x1 && this.left_coord <= this.next_closeCoordArray[a].x2)) {
            if ((next_closetop <= ((this.next_closeCoordArray[a].y1 + 6)) && next_closetop > ((this.next_closeCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.next_close_ = this.cnd_array[i].next_close;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showNextCloseTooltip = true;
              break;
            } else {
              this.showNextCloseTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnAskBidVolume(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".askbidvolume-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbidvolumetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbidvolumeCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.askbidvolumeCoordArray[a].x1 && this.left_coord <= this.askbidvolumeCoordArray[a].x2) && (askbidvolumetop <= ((this.askbidvolumeCoordArray[a].y1 + 6)) && askbidvolumetop > ((this.askbidvolumeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_bid_volume_ = this.cnd_array[i].total_volume;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidVolumeTooltip = true;
            break;
          } else {
            this.showAskBidVolumeTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.askbidvolumeCoordArray[a].x1 && this.left_coord <= this.askbidvolumeCoordArray[a].x2) && (askbidvolumetop <= ((this.askbidvolumeCoordArray[a].y1 + 6)) && askbidvolumetop > ((this.askbidvolumeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_bid_volume_ = this.cnd_array[i].total_volume;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidVolumeTooltip = true;
            break;
          } else {
            this.showAskBidVolumeTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnAskBidVolumeB(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".askbidvolumeb-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbidvolumebtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbidvolumebCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.askbidvolumebCoordArray[a].x1 && this.left_coord <= this.askbidvolumebCoordArray[a].x2) && (askbidvolumebtop <= ((this.askbidvolumebCoordArray[a].y1 + 6)) && askbidvolumebtop > ((this.askbidvolumebCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_bid_volume_b_ = this.cnd_array[i].total_volume_b;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidVolumeBTooltip = true;
            break;
          } else {
            this.showAskBidVolumeBTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.askbidvolumebCoordArray[a].x1 && this.left_coord <= this.askbidvolumebCoordArray[a].x2) && (askbidvolumebtop <= ((this.askbidvolumebCoordArray[a].y1 + 6)) && askbidvolumebtop > ((this.askbidvolumebCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_bid_volume_b_ = this.cnd_array[i].total_volume_b;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidVolumeBTooltip = true;
            break;
          } else {
            this.showAskBidVolumeBTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnAskBidDelta(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".askbiddelta-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbiddeltatop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askBidDeltaCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.askBidDeltaCoordArray[a].x1 && this.left_coord <= this.askBidDeltaCoordArray[a].x2) && (askbiddeltatop <= ((this.askBidDeltaCoordArray[a].y1 + 4)) && askbiddeltatop > ((this.askBidDeltaCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            if (typeof (this.cnd_array[i].ask_bid_diff) !== 'undefined') {
              this.ask_bid_delta_ = this.cnd_array[i].ask_bid_diff;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDeltaTooltip = true;
            break;
          } else {
            this.showAskBidDeltaTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.askBidDeltaCoordArray[a].x1 && this.left_coord <= this.askBidDeltaCoordArray[a].x2) && (askbiddeltatop <= ((this.askBidDeltaCoordArray[a].y1 + 4)) && askbiddeltatop > ((this.askBidDeltaCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }


            if (typeof (this.cnd_array[i].ask_bid_diff) !== 'undefined') {
              this.ask_bid_delta_ = this.cnd_array[i].ask_bid_diff;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDeltaTooltip = true;
            break;
          } else {
            this.showAskBidDeltaTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnAskBidDeltaB(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".askbiddeltab-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbiddeltabtop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askBidDeltabCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.askBidDeltabCoordArray[a].x1 && this.left_coord <= this.askBidDeltabCoordArray[a].x2) && (askbiddeltabtop <= ((this.askBidDeltabCoordArray[a].y1 + 4)) && askbiddeltabtop > ((this.askBidDeltabCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            if (typeof (this.cnd_array[i].ask_bid_diff_b) !== 'undefined') {
              this.ask_bid_delta_b_ = this.cnd_array[i].ask_bid_diff_b;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDeltaBTooltip = true;
            break;
          } else {
            this.showAskBidDeltaBTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.askBidDeltabCoordArray[a].x1 && this.left_coord <= this.askBidDeltabCoordArray[a].x2) && (askbiddeltabtop <= ((this.askBidDeltabCoordArray[a].y1 + 4)) && askbiddeltabtop > ((this.askBidDeltabCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }


            if (typeof (this.cnd_array[i].ask_bid_diff_b) !== 'undefined') {
              this.ask_bid_delta_b_ = this.cnd_array[i].ask_bid_diff_b;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDeltaBTooltip = true;
            break;
          } else {
            this.showAskBidDeltaBTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnAskBidCount(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".askbidcount-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbidcounttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbidcountCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.askbidcountCoordArray[a].x1 && this.left_coord <= this.askbidcountCoordArray[a].x2) && (askbidcounttop <= ((this.askbidcountCoordArray[a].y1 + 4)) && askbidcounttop > ((this.askbidcountCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_count_ = this.cnd_array[i].ask_count;
            this.bid_count_ = this.cnd_array[i].bid_count;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidCountTooltip = true;
            break;
          } else {
            this.showAskBidCountTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.askbidcountCoordArray[a].x1 && this.left_coord <= this.askbidcountCoordArray[a].x2) && (askbidcounttop <= ((this.askbidcountCoordArray[a].y1 + 4)) && askbidcounttop > ((this.askbidcountCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_count_ = this.cnd_array[i].ask_count;
            this.bid_count_ = this.cnd_array[i].bid_count;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidCountTooltip = true;
            break;
          } else {
            this.showAskBidCountTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBidDelta(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".biddelta-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var biddeltatop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bidDeltaCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.bidDeltaCoordArray[a].x1 && this.left_coord <= this.bidDeltaCoordArray[a].x2) && (biddeltatop <= ((this.bidDeltaCoordArray[a].y1 + 4)) && biddeltatop > ((this.bidDeltaCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            let ask = parseFloat(this.cnd_array[i].ask_volume);
            let bid = parseFloat(this.cnd_array[i].bid_volume);
            this.bid_delta_ = (ask > bid ? ask / bid : bid / ask).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBidDeltaTooltip = true;
            break;
          } else {
            this.showBidDeltaTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.bidDeltaCoordArray[a].x1 && this.left_coord <= this.bidDeltaCoordArray[a].x2) && (biddeltatop <= ((this.bidDeltaCoordArray[a].y1 + 4)) && biddeltatop > ((this.bidDeltaCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            let ask = parseFloat(this.cnd_array[i].ask_volume);
            let bid = parseFloat(this.cnd_array[i].bid_volume);
            this.bid_delta_ = (ask > bid ? ask / bid : bid / ask).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBidDeltaTooltip = true;
            break;
          } else {
            this.showBidDeltaTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnAskBidDivisionB(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".askbiddivisionb-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbiddivisionbtop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbiddivisionbCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.askbiddivisionbCoordArray[a].x1 && this.left_coord <= this.askbiddivisionbCoordArray[a].x2) && (askbiddivisionbtop <= ((this.askbiddivisionbCoordArray[a].y1 + 4)) && askbiddivisionbtop > ((this.askbiddivisionbCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }

            let ask = parseFloat(this.cnd_array[i].ask_volume_b);
            let bid = parseFloat(this.cnd_array[i].bid_volume_b);
            this.ask_bid_division_b_ = (ask > bid ? ask / bid : bid / ask).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDivisionBTooltip = true;
            break;
          } else {
            this.showAskBidDivisionBTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.askbiddivisionbCoordArray[a].x1 && this.left_coord <= this.askbiddivisionbCoordArray[a].x2) && (askbiddivisionbtop <= ((this.askbiddivisionbCoordArray[a].y1 + 4)) && askbiddivisionbtop > ((this.askbiddivisionbCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            let ask = parseFloat(this.cnd_array[i].ask_volume_b);
            let bid = parseFloat(this.cnd_array[i].bid_volume_b);
            this.ask_bid_division_b_ = (ask > bid ? ask / bid : bid / ask).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDivisionBTooltip = true;
            break;
          } else {
            this.showAskBidDivisionBTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnScriptTime(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".scripttime-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var scripttimetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.scripttimeCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.scripttimeCoordArray[a].x1 && this.left_coord <= this.scripttimeCoordArray[a].x2) && (scripttimetop <= ((this.scripttimeCoordArray[a].y1 + 6)) && scripttimetop > ((this.scripttimeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            if (typeof (this.cnd_array[i].scriptCloseTime) !== 'undefined') {
              this.script_time_ = (((parseFloat(this.cnd_array[i].scriptCloseTime)) - (parseFloat(this.cnd_array[i].closeTime)) / 1000) / 60).toFixed(0);
            } else {
              this.script_time_ = '';
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showScriptTimeTooltip = true;
            break;
          } else {
            this.showScriptTimeTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.scripttimeCoordArray[a].x1 && this.left_coord <= this.scripttimeCoordArray[a].x2) && (scripttimetop <= ((this.scripttimeCoordArray[a].y1 + 6)) && scripttimetop > ((this.scripttimeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            if (typeof (this.cnd_array[i].scriptCloseTime) !== 'undefined') {
              this.script_time_ = (((parseFloat(this.cnd_array[i].scriptCloseTime)) - (parseFloat(this.cnd_array[i].closeTime)) / 1000) / 60).toFixed(0);
            } else {
              this.script_time_ = '';
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showScriptTimeTooltip = true;
            break;
          } else {
            this.showScriptTimeTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnDP1(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".dp1-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp1top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp1CoordArray.length > 1) {
        if ((this.left_coord > this.dp1CoordArray[a].x1 && this.left_coord <= this.dp1CoordArray[a].x2)) {
          if ((dp1top <= ((this.dp1CoordArray[a].y1 + 6)) && dp1top > ((this.dp1CoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 2) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 5) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 7) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.DP1_perc_ = this.cnd_array[i].DP1_perc;
            this.DP1_ =  (Math.abs(this.cnd_array[i].DP1)).toFixed(2);
            this.UP1_ = (Math.abs(this.cnd_array[i].UP1)).toFixed(2)
            // this.DP1_perc_ = this.cnd_array[i].DP1_perc;
            this.UP1_perc_ = this.cnd_array[i].UP1_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDP1Tooltip = true;
          } else {
            this.showDP1Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDP2(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".dp2-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp2top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp2CoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.dp2CoordArray[a].x1 && this.left_coord <= this.dp2CoordArray[a].x2)) {
            if ((dp2top <= ((this.dp2CoordArray[a].y1 + 6)) && dp2top > ((this.dp2CoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.DP2_perc_ = this.cnd_array[i].DP2_perc;

              this.DP2_ =  (Math.abs(this.cnd_array[i].DP2)).toFixed(2);
              this.UP2_ = (Math.abs(this.cnd_array[i].UP2)).toFixed(2);
              // this.DP2_perc_ = this.cnd_array[i].DP2_perc;
              this.UP2_perc_ = this.cnd_array[i].UP2_perc;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDP2Tooltip = true;
              // break;
            } else {
              this.showDP2Tooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.dp2CoordArray[a].x1 && this.left_coord <= this.dp2CoordArray[a].x2)) {
            if ((dp2top <= ((this.dp2CoordArray[a].y1 + 6)) && dp2top > ((this.dp2CoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.DP2_perc_ = this.cnd_array[i].DP2_perc;

              this.DP2_ =  (Math.abs(this.cnd_array[i].DP2)).toFixed(2);
              this.UP2_ = (Math.abs(this.cnd_array[i].UP2)).toFixed(2);
              // this.DP2_perc_ = this.cnd_array[i].DP2_perc;
              this.UP2_perc_ = this.cnd_array[i].UP2_perc;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDP2Tooltip = true;
              // break;
            } else {
              this.showDP2Tooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnDP3(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".dp3-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp3top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp3CoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.dp3CoordArray[a].x1 && this.left_coord <= this.dp3CoordArray[a].x2)) {
            if ((dp3top <= ((this.dp3CoordArray[a].y1 + 6)) && dp3top > ((this.dp3CoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.DP3_perc_ = this.cnd_array[i].DP3_perc;

              this.DP3_ =  (Math.abs(this.cnd_array[i].DP3)).toFixed(2);
              this.UP3_ = (Math.abs(this.cnd_array[i].UP3)).toFixed(2);
              // this.DP3_perc_ = this.cnd_array[i].DP3_perc;
              this.UP3_perc_ = this.cnd_array[i].UP3_perc;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDP3Tooltip = true;
              // break;
            } else {
              this.showDP3Tooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.dp3CoordArray[a].x1 && this.left_coord <= this.dp3CoordArray[a].x2)) {
            if ((dp3top <= ((this.dp3CoordArray[a].y1 + 6)) && dp3top > ((this.dp3CoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.DP3_perc_ = this.cnd_array[i].DP3_perc;

              this.DP3_ =  (Math.abs(this.cnd_array[i].DP3)).toFixed(2);
              this.UP3_ = (Math.abs(this.cnd_array[i].UP3)).toFixed(2);
              // this.DP3_perc_ = this.cnd_array[i].DP3_perc;
              this.UP3_perc_ = this.cnd_array[i].UP3_perc;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDP3Tooltip = true;
              // break;
            } else {
              this.showDP3Tooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBigBuyerSellerContractsCount(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".bigbuyersellercontractscount-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellercontractscounttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellercontractsCountCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.bigBuyerSellercontractsCountCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellercontractsCountCoordArray[a].x2)) {
            if ((bigbuyersellercontractscounttop <= ((this.bigBuyerSellercontractsCountCoordArray[a].y1 + 4)) && bigbuyersellercontractscounttop > ((this.bigBuyerSellercontractsCountCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_contracts_count_ = (typeof this.cnd_array[i].bigBuyerContractCount_h !== 'undefined') ? this.cnd_array[i].bigBuyerContractCount_h : 0;
              this.big_seller_contracts_count_ = (typeof this.cnd_array[i].bigSellerContractCount_h !== 'undefined') ? this.cnd_array[i].bigSellerContractCount_h : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellercontractsCountTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellercontractsCountTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.bigBuyerSellercontractsCountCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellercontractsCountCoordArray[a].x2)) {
            if ((bigbuyersellercontractscounttop <= ((this.bigBuyerSellercontractsCountCoordArray[a].y1 + 4)) && bigbuyersellercontractscounttop > ((this.bigBuyerSellercontractsCountCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_contracts_count_ = (typeof this.cnd_array[i].bigBuyerContractCount_h !== 'undefined') ? this.cnd_array[i].bigBuyerContractCount_h : 0;
              this.big_seller_contracts_count_ = (typeof this.cnd_array[i].bigSellerContractCount_h !== 'undefined') ? this.cnd_array[i].bigSellerContractCount_h : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellercontractsCountTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellercontractsCountTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBigBuyerSellerContractsVol(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".bigbuyersellercontractsvol-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellercontractsvoltop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellercontractsVolCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.bigBuyerSellercontractsVolCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellercontractsVolCoordArray[a].x2)) {
            if ((bigbuyersellercontractsvoltop <= ((this.bigBuyerSellercontractsVolCoordArray[a].y1 + 4)) && bigbuyersellercontractsvoltop > ((this.bigBuyerSellercontractsVolCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_contracts_vol_ = (typeof this.cnd_array[i].bigBuyerContractVol_h !== 'undefined') ? this.cnd_array[i].bigBuyerContractVol_h : 0;
              this.big_seller_contracts_vol_ = (typeof this.cnd_array[i].bigSellerContractVol_h !== 'undefined') ? this.cnd_array[i].bigSellerContractVol_h : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellercontractsVolTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellercontractsVolTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.bigBuyerSellercontractsVolCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellercontractsVolCoordArray[a].x2)) {
            if ((bigbuyersellercontractsvoltop <= ((this.bigBuyerSellercontractsVolCoordArray[a].y1 + 4)) && bigbuyersellercontractsvoltop > ((this.bigBuyerSellercontractsVolCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_contracts_vol_ = (typeof this.cnd_array[i].bigBuyerContractVol_h !== 'undefined') ? this.cnd_array[i].bigBuyerContractVol_h : 0;
              this.big_seller_contracts_vol_ = (typeof this.cnd_array[i].bigSellerContractVol_h !== 'undefined') ? this.cnd_array[i].bigSellerContractVol_h : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellercontractsVolTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellercontractsVolTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBigBuyerSellerDelta(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".bigbuyersellerdelta-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellerdeltatop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellerDeltaCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.bigBuyerSellerDeltaCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellerDeltaCoordArray[a].x2)) {
            if ((bigbuyersellerdeltatop <= ((this.bigBuyerSellerDeltaCoordArray[a].y1 + 4)) && bigbuyersellerdeltatop > ((this.bigBuyerSellerDeltaCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_seller_delta_ = (typeof this.cnd_array[i].bigBuyerSellerDelta_h !== 'undefined') ? parseFloat(this.cnd_array[i].bigBuyerSellerDelta_h).toFixed(2) : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellerDeltaTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellerDeltaTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.bigBuyerSellerDeltaCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellerDeltaCoordArray[a].x2)) {
            if ((bigbuyersellerdeltatop <= ((this.bigBuyerSellerDeltaCoordArray[a].y1 + 4)) && bigbuyersellerdeltatop > ((this.bigBuyerSellerDeltaCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_seller_delta_ = (typeof this.cnd_array[i].bigBuyerSellerDelta_h !== 'undefined') ? parseFloat(this.cnd_array[i].bigBuyerSellerDelta_h).toFixed(2) : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellerDeltaTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellerDeltaTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBigBuyerSellerDiff(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".bigbuyersellerdiff-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellerdifftop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellerDiffCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.bigBuyerSellerDiffCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellerDiffCoordArray[a].x2)) {
            if ((bigbuyersellerdifftop <= ((this.bigBuyerSellerDiffCoordArray[a].y1 + 4)) && bigbuyersellerdifftop > ((this.bigBuyerSellerDiffCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_seller_diff_ = (typeof this.cnd_array[i].bigBuyerSellerDiff_h !== 'undefined') ? parseFloat(this.cnd_array[i].bigBuyerSellerDiff_h).toFixed(2) : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellerDiffTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellerDiffTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.bigBuyerSellerDiffCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellerDiffCoordArray[a].x2)) {
            if ((bigbuyersellerdifftop <= ((this.bigBuyerSellerDiffCoordArray[a].y1 + 4)) && bigbuyersellerdifftop > ((this.bigBuyerSellerDiffCoordArray[a].y2 + 4)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 2) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 5) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 7) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.big_buyer_seller_diff_ = (typeof this.cnd_array[i].bigBuyerSellerDiff_h !== 'undefined') ? parseFloat(this.cnd_array[i].bigBuyerSellerDiff_h).toFixed(2) : 0;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showBigBuyerSellerDiffTooltip = true;
              // break;
            } else {
              this.showBigBuyerSellerDiffTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnCandelTrends(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".candeltrends-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var candeltrendstop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.candeltrendsCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.candeltrendsCoordArray[a].x1 && this.left_coord <= this.candeltrendsCoordArray[a].x2)) {
            if ((candeltrendstop <= ((this.candeltrendsCoordArray[a].y1 + 6)) && candeltrendstop > ((this.candeltrendsCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.candel_trends_ = this.cnd_array[i].daily_trend;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showCandelTrendsTooltip = true;
              // break;
            } else {
              this.showCandelTrendsTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.candeltrendsCoordArray[a].x1 && this.left_coord <= this.candeltrendsCoordArray[a].x2)) {
            if ((candeltrendstop <= ((this.candeltrendsCoordArray[a].y1 + 6)) && candeltrendstop > ((this.candeltrendsCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.candel_trends_ = this.cnd_array[i].daily_trend;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showCandelTrendsTooltip = true;
              // break;
            } else {
              this.showCandelTrendsTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnDPUPTrendDirection(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".dpuptrenddirection-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dpuptrenddirectiontop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dpuptrenddirectionCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.dpuptrenddirectionCoordArray[a].x1 && this.left_coord <= this.dpuptrenddirectionCoordArray[a].x2)) {
            if ((dpuptrenddirectiontop <= ((this.dpuptrenddirectionCoordArray[a].y1 + 6)) && dpuptrenddirectiontop > ((this.dpuptrenddirectionCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.dpup_trend_direction_ = this.cnd_array[i].dpup_trend_direction;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDPUPTrendDirectionTooltip = true;
              // break;
            } else {
              this.showDPUPTrendDirectionTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.dpuptrenddirectionCoordArray[a].x1 && this.left_coord <= this.dpuptrenddirectionCoordArray[a].x2)) {
            if ((dpuptrenddirectiontop <= ((this.dpuptrenddirectionCoordArray[a].y1 + 6)) && dpuptrenddirectiontop > ((this.dpuptrenddirectionCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.dpup_trend_direction_ = this.cnd_array[i].dpup_trend_direction;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDPUPTrendDirectionTooltip = true;
              // break;
            } else {
              this.showDPUPTrendDirectionTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }


  pricecheck() {
    var min, max;
    for (var i = 0; i <= this.price_length; i++) {
      if (this.ending_index < 168) {
        max = this.onup_maxprice;
        if (isNaN(max)) {
          max = this.onovercand_maxprice
        }
        min = this.onup_minprice;
        if (isNaN(min)) {
          min = this.onovercand_minprice
        }
      } else {
        max = this.onenter_maxprice;
        min = this.onenter_minprice;
      }
      var diff = max - min;
      var value_diff: number = ((diff / 100) * i);
      var value1 = parseFloat(min + value_diff).toFixed(8);
      this.top_coord = parseFloat(this.top_coord).toFixed(0);
      var valueCandlestickHead = ((this.candleCoordArray[0].pricestart_point + (this.componenthead_height + 30)) - ((this.candleCoordArray[0].pline_diff) * i)).toFixed(0);
      var valueCandlestickWithoutHead = (this.candleCoordArray[0].pricestart_point - ((this.candleCoordArray[0].pline_diff) * i)).toFixed(0);
      if (this.componenthead_height > 0) {
        if (this.top_coord == valueCandlestickHead) {
          // if(this.top_coord == (this.candleCoordArray[0].pricestart_point + (this.componenthead_height + 30)) - ((this.candleCoordArray[0].pline_diff)*i)){
          this.price = value1;
          break;
        }
      }
      if (this.componenthead_height == 0) {
        if (this.top_coord == valueCandlestickWithoutHead) {
          // if(this.top_coord == this.candleCoordArray[0].pricestart_point - ((this.candleCoordArray[0].pline_diff)*i)){
          this.price = value1;
          break;
        }
      }
    }
    // price check end
  }


  mouseOverOnCandels(e) {
    this.onovercand_maxprice = typeof parseFloat(this.candleCoordArray[0].max_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].max_value);
    this.onovercand_minprice = typeof parseFloat(this.candleCoordArray[0].min_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].min_value);
  }


  mouseMove(e) {
    this.gettooltipHeight();
    this.getCandleStickHeadHeight();
    this.pricecheck();
  }
  mousedown(e) {
    if ((this.componenthead_height > 0 && this.top_coord > 80) || (this.componenthead_height == 0 && this.top_coord > 25)) {
      this.ondown_x = e.clientX;
      this.ondown_starting = this.starting_index;
      this.ondown_ending = this.ending_index;
      this.ondown_maxprice = parseFloat(this.candleCoordArray[0].max_value);
      this.ondown_minprice = parseFloat(this.candleCoordArray[0].min_value);
    }
  }
  mouseenter(e) {
    this.onenter_maxprice = typeof parseFloat(this.candleCoordArray[0].max_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].max_value);
    this.onenter_minprice = typeof parseFloat(this.candleCoordArray[0].min_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].min_value);
  }
  mouseleave(e) {

  }
  mouseup(e) {
    if ((this.componenthead_height > 0 && this.top_coord > 80) || (this.componenthead_height == 0 && this.top_coord > 25)) {
      this.onup_x = e.clientX;
      this.increment_decrement();
      this.onup_maxprice = parseFloat(this.candleCoordArray[0].max_value);
      this.onup_minprice = parseFloat(this.candleCoordArray[0].min_value);
      if (this.x_diff == 0) {
      } else {
        this.line_append_updated();
        this.updatePricelineArray();
        this.line_append_updated_horizontal();
      }
    }
  }
  dblclick() {
    this.line_append()
  }
  keyReleased: boolean = true;
  onKeyDown(e) {
    if (this.keyReleased) {
      this.before_candle_width = this.candleCoordArray[0].tline_diff
      this.before_length = this.time_length;
      this.onkeydown_maxprice = parseFloat(this.candleCoordArray[0].max_value);
      this.onkeydown_minprice = parseFloat(this.candleCoordArray[0].min_value);
      // console.log('pressed');
      this.keyReleased = false;
    }
  }
  onKeyUp(e) {
    if (!this.keyReleased) {
      setTimeout(() => {
        this.after_length = this.time_length;
        this.after_candle_width = this.candleCoordArray[0].tline_diff;
        this.onkeyup_maxprice = parseFloat(this.candleCoordArray[0].max_value);
        this.onkeyup_minprice = parseFloat(this.candleCoordArray[0].min_value);
      }, 1);

      setTimeout(() => {
        for (let i = 0; i < this.lineArray.length; i++) {
          if (this.after_length != this.before_length) {

            // this.lineArray[i].fromleft = (((this.lineArray[i].fromleft - this.candleCoordArray[0].fromleft) * this.after_candle_width) / this.before_candle_width) + this.candleCoordArray[0].fromleft;
            this.lineArray[i].fromleft = (((this.lineArray[i].fromleft - this.candleCoordArray[0].fromright) * this.after_candle_width) / this.before_candle_width) + this.candleCoordArray[0].fromright;
          }
        }
        if (this.lineArray.length > 0) {
          this.updatePricelineArray();
          this.line_append_updated_horizontal();
        }
      }, 2);
      this.keyReleased = true;
    }
  }




  line_append() {
    var lineArray_object = new Object();
    var left = this.left_coord;
    var top = this.top_coord;
    if ((this.componenthead_height > 0 && this.top_coord > 80) || (this.componenthead_height == 0 && this.top_coord > 25)) {
      lineArray_object['fromleft'] = left;
      lineArray_object['fromtop'] = top;
      lineArray_object['time'] = this.openTime_human_readible_;
      lineArray_object['price'] = this.price;
      this.lineArray.push(lineArray_object);
    }

  }
  line_append_updated() {

    for (var i = 0; i < this.lineArray.length; i++) {
      var ver_diff = this.onup_x - this.ondown_x;
      if (this.starting_index == 0) {
        this.decrement = this.ondown_starting - this.starting_index;
      }
      if (this.ending_index == this.cnd_array.length) {
        this.increment = this.ondown_ending - this.ending_index;
      }
      if (ver_diff > 0) {
        this.lineArray[i].fromleft = this.lineArray[i].fromleft + (this.decrement * this.candleCoordArray[0].tline_diff);
      } else if (ver_diff < 0) {
        this.lineArray[i].fromleft = this.lineArray[i].fromleft + (this.increment * this.candleCoordArray[0].tline_diff);
      }
    }
  }//vartical

  updatePricelineArray() {
    this.pricelineArray = [];
    var upmax
    var upmin
    for (var i = 0; i < this.price_length; i++) {
      var diff;
      if ((this.onkeyup_minprice != this.onkeydown_minprice) || (this.onkeyup_maxprice != this.onkeydown_maxprice)) {
        upmax = this.onkeyup_maxprice
        upmin = this.onkeyup_minprice;
      }
      else {
        upmax = this.onup_maxprice
        upmin = this.onup_minprice;
      }
      diff = upmax - upmin;
      var value = (diff / 100) * i;
      var tag = parseFloat(upmin + value).toFixed(8);
      if (this.componenthead_height > 0) {
        let selected_price_coord = (this.candleCoordArray[0].pricestart_point + (this.componenthead_height + 30)) - (this.candleCoordArray[0].pline_diff) * i;
        var price_object = new Object();
        price_object['price'] = tag;
        price_object['coord'] = selected_price_coord;
        this.pricelineArray.push(price_object);
      }
      if (this.componenthead_height == 0) {
        let selected_price_coord = (this.candleCoordArray[0].pricestart_point) - (this.candleCoordArray[0].pline_diff) * i;
        var price_object = new Object();
        price_object['price'] = tag;
        price_object['coord'] = selected_price_coord;
        this.pricelineArray.push(price_object);
      }
    }
  }//horozontal

  line_append_updated_horizontal() {
    this.coord = -1000;
    setTimeout(() => {
      if (this.lineArray.length > 0) {
        for (let i = 0; i < this.lineArray.length; i++) {
          if ((this.onup_maxprice != this.ondown_maxprice) || (this.onup_minprice != this.ondown_minprice) || (this.onkeyup_minprice != this.onkeydown_minprice) || (this.onkeyup_maxprice != this.onkeydown_maxprice)) {
            let coord_arr = this.pricelineArray.find(x => x.price >= this.lineArray[i].price);
            if (typeof coord_arr != 'undefined') {
              if (typeof coord_arr.coord != 'undefined') {
                this.coord = coord_arr.coord;
                this.lineArray[i].fromtop = this.coord;
              } else {
                this.lineArray[i].fromtop = this.coord;
              }
            } else {
              this.lineArray[i].fromtop = this.coord;
            }
          }
        }
      }
    }, 2);
  }//horizontal

  removeline(index) {
    this.lineArray.splice(index, 1);
  }
  removeAllLines() {
    this.lineArray = []
  }

  increment_decrement() {
    this.x_diff = this.onup_x - this.ondown_x;
    var x_diff = this.x_diff;
    if (x_diff > 0) {
      this.decrement = +((x_diff / this.candleCoordArray[0].tline_diff).toFixed(0));
      this.backward();
    }
    if (x_diff < 0) {
      this.increment = +((x_diff / this.candleCoordArray[0].tline_diff).toFixed(0));
      this.forward();
    }

  }
  forward() {
    if (this.ending_index < this.cnd_array.length) {
      this.starting_index = this.starting_index - this.increment;
      this.ending_index = this.ending_index - this.increment;
      if (this.ending_index > this.cnd_array.length || this.ending_index == this.cnd_array.length) {
        this.ending_index = this.cnd_array.length;
        this.starting_index = this.ending_index - this.time_length;
      }
      this.changearrayValue();
    } else {
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.changearrayValue();
    }
  }
  backward() {
    if (this.starting_index > 0) {
      this.starting_index = this.starting_index - this.decrement;
      this.ending_index = this.ending_index - this.decrement;
      if (this.starting_index < 0 || this.starting_index == 0) {
        this.starting_index = 0;
        this.ending_index = this.starting_index + this.time_length;
      }
      this.changearrayValue();
    } else {
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.changearrayValue();
    }
  }

}
