 <div class="clearfix"></div>
     
<div class="row">
    <div class="col-12 col-md-4">
        <div class="card">
            <div class="card-header bg-danger text-white"><strong>Buy ( {{currentMarketPrice}} )</strong> <span class="head-title-img-icon"><img src="../assets/images/hand.png"></span></div>
            <div class="card-body pt-0">
                <div class="row-table">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-danger text-center">#</th>
                                    <th class="text-danger">Price (BTC)</th>
                                    <th class="text-danger">Amount ({{currncy}})</th>
                                    <th class="text-danger">Total (BTC)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let element of bidPricesArr;let i = index ">
                                    <td class="text-center">{{i+1}}</td>
                                    <td>
                                    {{element.price}}
                                    </td>
                                    <td>
                                    {{element.quantity}}
                                    </td>
                                    <td>{{(element.volume)}}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-4">
        <div class="card">
            <div class="card-header bg-success text-white"><strong>Sell ({{currentMarketPrice}})</strong> <span class="head-title-img-icon"><img src="../assets/images/wallet.png"></span></div>
            <div class="card-body pt-0">
                <div class="row-table">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-success text-center">#</th>
                                    <th class="text-success">Price (BTC)</th>
                                    <th class="text-success">Amount ({{currncy}})</th>
                                    <th class="text-success">Total (BTC)</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                                <tr *ngFor="let element of askPricesArr;let i = index ">
                                    <td class="text-center">{{i+1}}</td>
                                    <td>
                                    {{element.price}}
                                    </td>
                                    <td>
                                    {{element.quantity}}
                                    </td>
                                    <td>{{(element.volume)}}</td>
                                </tr> 
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-4">
        <div class="card">
            <div class="card-header bg-primary text-white"><strong>Trade History</strong> <span class="head-title-img-icon"><img src="../assets/images/layer.png"></span></div>
            <div class="card-body pt-0">
                <div class="row-table">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-primary text-center">#</th>
                                    <th class="text-primary">Price (BTC)</th>
                                    <th class="text-primary">Amount ({{currncy}})</th>
                                    <th class="text-primary text-">Total (BTC)</th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr *ngFor="let element of marketHistoryArr;let i = index ">
                                    <td class="text-center;text-{{(element.quantity =='true')?'danger':'success'}}">{{i+1}}</td>
                                    <td class="text-{{(element.maker =='true')?'danger':'success'}}">
                                    {{element.price}}
                                    </td>
                                    <td class="text-{{(element.maker =='true')?'danger':'success'}}">
                                    {{element.quantity}}
                                    </td>
                                    <td class="text-{{(element.maker =='true')?'danger':'success'}}">{{(element.volume)}}</td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>