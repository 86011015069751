import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { MainChartService } from '../../main-chart.service';

@Component({
  selector: 'app-top-wick-signal',
  templateUrl: './top-wick-signal.component.html',
  styleUrls: []
})
export class TopWickSignalComponent implements OnInit {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private c: CanvasRenderingContext2D;
  cnd_array;
  general_array;
  total_cnd;
  first_cnd;
  starting_index;
  ending_index;
  increment;
  decrement;;


  coordinateArray;
  topwicksignal_start_point;
  topwicksignal_end_point;
  topwicksignal_initial_point;
  topwicksignal_final_point;
  Width;
  Height;
  c_Height;
  c_Width;
  graph_height;
  graph_width;
  pline_difference;
  price_length;
  tline_difference;
  time_length;
  y_from_top = 5;
  y_from_bottom = 5;
  x_from_left = 100;
  x_from_right = 20;




  constructor(
    private service: MainChartService
  ) { }

  row_length = 15;
  counter = 0;
  chartTool: boolean = false;


  ngOnInit() {
    this.c = this.canvas.nativeElement.getContext('2d');
    this.service.chartData.subscribe(data => {
      this.cnd_array = data
    });
    this.service.topwicksignalcoordData.subscribe(data => {
      this.coordinateArray = data
    });
    this.total_cnd = this.cnd_array.length;
    this.first_cnd = this.cnd_array[0];

    this.service.canvasData.subscribe(data => {
      this.general_array = data;
      if (this.counter > 0) {
        this.rander();
      }
    });
    if (this.counter == 0) {
      this.rander();
      this.sidebar_visibility_f()
    }
  }
  rander() {
    this.getWidth();
    this.getHeight();
    this.c_Width = this.Width;
    this.c_Height = this.Height;
    this.price_length = this.general_array[0].price_length;
    this.time_length = this.general_array[0].time_length;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;
    this.renderCharts();
  }

  chartTool_func() {
    this.chartTool = !this.chartTool;
  }
  getWidth() {
    // this.Width = 1200;
    this.Width = $(".canvas_topwick").width();
  }
  getHeight() {
    this.Height = $(".canvas_topwick").height();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.rander();
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations       //////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////


  renderCharts() {
    setTimeout(() => {
      this.setCanvasHeightWidth();
      this.priceLineDifference(this.c_Height);
      this.timeLineDifference(this.c_Width);
      this.drawGraph();
      this.counter = 1;
    }, 1);
  }

  priceLineDifference(can_h) {
    var total_graph_height = can_h - this.y_from_bottom - this.y_from_top;
    this.graph_height = total_graph_height;
    this.pline_difference = (total_graph_height / this.price_length);
  }

  timeLineDifference(can_w) {
    var total_graph_width = can_w - this.x_from_left - this.x_from_right;
    this.graph_width = total_graph_width;
    this.tline_difference = total_graph_width / this.time_length;
  }
  setCanvasHeightWidth() {
    this.canvas.nativeElement.height = this.Height
    this.canvas.nativeElement.width = this.Width;
  }


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations End       //////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////









  ////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  ////////////      Graph      /////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////
  ////////////////////////////////////////////


  drawGraph() {
    this.drawPriceLine_Tag(this.c_Height);
    this.drawBlocks();
  }
  drawPriceLine_Tag(can_h) {
    var y = can_h - this.y_from_bottom;
    var x = this.x_from_left;
    var t_graph_height = this.graph_height;// Total Graph Height
    var point_y_perc = t_graph_height / 100;
    var point_y = point_y_perc * 50;// calculation for Label Point Y
    var point_x_perc = this.x_from_left / 100;
    var point_x = point_x_perc * 75;
    var tag = 'TopWick Signal';
    this.c.fillStyle = 'white';
    this.c.fillText(tag, x - (point_x), y - point_y);
  }
  drawBlocks() {
    var _w = this.tline_difference;
    var block_width_perc = this.tline_difference / 100;
    var block_l_off = 5 * block_width_perc;
    var block_r_off = 5 * block_width_perc;
    var block_w = _w - (block_l_off + block_r_off);
    var block_h = 6;
    var blocks_y_space = 2;
    var x = (this.x_from_left + block_l_off);
    var y = this.y_from_top;
      if ((this.time_length > (this.ending_index - this.starting_index)) && (this.time_length > this.cnd_array.length)) {
        this.ending_index = this.cnd_array.length;
      }
      if (this.ending_index > this.cnd_array.length) {
        this.ending_index = this.cnd_array.length;
      }
      this.coordinateArray = [];
      for (var column = this.starting_index; column < this.ending_index; column++) {
          var move = this.cnd_array[column].top_wick_aggregate;
          var colorname = '';
          var vlu_4_colors = '';
          if(move){
            vlu_4_colors = "green";
          }else{
            vlu_4_colors = "grey";
          }
      //     const Datefromlocalstorage =(localStorage.getItem('Highlighted Candle'));
      // var openTime_human_readible_ = this.cnd_array[column].openTime_human_readible;
      // if(openTime_human_readible_ == Datefromlocalstorage){
      //   this.c.fillStyle = 'rgba(0,0,0,0.000001)';
      //   this.c.fillRect(x - block_l_off, 0, block_w + block_r_off, this.graph_height);
      // }
          this.c.fillStyle = vlu_4_colors;


          this.topwicksignal_start_point = x;
          this.topwicksignal_end_point = this.topwicksignal_start_point + block_w;
          this.topwicksignal_initial_point = y + block_h;
          this.topwicksignal_final_point = y;

          let topwicksignal_array_object = new Object();
          topwicksignal_array_object['x1'] = this.topwicksignal_start_point;
          topwicksignal_array_object['x2'] = this.topwicksignal_end_point;
          topwicksignal_array_object['y1'] = this.topwicksignal_initial_point;
          topwicksignal_array_object['y2'] = this.topwicksignal_final_point;

          this.coordinateArray.push(topwicksignal_array_object);



          this.c.fillRect(x, y, block_w, block_h);
        y = this.y_from_top;
        x += this.tline_difference;
      }
      this.changeValue();
    }
    changeValue(){
      var ArrayTopWickSignal = this.coordinateArray
      this.service.new_topwicksignalData(ArrayTopWickSignal);
    }
}
