import { Injectable ,EventEmitter, Output} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareddataService {

  @Output() globalValueChanged: EventEmitter<any> = new EventEmitter();

  private selectedCoinSource = new BehaviorSubject('');
  currentCoin = this.selectedCoinSource.asObservable();


  private APPLICATIONMODE = new BehaviorSubject('');
  CURRENTMODE = this.APPLICATIONMODE.asObservable();

  private canvasWidth = new BehaviorSubject(window.innerWidth);
  currentWidth = this.canvasWidth.asObservable();


  constructor() { }
  changeWidth(width) {
    this.canvasWidth.next(width)
  }
  changeCoin(coin: string) {
    localStorage.setItem('GLOBALCOIN', coin);
    let send = {};
        send['coin'] = coin;
        send['type'] = 'coin';
    this.globalValueChanged.emit(send);
    this.selectedCoinSource.next(coin)
  }//End of changeCoin


  changeExchange(exchange: string) {
    localStorage.setItem('GLOBALEXCHANGETYPE',exchange);
    let send = {};
        send['exchange'] = exchange;
        send['type'] = 'exchange';
    this.globalValueChanged.emit(send);
  }//End of changeExchange
  

  changeAppMode(application_mode: string) {
    let send = {};
    send['application_mode'] = application_mode;
    send['type'] = 'application_mode';
    localStorage.setItem('GLOBALEAPPLICATIONMODE',application_mode);
    this.globalValueChanged.emit(send); 
  }//End of changeAppMode
  
}//END OF ShareddataService
