import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { MainChartService } from '../../main-chart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dp-up-trend-direction',
  templateUrl: './dp-up-trend-direction.component.html',
  styleUrls: []
})
export class DpUpTrendDirectionComponent implements OnInit {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private c: CanvasRenderingContext2D;
  cnd_array;
  general_array;
  total_cnd;
  first_cnd;
  starting_index;
  ending_index;
  increment;
  decrement;
  DailyData : boolean = false;


  coordinateArray;
  dpuptrenddirection_start_point;
  dpuptrenddirection_end_point;
  dpuptrenddirection_initial_point;
  dpuptrenddirection_final_point;
  Width;
  Height;
  c_Height;
  c_Width;
  graph_height;
  graph_width;
  pline_difference;
  price_length;
  tline_difference;
  time_length;
  y_from_top = 5;
  y_from_bottom = 5;
  x_from_left = 100;
  x_from_right = 20;




  constructor(
    private service: MainChartService,
    private router: Router,
  ) { }

  row_length = 15;
  counter = 0;
  chartTool: boolean = false;


  async ngOnInit() {
    this.c = this.canvas.nativeElement.getContext('2d');
    // this.service.chartData.subscribe(data => {
    //   this.cnd_array = data
    // });



    var url = this.router.url
    this.c = this.canvas.nativeElement.getContext('2d');
    var n = url.includes("dailychart");
    if(n == true){
      // daily chart service executed
      this.DailyData = true
      await this.service.dailyChartData.subscribe(data => {
        this.cnd_array = data;
        if (this.counter > 0) {
          this.rander();
        }
      });
    } else{
      // main chart service executed
      this.DailyData = false;
      await this.service.chartData.subscribe(data => {
        this.cnd_array = data;
        if (this.counter > 0) {
          this.rander();
        }
      });
    }






    this.service.dpuptrenddirectioncoordData.subscribe(data => {
      this.coordinateArray = data
    });
    this.total_cnd = this.cnd_array.length;
    this.first_cnd = this.cnd_array[0];

    this.service.canvasData.subscribe(data => {
      this.general_array = data;
      if (this.counter > 0) {
        this.rander();
      }
    });
    if (this.counter == 0) {
      this.rander();
      this.sidebar_visibility_f()
    }
  }
  rander() {
    this.getWidth();
    this.getHeight();
    this.c_Width = this.Width;
    this.c_Height = this.Height;
    this.price_length = this.general_array[0].price_length;
    this.time_length = this.general_array[0].time_length;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;
    this.renderCharts();
  }

  chartTool_func() {
    this.chartTool = !this.chartTool;
  }
  getWidth() {
    // this.Width = 1200;
    this.Width = $(".canvas_dpuptrenddirection").width();
  }
  getHeight() {
    this.Height = $(".canvas_dpuptrenddirection").height();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.rander();
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations       //////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////


  renderCharts() {
    setTimeout(() => {
      this.setCanvasHeightWidth();
      this.priceLineDifference(this.c_Height);
      this.timeLineDifference(this.c_Width);
      this.drawGraph();
      this.counter = 1;
    }, 1);
  }

  priceLineDifference(can_h) {
    var total_graph_height = can_h - this.y_from_bottom - this.y_from_top;
    this.graph_height = total_graph_height;
    this.pline_difference = (total_graph_height / this.price_length);
  }

  timeLineDifference(can_w) {
    var total_graph_width = can_w - this.x_from_left - this.x_from_right;
    this.graph_width = total_graph_width;
    this.tline_difference = total_graph_width / this.time_length;
  }
  setCanvasHeightWidth() {
    this.canvas.nativeElement.height = this.Height
    this.canvas.nativeElement.width = this.Width;
  }


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations End       //////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////









  ////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  ////////////      Graph      /////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////
  ////////////////////////////////////////////


  drawGraph() {
    this.drawPriceLine_Tag(this.c_Height);
    this.drawBlocks();
  }
  drawPriceLine_Tag(can_h) {
    var y = can_h - this.y_from_bottom;
    var x = this.x_from_left;
    var t_graph_height = this.graph_height;// Total Graph Height
    var point_y_perc = t_graph_height / 100;
    var point_y = point_y_perc * 50;// calculation for Label Point Y
    var point_x_perc = this.x_from_left / 100;
    var point_x = point_x_perc * 75;

    if(this.DailyData == true){
      var tag = 'DPUP Trend Direc';
      this.c.fillStyle = 'white';
      this.c.fillText(tag, x - (point_x), y - point_y);
    } else {
      var tag = 'Daily DPUP';
      var tag1 =  'Trend Direc';
      this.c.fillStyle = 'white';
      this.c.fillText(tag, x - (point_x), y - point_y);
      this.c.fillText(tag1, x - (point_x), y - (point_y + 15));
    }
  }
  drawBlocks() {
    var _w = this.tline_difference;
    var block_width_perc = this.tline_difference / 100;
    var block_l_off = 5 * block_width_perc;
    var block_r_off = 5 * block_width_perc;
    var block_w = _w - (block_l_off + block_r_off);
    var block_h = 6;
    var blocks_y_space = 2;
    var x = (this.x_from_left + block_l_off);
    var y = this.y_from_top;
      if ((this.time_length > (this.ending_index - this.starting_index)) && (this.time_length > this.cnd_array.length)) {
        this.ending_index = this.cnd_array.length;
      }
      if (this.ending_index > this.cnd_array.length) {
        this.ending_index = this.cnd_array.length;
      }
      this.coordinateArray = [];
      for (var column = this.starting_index; column < this.ending_index; column++) {
        if(this.DailyData == true){
          var move = this.cnd_array[column].dpup_trend_direction;
        } else {
          var move = this.cnd_array[column].daily_dpup_trend_direction;
        }

          var colorname = '';
          var vlu_4_colors = '';
          if(move == 'up'){
            vlu_4_colors = "blue";
          }else if(move == 'down'){
            vlu_4_colors = "red";
          } else {
            vlu_4_colors = "white";
          }
      //     const Datefromlocalstorage =(localStorage.getItem('Highlighted Candle'));
      // var openTime_human_readible_ = this.cnd_array[column].openTime_human_readible;
      // if(openTime_human_readible_ == Datefromlocalstorage){
      //   this.c.fillStyle = 'rgba(0,0,0,0.000001)';
      //   this.c.fillRect(x - block_l_off, 0, block_w + block_r_off, this.graph_height);
      // }
          this.c.fillStyle = vlu_4_colors;


          this.dpuptrenddirection_start_point = x;
          this.dpuptrenddirection_end_point = this.dpuptrenddirection_start_point + block_w;
          this.dpuptrenddirection_initial_point = y + block_h;
          this.dpuptrenddirection_final_point = y;

          let dpuptrenddirection_array_object = new Object();
          dpuptrenddirection_array_object['x1'] = this.dpuptrenddirection_start_point;
          dpuptrenddirection_array_object['x2'] = this.dpuptrenddirection_end_point;
          dpuptrenddirection_array_object['y1'] = this.dpuptrenddirection_initial_point;
          dpuptrenddirection_array_object['y2'] = this.dpuptrenddirection_final_point;

          this.coordinateArray.push(dpuptrenddirection_array_object);



          this.c.fillRect(x, y, block_w, block_h);
        y = this.y_from_top;
        x += this.tline_difference;
      }
      this.changeValue();
    }
    changeValue(){
      var Arraydpuptrenddirection = this.coordinateArray
      this.service.new_dpuptrenddirectionData(Arraydpuptrenddirection);
    }

}
