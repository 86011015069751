<div class="mainloginsignup">
    <div class="sidebar-overlay"></div>
    <div class="container-fluid h-100">
    	<div class="row justify-content-center h-100">
            <div class="col-12 col-sm-12 col-lg-5 text-white">
            	<div class="row align-items-end h-50">
                	<div class="col-12">
                    	<h1 class="border border-light border-top-0 border-bottom-0 border-right-0 pl-3 mt-5 mb-0">Login</h1>
                    </div>
                </div>
                <div class="row align-items-end h-50">
                    <div class="col-12">
                        <!-- <p>Don't have an account? <strong><a [routerLink]="['/add-user']" class="text-light">Sign Up</a></strong></p> -->
                    </div>
  				</div>
            </div>
            <div class="col-12 col-sm-12 col-lg-5">
            	<div class="row align-items-center h-100">
                	<div class="col-12">
                        <div class="col-12 bg-white card">
                            <div class="login-log text-center pt-5 pb-5">
                                <img class="img-fluid" src="../../assets/images/login-logo.png">
                            </div>
                            <div class="digi-form card-body">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label><span class="dg-icon mr-2"><i data-feather="user"></i></span>Username</label>
                                                <input formControlName="username" type="text" class="form-control border border-top-0 border-left-0 border-right-0 btn-outline-light mb-5 pl-0 pr-0" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">

                                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                <div *ngIf="f.username.errors.required">Username is required</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label><span class="dg-icon mr-2"><i data-feather="lock"></i></span>Password</label>
                                                <input formControlName="password" type="password" class="form-control border border-top-0 border-left-0 border-right-0 btn-outline-light mb-5 pl-0 pr-0" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">

                                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-12 text-right">
                                            <div class="form-group forgetpass">
                                                <!-- <p><a href="forgetpassword.html" class="text-muted">Forgot Password?</a></p> -->
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group rememberme">
                                                <label><input type="checkbox" class="mr-2"> Remember me</label>
                                            </div>
                                        </div>
                                        <div class="col-12 text-center">
                                            <div class="form-group">
                                               	<button [disabled]="loading"  class="btn btn-primary btn-xl">Login</button>
                                                   <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            </div>
                                            <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
