import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { MainChartService } from '../../main-chart.service';

@Component({
  selector: 'app-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: []
})
export class BoxesComponent implements OnInit {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private c: CanvasRenderingContext2D;
  cnd_array;
  general_array;
  total_cnd;
  first_cnd;
  starting_index;
  ending_index;
  increment;
  decrement;;

  coordinateArray;
  dkboxes_start_point;
  dkboxes_end_point;
  dkboxes_initial_point;
  dkboxes_final_point;
  Height;
  Width;
  c_Height;
  c_Width;
  graph_height;
  graph_width;
  pline_difference;
  price_length;
  tline_difference;
  time_length;
  y_from_top = 5;
  y_from_bottom = 5;
  x_from_left = 100;
  x_from_right = 20;


  constructor(
    private service: MainChartService
  ) { }

  row_length = 5;
  counter = 0;
  chartTool: boolean = false;


  ngOnInit() {
    this.c = this.canvas.nativeElement.getContext('2d');
    this.service.chartData.subscribe(data => {
      this.cnd_array = data
    });

    this.service.dkboxcoordData.subscribe(data => {
      this.coordinateArray = data;
    });

    this.total_cnd = this.cnd_array.length;
    this.first_cnd = this.cnd_array[0];

    this.service.canvasData.subscribe(data => {
      this.general_array = data;
      if (this.counter > 0) {
        this.rander();
      }
    });
    if (this.counter == 0) {
      this.rander();
      this.sidebar_visibility_f()
    }
  }
  rander() {
    this.getWidth();
    this.getHeight();
    this.c_Height = this.Height;
    this.c_Width = this.Width;
    this.price_length = this.general_array[0].price_length;
    this.time_length = this.general_array[0].time_length;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;
    this.renderCharts();
  }

  chartTool_func() {
    this.chartTool = !this.chartTool;
  }
  getWidth() {
    // this.Width = 1200;
    this.Width = $(".canvas_boxes").width();
  }
  getHeight() {
    // this.Width = 1200;
    this.Height = $(".canvas_boxes").height();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.rander();
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations       //////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////


  renderCharts() {
    setTimeout(() => {
      this.setCanvasHeightWidth();
      this.priceLineDifference(this.c_Height);
      this.timeLineDifference(this.c_Width);
      this.drawGraph();
      this.counter = 1;
    }, 1);
  }

  priceLineDifference(can_h) {
    var total_graph_height = can_h - this.y_from_bottom - this.y_from_top;
    this.graph_height = total_graph_height;
    this.pline_difference = (total_graph_height / this.price_length);
  }

  timeLineDifference(can_w) {
    var total_graph_width = can_w - this.x_from_left - this.x_from_right;
    this.graph_width = total_graph_width;
    this.tline_difference = total_graph_width / this.time_length;
  }
  setCanvasHeightWidth() {
    this.canvas.nativeElement.height = this.Height;
    this.canvas.nativeElement.width = this.Width;
  }


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations End       //////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////









  ////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  ////////////      Graph      /////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////
  ////////////////////////////////////////////


  drawGraph() {
    this.drawPriceLine_Tag(this.c_Height);
    this.drawBlocks();
  }
  drawPriceLine_Tag(can_h) {
    var block_h = 6;
    var blocks_y_space = 2;
    var y1 = this.y_from_top + block_h;
    var y2 = y1 + (block_h + blocks_y_space);
    var y3 = y2 + (block_h + blocks_y_space);
    var y4 = y3 + (block_h + blocks_y_space)
    var y5 = y4 + (block_h + blocks_y_space)
    var x = this.x_from_left;
    var point_x_perc = this.x_from_left / 100;
    var point_x = point_x_perc * 75;
    var tag1 = 'RM';
    var tag2 = 'HK';
    var tag3 = 'Spike_buy';
    var tag4 = 'Spike_sell';
    var tag5 = 'Get_supply';
    this.c.font = "normal 9px Arial";
    this.c.fillStyle = 'white';
    this.c.fillText(tag1, x - (point_x), y1);
    this.c.fillText(tag2, x - (point_x), y2);
    this.c.fillText(tag3, x - (point_x), y3);
    this.c.fillText(tag4, x - (point_x), y4);
    this.c.fillText(tag5, x - (point_x), y5);
  }
  drawBlocks() {
    var _w = this.tline_difference;
    var block_width_perc = this.tline_difference / 100;
    var block_l_off = 5 * block_width_perc;
    var block_r_off = 5 * block_width_perc;
    var block_w = _w - (block_l_off + block_r_off);
    var block_h = 6;
    var blocks_y_space = 2;
    var x = (this.x_from_left + block_l_off);
    var y = this.y_from_top;

      if ((this.time_length > (this.ending_index - this.starting_index)) && (this.time_length > this.cnd_array.length)) {
        this.ending_index = this.cnd_array.length;
      }
      if (this.ending_index > this.cnd_array.length) {
        this.ending_index = this.cnd_array.length;
      }
      this.coordinateArray = [];
      for (var column = this.starting_index; column < this.ending_index; column++) {
        var test_array =  new Object();
        for (var row = 1; row <= this.row_length; row++) {
          var truelevelarr = this.cnd_array[column].rinkos_data;

          if(typeof(truelevelarr) !== 'undefined'){


          if(typeof(truelevelarr) !== 'undefined'){
            var color = "";
            if(row == 1){
              color = truelevelarr.candle_rinkos_data_rm;
            }
            if(row == 2){
              color = truelevelarr.candle_rinkos_data_hk;
            }
            if(row == 3){
              color = truelevelarr.candle_rinkos_data_spike_buy;
            }
            if(row == 4){
              color = truelevelarr.candle_rinkos_data_spike_sell;
            }
            if(row == 5){
              color = truelevelarr.candle_rinkos_data_get_supply;
            }
            this.c.fillStyle = color;
            this.dkboxes_start_point = x;
            this.dkboxes_end_point = x + block_w;
            this.dkboxes_final_point = y;
            this.dkboxes_initial_point = y + ((this.row_length * (block_h + blocks_y_space)) - 2);
            if(row == 1){
              let dkboxes_array_object = new Object();
              dkboxes_array_object['x1'] = this.dkboxes_start_point;
              dkboxes_array_object['x2'] = this.dkboxes_end_point;
              dkboxes_array_object['y1'] = this.dkboxes_initial_point;
              dkboxes_array_object['y2'] = this.dkboxes_final_point;
              test_array = (dkboxes_array_object);
            }
              this.c.fillRect(x, y, block_w, block_h);
              y += (block_h + blocks_y_space);
          }
          }
        }
        let coordinateArray_object = new Object();
        coordinateArray_object[column]= test_array;
        this.coordinateArray.push(test_array);
        y = this.y_from_top;
        x += this.tline_difference;
      }
      this.changeValue();
    }
    changeValue(){
      var ArrayDKBox = this.coordinateArray;
      this.service.new_dkboxData(ArrayDKBox);
    }
}
