<!-- Seven Level Start -->
<div class="chartuix-outer">
  <div class="chartuix">
      <!-- <div class="chartuix-tool-toggle">
          <div class="cuix-tt-icon cuix-tt-show" *ngIf="!chartTool" (click)="chartTool_func()">
              <i-feather name="Maximize-2" title="Show"></i-feather>
          </div>
          <div class="cuix-tt-icon cuix-tt-hide" *ngIf="chartTool" (click)="chartTool_func()">
              <i-feather name="Minimize-2" title="Hide"></i-feather>
          </div>
      </div> -->
      <div class="chartuix-inner">
          <div class="chartuix-head" *ngIf="chartTool">
              <div class="chartuix-head-inner">
                  <div class="chartuix-tool-title">
                      <h2>Seven Level Average</h2>
                  </div>
                  <div class="chartuix-tool-item">
                      <i-feather name="Maximize" title="Maximize"></i-feather>
                  </div>
                  <div class="chartuix-tool-item">
                      <i-feather name="Minimize" title="Minimize"></i-feather>
                  </div>
              </div>
          </div>
          <div class="chartuix-body">
              <div class="chartuix-body-panel">
                  <div class="canvas_fourteenlevelavg">
                      <canvas id="myCanvas" #canvas style=" background-color: rgba(0, 0,0, 0.5);"></canvas>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Seven Level End -->
