<ng-container *ngFor="let coin of userCoinsArr">
  <ng-container *ngIf="coin.symbol == currentCoin">

  <div class="main-chart-outer" (scroll)=" divScroll($event)">
      <div class="main-chart">
          <div class="main-chart-inner">
              <!-- MAin Head -->
              <div class="main-chart-head">
                  <!-- <div class="main-chart-tool-toggle">
                      <div class="mc-tt-icon mc-tt-show"><i-feather name="Maximize-2"></i-feather></div>
                      <div class="mc-tt-icon mc-tt-hide"><i-feather name="Minimize-2"></i-feather></div>
                  </div> -->
                  <div class="main-chart-head-inner">
                      <div class="mchi-outer">
                          <div class="mchi-main">
                              <div class="mchi-inner">
                                  <div class="mchi-head">
                                      <div class="mchi-head-title">
                                          <h2>Filter</h2>
                                      </div>
                                      <ng-container *ngIf="coin.symbol == currentCoin">
                                          <div class="mchi-head-item" (click)="filter_all_hide_func()"
                                              *ngIf="openFilterFooter"
                                              style="color:#f00">
                                              <i-feather name="chevron-up" title="Close Filter"></i-feather>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin">
                                          <div class="mchi-head-item" (click)="open_filter_footer_func()" *ngIf="openFilterFooter ==  false"
                                              style="color:#0f0">
                                              <i-feather name="bar-chart-2" title="Open Filter"></i-feather>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                          <div class="mchi-head-item" (click)="show_all_charts()" *ngIf="showtoadmin && openFilterFooter"
                                              style="color:#8BC34A;font-size: 16px;" [ngClass]="{'active':showAllchart == true }">
                                              <i-feather name="Trending-Up" title="Active All Charts"></i-feather>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                          <ng-container *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                              <div class="mchi-head-item" (click)="filter_1_func()" *ngIf="openFilterFooter"
                                              style="color:#8BC34A;font-size: 16px;" title="Other Charts" [ngClass]="{'active':filter_1 == true }">
                                                  Others
                                              </div>
                                          </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                          <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Rabi', 'Ali', 'Hassan', 'Wasiq']">
                                              <div class="mchi-head-item" (click)="filter_2_func()" *ngIf="openFilterFooter"
                                              style="color:#8BC34A;font-size: 16px;" title="Seven Level Charts" [ngClass]="{'active':filter_2 == true }">
                                                  Seven Level
                                              </div>
                                          </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                          <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Rabi', 'Ali', 'Hassan', 'Wasiq']">
                                              <div class="mchi-head-item" (click)="filter_7_func()" *ngIf="openFilterFooter"
                                              style="color:#8BC34A;font-size: 16px;" title="Seven Level Charts" [ngClass]="{'active':filter_7 == true }">
                                                  Percentage Pressure
                                              </div>
                                          </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                          <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                              <div class="mchi-head-item" (click)="filter_3_func()" *ngIf="openFilterFooter"
                                                  style="color:#8BC34A;font-size: 16px;" title="Candle Volume Charts" [ngClass]="{'active':filter_3 == true }">
                                                  Candle Volume
                                              </div>
                                          </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                          <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                              <div class="mchi-head-item" (click)="filter_4_func()" *ngIf="openFilterFooter"
                                                  style="color:#8BC34A;font-size: 16px;" title="Volume Indicators Charts" [ngClass]="{'active':filter_4 == true }">
                                                  Volume
                                              </div>
                                          </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                          <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                              <div class="mchi-head-item" (click)="filter_5_func()" *ngIf="openFilterFooter"
                                              style="color:#8BC34A;font-size: 16px;" title="Big Contractors Charts" [ngClass]="{'active':filter_5 == true }">
                                                  Big Contractors
                                              </div>
                                          </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                          <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                              <div class="mchi-head-item" (click)="filter_6_func()" *ngIf="openFilterFooter"
                                              style="color:#8BC34A;font-size: 16px;" title="News Charts" [ngClass]="{'active':filter_6 == true }">
                                                  News
                                              </div>
                                          </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                          <div class="mchi-head-item" (click)="closeAllCharts()" *ngIf="showtoadmin && openFilterFooter"
                                              style="color:#8BC34A;font-size: 16px;" [ngClass]="{'active':closeAllChart == true }">
                                              <i-feather name="Trending-Down" title="Close All Charts"></i-feather>
                                          </div>
                                      </ng-container>
                                  </div>
                                  <div class="mchi-body" *ngIf="openFilterFooter">
                                    <ng-container *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']" >
                                      <div class="mchi-item" *ngIf="filter_1">
                                          <div class="mchi-item-head">
                                              <h2>Select Charts</h2>
                                          </div>
                                          <div class="mchi-item-body">
                                              <div class="row">
                                                  <div class="col-12 pl-4 pr-4">
                                                      <div class="row">
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showCandleSticks" (change)="setcomponentstate()">
                                                                      <span>Candle Sticks</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showTrueLevels" (change)="setcomponentstate()">
                                                                      <span>True Levels</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showBoxes" (change)="setcomponentstate()">
                                                                      <span>DK Boxes</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showDemandSupply" (change)="setcomponentstate()">
                                                                      <span>Demand Supply</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showResistance" (change)="setcomponentstate()">
                                                                      <span>Resistance</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showStrategy" (change)="setcomponentstate()">
                                                                      <span>Strategy</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showRejection" (change)="setcomponentstate()">
                                                                      <span>Rejection</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showMove" (change)="setcomponentstate()">
                                                                      <span>Move</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showBodyMove" (change)="setcomponentstate()">
                                                                      <span>Body Move</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showTopWickSignal" (change)="setcomponentstate()">
                                                                      <span>Top Wick Signal</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showCandelTrends" (change)="setcomponentstate()">
                                                                    <span>Candel Trends</span>
                                                                </div>
                                                            </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showDailyCandelTrends" (change)="setcomponentstate()">
                                                                    <span>Daily Candel Trends</span>
                                                                </div>
                                                            </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                            <div class="btnchecbox" >
                                                                <div class="btn-test-checked">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="showDPUPTrendDirection" (change)="setcomponentstate()">
                                                                    <span>DPUP Trend Direction</span>
                                                                </div>
                                                            </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showNextClose" (change)="setcomponentstate()">
                                                                      <span>Next Close</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showIncreasingVolume" (change)="setcomponentstate()">
                                                                      <span>BRD</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showBlackDotUpPressure" (change)="setcomponentstate()">
                                                                      <span>Black Dot Up Pressure</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showDP" (change)="setcomponentstate()">
                                                                      <span>UP & DP</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="currentCoin !== 'BTCUSDT' && coin.symbol == currentCoin  && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showDPBTC" (change)="setcomponentstate()">
                                                                      <span>UP & DP (BTC)</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showBlackDotPressureU10" (change)="setcomponentstate()">
                                                                      <span>BDPU10</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showAskBidVolume" (change)="setcomponentstate()">
                                                                      <span>Ask Bid Volume</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showAskBidVolumeB" (change)="setcomponentstate()">
                                                                      <span>Ask Bid Volume B</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showAskBidDelta" (change)="setcomponentstate()">
                                                                      <span>Ask Bid Delta</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showAskBidDeltaB" (change)="setcomponentstate()">
                                                                      <span>Ask Bid Delta B</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showBidDelta" (change)="setcomponentstate()">
                                                                      <span>Ask Bid Division</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showAskBidDivisionB" (change)="setcomponentstate()">
                                                                      <span>Ask Bid Division B</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showAskBidCount" (change)="setcomponentstate()">
                                                                      <span>Ask Bid Count</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showVolumeComparison" (change)="setcomponentstate()">
                                                                      <span>Volume Comparison</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showRangesComparison" (change)="setcomponentstate()">
                                                                      <span>Ranges Comparison</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showScriptTime" (change)="setcomponentstate()">
                                                                      <span>Script Time</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showCoinMetaCount" (change)="setcomponentstate()">
                                                                      <span>Coin Meta Count</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Ali','Ijaz','Asim','Wasiq','Rabi','abbas','User','Waleed','Huzaifa','Shahzad','Doug', 'Tehmina', 'Hassan']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showCandlePerc" (change)="setcomponentstate()">
                                                                      <span>Candle Perc</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas','Waleed','Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showActiveDownBarrierPerc" (change)="setcomponentstate()">
                                                                      <span>Active Down Barrier Perc</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas','Waleed','Huzaifa','Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showActiveUpBarrierPerc" (change)="setcomponentstate()">
                                                                      <span>Active Up Barrier Perc</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showCandleSTPerc"
                                                                          (change)="setcomponentstate()">
                                                                      <span>Candle ST Perc</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showCandleVSTPerc"
                                                                          (change)="setcomponentstate()">
                                                                      <span>Candle VST Perc</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showTwoWayBar" (change)="setcomponentstate()">
                                                                      <span>Two Way Bars</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showBlackWallAverg" (change)="setcomponentstate()">
                                                                      <span>Black Wall Averg</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showBigContractsAverg" (change)="setcomponentstate()">
                                                                      <span>Big Contracts Averg</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showBodyPercentile" (change)="setcomponentstate()">
                                                                      <span>Body Percentile</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox"
                                                                          [(ngModel)]="showBlackWallMinMax" (change)="setcomponentstate()">
                                                                      <span>Black Wall MinMax</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                              <div class="btnchecbox" >
                                                                  <div class="btn-test-checked">
                                                                      <input type="checkbox" [(ngModel)]="showT2BCP" (change)="setcomponentstate()">
                                                                      <span>T2BCP Ask Bid Vol</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </ng-container>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Rabi', 'Ali', 'Hassan', 'Wasiq']">
                                      <div class="mchi-item" *ngIf="filter_2">
                                          <div class="mchi-item-head">
                                              <h2>Seven Level Charts</h2>
                                          </div>
                                          <div class="mchi-item-body">
                                              <div class="row">
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelAvgDiffPerc" (change)="setcomponentstate()">
                                                              <span>Seven Level Avg Diff Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelPerc" (change)="setcomponentstate()">
                                                              <span>Seven Level Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelMinVSTPerc"
                                                                  (change)="setcomponentstate()">
                                                              <span>Seven Level Min VST Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox" [(ngModel)]="showSevenLevel" (change)="setcomponentstate()">
                                                              <span>Seven Level Average</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox" [(ngModel)]="showSevenLevelST" (change)="setcomponentstate()">
                                                              <span>Seven Level ST </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox" [(ngModel)]="showSevenLevelVST" (change)="setcomponentstate()">
                                                              <span>Seven Level VST</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelMinMax" (change)="setcomponentstate()">
                                                              <span>Seven Level MinMax</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelMinPerc" (change)="setcomponentstate()">
                                                              <span>Seven Level Min Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelMinPercST" (change)="setcomponentstate()">
                                                              <span>Seven Level Min Percentile ST</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelMaxPerc" (change)="setcomponentstate()">
                                                              <span>Seven Level Max Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showSevenLevelMaxPercST" (change)="setcomponentstate()">
                                                              <span>Seven Level Max Percentile ST</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showBlackWallPerc" (change)="setcomponentstate()">
                                                              <span>Black Wall Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showFourteenLevelAvg" (change)="setcomponentstate()">
                                                              <span>Fourteen Level Average</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showFourteenLevelPerc" (change)="setcomponentstate()">
                                                              <span>Fourteen Level Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showFourteenLevelAvgDiffPerc" (change)="setcomponentstate()">
                                                              <span>Fourteen Level Avg Diff Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Rabi', 'Ali', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showFourteenLevelMinMax" (change)="setcomponentstate()">
                                                              <span>Fourteen Level MinMax</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                      <div class="mchi-item" *ngIf="filter_3">
                                          <div class="mchi-item-head">
                                              <h2>Candle Volume</h2>
                                          </div>
                                          <div class="mchi-item-body">
                                              <div class="row">
                                                <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox" [(ngModel)]="showBUpperWick" (change)="setcomponentstate()">
                                                              <span>B Upper Wick</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                </ng-container>
                                                <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox" [(ngModel)]="showBLowerWick" (change)="setcomponentstate()">
                                                              <span>B Lower Wick</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                </ng-container>
                                                <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                    <div class="btnchecbox" >
                                                        <div class="btn-test-checked">
                                                            <input type="checkbox" [(ngModel)]="showUpperWickMove" (change)="setcomponentstate()">
                                                            <span>Upper Wick Move</span>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </ng-container>
                                                <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox" [(ngModel)]="showLowerWickMove" (change)="setcomponentstate()">
                                                              <span>Lower Wick Move</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                </ng-container>

                                              </div>
                                          </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                      <div class="mchi-item" *ngIf="filter_4">
                                              <div class="mchi-item-head">
                                                  <h2>Volume</h2>
                                              </div>
                                              <div class="mchi-item-body">
                                                  <div class="row">
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showT3MinMax" (change)="setcomponentstate()">
                                                                  <span>T1COT</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showT2MinMax" (change)="setcomponentstate()">
                                                                  <span>T2COT</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showT4MinMax" (change)="setcomponentstate()">
                                                                  <span>T4COT</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                      <div class="mchi-item" *ngIf="filter_5">
                                              <div class="mchi-item-head">
                                                  <h2>Big Contractors</h2>
                                              </div>
                                              <div class="mchi-item-body">
                                                  <div class="row">
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigContractsPerc" (change)="setcomponentstate()">
                                                                  <span>Big Contracts Perc</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigBuyerContractsPercVST" (change)="setcomponentstate()">
                                                                  <span>LBBCTLT</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigBuyerContractsPercST" (change)="setcomponentstate()">
                                                                  <span>LBBCTST</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigSellerContractsPercVST" (change)="setcomponentstate()">
                                                                  <span>LBSCTLT</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigSellerContractsPercST" (change)="setcomponentstate()">
                                                                  <span>LBSCTST</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigBuyerSellerContractsCount" (change)="setcomponentstate()">
                                                                  <span>Hourly Big Contractor Count</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigBuyerSellerContractsVol" (change)="setcomponentstate()">
                                                                  <span>Hourly Big Contractor  Vol</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigBuyerSellerDelta" (change)="setcomponentstate()">
                                                                  <span>Hourly Big Contractor Delta</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigBuyerSellerDiff" (change)="setcomponentstate()">
                                                                  <span>Hourly Big Contractor Diff</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox"
                                                                      [(ngModel)]="showBigBuyerSellerDiffPerc" (change)="setcomponentstate()">
                                                                  <span>Hourly Big Contractor Diff Perc</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showUpperWick" (change)="setcomponentstate()">
                                                                  <span>Upper Wick</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showUpperWickST"
                                                                      (change)="setcomponentstate()">
                                                                  <span>Upper Wick ST</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showUpperWickVST"
                                                                      (change)="setcomponentstate()">
                                                                  <span>Upper Wick VST</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showLowerWick" (change)="setcomponentstate()">
                                                                  <span>Lower Wick</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showLowerWickST"
                                                                      (change)="setcomponentstate()">
                                                                  <span>Lower Wick ST</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                          <div class="btnchecbox" >
                                                              <div class="btn-test-checked">
                                                                  <input type="checkbox" [(ngModel)]="showLowerWickVST"
                                                                      (change)="setcomponentstate()">
                                                                  <span>Lower Wick VST</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                      <div class="mchi-item" *ngIf="filter_6">
                                          <div class="mchi-item-head">
                                              <h2>News</h2>
                                          </div>
                                          <div class="mchi-item-body">
                                              <div class="row">
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTelegramSentiment" (change)="setcomponentstate()">
                                                              <span>Telegram Sentiment</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTelegramITPercentile" (change)="setcomponentstate()">
                                                              <span>Telegram IT Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTelegramSTPercentile" (change)="setcomponentstate()">
                                                              <span>Telegram LT Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTelegramVSTPercentile" (change)="setcomponentstate()">
                                                              <span>Telegram VST Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTwitterSentiment" (change)="setcomponentstate()">
                                                              <span>Twitter Sentiment</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTwitterITPercentile" (change)="setcomponentstate()">
                                                              <span>Twitter LT Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTwitterSTPercentile" (change)="setcomponentstate()">
                                                              <span>Twitter ST Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showTwitterVSTPercentile" (change)="setcomponentstate()">
                                                              <span>Twitter VST Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showNewsSentiment" (change)="setcomponentstate()">
                                                              <span>News Sentiment</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showNewsITPercentile" (change)="setcomponentstate()">
                                                              <span>News LT Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showNewsSTPercentile" (change)="setcomponentstate()">
                                                              <span>News ST Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showNewsVSTPercentile" (change)="setcomponentstate()">
                                                              <span>News VST Percentile</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                      <div class="mchi-item" *ngIf="filter_7">
                                          <div class="mchi-item-head">
                                              <h2>Order Book</h2>
                                          </div>
                                          <div class="mchi-item-body">
                                              <div class="row">
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook1Averg" (change)="setcomponentstate()">
                                                              <span>1 Perc Diff Avg</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook2Averg" (change)="setcomponentstate()">
                                                              <span>2 Perc Diff Avg</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook3Averg" (change)="setcomponentstate()">
                                                              <span>3 Perc Diff Avg</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook1AvergPerc" (change)="setcomponentstate()">
                                                              <span>1 Perc Diff Avg Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook2AvergPerc" (change)="setcomponentstate()">
                                                              <span>2 Perc Diff Avg Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook3AvergPerc" (change)="setcomponentstate()">
                                                              <span>3 Perc Diff Avg Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook1MinPerc" (change)="setcomponentstate()">
                                                              <span>1 Perc Diff Min Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1"  *ngxPermissionsOnly="['Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']">
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook2MinPerc" (change)="setcomponentstate()">
                                                              <span>2 Perc Diff Min Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook3MinPerc" (change)="setcomponentstate()">
                                                              <span>3 Perc Diff Min Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook1MaxPerc" (change)="setcomponentstate()">
                                                              <span>1 Perc Diff Max Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook2MaxPerc" (change)="setcomponentstate()">
                                                              <span>2 Perc Diff Max Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook3MaxPerc" (change)="setcomponentstate()">
                                                              <span>3 Perc Diff Max Perc</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook1MinMax" (change)="setcomponentstate()">
                                                              <span>1 Min Max</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook2MinMax" (change)="setcomponentstate()">
                                                              <span>2 Min Max</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-1 pl-1" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa', 'Doug', 'Tehmina', 'Hassan', 'Wasiq']" >
                                                      <div class="btnchecbox" >
                                                          <div class="btn-test-checked">
                                                              <input type="checkbox"
                                                                  [(ngModel)]="showOrderBook3MinMax" (change)="setcomponentstate()">
                                                              <span>3 Min Max</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </ng-container>
                                    <div class="mchi-foot" *ngIf="openFilterFooter">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label>PLine {{price_length}}</label>
                                                    <input type="range" class="form-control" min="50" max="200"
                                                        [(ngModel)]="price_length" (change)="changearrayValue()">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label>TLine {{time_length}}</label>
                                                    <input type="range" class="form-control" min="25" max="168"
                                                        [(ngModel)]="time_length" (change)="changearrayValue()"
                                                        (mousedown)="onKeyDown($event)" (mouseup)="onKeyUp($event)" (keydown.arrowup)= "onKeyDown($event)" (keyup.arrowup)= "onKeyUp($event)" (keydown.arrowdown)= "onKeyDown($event)" (keyup.arrowdown)= "onKeyUp($event)" (keydown.arrowleft)= "onKeyDown($event)" (keyup.arrowleft)= "onKeyUp($event)" (keydown.arrowright)= "onKeyDown($event)" (keyup.arrowright)= "onKeyUp($event)">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-2 col-lg-2 pr-1 pl-1">
                                                <div class="btnchecbox">
                                                    <div class="btn-test-checked">
                                                        <input type="checkbox" [(ngModel)]="candleScroller" (change)="setcomponentstate()">
                                                        <span>Enable Scroller</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-2 col-lg-2 pr-1 pl-1">
                                                <div class="btnchecbox">
                                                    <div class="btn-test-checked">
                                                        <input type="checkbox" [(ngModel)]="popUpOnCandleStick" (change)="setcomponentstate()">
                                                        <span>CandleStick Tooltip</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-2 col-lg-2 pr-1 pl-1">
                                                <div class="btnchecbox">
                                                    <div class="btn-test-checked">
                                                        <input type="checkbox" [(ngModel)]="popUpOnCandle" (change)="setcomponentstate()">
                                                        <span>Other Charts Tooltip</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                                <div class="form-group">
                                                    <label>Candle By Date:</label>
                                                    <input type="date" [(ngModel)]="search_date" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <div class="form-group">
                                                    <label>&nbsp;</label>
                                                    <button (click)="date_search_candle()" class="btn btn-block btn-primary">Search</button>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-6 col-md-2">
                                              <label>Select TrueLevel type</label>
                                              <select class="form-control" name="searchOrderType" [(ngModel)]="truelevel_type" (change)="changearrayValue()">
                                                <option value="both">Both</option>
                                                <option value="live">Live</option>
                                                <option value="test">Test</option>
                                              </select>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <div class="form-group">
                                                    <label>&nbsp;</label>
                                                    <button class="btn btn-block btn-primary" *ngIf="show_backward"
                                                        (click)="show_backward_c()">Backward</button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <div class="form-group">
                                                    <label>&nbsp;</label>
                                                    <button class="btn btn-block btn-primary" *ngIf="show_forward"
                                                        (click)="show_forward_f()">Forward</button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                              <div class="form-group">
                                                  <label>&nbsp;</label>
                                                  <button class="btn btn-block btn-primary" *ngIf="show_forward"
                                                      (click)="show_Today_f()">ToDay</button>
                                              </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
                                                <label>&nbsp;</label>
                                                <button class="btn btn-block btn-danger" (click)="RemovesettingLocalStorage()">Refresh</button>
                                            </div>

                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2" *ngxPermissionsOnly="['abbas', 'Waleed', 'Huzaifa' ,'Wasiq', 'Tehmina', 'Hassan']">
                                                <label>&nbsp;</label>
                                                <button *ngIf="time_length < 200" class="btn btn-block btn-primary" (click)="SetTimeLength(200)" >Empty Space</button>
                                                <button *ngIf="time_length > 168" class="btn btn-block btn-success" (click)="SetTimeLength(168)">Remove Empty Space</button>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                                <label>&nbsp;</label>
                                                <button class="btn btn-block btn-danger" style="color: #FDFCFA;"
                                                        (click)="removeAllLines()">Lines Remove</button>
                                            </div>

                                        </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- Main Head End -->
              <!-- Main Body Start-->
              <div class="main-chart-body">

                  <div class="loaderiner loaderlight" *ngIf="dataLoaderWaiting">
                      <img src="../../assets/images/loaderx.gif">
                  </div>
                  <div class="main-chart-body-panel" id="main-chart-body-panel" (mousemove)="mouseMove($event)"
                      (mouseenter)="mouseenter($event)" (mousedown)="mousedown($event)" (mouseup)="mouseup($event)"
                      (mouseleave)="mouseleave($event)" (dblclick)="dblclick()">

                      <!-- Charts Start -->
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div *ngIf="!candleScroller">
                            <div  class="candlesticks-selector" *ngIf="showCandleSticks" (mousemove) = "mouseMoveOnCandels($event)" (mouseover) = "mouseOverOnCandels($event)" (mouseenter)="mouseEnterComponent($event)" >
                                <app-candlesticks [currentCoin]="currentCoin"></app-candlesticks>
                            </div>
                        </div>
                        <div *ngIf="candleScroller">
                            <div  mouseWheel (mouseWheelUp)="mouseWheelUpFunc($event)" (mouseWheelDown)="mouseWheelDownFunc($event)" class="candlesticks-selector" *ngIf="showCandleSticks" (mousemove) = "mouseMoveOnCandels($event)" (mouseover) = "mouseOverOnCandels($event)" (mouseenter)="mouseEnterComponent($event)" >
                                <app-candlesticks [currentCoin]="currentCoin"></app-candlesticks>
                            </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="truelevels-selector" id="tlevels" *ngIf="showTrueLevels" (mousemove) = "mouseMoveOnTruelevels($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-truelevels></app-truelevels>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="boxes-selector" *ngIf="showBoxes" id="box" (mousemove) = "mouseMoveOnBoxes($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-boxes></app-boxes>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="demandsupply-selector" *ngIf="showDemandSupply" id="dsupply" (mousemove) = "mouseMoveOnDemandSupply($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-demand-supply></app-demand-supply>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="resistance-selector" *ngIf="showResistance" (mousemove) = "mouseMoveOnResistance($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-resistance></app-resistance>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="strategy-selector" *ngIf="showStrategy" (mousemove) = "mouseMoveOnStrategy($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-strategy></app-strategy>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="rejection-selector" *ngIf="showRejection" (mousemove) = "mouseMoveOnRejection($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-rejection></app-rejection>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="move-selector" *ngIf="showMove" (mousemove) = "mouseMoveOnMove($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-move></app-move>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="bodymove-selector" *ngIf="showBodyMove" (mousemove) = "mouseMoveOnBodyMove($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-body-move></app-body-move>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="upperwickmove-selector" *ngIf="showUpperWickMove" (mousemove)="mouseMoveOnUpperWickMove($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-upper-wick-move></app-upper-wick-move>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="lowerwickmove-selector" *ngIf="showLowerWickMove" (mousemove)="mouseMoveOnLowerWickMove($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-lower-wick-move></app-lower-wick-move>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="topwicksignal-selector" *ngIf="showTopWickSignal" (mousemove) = "mouseMoveOnTopWickSignal($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-top-wick-signal></app-top-wick-signal>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="candeltrends-selector" *ngIf="showCandelTrends" (mousemove) = "mouseMoveOnCandelTrends($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-candel-trends></app-candel-trends>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="dailycandeltrends-selector" *ngIf="showDailyCandelTrends" (mousemove) = "mouseMoveOnDailyCandelTrends($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-candel-trends [chartTitle]="'daily-trends'"></app-candel-trends>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="dpuptrenddirection-selector" *ngIf="showDPUPTrendDirection" (mousemove)="mouseMoveOnDPUPTrendDirection($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-dp-up-trend-direction></app-dp-up-trend-direction>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="next_close-selector" *ngIf="showNextClose" (mousemove) = "mouseMoveOnNextClose($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-next-close></app-next-close>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="increasingvolume-selector" *ngIf="showIncreasingVolume" (mousemove) = "mouseMoveOnIncreasingVolume($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-increasing-volume></app-increasing-volume>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="blackdotuppressure-selector" *ngIf="showBlackDotUpPressure" (mousemove)="mouseMoveOnBlackDotUpPressure($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-black-dot-up-pressure></app-black-dot-up-pressure>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelavgdiffperc-selector" *ngIf="showSevenLevelAvgDiffPerc" (mousemove)="mouseMoveOnSevenLevelAvgDiffPerc($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-avg-diff-perc></app-seven-level-avg-diff-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="fourteenlevelavgdiffperc-selector" *ngIf="showFourteenLevelAvgDiffPerc" (mousemove)="mouseMoveOnFourteenLevelAvgDiffPerc($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-fourteen-level-avg-diff-perc></app-fourteen-level-avg-diff-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="dp1-selector" *ngIf="showDP" (mousemove) = "mouseMoveOnDP1($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-dp1></app-dp1>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="dp2-selector" *ngIf="showDP" (mousemove) = "mouseMoveOnDP2($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-dp2></app-dp2>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="dp3-selector" *ngIf="showDP" (mousemove) = "mouseMoveOnDP3($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-dp3></app-dp3>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="dp1_btc-selector" *ngIf="showDPBTC && currentCoin !== 'BTCUSDT'" (mousemove)="mouseMoveOnDP1BTC($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-dp1-btc></app-dp1-btc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="dp2_btc-selector" *ngIf="showDPBTC && currentCoin !== 'BTCUSDT'" (mousemove)="mouseMoveOnDP2BTC($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-dp2-btc></app-dp2-btc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="dp3_btc-selector" *ngIf="showDPBTC && currentCoin !== 'BTCUSDT'" (mousemove)="mouseMoveOnDP3BTC($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-dp3-btc></app-dp3-btc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelpercentile-selector" *ngIf="showSevenLevelPerc" (mousemove) = "mouseMoveOnSevenLevelPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-sevenlevelpercentile></app-sevenlevelpercentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="fourteenlevelpercentile-selector" *ngIf="showFourteenLevelPerc" (mousemove)="mouseMoveOnFourteenLevelPercentile($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-fourteen-level-percentile></app-fourteen-level-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelminvstpercentile-selector" *ngIf="showSevenLevelMinVSTPerc" (mousemove)="mouseMoveOnSevenLevelMinVSTPercentile($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-min-very-short-term-percentile></app-seven-level-min-very-short-term-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelminpercentile-selector" *ngIf="showSevenLevelMinPerc" (mousemove)="mouseMoveOnSevenLevelMinPercentile($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-min-perc></app-seven-level-min-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelminpercentilest-selector" *ngIf="showSevenLevelMinPercST" (mousemove)="mouseMoveOnSevenLevelMinPercentileST($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-min-perc-st></app-seven-level-min-perc-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelmaxpercentile-selector" *ngIf="showSevenLevelMaxPerc" (mousemove)="mouseMoveOnSevenLevelMaxPercentile($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-max-perc></app-seven-level-max-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelmaxpercentilest-selector" *ngIf="showSevenLevelMaxPercST" (mousemove)="mouseMoveOnSevenLevelMaxPercentileST($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-max-perc-st></app-seven-level-max-perc-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevel-selector" *ngIf="showSevenLevel" (mousemove)="mouseMoveOnSevenLevel($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-sevenlevel></app-sevenlevel>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="fourteenlevelavg-selector" *ngIf="showFourteenLevelAvg" (mousemove)="mouseMoveOnFourteenLevelAvg($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-fourteen-level-avg></app-fourteen-level-avg>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelst-selector" *ngIf="showSevenLevelST" (mousemove) = "mouseMoveOnSevenLevelST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-avg-st></app-seven-level-avg-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelvst-selector" *ngIf="showSevenLevelVST" (mousemove) = "mouseMoveOnSevenLevelVST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-avg-vst></app-seven-level-avg-vst>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="sevenlevelminmax-selector" *ngIf="showSevenLevelMinMax" (mousemove) = "mouseMoveOnSevenLevelMinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-seven-level-min-max></app-seven-level-min-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="fourteenlevelminmax-selector" *ngIf="showFourteenLevelMinMax" (mousemove) = "mouseMoveOnFourteenLevelMinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-fourteen-level-min-max></app-fourteen-level-min-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="blackdotpressureu10-selector" *ngIf="showBlackDotPressureU10" (mousemove) = "mouseMoveOnBlackDotPressureU10($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-black-dot-pressure-u10></app-black-dot-pressure-u10>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="askbidvolume-selector" *ngIf="showAskBidVolume" (mousemove) = "mouseMoveOnAskBidVolume($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-ask-bid-volume></app-ask-bid-volume>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="askbidvolumeb-selector" *ngIf="showAskBidVolumeB" (mousemove) = "mouseMoveOnAskBidVolumeB($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-ask-bid-volume-b></app-ask-bid-volume-b>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="askbiddelta-selector" *ngIf="showAskBidDelta" (mousemove) = "mouseMoveOnAskBidDelta($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-ask-bid-delta></app-ask-bid-delta>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="askbiddeltab-selector" *ngIf="showAskBidDeltaB" (mousemove) = "mouseMoveOnAskBidDeltaB($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-ask-bid-delta-b></app-ask-bid-delta-b>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="biddelta-selector" *ngIf="showBidDelta" (mousemove) = "mouseMoveOnBidDelta($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-bid-delta></app-bid-delta>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="askbiddivisionb-selector" *ngIf="showAskBidDivisionB" (mousemove) = "mouseMoveOnAskBidDivisionB($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-ask-bid-division-b></app-ask-bid-division-b>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="askbidcount-selector" *ngIf="showAskBidCount" (mousemove) = "mouseMoveOnAskBidCount($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-ask-bid-count></app-ask-bid-count>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="volumecomparison-selector" *ngIf="showVolumeComparison" (mousemove) = "mouseMoveOnVolumeComparison($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-volume-comparison></app-volume-comparison>
                        </div>
                      </ng-container>
                        <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="rangescomparison-selector" *ngIf="showRangesComparison" (mousemove) = "mouseMoveOnRangesComparison($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-ranges-comparison></app-ranges-comparison>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="scripttime-selector" *ngIf="showScriptTime" (mousemove) = "mouseMoveOnScriptTime($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-scripttime></app-scripttime>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="coinmetacount-selector" *ngIf="showCoinMetaCount" (mousemove) = "mouseMoveOnCoinMetaCount($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-coin-meta-count></app-coin-meta-count>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="blackwallpercentile-selector" *ngIf="showBlackWallPerc" (mousemove) = "mouseMoveOnBlackWallPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-blackwallpercentile></app-blackwallpercentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigcontractspercentile-selector" *ngIf="showBigContractsPerc" (mousemove) = "mouseMoveOnBigContractsPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-bigcontractspercentile></app-bigcontractspercentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigbuyercontractspercentilest-selector" *ngIf="showBigBuyerContractsPercST" (mousemove) = "mouseMoveOnBigBuyerContractsPercentileST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-buyers-contracts1-perc-st></app-big-buyers-contracts1-perc-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigbuyercontractspercentilevst-selector" *ngIf="showBigBuyerContractsPercVST" (mousemove) = "mouseMoveOnBigBuyerContractsPercentileVST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-buyers-contracts1-perc></app-big-buyers-contracts1-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigsellercontractspercentilest-selector" *ngIf="showBigSellerContractsPercST" (mousemove) = "mouseMoveOnBigSellerContractsPercentileST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-sellers-contracts1-perc-st></app-big-sellers-contracts1-perc-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigsellercontractspercentilevst-selector" *ngIf="showBigSellerContractsPercVST" (mousemove) = "mouseMoveOnBigSellerContractsPercentileVST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-sellers-contracts1-perc></app-big-sellers-contracts1-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigbuyersellercontractscount-selector" *ngIf="showBigBuyerSellerContractsCount" (mousemove) = "mouseMoveOnBigBuyerSellerContractsCount($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-buyers-sellers-contracts-count></app-big-buyers-sellers-contracts-count>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigbuyersellercontractsvol-selector" *ngIf="showBigBuyerSellerContractsVol" (mousemove) = "mouseMoveOnBigBuyerSellerContractsVol($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-buyers-sellers-contracts-vol></app-big-buyers-sellers-contracts-vol>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigbuyersellerdelta-selector" *ngIf="showBigBuyerSellerDelta" (mousemove) = "mouseMoveOnBigBuyerSellerDelta($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-buyer-seller-delta></app-big-buyer-seller-delta>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigbuyersellerdiff-selector" *ngIf="showBigBuyerSellerDiff" (mousemove) = "mouseMoveOnBigBuyerSellerDiff($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-buyer-seller-diff></app-big-buyer-seller-diff>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigbuyersellerdiffperc-selector" *ngIf="showBigBuyerSellerDiffPerc" (mousemove) = "mouseMoveOnBigBuyerSellerDiffPerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-buyer-seller-diff-percentile></app-big-buyer-seller-diff-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="candlepercentile-selector" *ngIf="showCandlePerc" (mousemove) = "mouseMoveOnCandlePercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-candlepercentile></app-candlepercentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="activedownbarrierpercentile-selector" *ngIf="showActiveDownBarrierPerc" (mousemove)="mouseMoveOnActiveDownBarrierPercentile($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-active-down-barrier></app-active-down-barrier>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="activeupbarrierpercentile-selector" *ngIf="showActiveUpBarrierPerc" (mousemove)="mouseMoveOnActiveUpBarrierPercentile($event)" (mouseenter)="mouseEnterComponent($event)">
                            <app-active-up-barrier></app-active-up-barrier>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="candlestpercentile-selector" *ngIf="showCandleSTPerc" (mousemove) = "mouseMoveOnCandleSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-candle-short-term-percentile></app-candle-short-term-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto') || coin['coin_categories'].includes('partial_auto') || coin['coin_categories'].includes('manual'))">
                        <div class="candlevstpercentile-selector" *ngIf="showCandleVSTPerc" (mousemove) = "mouseMoveOnCandleVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-candle-vstpercentile></app-candle-vstpercentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="twowaybar-selector" *ngIf="showTwoWayBar" (mousemove) = "mouseMoveOnTwoWayBar($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-two-way-bar></app-two-way-bar>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="blackwallavg-selector" *ngIf="showBlackWallAverg" (mousemove) = "mouseMoveOnBlackWallAverage($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-black-wall-averg></app-black-wall-averg>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bigcontractsavg-selector" *ngIf="showBigContractsAverg" (mousemove) = "mouseMoveOnBigContractsAverage($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-big-contracts-averg></app-big-contracts-averg>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook1avg-selector" *ngIf="showOrderBook1Averg" (mousemove)="mouseMoveOnOrderBook1Average($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook1-perc-diff-avg></app-orderbook1-perc-diff-avg>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook2avg-selector" *ngIf="showOrderBook2Averg" (mousemove)="mouseMoveOnOrderBook2Average($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook2-perc-diff-avg></app-orderbook2-perc-diff-avg>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook3avg-selector" *ngIf="showOrderBook3Averg" (mousemove)="mouseMoveOnOrderBook3Average($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook3-perc-diff-avg></app-orderbook3-perc-diff-avg>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook1avgperc-selector" *ngIf="showOrderBook1AvergPerc" (mousemove)="mouseMoveOnOrderBook1AveragePerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook1-perc-diff-avg-perc></app-orderbook1-perc-diff-avg-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook2avgperc-selector" *ngIf="showOrderBook2AvergPerc" (mousemove)="mouseMoveOnOrderBook2AveragePerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook2-perc-diff-avg-perc></app-orderbook2-perc-diff-avg-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook3avgperc-selector" *ngIf="showOrderBook3AvergPerc" (mousemove)="mouseMoveOnOrderBook3AveragePerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook3-perc-diff-avg-perc></app-orderbook3-perc-diff-avg-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook1minperc-selector" *ngIf="showOrderBook1MinPerc" (mousemove)="mouseMoveOnOrderBook1MinPerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook1-perc-diff-min-perc></app-orderbook1-perc-diff-min-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook2minperc-selector" *ngIf="showOrderBook2MinPerc" (mousemove)="mouseMoveOnOrderBook2MinPerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook2-perc-diff-min-perc></app-orderbook2-perc-diff-min-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook3minperc-selector" *ngIf="showOrderBook3MinPerc" (mousemove)="mouseMoveOnOrderBook3MinPerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook3-perc-diff-min-perc></app-orderbook3-perc-diff-min-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook1maxperc-selector" *ngIf="showOrderBook1MaxPerc" (mousemove)="mouseMoveOnOrderBook1MaxPerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook1-perc-diff-max-perc></app-orderbook1-perc-diff-max-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook2maxperc-selector" *ngIf="showOrderBook2MaxPerc" (mousemove)="mouseMoveOnOrderBook2MaxPerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook2-perc-diff-max-perc></app-orderbook2-perc-diff-max-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook3maxperc-selector" *ngIf="showOrderBook3MaxPerc" (mousemove)="mouseMoveOnOrderBook3MaxPerc($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook3-perc-diff-max-perc></app-orderbook3-perc-diff-max-perc>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook1minmax-selector" *ngIf="showOrderBook1MinMax" (mousemove)="mouseMoveOnOrderBook1MinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook1-min-max></app-orderbook1-min-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook2minmax-selector" *ngIf="showOrderBook2MinMax" (mousemove)="mouseMoveOnOrderBook2MinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook2-min-max></app-orderbook2-min-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="orderbook3minmax-selector" *ngIf="showOrderBook3MinMax" (mousemove)="mouseMoveOnOrderBook3MinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-orderbook3-min-max></app-orderbook3-min-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="upperwick-selector" *ngIf="showUpperWick" (mousemove) = "mouseMoveOnUpperwick($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-upper-wick-percentile></app-upper-wick-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bupperwick-selector" *ngIf="showBUpperWick" (mousemove) = "mouseMoveOnBUpperwick($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-b-upper-wick></app-b-upper-wick>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="upperwickst-selector" *ngIf="showUpperWickST" (mousemove) = "mouseMoveOnUpperwickST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-upper-wick-short-term-percentile></app-upper-wick-short-term-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="upperwickvst-selector" *ngIf="showUpperWickVST" (mousemove) = "mouseMoveOnUpperwickVST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-upper-wick-vstpercentile></app-upper-wick-vstpercentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="lowerwick-selector" *ngIf="showLowerWick" (mousemove) = "mouseMoveOnLowerwick($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-lower-wick-percentile></app-lower-wick-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="blowerwick-selector" *ngIf="showBLowerWick" (mousemove) = "mouseMoveOnBLowerwick($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-blower-wick></app-blower-wick>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="lowerwickst-selector" *ngIf="showLowerWickST" (mousemove) = "mouseMoveOnLowerwickST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-lower-wick-short-term-percentile></app-lower-wick-short-term-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="lowerwickvst-selector" *ngIf="showLowerWickVST" (mousemove) = "mouseMoveOnLowerwickVST($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-lower-wick-vstpercentile></app-lower-wick-vstpercentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="bodypercentile-selector" *ngIf="showBodyPercentile" (mousemove) = "mouseMoveOnBodyPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-body-percentile></app-body-percentile>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="blackwallminmax-selector" *ngIf="showBlackWallMinMax" (mousemove) = "mouseMoveOnBlackWallMinMax($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-black-wall-min-max></app-black-wall-min-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="telegram-selector" *ngIf="showTelegramSentiment" (mousemove) = "mouseMoveOnTelegram($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-telegram-sentiment></app-telegram-sentiment>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="telegramitperc-selector" *ngIf="showTelegramITPercentile" (mousemove) = "mouseMoveOnTelegramITPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-telegram-perc-it></app-telegram-perc-it>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="telegramstperc-selector" *ngIf="showTelegramSTPercentile" (mousemove) = "mouseMoveOnTelegramSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-telegram-perc-st></app-telegram-perc-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="telegramvstperc-selector" *ngIf="showTelegramVSTPercentile" (mousemove) = "mouseMoveOnTelegramVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-telegram-perc-vst></app-telegram-perc-vst>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="twitter-selector" *ngIf="showTwitterSentiment" (mousemove) = "mouseMoveOnTwitter($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-twitter-sentiment></app-twitter-sentiment>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="twitteritperc-selector" *ngIf="showTwitterITPercentile" (mousemove) = "mouseMoveOnTwitterITPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-twitter-perc-it></app-twitter-perc-it>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="twitterstperc-selector" *ngIf="showTwitterSTPercentile" (mousemove) = "mouseMoveOnTwitterSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-twitter-perc-st></app-twitter-perc-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="twittervstperc-selector" *ngIf="showTwitterVSTPercentile" (mousemove) = "mouseMoveOnTwitterVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-twitter-perc-vst></app-twitter-perc-vst>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="news-selector" *ngIf="showNewsSentiment" (mousemove) = "mouseMoveOnNews($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-news-sentiment></app-news-sentiment>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="newsitperc-selector" *ngIf="showNewsITPercentile" (mousemove) = "mouseMoveOnNewsITPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-news-perc-it></app-news-perc-it>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="newsstperc-selector" *ngIf="showNewsSTPercentile" (mousemove) = "mouseMoveOnNewsSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-news-perc-st></app-news-perc-st>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="newsvstperc-selector" *ngIf="showNewsVSTPercentile" (mousemove) = "mouseMoveOnNewsVSTPercentile($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-news-perc-vst></app-news-perc-vst>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="t3minmax-selector" *ngIf="showT3MinMax" (mousemove) = "mouseMoveOnT3($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-t3-cotmin-max></app-t3-cotmin-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="t2minmax-selector" *ngIf="showT2MinMax" (mousemove) = "mouseMoveOnT2($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-t2-cotmin-max></app-t2-cotmin-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="t4minmax-selector" *ngIf="showT4MinMax" (mousemove) = "mouseMoveOnT4($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-t4-cotmin-max></app-t4-cotmin-max>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="coin.symbol == currentCoin && (coin['coin_categories'].includes('auto'))">
                        <div class="t2bcp-selector" *ngIf="showT2BCP" (mousemove) = "mouseMoveOnT2BCP($event)"(mouseenter)="mouseEnterComponent($event)">
                            <app-t2-bcp15-ask-bid-vol></app-t2-bcp15-ask-bid-vol>
                        </div>
                      </ng-container>
                      <!-- Charts End -->
                  </div>
                  <div class="main-chart-codinate_y" [style.left.px]="left_coord" *ngIf="left_coord > 0">
                      <span class="time" style="color: #FDFCFA;">{{openTime_human_readible_}}</span>
                  </div>

                  <div class="main-chart-codinate_x" [style.top.px]="top_coord" *ngIf="top_coord > 0">
                      <span class="price" *ngIf="showPriceLine">{{price}}</span>
                  </div>


                  <div *ngFor="let item of lineArray; let i = index">
                      <div class="main-chart-liney-codinate" [style.left.px]="item.fromleft">
                          <span class="remove_line" (click)="removeline(i)">x</span>
                          <span class="time">{{item.time}}</span>
                      </div>
                      <div class="main-chart-linex-codinate" [style.top.px]="item.fromtop " >
                          <span class="remove_line" (click)="removeline(i)">x</span>
                          <span class="price" *ngIf="showPriceLine">{{item.price}}</span>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTooltip && popUpOnCandleStick">
                      <div class="tootip-item">
                          <p>
                              <strong>High :</strong> {{high_}}
                          </p>
                          <p>
                              <strong>Low :</strong> {{low_}}
                          </p>
                          <p>
                              <strong>Open :</strong> {{open_}}
                          </p>
                          <p>
                              <strong>Close :</strong> {{close_}}
                          </p>
                          <p>
                              <strong>Volume :</strong> {{volume_}}
                          </p>
                          <p>
                              <strong>Ask :</strong> {{ask_}}
                          </p>
                          <p>
                              <strong>Bid :</strong> {{bid_}}
                          </p>
                          <p>
                              <strong>Ask_B :</strong> {{ask_b_}}
                          </p>
                          <p>
                              <strong>Bid_B :</strong> {{bid_b_}}
                          </p>
                          <p>
                              <strong>Ask Bid Volume :</strong>{{total_volume_}}
                          </p>
                          <p>
                              <strong>Demand Base :</strong> {{demandbasecandel_}}
                          </p>
                          <p>
                              <strong>Supply Base :</strong> {{supplybasecandel_}}
                          </p>
                          <p>
                              <strong>Swing :</strong> {{swing_}}
                          </p>
                          <p>
                              <strong>Open Time :</strong> {{openTime_human_readible_}}
                          </p>
                          <p>
                              <strong>Close Time :</strong>{{closeTime_human_readible_}}
                          </p>
                          <p>
                              <strong>Top Wick :</strong>{{top_wick_prices_}}
                          </p>
                          <p>
                              <strong>Active Down Barrier :</strong>{{active_down_barrier_}}
                          </p>
                          <p>
                              <strong>Active Up Barrier :</strong>{{active_up_barrier_}}
                          </p>
                          <p>
                              <strong>Down Barrier Daily:</strong>{{down_barrier_value_daily_}}
                          </p>
                          <p>
                              <strong>Up Barrier Daily:</strong>{{up_barrier_value_daily_}}
                          </p>
                          <p>
                            <strong>Barrier Avg Move:</strong>{{barrier_avg_move_}}
                          </p>

                          <!-- Fill hal ye hide ha  -->
                          <!-- <p><strong>DP1 : </strong>{{DP1_}}</p>
                          <p><strong>DP2 : </strong>{{DP2_}}</p>
                          <p><strong>DP3 : </strong>{{DP3_}}</p>
                          <p><strong>UP1 : </strong>{{UP1_}}</p>
                          <p><strong>UP2 : </strong>{{UP2_}}</p>
                          <p><strong>UP3 : </strong>{{UP3_}}</p> -->

                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTruelevelTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong style="font-size: 15px;">L1 = </strong><span style="font-size: 12px;">Buy: </span>{{L1_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L1_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L2 </strong><span style="font-size: 12px;">Buy: </span>{{L2_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L2_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L3 </strong><span style="font-size: 12px;">Buy: </span>{{L3_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L3_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L4 </strong><span style="font-size: 12px;">Buy: </span>{{L4_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L4_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L5 </strong><span style="font-size: 12px;">Buy: </span>{{L5_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L5_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L6 </strong><span style="font-size: 12px;">Buy: </span>{{L6_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L6_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L7 </strong><span style="font-size: 12px;">Buy: </span>{{L7_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L7_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L8 </strong><span style="font-size: 12px;">Buy: </span>{{L8_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L8_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L9 </strong><span style="font-size: 12px;">Buy: </span>{{L9_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L9_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L10 </strong><span style="font-size: 12px;">Buy: </span>{{L10_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L10_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L11 </strong><span style="font-size: 12px;">Buy: </span>{{L11_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L11_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L12 </strong><span style="font-size: 12px;">Buy: </span>{{L12_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L12_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L13 </strong><span style="font-size: 12px;">Buy: </span>{{L13_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L13_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L14 </strong><span style="font-size: 12px;">Buy: </span>{{L14_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L14_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L15 </strong><span style="font-size: 12px;">Buy: </span>{{L15_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L15_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L16 </strong><span style="font-size: 12px;">Buy: </span>{{L16_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L16_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L17 </strong><span style="font-size: 12px;">Buy: </span>{{L17_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L17_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L18 </strong><span style="font-size: 12px;">Buy: </span>{{L18_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L18_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L19 </strong><span style="font-size: 12px;">Buy: </span>{{L19_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L19_sell_price_}}
                          </p>
                          <p>
                              <strong style="font-size: 15px;">L20 </strong><span style="font-size: 12px;">Buy: </span>{{L20_buy_price_}}<span style="font-size: 12px;"> Sell:</span>{{L20_sell_price_}}
                          </p>
                      </div>
                  </div>
                  <!-- <div class="tooltip-box" [style.left.px]="left_coord + 20" [style.top.px]="tooltip_coord"
                      *ngIf="showDKBoxeTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Rinkos RM: </strong>{{rinkos_rm_}}
                          </p>
                          <p>
                              <strong>Rinkos HK: </strong>{{rinkos_hk_}}
                          </p>
                          <p>
                              <strong>Spike Buy: </strong>{{rinkos_spike_buy_}}
                          </p>
                          <p>
                              <strong>Spike Sell: </strong>{{rinkos_spike_sell_}}
                          </p>
                          <p>
                              <strong>Rinkos Supply: </strong>{{rinkos_supply_}}
                          </p>
                      </div>
                  </div> -->
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDemandSupplyTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Candle Type :</strong> {{demand_supply_candel_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showResistanceTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Resistance Value :</strong> {{resistance_value_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showStrategyTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Strategy Value :</strong> {{strategy_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showRejectionTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Rejected Candle Type :</strong> {{rejected_candle_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showMoveTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Move Value :</strong> {{move_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBodyMoveTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Body Move:</strong> {{body_move_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showUpperWickMoveTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Upper Wick Move:</strong> {{upper_wick_move_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showLowerWickMoveTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Lower Wick Move:</strong> {{lower_wick_move_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTopWickSignalTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Top Wick Aggregate :</strong> {{top_wick_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showCandelTrendsTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Candel Trends :</strong> {{candel_trends_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDailyCandelTrendsTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Daily Candel Trends :</strong> {{daily_candel_trends_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDPUPTrendDirectionTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Daily DPUP Trend Direction :</strong> {{dpup_trend_direction_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showNextCloseTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Next Close :</strong> {{next_close_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDP1Tooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p *ngIf="DP1_ > 0 || DP1_ < 0">
                              <strong>DP1 :</strong> {{DP1_}} %
                          </p>
                          <p *ngIf="UP1_ > 0 || UP1_ < 0">
                              <strong>UP1 :</strong> {{UP1_}} %
                          </p>
                          <p *ngIf="DP1_ > 0 || DP1_ < 0">
                              <strong>DP1 Perc :</strong> {{DP1_perc_}}
                          </p>
                          <p *ngIf="UP1_ > 0 || UP1_ < 0">
                              <strong>UP1 Perc :</strong> {{UP1_perc_}}
                          </p>
                      </div>
                  </div>

                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDP1BTCTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p *ngIf="DP1_BTC_ > 0 || DP1_BTC_ < 0">
                              <strong>DP1 BTC :</strong> {{DP1_BTC_}} %
                          </p>
                          <p *ngIf="UP1_BTC_ > 0 || UP1_BTC_ < 0">
                              <strong>UP1 BTC :</strong> {{UP1_BTC_}} %
                          </p>
                          <p *ngIf="DP1_BTC_ > 0 || DP1_BTC_ < 0">
                              <strong>DP1 Perc BTC :</strong> {{DP1_perc_BTC_}}
                          </p>
                          <p *ngIf="UP1_BTC_ > 0 || UP1_BTC_ < 0">
                              <strong>UP1 Perc BTC :</strong> {{UP1_perc_BTC_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDP2Tooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p *ngIf="DP2_ > 0 || DP2_ < 0">
                              <strong>DP2 :</strong> {{DP2_}} %
                          </p>
                          <p *ngIf="UP2_ > 0 || UP2_ < 0">
                              <strong>UP2 :</strong> {{UP2_}} %
                          </p>
                          <p *ngIf="DP2_ > 0 || DP2_ < 0">
                              <strong>DP2 Perc :</strong> {{DP2_perc_}}
                          </p>
                          <p *ngIf="UP2_ > 0 || UP2_ < 0">
                              <strong>UP2 Perc :</strong> {{UP2_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDP2BTCTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p *ngIf="DP2_BTC_ > 0 || DP2_BTC_ < 0">
                              <strong>DP2 BTC :</strong> {{DP2_BTC_}} %
                          </p>
                          <p *ngIf="UP2_BTC_ > 0 || UP2_BTC_ < 0">
                              <strong>UP2 BTC :</strong> {{UP2_BTC_}} %
                          </p>
                          <p *ngIf="DP2_BTC_ > 0 || DP2_BTC_ < 0">
                              <strong>DP2 Perc BTC :</strong> {{DP2_perc_BTC_}}
                          </p>
                          <p *ngIf="UP2_BTC_ > 0 || UP2_BTC_ < 0">
                              <strong>UP2 Perc BTC :</strong> {{UP2_perc_BTC_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDP3Tooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p *ngIf="DP3_ > 0 || DP3_ < 0">
                              <strong>DP3 :</strong> {{DP3_}} %
                          </p>
                          <p *ngIf="UP3_ > 0 || UP3_ < 0">
                              <strong>UP3 :</strong> {{UP3_}} %
                          </p>
                          <p *ngIf="DP3_ > 0 || DP3_ < 0">
                              <strong>DP3 Perc :</strong> {{DP3_perc_}}
                          </p>
                          <p *ngIf="UP3_ > 0 || UP3_ < 0">
                              <strong>UP3 Perc :</strong> {{UP3_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showDP3BTCTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p *ngIf="DP3_BTC_ > 0 || DP3_BTC_ < 0">
                              <strong>DP3 BTC :</strong> {{DP3_BTC_}} %
                          </p>
                          <p *ngIf="UP3_BTC_ > 0 || UP3_BTC_ < 0">
                              <strong>UP3 BTC :</strong> {{UP3_BTC_}} %
                          </p>
                          <p *ngIf="DP3_BTC_ > 0 || DP3_BTC_ < 0">
                              <strong>DP3 Perc BTC :</strong> {{DP3_perc_BTC_}}
                          </p>
                          <p *ngIf="UP3_BTC_ > 0 || UP3_BTC_ < 0">
                              <strong>UP3 Perc BTC :</strong> {{UP3_perc_BTC_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigRaiseDropTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Big Drop :</strong> {{big_drop_}}
                          </p>
                          <p>
                              <strong>Big Raise :</strong> {{big_raise_}}
                          </p>
                          <p>
                              <strong>Big Drop Price:</strong> {{big_drop_price_}}
                          </p>
                          <p>
                              <strong>Big Raise Price:</strong> {{big_raise_price_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBlackDotUpPressureTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Black Dot Up Pressure :</strong> {{black_dot_up_ressure_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenLevelAvgDiffPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Seven Level Avg Diff Perc:</strong> {{seven_level_avg_diff_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showFourteenLevelAvgDiffPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Fourteen Level Avg Diff Perc:</strong> {{fourteen_level_avg_diff_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBlackDotPressureU10Tooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Black Dot Pressure U10 :</strong> {{black_dot_pressure_u10_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showAskBidVolumeTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Bid Volume :</strong> {{ask_bid_volume_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showAskBidVolumeBTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Bid Volume B :</strong> {{ask_bid_volume_b_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showAskBidCountTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Count :</strong> {{ask_count_}}
                          </p>
                          <p>
                              <strong>Bid Count :</strong> {{bid_count_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showCoinMetaCountTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Coin Meta Count:</strong> {{coin_meta_count_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showVolumeComparisonTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Volume Comparison:</strong> {{volume_comparison_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showRangesComparisonTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ranges Comparison:</strong> {{ranges_comparison_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showScriptTimeTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Time Taken:</strong> {{script_time_}} mins
                          </p>
                      </div>
                  </div>

                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBlackwallPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>BlackWall Perc :</strong> {{black_wall_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>SevenLevel Perc :</strong> {{seven_level_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showFourteenlevelPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>FourteenLevel Perc :</strong> {{fourteen_level_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelMinVSTPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>SevenLevelMin VST Perc :</strong> {{seven_level_min_vst_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelMinPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>SevenLevelMin Perc :</strong> {{seven_level_min_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelMinPercSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>SevenLevelMin Perc ST :</strong> {{seven_level_min_perc_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelMaxPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>SevenLevelMax Perc :</strong> {{seven_level_max_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelMaxPercSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>SevenLevelMax Perc ST :</strong> {{seven_level_max_perc_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigcontractsPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Big Contracts Perc :</strong> {{big_contracts_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigBuyercontractsPercSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>LBBCTST:</strong> {{big_buyer_contracts_perc_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigBuyercontractsPercVSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>LBBCTLT:</strong> {{big_buyer_contracts_perc_vst_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigSellercontractsPercSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>LBSCTST:</strong> {{big_seller_contracts_perc_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigSellercontractsPercVSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>LBSCTLT:</strong> {{big_seller_contracts_perc_vst_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigBuyerSellercontractsCountTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Buyers Count:</strong> {{big_buyer_contracts_count_}}
                          </p>
                          <p>
                              <strong>Sellers Count:</strong> {{big_seller_contracts_count_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigBuyerSellercontractsVolTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Buyers Vol:</strong> {{big_buyer_contracts_vol_}}
                          </p>
                          <p>
                              <strong>Sellers Vol:</strong> {{big_seller_contracts_vol_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigBuyerSellerDeltaTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Hourly Big Contractor Delta:</strong> {{big_buyer_seller_delta_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigBuyerSellerDiffTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Hourly Big Contractor Diff:</strong> {{big_buyer_seller_diff_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigBuyerSellerDiffPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Hourly Big Contractor Diff Perc:</strong> {{big_buyer_seller_diff_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showCandlePercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Candle Perc :</strong> {{candle_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showActiveDownBarrierPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Percentile :</strong> {{active_down_barrier_per_}}
                          </p>
                          <p>
                              <strong>Barrier Value :</strong> {{active_down_barrier_}}
                          </p>
                          <p>
                              <strong>Candle Volume :</strong> {{active_down_bar_vol_}}
                          </p>
                          <p>
                              <strong>Candle 5 Volume :</strong> {{active_down_bar_5_vol_}}
                          </p>
                          <p>
                              <strong>Candle Date :</strong> {{active_down_bar_date_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showActiveUpBarrierPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Percentile :</strong> {{active_up_barrier_per_}}
                          </p>
                          <p>
                              <strong>Barrier Value :</strong> {{active_up_barrier_}}
                          </p>
                          <p>
                              <strong>Candle Volume :</strong> {{active_up_bar_vol_}}
                          </p>
                          <p>
                              <strong>Candle 5 Volume :</strong> {{active_up_bar_5_vol_}}
                          </p>
                          <p>
                              <strong>Candle Date :</strong> {{active_up_bar_date_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showCandleSTPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Candle ST Perc :</strong> {{candle_st_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showCandleVSTPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Candle VST Perc :</strong> {{candle_vst_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTwoWayBarTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Volume :</strong> {{ask_volume_}}
                          </p>
                          <p>
                              <strong>Bid Volume :</strong> {{bid_volume_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showUpperwickPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>UpperWick Perc :</strong> {{upper_wick_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBUpperwickTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>B UpperWick:</strong> {{b_upper_wick_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showUpperwickSTPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>UpperWick ST Perc :</strong> {{upper_wick_st_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showUpperwickVSTPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>UpperWick VST Perc :</strong> {{upper_wick_vst_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showLowerwickPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>LowerWick Perc :</strong> {{lower_wick_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBLowerwickTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>B LowerWick :</strong> {{b_lower_wick_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showLowerwickSTPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>LowerWick ST Perc :</strong> {{lower_wick_st_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showLowerwickVSTPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>LowerWick VST Perc :</strong> {{lower_wick_vst_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBodyPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Body Perc :</strong> {{body_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelMinMaxTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Seven Level Max :</strong> {{sevenlevel_max_}}
                          </p>
                          <p>
                              <strong>Seven Level Min :</strong> {{sevenlevel_min_}}
                          </p>
                      </div>
                  </div>












                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook1AvergTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 1 Avg :</strong> {{_1_perc_diff_avg_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook2AvergTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 2 Avg :</strong> {{_2_perc_diff_avg_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook3AvergTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 3 Avg :</strong> {{_3_perc_diff_avg_}}
                          </p>
                      </div>
                  </div>








                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook1AvergPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 1 Avg Perc :</strong> {{_1_perc_diff_avg_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook2AvergPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 2 Avg Perc :</strong> {{_2_perc_diff_avg_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook3AvergPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 3 Avg Perc :</strong> {{_3_perc_diff_avg_perc_}}
                          </p>
                      </div>
                  </div>






                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook1MinPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 1 Min Perc :</strong> {{_1_perc_diff_min_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook2MinPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 2 Min Perc :</strong> {{_2_perc_diff_min_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook3MinPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 3 Min Perc :</strong> {{_3_perc_diff_min_perc_}}
                          </p>
                      </div>
                  </div>







                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook1MaxPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 1 Max Perc :</strong> {{_1_perc_diff_max_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook2MaxPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 2 Max Perc :</strong> {{_2_perc_diff_max_perc_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook3MaxPercTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 3 Max Perc :</strong> {{_3_perc_diff_max_perc_}}
                          </p>
                      </div>
                  </div>












                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook1MinMaxTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 1 Max :</strong> {{_1_perc_diff_max_}}
                          </p>
                          <p>
                              <strong>Order Book 1 Min :</strong> {{_1_perc_diff_min_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook2MinMaxTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 2 Max :</strong> {{_2_perc_diff_max_}}
                          </p>
                          <p>
                              <strong>Order Book 2 Min :</strong> {{_2_perc_diff_min_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showOrderBook3MinMaxTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Order Book 3 Max :</strong> {{_3_perc_diff_max_}}
                          </p>
                          <p>
                              <strong>Order Book 3 Min :</strong> {{_3_perc_diff_min_}}
                          </p>
                      </div>
                  </div>



















                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showFourteenlevelMinMaxTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Fourteen Level Max :</strong> {{fourteenlevel_max_}}
                          </p>
                          <p>
                              <strong>Fourteen Level Min :</strong> {{fourteenlevel_min_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBlackwallMinMaxTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Black Wall Max :</strong> {{blackwall_max_}}
                          </p>
                          <p>
                              <strong>Black Wall Min :</strong> {{blackwall_min_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBlackwallAvgTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Black Wall Avg :</strong> {{black_wall_avg_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelAvgTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Seven Level Avg :</strong> {{seven_level_avg_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showFourteenlevelAvgTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Fourteen Level Avg :</strong> {{fourteen_level_avg_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelAvgVSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Seven Level VST :</strong> {{seven_level_avg_vst_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showSevenlevelAvgSTTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Seven Level ST :</strong> {{seven_level_avg_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigcontractsAvgTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Big Contracts Avg :</strong> {{big_contracts_avg_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBigDeltaTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Big Delta :</strong> {{big_delta_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTelegramTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Positive Sentiment :</strong> {{tele_positive_sen_}}
                          </p>
                          <p>
                              <strong>Negative Sentiment :</strong> {{tele_negative_sen_}}
                          </p>
                          <p>
                              <strong>Neutral Sentiment :</strong> {{tele_neutral_sen_}}
                          </p>
                          <p>
                              <strong>Total Sentiment :</strong> {{tele_total_sen_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTelegramITPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Telegram IT Perc :</strong> {{telegram_perc_it_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTelegramSTPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Telegram ST Perc :</strong> {{telegram_perc_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTelegramVSTPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Telegram VST Perc :</strong> {{telegram_perc_vst_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTwitterTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Positive Sentiment :</strong> {{twit_positive_sen_}}
                          </p>
                          <p>
                              <strong>Negative Sentiment :</strong> {{twit_negative_sen_}}
                          </p>
                          <p>
                              <strong>Neutral Sentiment :</strong> {{twit_neutral_sen_}}
                          </p>
                          <p>
                              <strong>Total Sentiment :</strong> {{twit_total_sen_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTwitterITPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Twitter IT Perc :</strong> {{twitter_perc_it_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTwitterSTPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Twitter ST Perc :</strong> {{twitter_perc_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showTwitterVSTPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Twitter VST Perc :</strong> {{twitter_perc_vst_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showNewsTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Positive Sentiment :</strong> {{news_positive_sen_}}
                          </p>
                          <p>
                              <strong>Negative Sentiment :</strong> {{news_negative_sen_}}
                          </p>
                          <p>
                              <strong>Neutral Sentiment :</strong> {{news_neutral_sen_}}
                          </p>
                          <p>
                              <strong>Total Sentiment :</strong> {{news_total_sen_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showNewsITPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>News IT Perc :</strong> {{news_perc_it_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showNewsSTPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>News ST Perc :</strong> {{news_perc_st_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showNewsVSTPerctooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>News VST Perc :</strong> {{news_perc_vst_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showT1Tooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>T1 Max:</strong> {{t1_max_}}
                          </p>
                          <p>
                              <strong>T1 Min:</strong> {{t1_min_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showT2Tooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>T2 Max:</strong> {{t2_max_}}
                          </p>
                          <p>
                              <strong>T2 Min:</strong> {{t2_min_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showT4Tooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>T4 Max:</strong> {{t4_max_}}
                          </p>
                          <p>
                              <strong>T4 Min:</strong> {{t4_min_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showAskBidDeltaTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Bid Delta:</strong> {{ask_bid_delta_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showAskBidDeltaBTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Bid Delta B :</strong> {{ask_bid_delta_b_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showBidDeltaTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Bid Division:</strong> {{bid_delta_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showAskBidDivisionBTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>Ask Bid Division B:</strong> {{ask_bid_division_b_}}
                          </p>
                      </div>
                  </div>
                  <div class="tooltip-box" [style.left.px]="left_coord + deduction" [style.top.px]="tooltip_coord"
                      *ngIf="showT2BCPTooltip && popUpOnCandle">
                      <div class="tootip-item">
                          <p>
                              <strong>T2BCP Max :</strong> {{t2bcp_max_}}
                          </p>
                          <p>
                              <strong>T2BCP Min :</strong> {{t2bcp_min_}}
                          </p>
                      </div>
                  </div>

              </div>
              <!-- Main Body End -->
          </div>
      </div>
  </div>
  </ng-container>
</ng-container>
