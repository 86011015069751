import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApicallsService {

  constructor(private http: HttpClient) { }
  public  binanceApiURL: string = environment.binanceApiURL;
  public  binanceApiURL_rk: string = environment.binanceApiURL_rk;
  public  bamApiURL: string = environment.bamApiURL;
  public  binanceChartApi : string = environment.binanceChartApi;
  public  binanceChartApi_rk : string = environment.binanceChartApi_rk;
  public  bamChartApi : string = environment.bamChartApi;
  public  coinBaseProChartApi : string = environment.coinBaseProChartApi;



   getCharts(admin_id : string,coin :string,application_mode: string,exchange: string){

    if(exchange == 'binance'){
      var url = this.binanceChartApi;
    }else if(exchange == 'bam'){
      var url = this.bamChartApi
    }else{
      var url = this.coinBaseProChartApi;
    }
    return this.http.post<any>(url, {admin_id,coin,application_mode})
    .pipe(map(resp => {
        return resp;
    }));
  }//End of getCharts



  getCharts_rk(admin_id : string,coin :string,application_mode: string,exchange: string){

    if(exchange == 'binance'){
      var url = this.binanceChartApi_rk;
    }else if(exchange == 'bam'){
      var url = this.bamChartApi
    }else{
      var url = this.coinBaseProChartApi;
    }
    return this.http.post<any>(url, {admin_id,coin,application_mode})
    .pipe(map(resp => {
        return resp;
    }));
  }//End of getCharts



  listDashboardData(_id : string,coin :string,exchange: string){
    let url = (exchange == 'bam')?(this.bamApiURL+'api/listDashboardData'):(this.binanceApiURL+`apiEndPoint/listDashboardData`);
    return this.http.post<any>(url, {_id,coin,exchange})
    .pipe(map(resp => {
        return resp;
    }));
  }//End of listDashboardData


  listManualOrderComponent(_id : string){
    return this.http.post<any>(this.binanceApiURL+`apiEndPoint/listManualOrderComponent`, {_id})
    .pipe(map(resp => {
        return resp;
    }));
  }//End of listManualOrderComponent


  CoinArray: any = [];
  array = new BehaviorSubject<any>(this.CoinArray);
  coinData = this.array.asObservable();


  listCurrentUserCoins(admin_id : string,exchange : string){
    // let url = (exchange == 'bam') ? (this.bamApiURL + 'api/listUserCoinsAPI') : (this.binanceApiURL + `apiEndPoint/listUserCoinsApi`);
    // let url = this.binanceApiURL + `apiEndPoint/listUserCoinsApi`;
    let url = this.binanceApiURL + `apiEndPoint/getAllGlobalCoins`;
    return this.http.post<any>(url,{admin_id, "candleCoins": "candleCoins"}).pipe(map(resp=>{
      this.array.next(resp)
      return resp;
    }))
  }//End of listCurrentUserCoins

  listCurrentmarketPrice(coin : string,exchange: string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listCurrentmarketPrice',{coin,exchange}).pipe(map(resp=>{
      return resp;
    }))
  }//End of listCurrentmarketPrice

  listManualOrderDetail(coin : string,_id : string,exchange: string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listManualOrderDetail',{coin,_id,exchange}).pipe(map(resp=>{
      return resp;
    }))
  }//End of listManualOrderDetail

  createManualOrder(orderArr,tempOrderArr,orderId){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/createManualOrder',{orderArr,tempOrderArr,orderId}).pipe(map(resp=>{
       return resp;
    }))
  }//End of createManualOrder


  createManualOrderByChart(orderArr,tempOrderArr,orderId){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/createManualOrderByChart',{orderArr,tempOrderArr,orderId}).pipe(map(resp=>{
       return resp;
    }))
  }//End of createManualOrderByChart

  makeManualOrderSetForSell(orderArr,orderId){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/makeManualOrderSetForSell',{orderArr,orderId}).pipe(map(resp=>{
       return resp;
    }))
  }//End of makeManualOrderSetForSell

  createAutoOrder(orderArr){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/createAutoOrder',{orderArr}).pipe(map(resp=>{
       return resp;
    }))
  }//End of createAutoOrder


  listOrderListing(postData){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listOrderListing',{postData}).pipe(map(resp=>{
       return resp;
    }))
  }//End of listOrderListing



  calculate_average_profit(postData){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/calculate_average_profit',{postData}).pipe(map(resp=>{
       return resp;
    }))
  }//End of calculate_average_profit





  listAutoOrderDetail(_id:string,exchange :string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listAutoOrderDetail',{_id,exchange}).pipe(map(resp=>{
      return resp;
    }))
  }//End of listAutoOrderDetail


  listmarketPriceMinNotation(coin : string,exchange : string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listmarketPriceMinNotation',{coin,exchange}).pipe(map(resp=>{
      return resp;
    }))
  }//End of listmarketPriceMinNotation

  playOrder(orderId : string,status : string,exchange : string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/playOrder',{orderId,status,exchange}).pipe(map(resp=>{
      return resp;
    }))
  }//End of playOrder

  listOrderDetail(orderId : string,exchange : string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listOrderDetail',{orderId,exchange}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of listOrderDetail



  deleteOrder(orderId : string,exchange : string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/deleteOrder',{orderId,exchange}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of deleteOrder


  listOrderById(orderId : string,exchange : string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listOrderById',{orderId,exchange}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of listOrderById



  orderMoveToLth(orderId : string,lth_profit: number,exchange:string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/orderMoveToLth',{orderId,lth_profit,exchange}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of orderMoveToLth


  sellOrderManually(orderId : string,currentMarketPriceByCoin: number,definedSellPrice : number,exchange:string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/sellOrderManually',{orderId,currentMarketPriceByCoin,definedSellPrice,exchange}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of sellOrderManually



  buyOrderManually(orderId : string,coin: string,exchange : string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/buyOrderManually',{orderId,coin,exchange}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of buyOrderManually



  listOrdersForChart(admin_id : string,application_mode: string,exchange : string,coin: string){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/listOrdersForChart',{admin_id,application_mode,exchange,coin}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of listOrders


  listOrdersForChart_rk(admin_id : string,application_mode: string,exchange : string,coin: string){

    return this.http.post<any>(this.binanceApiURL_rk,{admin_id,application_mode,exchange,coin}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of listOrders



  updateOrderfromdraging(exchange : string,orderId : string,side : string ,updated_price){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/updateOrderfromdraging',{exchange,orderId,side,updated_price}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of updateOrderfromdraging


  updateBuyPriceFromDragging(exchange : string,orderId : string, previous_buy_price,updated_buy_price){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/updateBuyPriceFromDragging',{exchange,orderId,previous_buy_price,updated_buy_price}).pipe(map(resp=>{
      return resp;
    },err=>{
      return err;
    }))
  }//End of updateBuyPriceFromDragging



  editAutoOrder(orderArr){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/editAutoOrder',{orderArr}).pipe(map(resp=>{
       return resp;
    }))
  }//End of editAutoOrder

  lisEditManualOrderById(orderId,exchange){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/lisEditManualOrderById',{orderId,exchange}).pipe(map(resp=>{
       return resp;
    }))
  }//End of lisEditManualOrderById


  updateManualOrder(buyOrderId,sellOrderId,tempSellOrderId,buyorderArr,sellOrderArr,tempOrderArr,exchange){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/updateManualOrder',{buyOrderId,sellOrderId,tempSellOrderId,buyorderArr,sellOrderArr,tempOrderArr,exchange}).pipe(map(resp=>{
       return resp;
    }))
  }//End of updateManualOrderc

  saveBamCredentials(user_id,api_key,api_secret){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/saveBamCredentials',{user_id,api_key,api_secret}).pipe(map(resp=>{
       return resp;
    }))
  }//End of saveBamCredentials


  getBamCredentials(user_id){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/getBamCredentials',{user_id}).pipe(map(resp=>{
       return resp;
    }))
  }//End of getBamCredentials

  setForSell(sellOrderArr,exchange,buyOrderId){
    return this.http.post<any>(this.binanceApiURL+'apiEndPoint/setForSell',{sellOrderArr,exchange,buyOrderId}).pipe(map(resp=>{
       return resp;
    }))
  }//End of setForSell



  getCoins(admin_id,exchange){
      let url = (exchange == 'bam')?(this.bamApiURL+'api/manageCoins'):(this.binanceApiURL+`apiEndPoint/manageCoins`);
      return this.http.post<any>(url,{admin_id}).pipe(map(resp=>{
        return resp;
      }));
   }//End of getCoins



   listBamCurrentMarketPrice(coin,exchange){
    return this.http.post<any>(this.bamApiURL+'api/listCurrentmarketPrice',{exchange,coin}).pipe(map(resp=>{
      return resp;
    }));
   }//End of listBamCurrentMarketPrice





   get_bam_credentials(admin_id){

   }//End of get_bam_credentials

   saveSelectedCoins(coin_arr,user_id,exchange){
        let url = (exchange == 'bam')?(this.bamApiURL+'api/addUserCoin'):(this.binanceApiURL+`apiEndPoint/addUserCoin`);
        return this.http.post<any>(url,{coin_arr,user_id}).pipe(map(resp=>{
          return resp;
        }));
   }


    getUserInfo(user_id,exchange){
        let url = (exchange == 'bam')?(this.bamApiURL+'api/get_user_info'):(this.binanceApiURL+`apiEndPoint/get_user_info`);
        return this.http.post<any>(url,{user_id}).pipe(map(resp=>{
          return resp;
        }));
    }

    getUserInfo1(admin_id){
      const url = 'http://34.205.124.51:3010/apiEndPoint/get_user_info'
      // const url = 'https://app.digiebot.com/admin/Api_calls/get_user_profile_image'

      const obj = {
      "user_id": admin_id
      }
      return this.http.post(url, obj)
      }








    //Danish work :::::::::::::::::::::::::: --- ::: --:: --:: --::



   updateProfile(user_id,profile_image,first_name,last_name,phone_number,time_zone){
    const url = 'http://207.180.198.49:3000/api/update_user_info'
    const obj = {
      "user_id": user_id,
      "first_name":first_name,
      "last_name" :last_name,
      "phone_number" :phone_number,
      "timezone" :time_zone,
      "profile_image" :profile_image,
    }
    return this.http.post(url, obj)
    // .subscribe((response) => console.log("orderListingOnLoad in service ",response));
   }

   ChangePassword(user_id,password){
    const url = 'http://207.180.198.49:3000/api/update_user_info'
    const obj = {
      "user_id": user_id,
      "password" :password,

    }
    return this.http.post(url, obj)
    // .subscribe((response) => console.log("orderListingOnLoad in service ",response));
   }

   sendKeySecret(user_id,apiKey,apiSecret,pass_phrase){
    const url = 'http://207.180.198.49:3000/api/update_user_info'
    const obj = {
      "user_id": user_id,
      "api_key":apiKey,
      "api_secret":apiSecret,
      "pass_phrase":pass_phrase
    }
    return this.http.post(url, obj)
    // .subscribe((response) => console.log("orderListingOnLoad in service ",response));
   }

  setupAuth() {
    return this.http.post("http://207.180.198.49:3000/tfa/setup", {}, { observe: 'response' })

    // return this.http.post("http://localhost:3000/tfa/setup", {}, { observe: 'response' })
  }

  getAuth() {
    return this.http.get("http://207.180.198.49:3000/tfa/setup", { observe: 'response' });

    // return this.http.get("http://localhost:3000/tfa/setup", { observe: 'response' });
  }

  verifyAuth(token: any) {
    return this.http.post("http://207.180.198.49:3000/tfa/verify", { token }, { observe: 'response' });

    // return this.http.post("http://localhost:3000/tfa/verify", { token }, { observe: 'response' });
  }

  deleteAuth() {
    return this.http.delete("http://207.180.198.49:3000/tfa/setup", { observe: 'response' });

    return this.http.delete("http://localhost:3000/tfa/setup", { observe: 'response' });
  }


  // deleteCoin(coin_id){
  //   // api will be given by sir saeed ullah
  //   const url = 'http://207.180.198.49:3000/api/deletecoin';
  //   const obj = {
  //      "order_id" : coin_id,
  //   }
  //   return this.http.post(url, obj)
  //  }
 //  Profile image..etc  danish
  get_user_profile_image(admin_id){
    const url = 'https://app.digiebot.com/admin/Api_calls/get_user_profile_image'
    const obj = {
    "user_id": admin_id
    }
    return this.http.post(url, obj)
  }

  trading_on_off(admin_id){
    const url = 'https://app.digiebot.com/admin/Api_calls/get_trading_status'
    const obj = {
    "user_id": admin_id
    }
    return this.http.post(url, obj)
   }

   deleteCoin(coin_id){
    // api will be given by sir saeed ullah
    const url = 'https://app.digiebot.com/admin/Api_calls/delete_coin';
    const obj = {
       "coin_id" : coin_id,
    }
    return this.http.post(url, obj)
   }

   manageCoins(admin_id){
    const url = 'https://app.digiebot.com/admin/Api_calls/manage_coins'
    const obj = {
    "user_id": admin_id
    }
    return this.http.post(url, obj)
   }


   /**
    * Waqar Functions
    */

   getCandleChart(coin){
    let url = "https://scriptapis.digiebot.com/candles/";
    return this.http.post<any>(url, {coin})
    .pipe(map(resp => {
        console.log(resp.data)
        return resp.data;
    }));
  }

}//End of Class

























