import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({ templateUrl: 'login.component.html', styleUrls: ['./login.component.css'] })

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    deviceInfo;
    device;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private deviceService: DeviceDetectorService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        if (!localStorage.getItem('refresh_flag')) {
            localStorage.setItem('refresh_flag', 'true');
        }// Check Added By Huzaifa
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    refresh(){
        location.reload();
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    // WhiteListAPI = 'http://3.232.17.202:2603/apiEndPoint/is_ipaddress_whitelisted';
    WhiteListAPI = 'https://scripts.digiebot.com/admin/candle_cronjob/checkIfIpIsWhiteListed';
    UserLog = 'https://scripts.digiebot.com/admin/candle_cronjob/createLoginLog';
    IPData;

    getIpInfo(){
      let getIPUrl ='https://extreme-ip-lookup.com/json';
      return this.http.get<any>(getIPUrl).toPromise();
    }
    saveUserLog(data){
      let UserLogUrl = this.UserLog;
      return this.http.post<any>(UserLogUrl, JSON.stringify(data)).toPromise();
    }
    getIPWhiteListInfo(data){
      // const headers = new HttpHeaders()
      //   .set('Content-Type', 'application/json')
      //   .set('Authorization', 'ipwhitelisted#Um4dRaZ3evBhGDZVICd3');
      // let IPWhiteListUrl = this.WhiteListAPI;
      // return this.http.post<any>(IPWhiteListUrl, JSON.stringify(data), { headers: headers }).toPromise();
      let IPWhiteListUrl = this.WhiteListAPI;
      return this.http.post<any>(IPWhiteListUrl, JSON.stringify(data)).toPromise();
    }
    getBrowser(){
      this.deviceInfo = this.deviceService.getDeviceInfo();
      console.log(this.deviceInfo)
      if(this.deviceService.isMobile()){
        this.device = 'mobile'
      } else if(this.deviceService.isTablet()){
        this.device = 'tablet'
      }else if(this.deviceService.isDesktop()){
        this.device = 'desktop'
      }
      console.log(this.device)
    }





    async onSubmit() {
      this.getBrowser()
      this.IPData = await this.getIpInfo();
      console.log(this.IPData)
      this.submitted = true;
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      var data = await this.authenticationService.login(this.f.username.value, this.f.password.value)
      console.log('data', data);
      // if (data) {
      if (data['success'] == true) {
        var whitelistdata = {};
        whitelistdata['ip_address'] = this.IPData.query;
        whitelistdata['country'] = this.IPData.countryCode;
        whitelistdata['location'] = this.IPData.city;
        whitelistdata['comments'] = data.username + ' from Candle Chart';
        console.log(whitelistdata);
        data = data['user'];
        console.log('user', data)
        var is_ip_white_listed = await this.getIPWhiteListInfo(whitelistdata);
        var logdata = {};
        if(is_ip_white_listed['result']){
          logdata['first_name'] = data.firstName;
          logdata['last_name'] = data.lastName;
          logdata['username'] = data.username;
          logdata['email_address'] = data.email_address;
          logdata['userid'] = data.id;
          logdata['location'] = this.IPData.city +', '+this.IPData.countryCode;
          logdata['IP'] = this.IPData.query;
          logdata['source'] = 'candles.digiebot.com';
          logdata['Device'] = this.device;
          logdata['Browser'] = this.deviceInfo['browser'] +' '+this.deviceInfo['browser_version'];
          logdata['Operating_System'] = this.deviceInfo['os']
          logdata['login_date'] = new Date();
          logdata['Geometry'] = 'lat '+this.IPData.lat + ' lon '+this.IPData.lon;
          logdata['user_role'] = data.role;
          logdata['userloggedin_digiesite'] = 'https://candles.digiebot.com/login'
          console.log(logdata)
          var saveLogs = await this.saveUserLog(logdata)
          if(data.username == 'Waleed' || data.username == 'Huzaifa' || data.username == 'abbas' || data.username == 'Doug' || data.username == 'Hassan' || data.username == 'Tehmina'){
            let access_routes = ['/mainchart2', '/dailychart'];
            localStorage.setItem("user_access_rights", JSON.stringify(access_routes));
            // this.router.navigate([this.returnUrl]);
            this.router.navigate(['/']);
            // setTimeout(() => {
              this.refresh();
            // }, 200);
          } else {
            let access_routes = ['/mainchart2'];
            localStorage.setItem("user_access_rights", JSON.stringify(access_routes));
            // this.router.navigate([this.returnUrl]);
            this.router.navigate(['/']);
            this.refresh();
          }
        } else {
          localStorage.removeItem('currentUser');
          this.error = 'Your IP not WhiteListed';
          this.loading = false;
        }
      } else {
        if(this.f.username.value == 'admin' && this.f.password.value == 'candlesadmin'){
          let access_routes = ['/mainchart2', '/dailychart'];
          localStorage.setItem("user_access_rights", JSON.stringify(access_routes));
          this.router.navigate(['/']);
            this.refresh();
        } else {
          this.error = 'Something went wrong!';
          this.loading = false;
        }
      }
    }

}
