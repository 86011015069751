import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { Socket } from 'ngx-socket-io';
import * as io  from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})

export class MainChartService {
  OrgArray: any = [];
  trueData: any = [];
  SOCKET_ENDPOINT = 'https://candlesapi.digiebot.com/';
  socket;
  constructor(private http: HttpClient) {
    this.setupSocketConnection();
  }
  /* Socket Block */
  //Socket Connection
  setupSocketConnection() {
    this.socket = io.io(this.SOCKET_ENDPOINT);
  }


  //Hourly Socket Data
  async getCandleChart(coin, back_date, fwd_date){
    this.socket.emit("hourly_data", { coin, back_date, fwd_date });
    return await this.getHourlySocketResponse();
  }
  getHourlySocketResponse() {
    return new Promise(resolve => this.socket.on('hourly_data', (data)=> {
      this.OrgArray = data
      this.array = new BehaviorSubject<any>(this.OrgArray);
      this.chartData = this.array.asObservable();
      resolve(data);
    }));
  }


 // Daily Socket Data
  async getDailyChartData(coin, back_date, fwd_date ){
    this.socket.emit("daily_data", { coin, back_date, fwd_date });
    return await this.getDailySocketResponse();
  }
  getDailySocketResponse() {
    return new Promise(resolve => this.socket.on('daily_data', (data)=> {
      this.DailyArray = data
      this.arraydaily = new BehaviorSubject<any>(this.DailyArray);
      this.dailyChartData = this.arraydaily.asObservable();
      resolve(data);
    }));
  }
  /* END Socket Block */

  DailyArray: any = [];
  arraydaily = new BehaviorSubject<any>(this.DailyArray);
  dailyChartData = this.arraydaily.asObservable();

  getDailyChartData1(coin, back_date, fwd_date) {
    let url = "https://scriptapis.digiebot.com/candles_daily/";
    return this.http.post<any>(url, { coin, back_date, fwd_date })
      .pipe(map(resp => {
        this.DailyArray = resp.data
        this.arraydaily = new BehaviorSubject<any>(this.DailyArray);
        this.dailyChartData = this.arraydaily.asObservable();
        return resp.data;
      }));
  }


  getCandleChart1(coin, back_date, fwd_date) {
    let url = "https://scriptapis.digiebot.com/candles/";
    return this.http.post<any>(url, { coin, back_date, fwd_date })
      .pipe(map(resp => {
        this.OrgArray = resp.data
        this.array = new BehaviorSubject<any>(this.OrgArray);
        this.chartData = this.array.asObservable();
        return resp.data;
      }));
  }

  array = new BehaviorSubject<any>(this.OrgArray);
  chartData = this.array.asObservable();


  array2 = new BehaviorSubject<any>(this.trueData);
  chartData2 = this.array2.asObservable();
  //Chart Data End


  // General Data
  OthArray: any = [
    {
      price_length: 100,
      time_length: 25,
      start_index: 143,
      end_index: 168,
      increment: 4,
      decrement: 4,
      truelevel_type: 'both',
    }
  ]
  array1 = new BehaviorSubject<any>(this.OthArray);
  canvasData = this.array1.asObservable();
  //General Data End

  //Update GeneralArray
  new_OthArray(array: any) {
    this.array1.next(array)
  }
  //Update GeneralArray End




  // for points and other things
  //Candlestick start
  candlestickData: any = [];
  array_candlestick = new BehaviorSubject<any>(this.candlestickData);
  candlestickcoordData = this.array_candlestick.asObservable();

  new_candlestickData(array: any) {
    this.array_candlestick.next(array);
  }
  //CandleStick end
  //truelevel Start
  truelevelData: any = [
    // {
    //   x1: null,
    //   x2: null,
    //   y1: null,
    //   y2: null,
    //   buy: null,
    //   sell: null,
    // }

  ]
  array_truelevel = new BehaviorSubject<any>(this.truelevelData);
  truelevelcoordData = this.array_truelevel.asObservable();

  new_truelevelData(array: any) {
    this.array_truelevel.next(array)
  }
  //truelevel End
  //dkbox start
  dkboxData: any = [];
  array_dkbox = new BehaviorSubject<any>(this.dkboxData);
  dkboxcoordData = this.array_dkbox.asObservable();

  new_dkboxData(array: any) {
    this.array_dkbox.next(array);
  }
  //dkbox end
  //DemandSuppply start
  demandsupplyData: any = [];
  array_demandsupply = new BehaviorSubject<any>(this.demandsupplyData);
  demandsupplycoordData = this.array_demandsupply.asObservable();

  new_denmandsupplyData(array: any) {
    this.array_demandsupply.next(array);
  }
  //DemandSupply end
  //Resistance start
   resistanceData: any = [];
   array_resistance = new BehaviorSubject<any>(this.resistanceData);
   resistancecoordData = this.array_resistance.asObservable();

   new_resistanceData(array: any) {
     this.array_resistance.next(array);
   }
  //Resistance end
  //strategy start
  strategyData: any = [];
  array_strategy = new BehaviorSubject<any>(this.strategyData);
  strategycoordData = this.array_strategy.asObservable();

  new_strategyData(array: any) {
    this.array_strategy.next(array);
  }
 //strategy end
  //rejection start
  rejectionData: any = [];
  array_rejection = new BehaviorSubject<any>(this.rejectionData);
  rejectioncoordData = this.array_rejection.asObservable();

  new_rejectionData(array: any) {
    this.array_rejection.next(array);
  }
  //rejection end
  //move start
  moveData: any = [];
  array_move = new BehaviorSubject<any>(this.moveData);
  movecoordData = this.array_move.asObservable();

  new_moveData(array: any) {
    this.array_move.next(array);
  }
  //move end
  //bodymove start
  bodymoveData: any = [];
  array_bodymove = new BehaviorSubject<any>(this.bodymoveData);
  bodymovecoordData = this.array_bodymove.asObservable();

  new_bodymoveData(array: any) {
    this.array_bodymove.next(array);
  }
  //bodymove end
   //upperwickmove start
   upperwickmoveData: any = [];
   array_upperwickmove = new BehaviorSubject<any>(this.upperwickmoveData);
   upperwickmovecoordData = this.array_upperwickmove.asObservable();

   new_upperwickmoveData(array: any) {
     this.array_upperwickmove.next(array);
   }
   //upperwickmove end
    //lowerwickmove start
  lowerwickmoveData: any = [];
  array_lowerwickmove = new BehaviorSubject<any>(this.lowerwickmoveData);
  lowerwickmovecoordData = this.array_lowerwickmove.asObservable();

  new_lowerwickmoveData(array: any) {
    this.array_lowerwickmove.next(array);
  }
  //lowerwickmove end
  //topwick start
  topwickData: any = [];
  array_topwicksignal = new BehaviorSubject<any>(this.topwickData);
  topwicksignalcoordData = this.array_topwicksignal.asObservable();

  new_topwicksignalData(array: any) {
    this.array_topwicksignal.next(array);
  }
  //topwick end
  //candeltrends start
  candeltrendsData: any = [];
  array_candeltrends = new BehaviorSubject<any>(this.candeltrendsData);
  candeltrendscoordData = this.array_candeltrends.asObservable();

  new_candeltrendsData(array: any) {
    this.array_candeltrends.next(array);
  }
  //candeltrends end


  //dailycandeltrends start
  dailycandeltrendsData: any = [];
  array_dailycandeltrends = new BehaviorSubject<any>(this.dailycandeltrendsData);
  dailycandeltrendscoordData = this.array_dailycandeltrends.asObservable();

  new_dailycandeltrendsData(array: any) {
    this.array_dailycandeltrends.next(array);
  }
  //dailycandeltrends end
  //dpuptrenddirection start
  dpuptrenddirectionData: any = [];
  array_dpuptrenddirection = new BehaviorSubject<any>(this.dpuptrenddirectionData);
  dpuptrenddirectioncoordData = this.array_dpuptrenddirection.asObservable();

  new_dpuptrenddirectionData(array: any) {
    this.array_dpuptrenddirection.next(array);
  }
  //dpuptrenddirection end
  //increasingvolume start
  increasingData: any = [];
  array_increasing = new BehaviorSubject<any>(this.increasingData);
  increasingcoordData = this.array_increasing.asObservable();

  new_increasingvolumeData(array: any) {
    this.array_increasing.next(array);
  }
  //increasingvolume end
  //blackdotup start
  blackdotup: any = [];
  array_blackdotup = new BehaviorSubject<any>(this.blackdotup);
  blackdotupcoordData = this.array_blackdotup.asObservable();

  new_blackdotupData(array: any) {
    this.array_blackdotup.next(array);
  }
  //blackdotup end
  //sevenlevelavgdiffperc start
  sevenlevelavgdiffperc: any = [];
  array_sevenlevelavgdiffperc = new BehaviorSubject<any>(this.sevenlevelavgdiffperc);
  sevenlevelavgdiffperccoordData = this.array_sevenlevelavgdiffperc.asObservable();

  new_sevenlevelavgdiffpercData(array: any) {
    this.array_sevenlevelavgdiffperc.next(array);
  }
  //sevenlevelavgdiffperc end
  //fourteenlevelavgdiffperc start
  fourteenlevelavgdiffperc: any = [];
  array_fourteenlevelavgdiffperc = new BehaviorSubject<any>(this.fourteenlevelavgdiffperc);
  fourteenlevelavgdiffperccoordData = this.array_fourteenlevelavgdiffperc.asObservable();

  new_fourteenlevelavgdiffpercData(array: any) {
    this.array_fourteenlevelavgdiffperc.next(array);
  }
  //fourteenlevelavgdiffperc end
  //next_close start
  next_close: any = [];
  array_next_close = new BehaviorSubject<any>(this.next_close);
  next_closecoordData = this.array_next_close.asObservable();

  new_next_closeData(array: any) {
    this.array_next_close.next(array);
  }
  //next_close end
  //dp_up1 start
  dp_up1: any = [];
  array_dp_up1 = new BehaviorSubject<any>(this.dp_up1);
  dp_up1coordData = this.array_dp_up1.asObservable();

  new_dp_up1Data(array: any) {
    this.array_dp_up1.next(array);
  }
  //dp_up1 end
  //dp_up1_btc start
  dp_up1_btc: any = [];
  array_dp_up1_btc = new BehaviorSubject<any>(this.dp_up1_btc);
  dp_up1_btccoordData = this.array_dp_up1_btc.asObservable();

  new_dp_up1_btcData(array: any) {
    this.array_dp_up1_btc.next(array);
  }
  //dp_up1_btc end
  //dp_up2 start
  dp_up2: any = [];
  array_dp_up2 = new BehaviorSubject<any>(this.dp_up2);
  dp_up2coordData = this.array_dp_up2.asObservable();

  new_dp_up2Data(array: any) {
    this.array_dp_up2.next(array);
  }
  //dp_up2 end
  //dp_up2_btc start
  dp_up2_btc: any = [];
  array_dp_up2_btc = new BehaviorSubject<any>(this.dp_up2_btc);
  dp_up2_btccoordData = this.array_dp_up2_btc.asObservable();

  new_dp_up2_btcData(array: any) {
    this.array_dp_up2_btc.next(array);
  }
  //dp_up2_btc end
  //dp_up3 start
  dp_up3: any = [];
  array_dp_up3 = new BehaviorSubject<any>(this.dp_up3);
  dp_up3coordData = this.array_dp_up3.asObservable();

  new_dp_up3Data(array: any) {
    this.array_dp_up3.next(array);
  }
  //dp_up3 end
  //dp_up3_btc start
  dp_up3_btc: any = [];
  array_dp_up3_btc = new BehaviorSubject<any>(this.dp_up3_btc);
  dp_up3_btccoordData = this.array_dp_up3_btc.asObservable();

  new_dp_up3_btcData(array: any) {
    this.array_dp_up3_btc.next(array);
  }
  //dp_up3_btc end
  //blackdotpressure_u10up start
  blackdotpressure_u10: any = [];
  array_blackdotpressure_u10 = new BehaviorSubject<any>(this.blackdotpressure_u10);
  blackdotpressure_u10coordData = this.array_blackdotpressure_u10.asObservable();

  new_blackdotpressure_u10Data(array: any) {
    this.array_blackdotpressure_u10.next(array);
  }
  //blackdotpressure_u10 end
  //askbidvolumeup start
  askbidvolume: any = [];
  array_askbidvolume = new BehaviorSubject<any>(this.askbidvolume);
  askbidvolumecoordData = this.array_askbidvolume.asObservable();

  new_askbidvolumeData(array: any) {
    this.array_askbidvolume.next(array);
  }
  //askbidvolume end
  //askbidvolumeb start
  askbidvolumeb: any = [];
  array_askbidvolumeb = new BehaviorSubject<any>(this.askbidvolumeb);
  askbidvolumebcoordData = this.array_askbidvolumeb.asObservable();

  new_askbidvolumebData(array: any) {
    this.array_askbidvolumeb.next(array);
  }
  //askbidvolumeb end
  //coinmetacountup start
  coinmetacount: any = [];
  array_coinmetacount = new BehaviorSubject<any>(this.coinmetacount);
  coinmetacountcoordData = this.array_coinmetacount.asObservable();

  new_coinmetacountData(array: any) {
    this.array_coinmetacount.next(array);
  }
  //coinmetacount end
  //volumecomparison start
  volumecomparison: any = [];
  array_volumecomparison = new BehaviorSubject<any>(this.volumecomparison);
  volumecomparisoncoordData = this.array_volumecomparison.asObservable();

  new_volumecomparisonData(array: any) {
    this.array_volumecomparison.next(array);
  }
  //volumecomparison end
  //rangescomparison start
  rangescomparison: any = [];
  array_rangescomparison = new BehaviorSubject<any>(this.rangescomparison);
  rangescomparisoncoordData = this.array_rangescomparison.asObservable();

  new_rangescomparisonData(array: any) {
    this.array_rangescomparison.next(array);
  }
  //volumecomparison end
  //scripttimeup start
  scripttime: any = [];
  array_scripttime = new BehaviorSubject<any>(this.scripttime);
  scripttimecoordData = this.array_scripttime.asObservable();

  new_scripttimeData(array: any) {
    this.array_scripttime.next(array);
  }
  //scripttime end
  //blackwallPerc start
  blackwallpercData: any = [];
  array_blackwallperc = new BehaviorSubject<any>(this.blackwallpercData);
  blackwallperccoordData = this.array_blackwallperc.asObservable();

  new_blackwallpercData(array: any) {
    this.array_blackwallperc.next(array);
  }
  //blackwallPerc end
  //seven levelPerc start
  sevenlevelpercData: any = []
  array_sevenlevelperc = new BehaviorSubject<any>(this.sevenlevelpercData);
  sevenlevelperccoordData = this.array_sevenlevelperc.asObservable();

  new_sevenlevelpercData(array: any) {
    this.array_sevenlevelperc.next(array)
  }
  //seven levelPerc end
  //Fourteen levelPerc start
  fourteenlevelpercData: any = []
  array_fourteenlevelperc = new BehaviorSubject<any>(this.fourteenlevelpercData);
  fourteenlevelperccoordData = this.array_fourteenlevelperc.asObservable();

  new_fourteenlevelpercData(array: any) {
    this.array_fourteenlevelperc.next(array)
  }
  //fourteen levelPerc end
  //seven levelminvstPerc start
  sevenlevelminvstpercData: any = []
  array_sevenlevelminvstperc = new BehaviorSubject<any>(this.sevenlevelminvstpercData);
  sevenlevelminvstperccoordData = this.array_sevenlevelminvstperc.asObservable();

  new_sevenlevelminvstpercData(array: any) {
    this.array_sevenlevelminvstperc.next(array)
  }
  //seven levelminvstPerc end
  //seven levelminPercst start
  sevenlevelminpercstData: any = []
  array_sevenlevelminpercst = new BehaviorSubject<any>(this.sevenlevelminpercstData);
  sevenlevelminpercstcoordData = this.array_sevenlevelminpercst.asObservable();

  new_sevenlevelminpercstData(array: any) {
    this.array_sevenlevelminpercst.next(array)
  }
  //seven levelminPercst end

  //seven levelminPerc start
  sevenlevelminpercData: any = []
  array_sevenlevelminperc = new BehaviorSubject<any>(this.sevenlevelminpercData);
  sevenlevelminperccoordData = this.array_sevenlevelminperc.asObservable();

  new_sevenlevelminpercData(array: any) {
    this.array_sevenlevelminperc.next(array)
  }
  //seven levelminPerc end
  //seven levelmaxPercst start
  sevenlevelmaxpercstData: any = []
  array_sevenlevelmaxpercst = new BehaviorSubject<any>(this.sevenlevelmaxpercstData);
  sevenlevelmaxpercstcoordData = this.array_sevenlevelmaxpercst.asObservable();

  new_sevenlevelmaxpercstData(array: any) {
    this.array_sevenlevelmaxpercst.next(array)
  }
  //seven levelmaxPercst end
  //seven levelmaxPerc start
  sevenlevelmaxpercData: any = []
  array_sevenlevelmaxperc = new BehaviorSubject<any>(this.sevenlevelmaxpercData);
  sevenlevelmaxperccoordData = this.array_sevenlevelmaxperc.asObservable();

  new_sevenlevelmaxpercData(array: any) {
    this.array_sevenlevelmaxperc.next(array)
  }
  //seven levelmaxPerc end
  //bigcontractsPerc start
  bigcontractspercData: any = []
  array_bigcontractsperc = new BehaviorSubject<any>(this.bigcontractspercData);
  bigcontractsperccoordData = this.array_bigcontractsperc.asObservable();

  new_bigcontractspercData(array: any) {
    this.array_bigcontractsperc.next(array);
  }
  //bigcontractsperc end
//bigbuyercontractsPercst start
bigbuyercontractspercstData: any = []
array_bigbuyercontractspercst = new BehaviorSubject<any>(this.bigbuyercontractspercstData);
bigbuyercontractspercstcoordData = this.array_bigbuyercontractspercst.asObservable();

new_bigbuyercontractspercstData(array: any) {
  this.array_bigbuyercontractspercst.next(array);
}
//bigbuyercontractspercst end
//bigsellercontractsPercst start
bigsellercontractspercstData: any = []
array_bigsellercontractspercst = new BehaviorSubject<any>(this.bigsellercontractspercstData);
bigsellercontractspercstcoordData = this.array_bigsellercontractspercst.asObservable();

new_bigsellercontractspercstData(array: any) {
  this.array_bigsellercontractspercst.next(array);
}
//bigsellercontractspercst end
//bigbuyercontractsPercvst start
bigbuyercontractspercvstData: any = []
array_bigbuyercontractspercvst = new BehaviorSubject<any>(this.bigbuyercontractspercvstData);
bigbuyercontractspercvstcoordData = this.array_bigbuyercontractspercvst.asObservable();

new_bigbuyercontractspercvstData(array: any) {
  this.array_bigbuyercontractspercvst.next(array);
}
//bigbuyercontractspercvst end
//bigsellercontractsPercvst start
bigsellercontractspercvstData: any = []
array_bigsellercontractspercvst = new BehaviorSubject<any>(this.bigsellercontractspercvstData);
bigsellercontractspercvstcoordData = this.array_bigsellercontractspercvst.asObservable();

new_bigsellercontractspercvstData(array: any) {
  this.array_bigsellercontractspercvst.next(array);
}
//bigsellercontractspercvst end
//bigbuyersellercontractsvol start
bigbuyersellercontractsvolData: any = []
array_bigbuyersellercontractsvol = new BehaviorSubject<any>(this.bigbuyersellercontractsvolData);
bigbuyersellercontractsvolcoordData = this.array_bigbuyersellercontractsvol.asObservable();

new_bigbuyersellercontractsvolData(array: any) {
  this.array_bigbuyersellercontractsvol.next(array);
}
//bigbuyersellercontractsvol end
//bigbuyersellerdelta start
bigbuyersellerdeltaData: any = []
array_bigbuyersellerdelta = new BehaviorSubject<any>(this.bigbuyersellerdeltaData);
bigbuyersellerdeltacoordData = this.array_bigbuyersellerdelta.asObservable();

new_bigbuyersellerdeltaData(array: any) {
  this.array_bigbuyersellerdelta.next(array);
}
//bigbuyersellerdelta end
//bigbuyersellerdiff start
bigbuyersellerdiffData: any = []
array_bigbuyersellerdiff = new BehaviorSubject<any>(this.bigbuyersellerdiffData);
bigbuyersellerdiffcoordData = this.array_bigbuyersellerdiff.asObservable();

new_bigbuyersellerdiffData(array: any) {
  this.array_bigbuyersellerdiff.next(array);
}
//bigbuyersellerdiff end
//bigbuyersellerdiffperc start
bigbuyersellerdiffpercData: any = []
array_bigbuyersellerdiffperc = new BehaviorSubject<any>(this.bigbuyersellerdiffpercData);
bigbuyersellerdiffperccoordData = this.array_bigbuyersellerdiffperc.asObservable();

new_bigbuyersellerdiffpercData(array: any) {
  this.array_bigbuyersellerdiffperc.next(array);
}
//bigbuyersellerdiffperc end


//bigbuyersellercontractscount start
bigbuyersellercontractscountData: any = []
array_bigbuyersellercontractscount = new BehaviorSubject<any>(this.bigbuyersellercontractscountData);
bigbuyersellercontractscountcoordData = this.array_bigbuyersellercontractscount.asObservable();

new_bigbuyersellercontractscountData(array: any) {
  this.array_bigbuyersellercontractscount.next(array);
}
//bigbuyersellercontractscount end
  //candlePerc Start
  candlepercData: any = []
  array_candleperc = new BehaviorSubject<any>(this.candlepercData);
  candleperccoordData = this.array_candleperc.asObservable();

  new_candlepercData(array: any) {
    this.array_candleperc.next(array);
  }
  //candlePerc End
  //activedownbarrierPerc Start
  activedownbarrierpercData: any = []
  array_activedownbarrierperc = new BehaviorSubject<any>(this.activedownbarrierpercData);
  activedownbarrierperccoordData = this.array_activedownbarrierperc.asObservable();

  new_activedownbarrierpercData(array: any) {
    this.array_activedownbarrierperc.next(array);
  }
  //activedownbarrierPerc End
  //activeupbarrierPerc Start
  activeupbarrierpercData: any = []
  array_activeupbarrierperc = new BehaviorSubject<any>(this.activeupbarrierpercData);
  activeupbarrierperccoordData = this.array_activeupbarrierperc.asObservable();

  new_activeupbarrierpercData(array: any) {
    this.array_activeupbarrierperc.next(array);
  }
  //activeupbarrierPerc End
  //candleSTPerc Start
  candlestpercData: any = []
  array_candlestperc = new BehaviorSubject<any>(this.candlestpercData);
  candlestperccoordData = this.array_candlestperc.asObservable();

  new_candlestpercData(array: any) {
    this.array_candlestperc.next(array);
  }
  //candleSTPerc End
  //candleVSTPerc Start
  candlevstpercData: any = []
  array_candlevstperc = new BehaviorSubject<any>(this.candlevstpercData);
  candlevstperccoordData = this.array_candlevstperc.asObservable();

  new_candlevstpercData(array: any) {
    this.array_candlevstperc.next(array);
  }
  //candleVSTPerc End
  //twowaybar Start
  twowaybarData: any = []
  array_twowaybar = new BehaviorSubject<any>(this.twowaybarData);
  twowaybarccoordData = this.array_twowaybar.asObservable();

  new_twowaybarData(array: any) {
    this.array_twowaybar.next(array);
  }
  //twowaybar End
  //askbidcount Start
  askbidcountData: any = []
  array_askbidcount = new BehaviorSubject<any>(this.askbidcountData);
  askbidcountcoordData = this.array_askbidcount.asObservable();

  new_askbidcountData(array: any) {
    this.array_askbidcount.next(array);
  }
  //askbidcount End
  //blackwall avg Start
   blackwallavgData: any = []
   array_blackwallavg = new BehaviorSubject<any>(this.blackwallavgData);
   blackwallavgcoordData = this.array_blackwallavg.asObservable();

   new_blackwallavgData(array: any) {
     this.array_blackwallavg.next(array);
   }
  //blackwall avg End
  //sevenlevel avg Start
  sevenlevelavgData: any = []
  array_sevenlevelavg = new BehaviorSubject<any>(this.sevenlevelavgData);
  sevenlevelavgcoordData = this.array_sevenlevelavg.asObservable();

  new_sevenlevelavgData(array: any) {
    this.array_sevenlevelavg.next(array);
  }
 //sevenlevel avg End
  //fourteenlevel avg Start
  fourteenlevelavgData: any = []
  array_fourteenlevelavg = new BehaviorSubject<any>(this.fourteenlevelavgData);
  fourteenlevelavgcoordData = this.array_fourteenlevelavg.asObservable();

  new_fourteenlevelavgData(array: any) {
    this.array_fourteenlevelavg.next(array);
  }
 //fourteenlevel avg End
 //sevenlevel avg st Start
 sevenlevelavgstData: any = []
 array_sevenlevelavgst = new BehaviorSubject<any>(this.sevenlevelavgstData);
 sevenlevelavgstcoordData = this.array_sevenlevelavgst.asObservable();

 new_sevenlevelavgstData(array: any) {
   this.array_sevenlevelavgst.next(array);
 }
//sevenlevel avg st End
//sevenlevel avg vst Start
sevenlevelavgvstData: any = []
array_sevenlevelavgvst = new BehaviorSubject<any>(this.sevenlevelavgvstData);
sevenlevelavgvstcoordData = this.array_sevenlevelavgvst.asObservable();

new_sevenlevelavgvstData(array: any) {
  this.array_sevenlevelavgvst.next(array);
}
//sevenlevel avg vst End
//bigcontracts avg Start
 bigcontractsavgData: any = []
 array_bigcontractsavg = new BehaviorSubject<any>(this.bigcontractsavgData);
 bigcontractsavgcoordData = this.array_bigcontractsavg.asObservable();

 new_bigcontractsavgData(array: any) {
   this.array_bigcontractsavg.next(array);
 }
//bigcontracts avg End
//upperwickPerc Start
upperwickpercData: any = []
array_upperwickperc = new BehaviorSubject<any>(this.upperwickpercData);
upperwickperccoordData = this.array_upperwickperc.asObservable();

new_upperwickpercData(array: any) {
  this.array_upperwickperc.next(array);
}
  //upperwickPerc End
  //bupperwick Start
bupperwickData: any = []
array_bupperwick = new BehaviorSubject<any>(this.bupperwickData);
bupperwickcoordData = this.array_bupperwick.asObservable();

new_bupperwickData(array: any) {
  this.array_bupperwick.next(array);
}
  //bupperwick End
  //upperwickSTPerc Start
  upperwickstpercData: any = []
  array_upperwickstperc = new BehaviorSubject<any>(this.upperwickstpercData);
  upperwickstperccoordData = this.array_upperwickstperc.asObservable();

  new_upperwickstpercData(array: any) {
    this.array_upperwickstperc.next(array);
  }
//upperwickSTPerc End
//upperwickVSTPerc Start
upperwickvstpercData: any = []
array_upperwickvstperc = new BehaviorSubject<any>(this.upperwickvstpercData);
upperwickvstperccoordData = this.array_upperwickvstperc.asObservable();

new_upperwickvstpercData(array: any) {
  this.array_upperwickvstperc.next(array);
}
//upperwickVSTPerc End
//lowerwickPerc Start
 lowerwickpercData: any = []
 array_lowerwickperc = new BehaviorSubject<any>(this.lowerwickpercData);
 lowerwickperccoordData = this.array_lowerwickperc.asObservable();

 new_lowerwickpercData(array: any) {
   this.array_lowerwickperc.next(array);
 }
  //lowerwickPerc End
  //blowerwick Start
 blowerwickData: any = []
 array_blowerwick = new BehaviorSubject<any>(this.blowerwickData);
 blowerwickcoordData = this.array_blowerwick.asObservable();

 new_blowerwickData(array: any) {
   this.array_blowerwick.next(array);
 }
  //blowerwick End
  //lowerwickSTPerc Start
  lowerwickstpercData: any = []
  array_lowerwickstperc = new BehaviorSubject<any>(this.lowerwickstpercData);
  lowerwickstperccoordData = this.array_lowerwickstperc.asObservable();

  new_lowerwickstpercData(array: any) {
    this.array_lowerwickstperc.next(array);
  }
//lowerwickSTPerc End
  //lowerwickVSTPerc Start
  lowerwickvstpercData: any = []
  array_lowerwickvstperc = new BehaviorSubject<any>(this.lowerwickvstpercData);
  lowerwickvstperccoordData = this.array_lowerwickvstperc.asObservable();

  new_lowerwickvstpercData(array: any) {
    this.array_lowerwickvstperc.next(array);
  }
//lowerwickVSTPerc End
//bodyPerc Start
bodypercData: any = []
array_bodyperc = new BehaviorSubject<any>(this.bodypercData);
bodyperccoordData = this.array_bodyperc.asObservable();

new_bodypercData(array: any) {
  this.array_bodyperc.next(array);
}
//bodyPerc End
//SevenlevelMinmax Start
sevenlevelminmaxData: any = []
array_sevenlevelminmax = new BehaviorSubject<any>(this.sevenlevelminmaxData);
sevenlevelminmaxcoordData = this.array_sevenlevelminmax.asObservable();

new_sevenlevelminmaxData(array: any) {
  this.array_sevenlevelminmax.next(array);
}
//SevenlevelMinmax End
//fourteenlevelMinmax Start
fourteenlevelminmaxData: any = []
array_fourteenlevelminmax = new BehaviorSubject<any>(this.fourteenlevelminmaxData);
fourteenlevelminmaxcoordData = this.array_fourteenlevelminmax.asObservable();

new_fourteenlevelminmaxData(array: any) {
  this.array_fourteenlevelminmax.next(array);
}
//fourteenlevelMinmax End
//blackwallminmax Start
blackwallminmaxData: any = []
array_blackwallminmax = new BehaviorSubject<any>(this.blackwallminmaxData);
blackwallminmaxcoordData = this.array_blackwallminmax.asObservable();

new_blackwallminmaxData(array: any) {
  this.array_blackwallminmax.next(array);
}
//blackwallminmax End
//bigdelta Start
 bigdeltaData: any = []
 array_bigdelta = new BehaviorSubject<any>(this.bigdeltaData);
 bigdeltacoordData = this.array_bigdelta.asObservable();

 new_bigdeltaData(array: any) {
   this.array_bigdelta.next(array);
 }
//bigdelta End
//telegram Start
telegramData: any = []
array_telegram = new BehaviorSubject<any>(this.telegramData);
telegramcoordData = this.array_telegram.asObservable();

new_telegramData(array: any) {
  this.array_telegram.next(array);
}
//telegram End
//telegram IT Start
telegramitpercData: any = []
array_telegramitperc = new BehaviorSubject<any>(this.telegramitpercData);
telegramitperccoordData = this.array_telegramitperc.asObservable();

new_telegramitpercData(array: any) {
  this.array_telegramitperc.next(array);
}
//telegram IT End
//telegram ST Start
telegramstpercData: any = []
array_telegramstperc = new BehaviorSubject<any>(this.telegramstpercData);
telegramstperccoordData = this.array_telegramstperc.asObservable();

new_telegramstpercData(array: any) {
  this.array_telegramstperc.next(array);
}
//telegram ST End
//telegram VST Start
telegramvstpercData: any = []
array_telegramvstperc = new BehaviorSubject<any>(this.telegramvstpercData);
telegramvstperccoordData = this.array_telegramvstperc.asObservable();

new_telegramvstpercData(array: any) {
  this.array_telegramvstperc.next(array);
}
//telegramvstperc End
//twitter Start
twitterData: any = []
array_twitter = new BehaviorSubject<any>(this.twitterData);
twittercoordData = this.array_twitter.asObservable();

new_twitterData(array: any) {
  this.array_twitter.next(array);
}
//twitter End
//twitterIT Start
twitteritpercData: any = []
array_twitteritperc = new BehaviorSubject<any>(this.twitteritpercData);
twitteritperccoordData = this.array_twitteritperc.asObservable();

new_twitteritpercData(array: any) {
  this.array_twitteritperc.next(array);
}
//twitterIT End
//twitter ST Start
twitterstpercData: any = []
array_twitterstperc = new BehaviorSubject<any>(this.twitterstpercData);
twitterstperccoordData = this.array_twitterstperc.asObservable();

new_twitterstpercData(array: any) {
  this.array_twitterstperc.next(array);
}
//twitter ST End
//twitter VST Start
twittervstpercData: any = []
array_twittervstperc = new BehaviorSubject<any>(this.twittervstpercData);
twittervstperccoordData = this.array_twittervstperc.asObservable();

new_twittervstpercData(array: any) {
  this.array_twittervstperc.next(array);
}
//twitter VST End
//news Start
newsData: any = []
array_news = new BehaviorSubject<any>(this.newsData);
newscoordData = this.array_news.asObservable();

new_newsData(array: any) {
  this.array_news.next(array);
}
//news End
//newsIT Start
newsitpercData: any = []
array_newsitperc = new BehaviorSubject<any>(this.newsitpercData);
newsitperccoordData = this.array_newsitperc.asObservable();

new_newsitpercData(array: any) {
  this.array_newsitperc.next(array);
}
//newsIT End
//newsST Start
newsstpercData: any = []
array_newsstperc = new BehaviorSubject<any>(this.newsstpercData);
newsstperccoordData = this.array_newsstperc.asObservable();

new_newsstpercData(array: any) {
  this.array_newsstperc.next(array);
}
//newsST End
//newsVST Start
newsvstpercData: any = []
array_newsvstperc = new BehaviorSubject<any>(this.newsvstpercData);
newsvstperccoordData = this.array_newsvstperc.asObservable();

new_newsvstpercData(array: any) {
  this.array_newsvstperc.next(array);
}
//newsVST End
//t1 Start
t1Data: any = []
array_t1 = new BehaviorSubject<any>(this.t1Data);
t1coordData = this.array_t1.asObservable();

new_t1Data(array: any) {
  this.array_t1.next(array);
}
//t1 End
//t2 Start
t2Data: any = []
array_t2 = new BehaviorSubject<any>(this.t2Data);
t2coordData = this.array_t2.asObservable();

new_t2Data(array: any) {
  this.array_t2.next(array);
}
//t2 End
//t4 Start
t4Data: any = []
array_t4 = new BehaviorSubject<any>(this.t4Data);
t4coordData = this.array_t4.asObservable();

new_t4Data(array: any) {
  this.array_t4.next(array);
}
//t4 End
//askbiddelta Start
askbiddeltaData: any = []
array_askbiddelta = new BehaviorSubject<any>(this.askbiddeltaData);
askbiddeltacoordData = this.array_askbiddelta.asObservable();

new_askbiddeltaData(array: any) {
  this.array_askbiddelta.next(array);
}
//askbiddelta End
//askbiddeltab Start
askbiddeltabData: any = []
array_askbiddeltab = new BehaviorSubject<any>(this.askbiddeltabData);
askbiddeltabcoordData = this.array_askbiddeltab.asObservable();

new_askbiddeltabData(array: any) {
  this.array_askbiddeltab.next(array);
}
//askbiddeltab End
//biddelta Start
biddeltaData: any = []
array_biddelta = new BehaviorSubject<any>(this.biddeltaData);
biddeltacoordData = this.array_biddelta.asObservable();

new_biddeltaData(array: any) {
  this.array_biddelta.next(array);
}
//biddelta End
//askbiddivisionb Start
askbiddivisionbData: any = []
array_askbiddivisionb = new BehaviorSubject<any>(this.askbiddivisionbData);
askbiddivisionbcoordData = this.array_askbiddivisionb.asObservable();

new_askbiddivisionbData(array: any) {
  this.array_askbiddivisionb.next(array);
}
//askbiddivisionb End
//t2bcpaskbidvol Start
t2bcpaskbidvolData: any = []
array_t2bcpaskbidvol = new BehaviorSubject<any>(this.t2bcpaskbidvolData);
t2bcpaskbidvolcoordData = this.array_t2bcpaskbidvol.asObservable();

new_t2bcpaskbidvolData(array: any) {
  this.array_t2bcpaskbidvol.next(array);
}
//t2bcpaskbidvol End










  // Order Book Starts


  //orderbook_1_perc_diff_ avg Start
  orderbook_1_perc_diff_avgData: any = []
  array_orderbook_1_perc_diff_avg = new BehaviorSubject<any>(this.orderbook_1_perc_diff_avgData);
  orderbook_1_perc_diff_avgcoordData = this.array_orderbook_1_perc_diff_avg.asObservable();

  new_orderbook_1_perc_diff_avgData(array: any) {
    this.array_orderbook_1_perc_diff_avg.next(array);
  }
  //orderbook_1_perc_diff_ avg End


  //orderbook_2_perc_diff_ avg Start
  orderbook_2_perc_diff_avgData: any = []
  array_orderbook_2_perc_diff_avg = new BehaviorSubject<any>(this.orderbook_2_perc_diff_avgData);
  orderbook_2_perc_diff_avgcoordData = this.array_orderbook_2_perc_diff_avg.asObservable();

  new_orderbook_2_perc_diff_avgData(array: any) {
    this.array_orderbook_2_perc_diff_avg.next(array);
  }
  //orderbook_2_perc_diff_ avg End



  //orderbook_3_perc_diff_ avg Start
  orderbook_3_perc_diff_avgData: any = []
  array_orderbook_3_perc_diff_avg = new BehaviorSubject<any>(this.orderbook_3_perc_diff_avgData);
  orderbook_3_perc_diff_avgcoordData = this.array_orderbook_3_perc_diff_avg.asObservable();

  new_orderbook_3_perc_diff_avgData(array: any) {
    this.array_orderbook_3_perc_diff_avg.next(array);
  }
  //orderbook_3_perc_diff_ avg End






  //orderbook_1_perc_diff_avg_perc Start
  orderbook_1_perc_diff_avg_percData: any = []
  array_orderbook_1_perc_diff_avg_perc = new BehaviorSubject<any>(this.orderbook_1_perc_diff_avg_percData);
  orderbook_1_perc_diff_avg_perccoordData = this.array_orderbook_1_perc_diff_avg_perc.asObservable();

  new_orderbook_1_perc_diff_avg_percData(array: any) {
    this.array_orderbook_1_perc_diff_avg_perc.next(array);
  }
  //orderbook_1_perc_diff_avg_perc End


  //orderbook_2_perc_diff_avg_perc Start
  orderbook_2_perc_diff_avg_percData: any = []
  array_orderbook_2_perc_diff_avg_perc = new BehaviorSubject<any>(this.orderbook_2_perc_diff_avg_percData);
  orderbook_2_perc_diff_avg_perccoordData = this.array_orderbook_2_perc_diff_avg_perc.asObservable();

  new_orderbook_2_perc_diff_avg_percData(array: any) {
    this.array_orderbook_2_perc_diff_avg_perc.next(array);
  }
  //orderbook_2_perc_diff_avg_perc End



  //orderbook_3_perc_diff_avg_perc Start
  orderbook_3_perc_diff_avg_percData: any = []
  array_orderbook_3_perc_diff_avg_perc = new BehaviorSubject<any>(this.orderbook_3_perc_diff_avg_percData);
  orderbook_3_perc_diff_avg_perccoordData = this.array_orderbook_3_perc_diff_avg_perc.asObservable();

  new_orderbook_3_perc_diff_avg_percData(array: any) {
    this.array_orderbook_3_perc_diff_avg_perc.next(array);
  }
  //orderbook_3_perc_diff_avg_perc End



  //orderbook1Minmax Start
  orderbook1minmaxData: any = []
  array_orderbook1minmax = new BehaviorSubject<any>(this.orderbook1minmaxData);
  orderbook1minmaxcoordData = this.array_orderbook1minmax.asObservable();

  new_orderbook1minmaxData(array: any) {
    this.array_orderbook1minmax.next(array);
  }
  //orderbook1Minmax End


  //orderbook2Minmax Start
  orderbook2minmaxData: any = []
  array_orderbook2minmax = new BehaviorSubject<any>(this.orderbook2minmaxData);
  orderbook2minmaxcoordData = this.array_orderbook2minmax.asObservable();

  new_orderbook2minmaxData(array: any) {
    this.array_orderbook2minmax.next(array);
  }
  //orderbook2Minmax End


  //orderbook3Minmax Start
  orderbook3minmaxData: any = []
  array_orderbook3minmax = new BehaviorSubject<any>(this.orderbook3minmaxData);
  orderbook3minmaxcoordData = this.array_orderbook3minmax.asObservable();

  new_orderbook3minmaxData(array: any) {
    this.array_orderbook3minmax.next(array);
  }


  //orderbook1minPerc start
  orderbook1minpercData: any = []
  array_orderbook1minperc = new BehaviorSubject<any>(this.orderbook1minpercData);
  orderbook1minperccoordData = this.array_orderbook1minperc.asObservable();

  new_orderbook1minpercData(array: any) {
    this.array_orderbook1minperc.next(array)
  }
  //orderbook1minPerc end

  //orderbook2minPerc start
  orderbook2minpercData: any = []
  array_orderbook2minperc = new BehaviorSubject<any>(this.orderbook2minpercData);
  orderbook2minperccoordData = this.array_orderbook2minperc.asObservable();

  new_orderbook2minpercData(array: any) {
    this.array_orderbook2minperc.next(array)
  }
  //orderbook2minPerc end

  //orderbook3minPerc start
  orderbook3minpercData: any = []
  array_orderbook3minperc = new BehaviorSubject<any>(this.orderbook3minpercData);
  orderbook3minperccoordData = this.array_orderbook3minperc.asObservable();

  new_orderbook3minpercData(array: any) {
    this.array_orderbook3minperc.next(array)
  }
  //orderbook3minPerc end



  //orderbook1maxPerc start
  orderbook1maxpercData: any = []
  array_orderbook1maxperc = new BehaviorSubject<any>(this.orderbook1maxpercData);
  orderbook1maxperccoordData = this.array_orderbook1maxperc.asObservable();

  new_orderbook1maxpercData(array: any) {
    this.array_orderbook1maxperc.next(array)
  }
  //orderbook1maxPerc end

  //orderbook2maxPerc start
  orderbook2maxpercData: any = []
  array_orderbook2maxperc = new BehaviorSubject<any>(this.orderbook2maxpercData);
  orderbook2maxperccoordData = this.array_orderbook2maxperc.asObservable();

  new_orderbook2maxpercData(array: any) {
    this.array_orderbook2maxperc.next(array)
  }
  //orderbook2maxPerc end

  //orderbook3maxPerc start
  orderbook3maxpercData: any = []
  array_orderbook3maxperc = new BehaviorSubject<any>(this.orderbook3maxpercData);
  orderbook3maxperccoordData = this.array_orderbook3maxperc.asObservable();

  new_orderbook3maxpercData(array: any) {
    this.array_orderbook3maxperc.next(array)
  }
  //orderbook3maxPerc end


  //orderbook3Minmax End


}
