<div class="card">
  <div class="card-header">
    <span>
      Add User
    </span>
  </div>
  <div class="card-body">
        <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
          <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                      <label><span class="dg-icon mr-2"><i data-feather="user"></i></span>First Name</label>
                      <input formControlName="firstName" type="text" class="form-control border border-top-0 border-left-0 border-right-0 btn-outline-light mb-5 pl-0 pr-0">
                  </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                    <label><span class="dg-icon mr-2"><i data-feather="user"></i></span>Last Name</label>
                    <input formControlName="lastName" type="text" class="form-control border border-top-0 border-left-0 border-right-0 btn-outline-light mb-5 pl-0 pr-0">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                    <label><span class="dg-icon mr-2"><i data-feather="user"></i></span>User Name</label>
                    <input formControlName="username" type="text" class="form-control border border-top-0 border-left-0 border-right-0 btn-outline-light mb-5 pl-0 pr-0">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                    <label><span class="dg-icon mr-2"><i data-feather="user"></i></span>Email</label>
                    <input formControlName="email_address" type="email" class="form-control border border-top-0 border-left-0 border-right-0 btn-outline-light mb-5 pl-0 pr-0">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                    <label><span class="dg-icon mr-2"><i data-feather="user"></i></span>Password</label>
                    <input formControlName="password" type="password" class="form-control border border-top-0 border-left-0 border-right-0 btn-outline-light mb-5 pl-0 pr-0">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                    <label><span class="dg-icon mr-2"><i data-feather="user"></i></span>Role</label>
                    <select class="form-control" formControlName="role">
                      <option value="" selected>Select Role</option>
                      <option value="user">User</option>
                      <option value="developer">Developer</option>
                      <option value="admin">Admin</option>
                    </select>
                </div>
              </div>
              <div class="col-12 text-center">
                  <div class="form-group">
                    <button  class="btn btn-primary btn-xl">Login</button>
                  </div>
              </div>
          </div>
      </form>
  </div>
</div>
