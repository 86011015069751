import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  signupForm;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
  ) {
    this.signupForm = this.formBuilder.group({
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      profile_image: ['0142a09455b35951e57e2e21e3d95ff9.jpg', Validators.required],
      role: ['', Validators.required],
      token: ['4f4f70e0348444c5cfca98f15a887516'],
      email_address: ['', [Validators.required, Validators.email]],
      timezone: ['Asia/Karachi'],
      check_api_settings: ['yes'],
      application_mode: ['test'],
      user_role: ['1'],
      special_role: ['1'],
      google_auth: ['no'],
      trigger_enable: ['no'],
      from_candles: ['yes'],
      password: ['', Validators.required],
      SessionTime: [ new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime()],
    });
   }

  ngOnInit() {
  }

  onSubmit(){
    console.log(this.signupForm.value)
    this.authenticationService.signUp(this.signupForm.value).subscribe(data => {
      console.log(data, 'All Working')
      if(data['success'] == true){
        this.signupForm.reset();
      }
    })
  }



}
