import { Injectable ,EventEmitter, Output} from '@angular/core';

import { HttpClient, HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { environment } from '../../environments/environment';




@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
                'Authorization': 'Basic ZGlnaWVib3Q6ZGlnaWVib3Q=',
            },
        });

        return next.handle(req);
    }

    @Output() isLoginSuccessfully: EventEmitter<any> = new EventEmitter();

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public  apiURL: string = environment.binanceApiURL;

    constructor(private http: HttpClient)
    {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        if (this.currentUserSubject['_value'] !== null) {
          // setTimeout(()=> {

            if (typeof this.currentUserSubject['_value']['SessionTime'] !== 'undefined' && this.currentUserSubject['_value']['SessionTime'] !== null) {
                if (parseFloat(this.currentUserSubject['_value']['SessionTime']) > new Date().getTime() ) {
                  console.log('Login successfully!');
                } else {
                  localStorage.removeItem('currentUser');
                  window.location.reload();
                }
            } else {
              localStorage.removeItem('currentUser');
            }
          // }, 1000)
        }
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    async login(username: string, password: string) {
        // return this.http.post<any>(this.apiURL+`apiEndPoint/authenticate_test`, { username, password })
        // let requestHeaders = new HttpHeaders();
        // // requestHeaders = requestHeaders.set('authorization', 'Basic ZGlnaWVib3Q6ZGlnaWVib3Q=');
        var user = await this.checkCredentials(username, password)
        // if (username == 'Shahzad' && password == 'shahzadvizz') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Shahzad", "firstName": "Shahzad", "lastName": "Ali", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "developer", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Wasiq' && password == 'wasiqvizz') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Wasiq", "firstName": "Syed", "lastName": "Wasiq", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "developer", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Asim' && password == 'asimvizz') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Asim", "firstName": "Asim", "lastName": "Mehmood", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "developer", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Hassan' && password == 'hassanvizz') {
        //   var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Hassan", "firstName": "Hassan", "lastName": "Ali", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "admin", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Ijaz' && password == 'ijazvizz') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Ijaz", "firstName": "Ejaz", "lastName": "Bhatti", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "developer", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Ali' && password == 'alivizz') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Ali", "firstName": "Ali", "lastName": "Abbas", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "developer", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'User' && password == ')(*&^%$#@!') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "User", "firstName": "Vizz", "lastName": "Developer", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "user", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'abbas' && password == 'Vizz(*&^%$#@!') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "abbas", "firstName": "Mudasser", "lastName": "Abbas", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "admin", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Doug' && password == 'KulaDigieT20') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Doug", "firstName": "Doug", "lastName": "Kyle", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "admin", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Waleed' && password == 'candlesadmin') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Waleed", "firstName": "Waleed", "lastName": "Khalid", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "admin", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Huzaifa' && password == 'candlesadmin') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Huzaifa", "firstName": "Huzaifa", "lastName": "Asif", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "admin", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // } else if (username == 'Tehmina' && password == 'tehmina11$najeeb') {
        //     var user = { "id": "5c0915befc9aadaac61dd1b8", "username": "Tehmina", "firstName": "Tehmina", "lastName": "Najeeb", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "admin", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
        // }
        if(username == 'admin' && password == 'candlesadmin'){
          var user_data1 = { "id": "5c0915befc9aadaac61dd1b8", "username": "Huzaifa", "firstName": "Huzaifa", "lastName": "Asif", "profile_image": "0142a09455b35951e57e2e21e3d95ff9.jpg", "role": "admin", "token": "4f4f70e0348444c5cfca98f15a887516", "email_address": "vizzdevelopertest@gmail.com", "timezone": "Asia/Karachi", "check_api_settings": "yes", "application_mode": "test", "user_role": "1", "special_role": "1", "google_auth": "no", "trigger_enable": "no", "from_candles": "yes", "SessionTime": new Date((new Date().getTime()) + (1000 * 60 * 10080)).getTime() };
          if (user_data1 && user_data1.token) {
              this.isLoginSuccessfully.emit(JSON.stringify(user_data1));
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user_data1));
              localStorage.setItem('loggedUserRole', JSON.stringify(user_data1.role));
              localStorage.setItem('loggedUserName', JSON.stringify(user_data1.username));
              // this.currentUserSubject.next(user);
          }
          return user_data1;
        } else {
          // Nothing to change
        }
        // return (user)
            // .pipe(map(user => {
                // login successful if there's a jwt token in the response
                var user_data = user.user
                if (user_data && user_data.token) {
                    this.isLoginSuccessfully.emit(JSON.stringify(user_data));
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user_data));
                    localStorage.setItem('loggedUserRole', JSON.stringify(user_data.role));
                    localStorage.setItem('loggedUserName', JSON.stringify(user_data.username));
                    // this.currentUserSubject.next(user);
                }

                return user;
            // }));
    }


    logout() {
      localStorage.removeItem('currentUser');
      localStorage.clear();
      this.currentUserSubject.next(null);
    }

    forgetPassword(email: string) {
        //Using static get request of forget password for the time being but it needs to be handeled in a proper way. //Umer Abbas [19-11-19]
        let forgetPasswordUrl ='http://207.180.212.175/digibot_backoffice/login/forget-password?email='+email;
        return this.http.get<any>(forgetPasswordUrl);
        // return this.http.post<any>(this.apiURL+`apiEndPoint/forgetPassword`, { email });
    }

    getUserAccessRights(user_id: string){
        // return this.http.get<any>('https://jsonplaceholder.typicode.com/todos/2')
        return this.http.post<any>('https://app.digiebot.com/admin/api_calls/get_user_profile_image', { user_id })
            .pipe(map(rights => {
                return rights;
            }));
    }



    signUp(data){
      return this.http.post<any>('https://candlesapi.digiebot.com/createUser', data)
            .pipe(map(user => {
                return user;
            }));
    }


    checkCredentials(username, password){
      return this.http.post<any>('https://candlesapi.digiebot.com/validateUser',  {'username': username, 'password': password }).toPromise();
    }

}
