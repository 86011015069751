import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthenticationService } from './_services/authentication.service';
import { ApicallsService } from './_services/apicalls.service';
import { ShareddataService } from './_services/shareddata.service';
import { first } from 'rxjs/operators';
import { User, Role } from './_models';
import{ ToastrService } from 'ngx-toastr';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
        timezones = [
          {'Area': 'Pacific/Midway',    'Zone' : "-1100"},
          {'Area': 'US/Samoa'           ,    'Zone' : "-1100"},
          {'Area': 'US/Hawaii'          ,    'Zone' : "-1000"},
          {'Area': 'US/Alaska'          ,    'Zone' : "-0900"},
          {'Area': 'US/Pacific'         ,    'Zone' : "-0800"},
          {'Area':  'America/Tijuana'    ,    'Zone' : "-0800"},
          {'Area': 'US/Arizona'         ,    'Zone' : "-0700"},
          {'Area': 'US/Mountain'        ,    'Zone' : "-0700"},
          {'Area': 'America/Chihuahua'  ,    'Zone' : "-0700"},
          {'Area': 'America/Mazatlan'   ,    'Zone' : "-0700"},
          {'Area': 'America/Mexico_City',    'Zone' : "-0600"},
          {'Area': 'America/Monterrey'  ,    'Zone' : "-0600"},
          {'Area': 'Canada/Saskatchewan',    'Zone' : "-0600"},
          {'Area': 'US/Central'         ,    'Zone' : "-0600"},
          {'Area': 'US/Eastern'         ,    'Zone' : "-0500"},
          {'Area': 'US/East-Indiana'    ,    'Zone' : "-0500"},
          {'Area': 'America/Bogota'     ,    'Zone' : "-0500"},
          {'Area': 'America/Lima'       ,    'Zone' : "-0500"},
          {'Area': 'America/Caracas'    ,    'Zone' : "-0430"},
          {'Area': 'Canada/Atlantic'    ,    'Zone' : "-0400"},
          {'Area': 'America/La_Paz'     ,    'Zone' : "-0400"},
          {'Area': 'America/Santiago'   ,    'Zone' : "-0400"},
          {'Area': 'Canada/Newfoundland',    'Zone' : "-0330"},
          {'Area': 'America/Buenos_Aires' ,    'Zone' : "-0300"},
          {'Area': 'Greenland'          ,    'Zone' : "-0300"},
          {'Area': 'Atlantic/Stanley'   ,    'Zone' : "-0200"},
          {'Area': 'Atlantic/Azores'    ,    'Zone' : "-0100"},
          {'Area': 'Atlantic/Cape_Verde',    'Zone' : "-0100"},
          {'Area': 'Africa/Casablanca'  ,    'Zone' : "+0000"},
          {'Area': 'Europe/Dublin'      ,    'Zone' : "+0000"},
          {'Area': 'Europe/Lisbon'      ,    'Zone' : "+0000"},
          {'Area': 'Europe/London'      ,    'Zone' : "+0000"},
          {'Area': 'Africa/Monrovia'    ,    'Zone' : "+0000"},
          {'Area': 'Europe/Amsterdam'   ,    'Zone' : "+0100"},
          {'Area': 'Europe/Belgrade'    ,    'Zone' : "+0100"},
          {'Area': 'Europe/Berlin'      ,    'Zone' : "+0100"},
          {'Area': 'Europe/Bratislava'  ,    'Zone' : "+0100"},
          {'Area': 'Europe/Brussels'    ,    'Zone' : "+0100"},
          {'Area': 'Europe/Budapest'    ,    'Zone' : "+0100"},
          {'Area': 'Europe/Copenhagen'  ,    'Zone' : "+0100"},
          {'Area': 'Europe/Ljubljana'   ,    'Zone' : "+0100"},
          {'Area': 'Europe/Madrid'      ,    'Zone' : "+0100"},
          {'Area': 'Europe/Paris'       ,    'Zone' : "+0100"},
          {'Area': 'Europe/Prague'      ,    'Zone' : "+0100"},
          {'Area': 'Europe/Rome'        ,    'Zone' : "+0100"},
          {'Area': 'Europe/Sarajevo'    ,    'Zone' : "+0100"},
          {'Area': 'Europe/Skopje'      ,    'Zone' : "+0100"},
          {'Area': 'Europe/Stockholm'   ,    'Zone' : "+0100"},
          {'Area': 'Europe/Vienna'      ,    'Zone' : "+0100"},
          {'Area': 'Europe/Warsaw'      ,    'Zone' : "+0100"},
          {'Area': 'Europe/Zagreb'      ,    'Zone' : "+0100"},
          {'Area': 'Europe/Athens'      ,    'Zone' : "+0200"},
          {'Area': 'Europe/Bucharest'   ,    'Zone' : "+0200"},
          {'Area': 'Africa/Cairo'       ,    'Zone' : "+0200"},
          {'Area': 'Africa/Harare'      ,    'Zone' : "+0200"},
          {'Area': 'Europe/Helsinki'    ,    'Zone' : "+0200"},
          {'Area': 'Europe/Istanbul'    ,    'Zone' : "+0200"},
          {'Area': 'Asia/Jerusalem'     ,    'Zone' : "+0200"},
          {'Area': 'Europe/Kiev'        ,    'Zone' : "+0200"},
          {'Area': 'Europe/Minsk'       ,    'Zone' : "+0200"},
          {'Area': 'Europe/Riga'        ,    'Zone' : "+0200"},
          {'Area': 'Europe/Sofia'       ,    'Zone' : "+0200"},
          {'Area': 'Europe/Tallinn'     ,    'Zone' : "+0200"},
          {'Area': 'Europe/Vilnius'     ,    'Zone' : "+0200"},
          {'Area': 'Asia/Baghdad'       ,    'Zone' : "+0300"},
          {'Area': 'Asia/Kuwait'        ,    'Zone' : "+0300"},
          {'Area': 'Africa/Nairobi'     ,    'Zone' : "+0300"},
          {'Area': 'Asia/Riyadh'        ,    'Zone' : "+0300"},
          {'Area': 'Europe/Moscow'      ,    'Zone' : "+0300"},
          {'Area': 'Asia/Tehran'        ,    'Zone' : "+0330"},
          {'Area': 'Asia/Baku'          ,    'Zone' : "+0400"},
          {'Area': 'Europe/Volgograd'   ,    'Zone' : "+0400"},
          {'Area': 'Asia/Muscat'        ,    'Zone' : "+0400"},
          {'Area': 'Asia/Tbilisi'       ,    'Zone' : "+0400"},
          {'Area': 'Asia/Yerevan'       ,    'Zone' : "+0400"},
          {'Area': 'Asia/Kabul'         ,    'Zone' : "+0430"},
          {'Area': 'Asia/Karachi'       ,    'Zone' : "+0500"},
          {'Area': 'Asia/Tashkent'      ,    'Zone' : "+0500"},
          {'Area': 'Asia/Kolkata'       ,    'Zone' : "+0530"},
          {'Area': 'Asia/Kathmandu'     ,    'Zone' : "+0545"},
          {'Area': 'Asia/Yekaterinburg' ,    'Zone' : "+0600"},
          {'Area': 'Asia/Almaty'        ,    'Zone' : "+0600"},
          {'Area': 'Asia/Dhaka'         ,    'Zone' : "+0600"},
          {'Area': 'Asia/Novosibirsk'   ,    'Zone' : "+0700"},
          {'Area': 'Asia/Bangkok'       ,    'Zone' : "+0700"},
          {'Area': 'Asia/Jakarta'       ,    'Zone' : "+0700"},
          {'Area': 'Asia/Krasnoyarsk'   ,    'Zone' : "+0800"},
          {'Area': 'Asia/Chongqing'     ,    'Zone' : "+0800"},
          {'Area': 'Asia/Hong_Kong'     ,    'Zone' : "+0800"},
          {'Area': 'Asia/Kuala_Lumpur'  ,    'Zone' : "+0800"},
          {'Area': 'Australia/Perth'    ,    'Zone' : "+0800"},
          {'Area': 'Asia/Singapore'     ,    'Zone' : "+0800"},
          {'Area': 'Asia/Taipei'        ,    'Zone' : "+0800"},
          {'Area': 'Asia/Ulaanbaatar'   ,    'Zone' : "+0800"},
          {'Area': 'Asia/Urumqi'        ,    'Zone' : "+0800"},
          {'Area': 'Asia/Irkutsk'       ,    'Zone' : "+0900"},
          {'Area': 'Asia/Seoul'         ,    'Zone' : "+0900"},
          {'Area': 'Asia/Tokyo'         ,    'Zone' : "+0900"},
          {'Area': 'Australia/Adelaide' ,    'Zone' : "+0930"},
          {'Area': 'Australia/Darwin'   ,    'Zone' : "+0930"},
          {'Area': 'Asia/Yakutsk'       ,    'Zone' : "+1000"},
          {'Area': 'Australia/Brisbane' ,    'Zone' : "+1000"},
          {'Area': 'Australia/Canberra' ,    'Zone' : "+1000"},
          {'Area': 'Pacific/Guam'       ,    'Zone' : "+1000"},
          {'Area': 'Australia/Hobart'   ,    'Zone' : "+1000"},
          {'Area': 'Australia/Melbourne',    'Zone' : "+1000"},
          {'Area': 'Pacific/Port_Moresby' ,    'Zone' : "+1000"},
          {'Area': 'Australia/Sydney'   ,    'Zone' : "+1000"},
          {'Area': 'Asia/Vladivostok'   ,    'Zone' : "+1100"},
          {'Area': 'Asia/Magadan'       ,    'Zone' : "+1200"},
          {'Area': 'Pacific/Auckland'   ,    'Zone' : "+1200"},
          {'Area': 'Pacific/Fiji'       ,    'Zone' : "+1200"},
      ];
      timezonevalue;
      tradeon:boolean =  false;
      tradeoff:boolean =  true;
      currentUser: User;
      userCoinsArr = [];
      username = JSON.parse(localStorage.getItem('loggedUserName'));
      userDetail = JSON.parse(localStorage.getItem('currentUser'));
      tradingStatusLabel : string = (this.userDetail==null)?'':( (this.userDetail.check_api_settings == 'yes') &&  (this.userDetail.application_mode == 'live'))?'live':'test';
      isTradingStatusLive : boolean = false;

      GETEXCHANGE :string = localStorage.getItem('GLOBALEXCHANGETYPE');
      GLOBALEXCHANGETYPE = (this.GETEXCHANGE =='' || this.GETEXCHANGE ==null)?'binance':this.GETEXCHANGE;

      GLOBALEAPPLICATIONMODE : any  = ( (localStorage.getItem('GLOBALEAPPLICATIONMODE') == null) || (localStorage.getItem('GLOBALEAPPLICATIONMODE') == ''))?this.tradingStatusLabel:localStorage.getItem('GLOBALEAPPLICATIONMODE');

      GLOBALCOIN : string = this.route.snapshot.paramMap.get('coin');
      GLOBALEXCHANGECOIN :string = localStorage.getItem('GLOBALCOIN_'+this.GLOBALEXCHANGETYPE);
      appModeClass : any = (this.GLOBALEAPPLICATIONMODE == 'live')?'account-type-live':'account-type-test';
      profile_image: any;
      auto_trading: any;
      custom_trading: any;
      user_id: any;
      width;
      current_coin: string;

      constructor(
          private router: Router,
          private route: ActivatedRoute,
          private authenticationService: AuthenticationService,
          private ApicallsService: ApicallsService,
          private ShareddataService : ShareddataService,
          private toastr: ToastrService,
          private permissionsService: NgxPermissionsService,
      )

      {
          this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
          authenticationService.isLoginSuccessfully.subscribe(user => this.islogin(user));
      }

      private islogin(user): void {
          let userObj = JSON.parse(user);
          let userId = userObj['id'];
          this.listCurrentUserCoins(userId);
      }//End of islogin

      ngOnInit() {


        var link = window.location.href.includes("localhost");
        if(link == false){
          console.log = function(){};
        }


        console.log(this.userDetail)
        var userdata = JSON.parse(localStorage.getItem('currentUser'));
        if(typeof userdata !== 'undefined'){
          if(typeof userdata['from_candles'] == 'undefined' || userdata['from_candles'] == null){
            // alert('no Candleuser');
            console.log('Not Authenticated From Candle')
            this.logout()
          } else {
            console.log('Authentic Candle User')
          }
        }





          const perm = JSON.parse(localStorage.getItem('loggedUserName'));
          this.permissionsService.loadPermissions([perm]);




          // var utcDate1 = new Date();
          // var utcdate = Date.UTC(utcDate1.getUTCFullYear(), (utcDate1.getUTCMonth() + 1), utcDate1.getUTCDate(), utcDate1.getUTCHours(), 0, 0);
          this.setExchange();
          (localStorage.getItem('GLOBALEAPPLICATIONMODE')=='' || localStorage.getItem('GLOBALEAPPLICATIONMODE')==null)?localStorage.setItem('GLOBALEAPPLICATIONMODE',this.GLOBALEAPPLICATIONMODE):'';
          let coin = this.route.snapshot.paramMap.get('coin');
          if(coin != ""){
              this.GLOBALCOIN =this.route.snapshot.paramMap.get('coin');
          }else{
              this.GLOBALCOIN = "BTCUSDT"
          }
          if (localStorage.getItem('currentCoin')) {
              this.current_coin = localStorage.getItem('currentCoin');
          } else {
              this.current_coin = coin;
          }
          this.tradingStatusLabel  = this.GLOBALEAPPLICATIONMODE;
          this.isTradingStatusLive = (this.GLOBALEAPPLICATIONMODE == 'live')?true:false;
          let id = (this.currentUser == null)?'':this.currentUser.id;
          this.listCurrentUserCoins(id);
          this.get_user_info();
          this.trading_on_off();

      }//End of ngOnInit

      setExchange(){
          localStorage.setItem('GLOBALEXCHANGETYPE',this.GLOBALEXCHANGETYPE)
      }


      get isAdmin() {
          return this.currentUser && this.currentUser.role === Role.Admin;
      }//End of is admin

      logout() {
          this.authenticationService.logout();
          this.router.navigate(['/login']);
      }//End of logout


      listCurrentUserCoins(id){

          this.ApicallsService.listCurrentUserCoins(id='global', this.GLOBALEXCHANGETYPE).pipe(first())
          .subscribe(
              data => {
                  var i = 0;
                  this.userCoinsArr = [];
                  for (let key = 0; key< data.message.length; key++) {
                      if (data.message[key].exchange_type == this.GLOBALEXCHANGETYPE) {
                          this.userCoinsArr[i++] = data.message[key];
                      }
                  }
                  if(this.userCoinsArr.length>0){
                      this.GLOBALCOIN = this.route.snapshot.paramMap.get('coin');
                }
              //   console.log(this.timezones,this.userCoinsArr)

              },
              error => {
                  console.log(error)
              });
      }//End of listCurrentUserCoins
      timezoneChange(zone){
          // var zoneee= this.timezones.find(element => element == zone);
          // console.log(this.timezonevalue, zone)
          localStorage.removeItem('TimeZone');
          localStorage.setItem('TimeZone', this.timezonevalue)
      }

      changeGlobalCoins(coin){


          this.GLOBALCOIN =this.route.snapshot.paramMap.get('coin');
          /* Reload Page... */
          // if (localStorage.getItem('currentCoin')) {
              // this.current_coin = localStorage.getItem('currentCoin');
          // } else {
              this.current_coin = coin;
          // }
          localStorage.removeItem('refresh_flag');
          var url = this.router.url;
          var n = url.includes("dailychart");
          if(n == true){
              this.router.navigate(['/dailychart',coin]);
              // window.open('https://candles.digiebot.com/dailychart/'+coin, '_blank');
          } else {
              this.router.navigate(['/mainchart2',coin]);
              // window.open('https://candles.digiebot.com/mainchart2/'+coin, '_blank');
          }

      }//End of changeGlobalCoins
      clickGlobalCoins(coin){


          // this.GLOBALCOIN =this.route.snapshot.paramMap.get('coin');
          /* Reload Page... */
          // if (localStorage.getItem('currentCoin')) {
              // this.current_coin = localStorage.getItem('currentCoin');
          // } else {
              // this.current_coin = coin;
          // }
          // localStorage.removeItem('refresh_flag');
          var url = this.router.url;
          var n = url.includes("dailychart");
          if(n == true){
              // this.router.navigate(['/dailychart',coin]);
              window.open('https://candles.digiebot.com/dailychart/'+coin, '_blank');
          } else {
              // this.router.navigate(['/mainchart2',coin]);
              window.open('https://candles.digiebot.com/mainchart2/'+coin, '_blank');
          }

      }//End of changeGlobalCoins

      toggleLiveTest(event){
          let caption = 'Notification';
          this.tradingStatusLabel = (event.target.checked)?'live':'test';
          this.appModeClass  = (this.tradingStatusLabel == 'live')?'account-type-live':'account-type-test';
          let message = 'Application Mode Change To '+this.tradingStatusLabel.toUpperCase();
          this.showSuccess(message,caption);
          this.ShareddataService.changeAppMode(this.tradingStatusLabel);
      }//End of toggleLiveTest

      globalExchangeChange(exchange){
          let caption = 'Notification';
          let message = 'Exchange Change To '+exchange.toUpperCase();
          this.showSuccess(message,caption);
          this.ShareddataService.changeExchange(exchange);

          this.GLOBALEXCHANGETYPE = exchange;
          let id = (this.currentUser == null)?'':this.currentUser.id;
          this.listCurrentUserCoins(id);
      }//End of globalExchangeChange


      showSuccess(message,caption){
          this.toastr.success(message,caption);
        }

        showError(message,caption){
            this.toastr.error(message,caption);
        }
        showInfo(message,caption){
            this.toastr.info(message,caption);
        }

        get_user_info(){
          this.userDetail = JSON.parse(localStorage.getItem('currentUser'));


        }

        trading_on_off(){



        }
















  //   timezones = [
  //       {'Area': 'Pacific/Midway',    'Zone' : "-1100"},
  //       {'Area': 'US/Samoa'           ,    'Zone' : "-1100"},
  //       {'Area': 'US/Hawaii'          ,    'Zone' : "-1000"},
  //       {'Area': 'US/Alaska'          ,    'Zone' : "-0900"},
  //       {'Area': 'US/Pacific'         ,    'Zone' : "-0800"},
  //       {'Area':  'America/Tijuana'    ,    'Zone' : "-0800"},
  //       {'Area': 'US/Arizona'         ,    'Zone' : "-0700"},
  //       {'Area': 'US/Mountain'        ,    'Zone' : "-0700"},
  //       {'Area': 'America/Chihuahua'  ,    'Zone' : "-0700"},
  //       {'Area': 'America/Mazatlan'   ,    'Zone' : "-0700"},
  //       {'Area': 'America/Mexico_City',    'Zone' : "-0600"},
  //       {'Area': 'America/Monterrey'  ,    'Zone' : "-0600"},
  //       {'Area': 'Canada/Saskatchewan',    'Zone' : "-0600"},
  //       {'Area': 'US/Central'         ,    'Zone' : "-0600"},
  //       {'Area': 'US/Eastern'         ,    'Zone' : "-0500"},
  //       {'Area': 'US/East-Indiana'    ,    'Zone' : "-0500"},
  //       {'Area': 'America/Bogota'     ,    'Zone' : "-0500"},
  //       {'Area': 'America/Lima'       ,    'Zone' : "-0500"},
  //       {'Area': 'America/Caracas'    ,    'Zone' : "-0430"},
  //       {'Area': 'Canada/Atlantic'    ,    'Zone' : "-0400"},
  //       {'Area': 'America/La_Paz'     ,    'Zone' : "-0400"},
  //       {'Area': 'America/Santiago'   ,    'Zone' : "-0400"},
  //       {'Area': 'Canada/Newfoundland',    'Zone' : "-0330"},
  //       {'Area': 'America/Buenos_Aires' ,    'Zone' : "-0300"},
  //       {'Area': 'Greenland'          ,    'Zone' : "-0300"},
  //       {'Area': 'Atlantic/Stanley'   ,    'Zone' : "-0200"},
  //       {'Area': 'Atlantic/Azores'    ,    'Zone' : "-0100"},
  //       {'Area': 'Atlantic/Cape_Verde',    'Zone' : "-0100"},
  //       {'Area': 'Africa/Casablanca'  ,    'Zone' : "+0000"},
  //       {'Area': 'Europe/Dublin'      ,    'Zone' : "+0000"},
  //       {'Area': 'Europe/Lisbon'      ,    'Zone' : "+0000"},
  //       {'Area': 'Europe/London'      ,    'Zone' : "+0000"},
  //       {'Area': 'Africa/Monrovia'    ,    'Zone' : "+0000"},
  //       {'Area': 'Europe/Amsterdam'   ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Belgrade'    ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Berlin'      ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Bratislava'  ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Brussels'    ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Budapest'    ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Copenhagen'  ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Ljubljana'   ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Madrid'      ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Paris'       ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Prague'      ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Rome'        ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Sarajevo'    ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Skopje'      ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Stockholm'   ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Vienna'      ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Warsaw'      ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Zagreb'      ,    'Zone' : "+0100"},
  //       {'Area': 'Europe/Athens'      ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Bucharest'   ,    'Zone' : "+0200"},
  //       {'Area': 'Africa/Cairo'       ,    'Zone' : "+0200"},
  //       {'Area': 'Africa/Harare'      ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Helsinki'    ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Istanbul'    ,    'Zone' : "+0200"},
  //       {'Area': 'Asia/Jerusalem'     ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Kiev'        ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Minsk'       ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Riga'        ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Sofia'       ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Tallinn'     ,    'Zone' : "+0200"},
  //       {'Area': 'Europe/Vilnius'     ,    'Zone' : "+0200"},
  //       {'Area': 'Asia/Baghdad'       ,    'Zone' : "+0300"},
  //       {'Area': 'Asia/Kuwait'        ,    'Zone' : "+0300"},
  //       {'Area': 'Africa/Nairobi'     ,    'Zone' : "+0300"},
  //       {'Area': 'Asia/Riyadh'        ,    'Zone' : "+0300"},
  //       {'Area': 'Europe/Moscow'      ,    'Zone' : "+0300"},
  //       {'Area': 'Asia/Tehran'        ,    'Zone' : "+0330"},
  //       {'Area': 'Asia/Baku'          ,    'Zone' : "+0400"},
  //       {'Area': 'Europe/Volgograd'   ,    'Zone' : "+0400"},
  //       {'Area': 'Asia/Muscat'        ,    'Zone' : "+0400"},
  //       {'Area': 'Asia/Tbilisi'       ,    'Zone' : "+0400"},
  //       {'Area': 'Asia/Yerevan'       ,    'Zone' : "+0400"},
  //       {'Area': 'Asia/Kabul'         ,    'Zone' : "+0430"},
  //       {'Area': 'Asia/Karachi'       ,    'Zone' : "+0500"},
  //       {'Area': 'Asia/Tashkent'      ,    'Zone' : "+0500"},
  //       {'Area': 'Asia/Kolkata'       ,    'Zone' : "+0530"},
  //       {'Area': 'Asia/Kathmandu'     ,    'Zone' : "+0545"},
  //       {'Area': 'Asia/Yekaterinburg' ,    'Zone' : "+0600"},
  //       {'Area': 'Asia/Almaty'        ,    'Zone' : "+0600"},
  //       {'Area': 'Asia/Dhaka'         ,    'Zone' : "+0600"},
  //       {'Area': 'Asia/Novosibirsk'   ,    'Zone' : "+0700"},
  //       {'Area': 'Asia/Bangkok'       ,    'Zone' : "+0700"},
  //       {'Area': 'Asia/Jakarta'       ,    'Zone' : "+0700"},
  //       {'Area': 'Asia/Krasnoyarsk'   ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Chongqing'     ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Hong_Kong'     ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Kuala_Lumpur'  ,    'Zone' : "+0800"},
  //       {'Area': 'Australia/Perth'    ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Singapore'     ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Taipei'        ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Ulaanbaatar'   ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Urumqi'        ,    'Zone' : "+0800"},
  //       {'Area': 'Asia/Irkutsk'       ,    'Zone' : "+0900"},
  //       {'Area': 'Asia/Seoul'         ,    'Zone' : "+0900"},
  //       {'Area': 'Asia/Tokyo'         ,    'Zone' : "+0900"},
  //       {'Area': 'Australia/Adelaide' ,    'Zone' : "+0930"},
  //       {'Area': 'Australia/Darwin'   ,    'Zone' : "+0930"},
  //       {'Area': 'Asia/Yakutsk'       ,    'Zone' : "+1000"},
  //       {'Area': 'Australia/Brisbane' ,    'Zone' : "+1000"},
  //       {'Area': 'Australia/Canberra' ,    'Zone' : "+1000"},
  //       {'Area': 'Pacific/Guam'       ,    'Zone' : "+1000"},
  //       {'Area': 'Australia/Hobart'   ,    'Zone' : "+1000"},
  //       {'Area': 'Australia/Melbourne',    'Zone' : "+1000"},
  //       {'Area': 'Pacific/Port_Moresby' ,    'Zone' : "+1000"},
  //       {'Area': 'Australia/Sydney'   ,    'Zone' : "+1000"},
  //       {'Area': 'Asia/Vladivostok'   ,    'Zone' : "+1100"},
  //       {'Area': 'Asia/Magadan'       ,    'Zone' : "+1200"},
  //       {'Area': 'Pacific/Auckland'   ,    'Zone' : "+1200"},
  //       {'Area': 'Pacific/Fiji'       ,    'Zone' : "+1200"},
  //   ];
  //   timezonevalue;
  //   tradeon:boolean =  false;
  //   tradeoff:boolean =  true;
  //   currentUser: User;
  //   userCoinsArr = [];
  //   username = JSON.parse(localStorage.getItem('loggedUserName'));
  //   userDetail = JSON.parse(localStorage.getItem('currentUser'));
  //   tradingStatusLabel : string = (this.userDetail==null)?'':( (this.userDetail.check_api_settings == 'yes') &&  (this.userDetail.application_mode == 'live'))?'live':'test';
  //   isTradingStatusLive : boolean = false;

  //   GETEXCHANGE :string = localStorage.getItem('GLOBALEXCHANGETYPE');
  //   GLOBALEXCHANGETYPE = (this.GETEXCHANGE =='' || this.GETEXCHANGE ==null)?'binance':this.GETEXCHANGE;

  //   GLOBALEAPPLICATIONMODE : any  = ( (localStorage.getItem('GLOBALEAPPLICATIONMODE') == null) || (localStorage.getItem('GLOBALEAPPLICATIONMODE') == ''))?this.tradingStatusLabel:localStorage.getItem('GLOBALEAPPLICATIONMODE');

  //   GLOBALCOIN : string = this.route.snapshot.paramMap.get('coin');
  //   GLOBALEXCHANGECOIN :string = localStorage.getItem('GLOBALCOIN_'+this.GLOBALEXCHANGETYPE);
  //   appModeClass : any = (this.GLOBALEAPPLICATIONMODE == 'live')?'account-type-live':'account-type-test';
  //   profile_image: any;
  //   auto_trading: any;
  //   custom_trading: any;
  //   user_id: any;
  //   width;
  //   current_coin: string;

  //   constructor(
  //       private router: Router,
  //       private route: ActivatedRoute,
  //       private authenticationService: AuthenticationService,
  //       private ApicallsService: ApicallsService,
  //       private ShareddataService : ShareddataService,
  //       private toastr: ToastrService,
  //       private permissionsService: NgxPermissionsService,
  //   )

  //   {
  //       this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  //       authenticationService.isLoginSuccessfully.subscribe(user => this.islogin(user));
  //   }

  //   private islogin(user): void {
  //       let userObj = JSON.parse(user);
  //       let userId = userObj['id'];
  //   }//End of islogin

  //   ngOnInit() {




  //       const perm = JSON.parse(localStorage.getItem('loggedUserName'));
  //       this.permissionsService.loadPermissions([perm]);




  //       // var utcDate1 = new Date();
  //       // var utcdate = Date.UTC(utcDate1.getUTCFullYear(), (utcDate1.getUTCMonth() + 1), utcDate1.getUTCDate(), utcDate1.getUTCHours(), 0, 0);
  //       this.setExchange();
  //       (localStorage.getItem('GLOBALEAPPLICATIONMODE')=='' || localStorage.getItem('GLOBALEAPPLICATIONMODE')==null)?localStorage.setItem('GLOBALEAPPLICATIONMODE',this.GLOBALEAPPLICATIONMODE):'';
  //       let coin = this.route.snapshot.paramMap.get('coin');
  //       if(coin != ""){
  //           this.GLOBALCOIN =this.route.snapshot.paramMap.get('coin');
  //       }else{
  //           this.GLOBALCOIN = "BTCUSDT"
  //       }
  //       if (localStorage.getItem('currentCoin')) {
  //           this.current_coin = localStorage.getItem('currentCoin');
  //       } else {
  //           this.current_coin = coin;
  //       }
  //       this.tradingStatusLabel  = this.GLOBALEAPPLICATIONMODE;
  //       this.isTradingStatusLive = (this.GLOBALEAPPLICATIONMODE == 'live')?true:false;
  //       let id = (this.currentUser == null)?'':this.currentUser.id;
  //       this.listCurrentUserCoins(id);
  //       this.get_user_info();
  //       this.trading_on_off();

  //   }//End of ngOnInit



  //   listCurrentUserCoins(id){
  //     this.ApicallsService.listCurrentUserCoins(id, this.GLOBALEXCHANGETYPE).pipe(first())
  //     .subscribe(
  //         data => {
  //         //   this.userCoinsArr = data.message;
  //             this.userCoinsArr = data.message.sort((a, b) => (a.symbol > b.symbol) ? 1 : ((b.symbol > a.symbol) ? -1 : 0));

  //           if(this.userCoinsArr.length>0){

  //             //   if (this.GLOBALCOIN == '' || this.GLOBALCOIN == null){
  //                 this.GLOBALCOIN = this.userCoinsArr[0]['symbol'];
  //                 localStorage.setItem('GLOBALCOIN_'+this.GLOBALEXCHANGETYPE,this.GLOBALCOIN);
  //                 localStorage.setItem('GLOBALCOIN',this.GLOBALCOIN);
  //                 this.changeGlobalCoins(this.GLOBALCOIN);
  //             //     console.log('if ran')
  //             //   }else{
  //             //     console.log('else ran')
  //             //     this.GLOBALCOIN  = localStorage.getItem('GLOBALCOIN_'+this.GLOBALEXCHANGETYPE);
  //             //     this.changeGlobalCoins(this.GLOBALCOIN)
  //             //   }

  //           }

  //         },
  //         error => {
  //             console.log(error)
  //         });
  // }//End of listCurrentUserCoins

  //   setExchange(){
  //       localStorage.setItem('GLOBALEXCHANGETYPE',this.GLOBALEXCHANGETYPE)
  //   }


  //   get isAdmin() {
  //       return this.currentUser && this.currentUser.role === Role.Admin;
  //   }//End of is admin

  //   logout() {
  //       this.authenticationService.logout();
  //       this.router.navigate(['/login']);
  //   }//End of logout


  //   timezoneChange(zone){
  //       // var zoneee= this.timezones.find(element => element == zone);
  //       // console.log(this.timezonevalue, zone)
  //       localStorage.removeItem('TimeZone');
  //       localStorage.setItem('TimeZone', this.timezonevalue)
  //   }

  //   changeGlobalCoins(coin){


  //       this.GLOBALCOIN =this.route.snapshot.paramMap.get('coin');
  //       /* Reload Page... */
  //       // if (localStorage.getItem('currentCoin')) {
  //           // this.current_coin = localStorage.getItem('currentCoin');
  //       // } else {
  //           this.current_coin = coin;
  //       // }
  //       localStorage.removeItem('refresh_flag');
  //       var url = this.router.url;
  //       var n = url.includes("dailychart");
  //       if(n == true){
  //           this.router.navigate(['/dailychart',coin]);
  //           // window.open('https://candles.digiebot.com/dailychart/'+coin, '_blank');
  //       } else {
  //           this.router.navigate(['/mainchart2',coin]);
  //           // window.open('https://candles.digiebot.com/mainchart2/'+coin, '_blank');
  //       }

  //   }//End of changeGlobalCoins
  //   clickGlobalCoins(coin){


  //       // this.GLOBALCOIN =this.route.snapshot.paramMap.get('coin');
  //       /* Reload Page... */
  //       // if (localStorage.getItem('currentCoin')) {
  //           // this.current_coin = localStorage.getItem('currentCoin');
  //       // } else {
  //           // this.current_coin = coin;
  //       // }
  //       // localStorage.removeItem('refresh_flag');
  //       var url = this.router.url;
  //       var n = url.includes("dailychart");
  //       if(n == true){
  //           // this.router.navigate(['/dailychart',coin]);
  //           // window.open('https://candles.digiebot.com/dailychart/'+coin, '_blank');
  //           window.open('http://localhost:4200/dailychart/'+coin, '_blank');
  //       } else {
  //           // this.router.navigate(['/mainchart2',coin]);
  //           // window.open('https://candles.digiebot.com/mainchart2/'+coin, '_blank');
  //           window.open('http://localhost:4200/mainchart2/'+coin, '_blank');
  //       }

  //   }//End of changeGlobalCoins

  //   toggleLiveTest(event){
  //       let caption = 'Notification';
  //       this.tradingStatusLabel = (event.target.checked)?'live':'test';
  //       this.appModeClass  = (this.tradingStatusLabel == 'live')?'account-type-live':'account-type-test';
  //       let message = 'Application Mode Change To '+this.tradingStatusLabel.toUpperCase();
  //       this.showSuccess(message,caption);
  //       this.ShareddataService.changeAppMode(this.tradingStatusLabel);
  //   }//End of toggleLiveTest

  //   globalExchangeChange(exchange){
  //       let caption = 'Notification';
  //       let message = 'Exchange Change To '+exchange.toUpperCase();
  //       this.showSuccess(message,caption);
  //       this.ShareddataService.changeExchange(exchange);

  //       this.GLOBALEXCHANGETYPE = exchange;
  //       let id = (this.currentUser == null)?'':this.currentUser.id;
  //   }//End of globalExchangeChange


  //   showSuccess(message,caption){
  //       this.toastr.success(message,caption);
  //     }

  //     showError(message,caption){
  //         this.toastr.error(message,caption);
  //     }
  //     showInfo(message,caption){
  //         this.toastr.info(message,caption);
  //     }

  //     get_user_info(){
  //       this.userDetail = JSON.parse(localStorage.getItem('currentUser'));


  //     }

  //     trading_on_off(){



  //     }

}//End of controller
