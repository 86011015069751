import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { MainChartService } from '../../main-chart.service';

@Component({
  selector: 'app-truelevels',
  templateUrl: './truelevels.component.html',
  styleUrls: []
})
export class TruelevelsComponent implements OnInit {
  @Output() truelevelEvent = new EventEmitter ();
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private c: CanvasRenderingContext2D;
  cnd_array;
  general_array;
  total_cnd;
  first_cnd;
  starting_index;
  ending_index;
  increment;
  decrement;;

  coordinateArray = [];
  Height;
  Width;
  c_Height;
  c_Width;
  graph_height;
  graph_width;
  pline_difference;
  price_length;
  tline_difference;
  time_length;
  truelevel_type;
  y_from_top = 5;
  y_from_bottom = 5;
  x_from_left = 100;
  x_from_right = 20;
  truelevel_start_point;
  truelevel_end_point;
  truelevel_initial_point;
  truelevel_final_point
  // test_array = [];

  // truelevelarr = [];


  constructor(
    private service: MainChartService
  ) { }

  row_length = 20;
  counter = 0;
  chartTool: boolean = false;


  ngOnInit() {
    this.c = this.canvas.nativeElement.getContext('2d');
    this.service.chartData.subscribe(data => {
      this.cnd_array = data
    });

    this.service.truelevelcoordData.subscribe(data => {
      this.coordinateArray = data;
    });

    this.total_cnd = this.cnd_array.length;
    this.first_cnd = this.cnd_array[0];

    this.service.canvasData.subscribe(data => {
      this.general_array = data;
      if (this.counter > 0) {
        this.rander();
      }
    });
    if (this.counter == 0) {
      this.rander();
      this.sidebar_visibility_f()
    }
  }
  rander() {
    this.getWidth();
    this.getHeight();
    this.c_Height = this.Height;
    this.c_Width = this.Width;
    this.price_length = this.general_array[0].price_length;
    this.time_length = this.general_array[0].time_length;
    this.truelevel_type = this.general_array[0].truelevel_type;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;
    this.renderCharts();
  }


  chartTool_func() {
    this.chartTool = !this.chartTool;
  }
  getWidth() {
    // this.Width = 1200;
    this.Width = $(".canvas_truelevels").width();
  }
  getHeight() {
    // this.Width = 1200;
    this.Height = $(".canvas_truelevels").height();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.rander();
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations       //////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////


  renderCharts() {
      this.setCanvasHeightWidth();
      this.priceLineDifference(this.c_Height);
      this.timeLineDifference(this.c_Width);
      this.drawGraph();
      this.counter = 1;
  }

  priceLineDifference(can_h) {
    var total_graph_height = can_h - this.y_from_bottom - this.y_from_top;
    this.graph_height = total_graph_height;
    this.pline_difference = (total_graph_height / this.price_length);
  }

  timeLineDifference(can_w) {
    var total_graph_width = can_w - this.x_from_left - this.x_from_right;
    this.graph_width = total_graph_width;
    this.tline_difference = total_graph_width / this.time_length;
  }
  setCanvasHeightWidth() {
    this.canvas.nativeElement.height = this.Height;
    this.canvas.nativeElement.width = this.Width;
  }


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////        Charts Initailizations End       //////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////









  ////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  ////////////      Graph      /////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////
  ////////////////////////////////////////////


  drawGraph() {
    this.drawPriceLine_Tag();
    this.drawBlocks();
  }
  drawPriceLine_Tag() {
    var block_h = 6;
    var blocks_y_space = 2;
    var y1 = this.y_from_top + block_h;
    var y2 = y1 + (block_h + blocks_y_space);
    var y3 = y2 + (block_h + blocks_y_space);
    var y4 = y3 + (block_h + blocks_y_space);
    var y5 = y4 + (block_h + blocks_y_space);
    var y6 = y5 + (block_h + blocks_y_space);
    var y7 = y6 + (block_h + blocks_y_space);
    var y8 = y7 + (block_h + blocks_y_space);
    var y9 = y8 + (block_h + blocks_y_space);
    var y10 = y9 + (block_h + blocks_y_space);
    var y11 = y10 + (block_h + blocks_y_space);
    var y12 = y11 + (block_h + blocks_y_space);
    var y13 = y12 + (block_h + blocks_y_space);
    var y14 = y13 + (block_h + blocks_y_space);
    var y15 = y14 + (block_h + blocks_y_space);
    var y16 = y15 + (block_h + blocks_y_space);
    var y17 = y16 + (block_h + blocks_y_space);
    var y18 = y17 + (block_h + blocks_y_space);
    var y19 = y18 + (block_h + blocks_y_space);
    var y20 = y19 + (block_h + blocks_y_space);
    var x = this.x_from_left;
    var point_x_perc = this.x_from_left / 100;
    var point_x = point_x_perc * 75;
    var tag1 = 'Level 1';
    var tag2 = 'L 2';
    var tag3 = 'L 3';
    var tag4 = 'L 4';
    var tag5 = 'L 5';
    var tag6 = 'L 6';
    var tag7 = 'L 7';
    var tag8 = 'L 8';
    var tag9 = 'L 9';
    var tag10 = 'L 10';
    var tag11 = 'L 11';
    var tag12 = 'L 12';
    var tag13 = 'L 13';
    var tag14 = 'L 14';
    var tag15 = 'L 15';
    var tag16 = 'L 16';
    var tag17 = 'L 17';
    var tag18 = 'L 18';
    var tag19 = 'L 19';
    var tag20 = 'L 20';
    this.c.font = "normal 9px Arial";
    this.c.fillStyle = 'white';
    this.c.fillText(tag1, x - (point_x), y1);
    this.c.fillText(tag2, x - (point_x), y2);
    this.c.fillText(tag3, x - (point_x), y3);
    this.c.fillText(tag4, x - (point_x), y4);
    this.c.fillText(tag5, x - (point_x), y5);
    this.c.fillText(tag6, x - (point_x), y6);
    this.c.fillText(tag7, x - (point_x), y7);
    this.c.fillText(tag8, x - (point_x), y8);
    this.c.fillText(tag9, x - (point_x), y9);
    this.c.fillText(tag10, x - (point_x), y10);
    this.c.fillText(tag11, x - (point_x), y11);
    this.c.fillText(tag12, x - (point_x), y12);
    this.c.fillText(tag13, x - (point_x), y13);
    this.c.fillText(tag14, x - (point_x), y14);
    this.c.fillText(tag15, x - (point_x), y15);
    this.c.fillText(tag16, x - (point_x), y16);
    this.c.fillText(tag17, x - (point_x), y17);
    this.c.fillText(tag18, x - (point_x), y18);
    this.c.fillText(tag19, x - (point_x), y19);
    this.c.fillText(tag20, x - (point_x), y20);
  }
  drawBlocks() {
    var _w = this.tline_difference;
    var block_width_perc = this.tline_difference / 100;
    var block_l_off = 5 * block_width_perc;
    var block_r_off = 5 * block_width_perc;
    var block_w = _w - (block_l_off + block_r_off);
    var block_h = 6;
    var blocks_y_space = 2;
    var x = (this.x_from_left + block_l_off);
    var y = this.y_from_top;

    if ((this.time_length > (this.ending_index - this.starting_index)) && (this.time_length > this.cnd_array.length)) {
      this.ending_index = this.cnd_array.length;
    }
    if (this.ending_index > this.cnd_array.length) {
      this.ending_index = this.cnd_array.length;
    }
    this.coordinateArray = [];

    var trueLevelType = this.truelevel_type;;

    for (var column = this.starting_index; column < this.ending_index ; column++) {
      var test_array =  new Object();
      for (var row = 1; row <= this.row_length; row++) {
        var truelevelarr = this.cnd_array[column].rules_array;
        if (typeof (truelevelarr) !== 'undefined') {
          if(typeof (truelevelarr['level_' + row]) !== 'undefined'){
            var color = truelevelarr['level_' + row].color;
            if (trueLevelType == 'live') {
              if (color == "light-blue" || color == 'blue-light' || color == 'light-red' || color == 'red-light') {
                color='white';
              }
            } else if (trueLevelType == 'test') {
              if (color == "blue" || color == 'plum' || color == 'red') {
                color='white';
              }
            }
            if (color == "blue") {
              this.c.fillStyle = "#0000ff";
            } else if (color == "light-blue" || color == 'blue-light') {
              this.c.fillStyle = "#03a9f4";
            } else if (color == 'light-red' || color == 'red-light') {
              this.c.fillStyle = "#fc9299";
            } else if (color == 'red') {
              this.c.fillStyle = "#ff0000";
            } else if (color == 'plum') {
              this.c.fillStyle = "#a8189c";
            } else if (color == "white") {
              this.c.fillStyle = "#4b4848";
            }

            this.truelevel_start_point = x;
            this.truelevel_end_point = x + block_w;
            this.truelevel_final_point = y;
            this.truelevel_initial_point = y + ((this.row_length * (block_h + blocks_y_space)) - 2);
            if(row == 1){
              let truelevels_array_object = new Object();
              truelevels_array_object['x1'] = this.truelevel_start_point;
              truelevels_array_object['x2'] = this.truelevel_end_point;
              truelevels_array_object['y1'] = this.truelevel_initial_point;
              truelevels_array_object['y2'] = this.truelevel_final_point;



              test_array = (truelevels_array_object);
            }



            this.c.fillRect(x, y, block_w, block_h);
            y += (block_h + blocks_y_space);
          }

        }
      }
      let coordinateArray_object = new Object();
      coordinateArray_object[column]= test_array;
      this.coordinateArray.push(test_array);

      y = this.y_from_top;
      x += this.tline_difference;
    }
    this.changeValue()
  }

  changeValue(){
    var ArrayTruelevel = this.coordinateArray;
    this.service.new_truelevelData(ArrayTruelevel);
  }
}
