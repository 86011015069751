import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { IconsModule } from './icons/icons.module';
import { WINDOW_PROVIDERS } from "./window.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ChartsModule } from 'ng2-charts';

// used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MainchartComponent } from './mainchart/mainchart.component';
import { CandlesticksComponent } from './mainchart/candlesticks/candlesticks.component';
import { TruelevelsComponent } from './mainchart/truelevels/truelevels.component';
import { BlackwallpercentileComponent } from './mainchart/blackwallpercentile/blackwallpercentile.component';
import { SevenlevelpercentileComponent } from './mainchart/sevenlevelpercentile/sevenlevelpercentile.component';
import { BigcontractspercentileComponent } from './mainchart/bigcontractspercentile/bigcontractspercentile.component';
import { CandlepercentileComponent } from './mainchart/candlepercentile/candlepercentile.component';
import { SevenlevelComponent } from './mainchart/sevenlevel/sevenlevel.component';
import { BottomchartComponent } from './mainchart/bottomchart/bottomchart.component';
import { TwoWayBarComponent } from './mainchart/two-way-bar/two-way-bar.component';
import { BlackWallAvergComponent } from './mainchart/black-wall-averg/black-wall-averg.component';
import { BigContractsAvergComponent } from './mainchart/big-contracts-averg/big-contracts-averg.component';
import { UpperWickPercentileComponent } from './mainchart/upper-wick-percentile/upper-wick-percentile.component';
import { LowerWickPercentileComponent } from './mainchart/lower-wick-percentile/lower-wick-percentile.component';
import { BodyPercentileComponent } from './mainchart/body-percentile/body-percentile.component';
import { DemandSupplyComponent } from './mainchart/demand-supply/demand-supply.component';
import { RejectionComponent } from './mainchart/rejection/rejection.component';
import { MoveComponent } from './mainchart/move/move.component';
import { TopWickSignalComponent } from './mainchart/top-wick-signal/top-wick-signal.component';
import { IncreasingVolumeComponent } from './mainchart/increasing-volume/increasing-volume.component';
import { SevenLevelMinMaxComponent } from './mainchart/seven-level-min-max/seven-level-min-max.component';
import { BlackWallMinMaxComponent } from './mainchart/black-wall-min-max/black-wall-min-max.component';
import { T3COTMinMaxComponent } from './mainchart/t3-cotmin-max/t3-cotmin-max.component';
import { T2COTMinMaxComponent } from './mainchart/t2-cotmin-max/t2-cotmin-max.component';
import { T4COTMinMaxComponent } from './mainchart/t4-cotmin-max/t4-cotmin-max.component';
import { TelegramSentimentComponent } from './mainchart/telegram-sentiment/telegram-sentiment.component';
import { TwitterSentimentComponent } from './mainchart/twitter-sentiment/twitter-sentiment.component';
import { NewsSentimentComponent } from './mainchart/news-sentiment/news-sentiment.component';
import { BoxesComponent } from './mainchart/boxes/boxes.component';
import { BlackDotUpPressureComponent } from './mainchart/black-dot-up-pressure/black-dot-up-pressure.component';
import { UpperWickShortTermPercentileComponent } from './mainchart/upper-wick-short-term-percentile/upper-wick-short-term-percentile.component';
import { LowerWickShortTermPercentileComponent } from './mainchart/lower-wick-short-term-percentile/lower-wick-short-term-percentile.component';
import { CandleShortTermPercentileComponent } from './mainchart/candle-short-term-percentile/candle-short-term-percentile.component';
import { SevenLevelMinVeryShortTermPercentileComponent } from './mainchart/seven-level-min-very-short-term-percentile/seven-level-min-very-short-term-percentile.component';
import { CandleVSTPercentileComponent } from './mainchart/candle-vstpercentile/candle-vstpercentile.component';
import { UpperWickVSTPercentileComponent } from './mainchart/upper-wick-vstpercentile/upper-wick-vstpercentile.component';
import { LowerWickVSTPercentileComponent } from './mainchart/lower-wick-vstpercentile/lower-wick-vstpercentile.component';
import { SevenLevelAvgSTComponent } from './mainchart/seven-level-avg-st/seven-level-avg-st.component';
import { SevenLevelAvgVSTComponent } from './mainchart/seven-level-avg-vst/seven-level-avg-vst.component';
import { BigSellersContracts1PercSTComponent } from './mainchart/big-sellers-contracts1-perc-st/big-sellers-contracts1-perc-st.component';
import { BigBuyersContracts1PercSTComponent } from './mainchart/big-buyers-contracts1-perc-st/big-buyers-contracts1-perc-st.component';
import { BigSellersContracts1PercComponent } from './mainchart/big-sellers-contracts1-perc/big-sellers-contracts1-perc.component';
import { BigBuyersContracts1PercComponent } from './mainchart/big-buyers-contracts1-perc/big-buyers-contracts1-perc.component';
import { TelegramPercSTComponent } from './mainchart/telegram-perc-st/telegram-perc-st.component';
import { NewsPercSTComponent } from './mainchart/news-perc-st/news-perc-st.component';
import { TwitterPercSTComponent } from './mainchart/twitter-perc-st/twitter-perc-st.component';
import { TwitterPercITComponent } from './mainchart/twitter-perc-it/twitter-perc-it.component';
import { TelegramPercITComponent } from './mainchart/telegram-perc-it/telegram-perc-it.component';
import { NewsPercITComponent } from './mainchart/news-perc-it/news-perc-it.component';
import { NewsPercVSTComponent } from './mainchart/news-perc-vst/news-perc-vst.component';
import { TelegramPercVSTComponent } from './mainchart/telegram-perc-vst/telegram-perc-vst.component';
import { TwitterPercVSTComponent } from './mainchart/twitter-perc-vst/twitter-perc-vst.component';
import { BlackDotPressureU10Component } from './mainchart/black-dot-pressure-u10/black-dot-pressure-u10.component';
import { AskBidDeltaComponent } from './mainchart/ask-bid-delta/ask-bid-delta.component';
import { BidDeltaComponent } from './mainchart/bid-delta/bid-delta.component';
import { T2BCP15AskBidVolComponent } from './mainchart/t2-bcp15-ask-bid-vol/t2-bcp15-ask-bid-vol.component';
import { AskBidVolumeComponent } from './mainchart/ask-bid-volume/ask-bid-volume.component';
import { VolumeComparisonComponent } from './mainchart/volume-comparison/volume-comparison.component';
import { CoinMetaCountComponent } from './mainchart/coin-meta-count/coin-meta-count.component';
import { AskBidDeltaBComponent } from './mainchart/ask-bid-delta-b/ask-bid-delta-b.component';
import { AskBidVolumeBComponent } from './mainchart/ask-bid-volume-b/ask-bid-volume-b.component';
import { AskBidDivisionBComponent } from './mainchart/ask-bid-division-b/ask-bid-division-b.component';
import { MouseWheelDirective } from './mainchart/mouse-wheel.directive';
import { BigBuyersSellersContractsVolComponent } from './mainchart/big-buyers-sellers-contracts-vol/big-buyers-sellers-contracts-vol.component';
import { BigBuyersSellersContractsCountComponent } from './mainchart/big-buyers-sellers-contracts-count/big-buyers-sellers-contracts-count.component';
import { DailyChartComponent } from './daily-chart/daily-chart.component';
import { BigBuyerSellerDeltaComponent } from './mainchart/big-buyer-seller-delta/big-buyer-seller-delta.component';
import { BigBuyerSellerDiffComponent } from './mainchart/big-buyer-seller-diff/big-buyer-seller-diff.component';
import { ScripttimeComponent } from './mainchart/scripttime/scripttime.component';
import { BigBuyerSellerDiffPercentileComponent } from './mainchart/big-buyer-seller-diff-percentile/big-buyer-seller-diff-percentile.component';
import { DP1Component } from './mainchart/dp1/dp1.component';
import { DP2Component } from './mainchart/dp2/dp2.component';
import { DP3Component } from './mainchart/dp3/dp3.component';
import { NextCloseComponent } from './mainchart/next-close/next-close.component';
import { SevenLevelAvgDiffPercComponent } from './mainchart/seven-level-avg-diff-perc/seven-level-avg-diff-perc.component';
import { AskBidCountComponent } from './mainchart/ask-bid-count/ask-bid-count.component';
import { BUpperWickComponent } from './mainchart/b-upper-wick/b-upper-wick.component';
import { BLowerWickComponent } from './mainchart/blower-wick/blower-wick.component';
import { BodyMoveComponent } from './mainchart/body-move/body-move.component';
import { CandelTrendsComponent } from './mainchart/candel-trends/candel-trends.component';
import { FourteenLevelAvgComponent } from './mainchart/fourteen-level-avg/fourteen-level-avg.component';
import { FourteenLevelPercentileComponent } from './mainchart/fourteen-level-percentile/fourteen-level-percentile.component';
import { FourteenLevelAvgDiffPercComponent } from './mainchart/fourteen-level-avg-diff-perc/fourteen-level-avg-diff-perc.component';
import { FourteenLevelMinMaxComponent } from './mainchart/fourteen-level-min-max/fourteen-level-min-max.component';
import { LowerWickMoveComponent } from './mainchart/lower-wick-move/lower-wick-move.component';
import { UpperWickMoveComponent } from './mainchart/upper-wick-move/upper-wick-move.component';
import { ActiveDownBarrierComponent } from './mainchart/active-down-barrier/active-down-barrier.component';
import { ActiveUpBarrierComponent } from './mainchart/active-up-barrier/active-up-barrier.component';
import { SevenLevelMaxPercComponent } from './mainchart/seven-level-max-perc/seven-level-max-perc.component';
import { SevenLevelMaxPercSTComponent } from './mainchart/seven-level-max-perc-st/seven-level-max-perc-st.component';
import { SevenLevelMinPercSTComponent } from './mainchart/seven-level-min-perc-st/seven-level-min-perc-st.component';
import { SevenLevelMinPercComponent } from './mainchart/seven-level-min-perc/seven-level-min-perc.component';
import { DpUpTrendDirectionComponent } from './mainchart/dp-up-trend-direction/dp-up-trend-direction.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RangesComparisonComponent } from './mainchart/ranges-comparison/ranges-comparison.component';
import { AddUserComponent } from './add-user/add-user.component';
import { Orderbook1PercDiffAvgComponent } from './mainchart/orderbook1-perc-diff-avg/orderbook1-perc-diff-avg.component';
import { Orderbook2PercDiffAvgComponent } from './mainchart/orderbook2-perc-diff-avg/orderbook2-perc-diff-avg.component';
import { Orderbook3PercDiffAvgComponent } from './mainchart/orderbook3-perc-diff-avg/orderbook3-perc-diff-avg.component';
import { Orderbook1PercDiffAvgPercComponent } from './mainchart/orderbook1-perc-diff-avg-perc/orderbook1-perc-diff-avg-perc.component';
import { Orderbook2PercDiffAvgPercComponent } from './mainchart/orderbook2-perc-diff-avg-perc/orderbook2-perc-diff-avg-perc.component';
import { Orderbook3PercDiffAvgPercComponent } from './mainchart/orderbook3-perc-diff-avg-perc/orderbook3-perc-diff-avg-perc.component';
import { Orderbook1PercDiffMaxPercComponent } from './mainchart/orderbook1-perc-diff-max-perc/orderbook1-perc-diff-max-perc.component';
import { Orderbook2PercDiffMaxPercComponent } from './mainchart/orderbook2-perc-diff-max-perc/orderbook2-perc-diff-max-perc.component';
import { Orderbook3PercDiffMaxPercComponent } from './mainchart/orderbook3-perc-diff-max-perc/orderbook3-perc-diff-max-perc.component';
import { Orderbook3PercDiffMinPercComponent } from './mainchart/orderbook3-perc-diff-min-perc/orderbook3-perc-diff-min-perc.component';
import { Orderbook2PercDiffMinPercComponent } from './mainchart/orderbook2-perc-diff-min-perc/orderbook2-perc-diff-min-perc.component';
import { Orderbook1PercDiffMinPercComponent } from './mainchart/orderbook1-perc-diff-min-perc/orderbook1-perc-diff-min-perc.component';
import { Orderbook1MinMaxComponent } from './mainchart/orderbook1-min-max/orderbook1-min-max.component';
import { Orderbook2MinMaxComponent } from './mainchart/orderbook2-min-max/orderbook2-min-max.component';
import { Orderbook3MinMaxComponent } from './mainchart/orderbook3-min-max/orderbook3-min-max.component';
import { ResistanceComponent } from './mainchart/resistance/resistance.component';
import { StrategyComponent } from './mainchart/strategy/strategy.component';
import { Dp1BTCComponent } from './mainchart/dp1-btc/dp1-btc.component';
import { Dp2BTCComponent } from './mainchart/dp2-btc/dp2-btc.component';
import { Dp3BTCComponent } from './mainchart/dp3-btc/dp3-btc.component';
// import { DeviceDetectorModule } from 'ngx-device-detector';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// const config: SocketIoConfig = { url: 'http://localhost:3000', options: { path : '/' } };

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MainchartComponent,
    CandlesticksComponent,
    TruelevelsComponent,
    BlackwallpercentileComponent,
    SevenlevelpercentileComponent,
    BigcontractspercentileComponent,
    CandlepercentileComponent,
    SevenlevelComponent,
    BottomchartComponent,
    TwoWayBarComponent,
    BlackWallAvergComponent,
    BigContractsAvergComponent,
    UpperWickPercentileComponent,
    LowerWickPercentileComponent,
    BodyPercentileComponent,
    DemandSupplyComponent,
    RejectionComponent,
    MoveComponent,
    TopWickSignalComponent,
    IncreasingVolumeComponent,
    SevenLevelMinMaxComponent,
    BlackWallMinMaxComponent,
    T3COTMinMaxComponent,
    T2COTMinMaxComponent,
    T4COTMinMaxComponent,
    TelegramSentimentComponent,
    TwitterSentimentComponent,
    NewsSentimentComponent,
    BoxesComponent,
    BlackDotUpPressureComponent,
    UpperWickShortTermPercentileComponent,
    LowerWickShortTermPercentileComponent,
    CandleShortTermPercentileComponent,
    SevenLevelMinVeryShortTermPercentileComponent,
    CandleVSTPercentileComponent,
    UpperWickVSTPercentileComponent,
    LowerWickVSTPercentileComponent,
    SevenLevelAvgSTComponent,
    SevenLevelAvgVSTComponent,
    BigSellersContracts1PercSTComponent,
    BigBuyersContracts1PercSTComponent,
    BigSellersContracts1PercComponent,
    BigBuyersContracts1PercComponent,
    TelegramPercSTComponent,
    NewsPercSTComponent,
    TwitterPercSTComponent,
    TwitterPercITComponent,
    TelegramPercITComponent,
    NewsPercITComponent,
    NewsPercVSTComponent,
    TelegramPercVSTComponent,
    TwitterPercVSTComponent,
    BlackDotPressureU10Component,
    AskBidDeltaComponent,
    BidDeltaComponent,
    T2BCP15AskBidVolComponent,
    AskBidVolumeComponent,
    VolumeComparisonComponent,
    CoinMetaCountComponent,
    AskBidDeltaBComponent,
    AskBidVolumeBComponent,
    AskBidDivisionBComponent,
    MouseWheelDirective,
    BigBuyersSellersContractsVolComponent,
    BigBuyersSellersContractsCountComponent,
    DailyChartComponent,
    BigBuyerSellerDeltaComponent,
    BigBuyerSellerDiffComponent,
    ScripttimeComponent,
    BigBuyerSellerDiffPercentileComponent,
    NextCloseComponent,
    DP1Component,
    DP2Component,
    DP3Component,
    SevenLevelAvgDiffPercComponent,
    AskBidCountComponent,
    BUpperWickComponent,
    BLowerWickComponent,
    BodyMoveComponent,
    CandelTrendsComponent,
    FourteenLevelAvgComponent,
    FourteenLevelPercentileComponent,
    FourteenLevelAvgDiffPercComponent,
    FourteenLevelMinMaxComponent,
    LowerWickMoveComponent,
    UpperWickMoveComponent,
    ActiveDownBarrierComponent,
    ActiveUpBarrierComponent,
    SevenLevelMaxPercComponent,
    SevenLevelMaxPercSTComponent,
    SevenLevelMinPercSTComponent,
    SevenLevelMinPercComponent,
    DpUpTrendDirectionComponent,
    RangesComparisonComponent,
    AddUserComponent,
    Orderbook1PercDiffAvgComponent,
    Orderbook2PercDiffAvgComponent,
    Orderbook3PercDiffAvgComponent,
    Orderbook1PercDiffAvgPercComponent,
    Orderbook2PercDiffAvgPercComponent,
    Orderbook3PercDiffAvgPercComponent,
    Orderbook1PercDiffMaxPercComponent,
    Orderbook2PercDiffMaxPercComponent,
    Orderbook3PercDiffMaxPercComponent,
    Orderbook3PercDiffMinPercComponent,
    Orderbook2PercDiffMinPercComponent,
    Orderbook1PercDiffMinPercComponent,
    Orderbook1MinMaxComponent,
    Orderbook2MinMaxComponent,
    Orderbook3MinMaxComponent,
    ResistanceComponent,
    StrategyComponent,
    Dp1BTCComponent,
    Dp2BTCComponent,
    Dp3BTCComponent,
  ],
  imports: [

    BrowserModule,
    IconsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    // DeviceDetectorModule.forRoot(),
    DragulaModule.forRoot(),
    ToastrModule.forRoot({
      timeOut:4500,
      positionClass: 'toast-top-right'
    }),
    NgxPermissionsModule.forRoot(),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      titleFontSize: "10",
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      showSubtitle: false,
      showInnerStroke: false,
    }),
    ChartsModule,

    // SocketIoModule.forRoot(config)

  ],





  providers: [
    DatePipe,
    DeviceDetectorService,
      WINDOW_PROVIDERS,
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
     AppComponent,
    // provider used to create fake backend
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
  constructor(router: Router) {}
}
