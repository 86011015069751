import { Component, OnInit,OnDestroy,ElementRef,ViewEncapsulation } from '@angular/core';
import { ApicallsService } from '../_services/apicalls.service';
import { first } from 'rxjs/operators';
import { ShareddataService } from '../_services/shareddata.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';



// Dont Remove these Below Mentioned Modules Otherwise UnExpected Results will Happen
import { NgModule } from '@angular/core';
import { Observable, interval } from 'rxjs';
import * as $ from 'jquery';

import{ ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';
// Dont Remove these Above Mentioned Modules Otherwise UnExpected Results will Happen

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit ,OnDestroy{






  subs = new Subscription();
  current_coin: string;
  htmlContent = [];
  userCoinsArr = [];
  showIndicator: boolean = true;
  showChart: boolean = true;
  addChartButton: boolean = false;
  addIndicatorButton: boolean = false;


  currentUser :any = JSON.parse(localStorage.getItem('currentUser'));
   askPricesArr = [];
   bidPricesArr = [];
   marketHistoryArr = [];
   currncy:string = '';
   currentMarketPrice : any = '';
   interval: any;
   GLOBALCOIN : string = '';
   GLOBALEXCHANGETYPE  : string = localStorage.getItem('GLOBALEXCHANGETYPE');
   timer : any

  constructor(private route: ActivatedRoute,private dragulaService: DragulaService, private ApicallsService: ApicallsService,private ShareddataService :ShareddataService,private elementRef: ElementRef) {
    ShareddataService.globalValueChanged.subscribe(receiveData => this.globalValueChanged(receiveData));

  } //Constructor End



  ngOnInit() {
    let id = (this.currentUser == null)?'':this.currentUser.id;
    this.ShareddataService.currentCoin.subscribe(coin => this.GLOBALCOIN = coin);





  }//End of ngOnInit

  ngOnDestroy(): void {
    this.elementRef.nativeElement.remove();
    clearTimeout(this.timer);
  }




  private globalValueChanged(receiveData): void {
    if(receiveData['type'] == 'coin'){
        this.GLOBALCOIN = receiveData['coin'];
    }else if(receiveData['type'] == 'exchange'){
        this.GLOBALEXCHANGETYPE = receiveData['exchange'];
    }
  }//End of globalValueChanged







}//End of HomeComponent
