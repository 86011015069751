<div class="main" *ngIf="currentUser">
	<div class="sidebar">
      	<div class="sidebar-overlay"></div>
        <div class="sidebar-coverlay">
            <div class="sidebar-logo">
                <a href="index.html"><img src="../assets/images/logo.png"></a>
            </div>
            <div class="sidebar-menu">
                <ul class="sidebar-menu-ul">
                    <li class="menu-item ">
                        <a [routerLink]="['/mainchart2', 'XRPBTC']" routerLinkActive="active" ><span class="menu-icon"><i-feather name="home"></i-feather></span>Hourly</a>
                    </li>
                    <li class="menu-item" *ngxPermissionsOnly="['Waleed', 'Huzaifa','abbas', 'Doug', 'Hassan', 'Tehmina', 'Wasiq']">
                      <a [routerLink]="['/dailychart', 'XRPBTC']" routerLinkActive="active" ><span class="menu-icon"><i-feather name="aperture"></i-feather></span>Daily</a>
                      <!-- <a href="https://candles.digiebot.com/dailychart/XRPBTC" routerLinkActive="active" ><span class="menu-icon"><i-feather name="aperture"></i-feather></span>Daily</a> -->
                    </li>
                    <li class="menu-item" *ngxPermissionsOnly="['Waleed', 'Huzaifa']">
                      <a [routerLink]="['/add-user']" routerLinkActive="active" ><span class="menu-icon"><i-feather name="aperture"></i-feather></span>Add User</a>
                      <!-- <a href="https://candles.digiebot.com/dailychart/XRPBTC" routerLinkActive="active" ><span class="menu-icon"><i-feather name="aperture"></i-feather></span>Daily</a> -->
                    </li>
                    <li class="menu-item has-child">
                        <a href="javascript:void(0);"><span class="menu-icon"><i-feather name="cpu"></i-feather></span>Coins <span class="menu-child-icon"><i-feather name="chevron-down"></i-feather></span></a>
                        <ul class="menu-child">
                            <li class="menu-item-child" *ngFor="let coin of userCoinsArr; let i = index">
                                <a routerLinkActive="active" (click)="clickGlobalCoins(coin.symbol)">{{coin.symbol}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="content-area">
    	<div class="top-header"  [ngClass]="appModeClass">
        	<div class="sidebar-closer">
            	<a href="javascript:void(0);"><i-feather name="menu"></i-feather></a>
            </div>
            <div class="top-head-left-side">
                <div class="dropdown">
                    <select class="form-control btn btn-default dropdown-toggle" [(ngModel)]="current_coin" (change)="changeGlobalCoins($event.target.value)" name="GLOBALCOIN">
                        <option *ngFor="let coin of userCoinsArr; let i = index" [value]="userCoinsArr[i].symbol" [routerLink]="['/mainchart2', userCoinsArr[i].symbol]" [selected]="(userCoinsArr[i].symbol == current_coin ? userCoinsArr[i].symbol : 0)">
                            {{userCoinsArr[i].symbol}}
                        </option>
                    </select>

                </div>
            </div>

            <div class="top-head-left-side mr-2">
                <div class="dropdown">
                    <select class="form-control btn btn-default dropdown-toggle ml-3" [(ngModel)]="GLOBALEXCHANGETYPE" name="GLOBALEXCHANGETYPE" (change)="globalExchangeChange($event.target.value)">
                        <option value="binance">Binance</option>
                        <option value="kraken">Kraken</option>
                        <option value="bam">BAM</option>
                    </select>
                </div>
            </div>
            <div class="top-head-left-side" *ngIf="false">
                <div class="dropdown">
                    <select class="form-control btn btn-default dropdown-toggle ml-3" [(ngModel)]="timezonevalue" name="TimeZone" (change)="timezoneChange($event.target.value)">
                        <option *ngFor="let z of timezones; let i = index" [value]="z['Zone']">{{z['Area']}}</option>
                    </select>
                </div>
            </div>


            <div class="top-head-left-side">
                <div class="top-head-exchange">
                    <div class="top-head-exchange-logo" *ngIf="GLOBALEXCHANGETYPE == 'kraken'">
                        <img src="../assets/images/coinbasepro.png">
                    </div>
                    <div class="top-head-exchange-logo" *ngIf="GLOBALEXCHANGETYPE == 'binance'">
                        <img src="../assets/images/binance.png">
                    </div>

                    <div class="top-head-exchange-logo" *ngIf="GLOBALEXCHANGETYPE == 'bam'">
                        <img src="../assets/images/bam.png">
                    </div>
                </div>

                 <!-- <div class="top-head-tradstatus">
                    <div class="tradstatus-box-on"> Auto Trading {{auto_trading}}</div>
                    <div class="tradstatus-box-on"> Custom Trading {{custom_trading}}</div>
                </div> -->

            </div>
            <div class="top-head-right-side ml-auto">

            	<div class="livechecbox">
                	<label>{{tradingStatusLabel | titlecase}}</label>
                    <div class="live-test-checked">
                        <input type="checkbox" (change)="toggleLiveTest($event)" [checked]="isTradingStatusLive">
                        <span></span>
                    </div>
                </div>
                <div class="top-notification" >
                    <a href="javascript:void(0);" class="have-notification"><i-feather name="bell"></i-feather></a>
                    <div class="top-noti-dropdown" *ngIf="false">
                    	<ul>
                        	<li><a href="#"><span class="ti-icon ti-success"><i-feather name="trending-up"></i-feather></span>Coin <strong>NCASHBTC</strong> bought on 11/05/2018 has been sold at a Profit of....</a></li>
                            <li><a href="#"><span class="ti-icon ti-danger"><i-feather name="trending-down"></i-feather></span>Coin <strong>NCASHBTC</strong> bought on 11/05/2018 has been sold at a Profit of....</a></li>
                            <li><a href="#"><span class="ti-icon ti-success"><i-feather name="trending-up"></i-feather></span>Coin <strong>NCASHBTC</strong> bought on 11/05/2018 has been sold at a Profit of....</a></li>
                            <li><a href="#"><span class="ti-icon ti-success"><i-feather name="trending-up"></i-feather></span>Coin <strong>NCASHBTC</strong> bought on 11/05/2018 has been sold at a Profit of....</a></li>
                            <li><a href="#"><span class="ti-icon ti-danger"><i-feather name="trending-down"></i-feather></span>Coin <strong>NCASHBTC</strong> bought on 11/05/2018 has been sold at a Profit of....</a></li>
                            <li><a href="#"><span class="ti-icon ti-success"><i-feather name="trending-up"></i-feather></span>Coin <strong>NCASHBTC</strong> bought on 11/05/2018 has been sold at a Profit of....</a></li>
                        </ul>
                    </div>
                </div>
                <div class="top-user">
                    <button class="btn-usert dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span *ngIf= "profile_image"  > <img  class="top-avatar" [src]="profile_image"><i-feather name="chevron-down"></i-feather> </span>
                        <!-- <span *ngIf= "!profile_image" class="top-avatar"><i class="fa fa-user-o" aria-hidden="true"></i></span> -->
                        <span *ngIf= "!profile_image" class="mt-3" style="color: #009688;">{{username}}<i-feather name="chevron-down"></i-feather></span>
                	</button>
                	<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" routerLink="/update-profile" routerLinkActive="active"><i-feather name="user"></i-feather>Update Profile</a>
                        <a *ngIf="false" class="dropdown-item" href="#"><i-feather name="pocket"></i-feather> My Wallet</a>
                        <a class="dropdown-item" href="#"><i-feather name="unlock"></i-feather> My Lock Screen</a>
                        <a class="dropdown-item" (click)="logout()"><i-feather name="log-out"></i-feather> Log Out</a>
                        <a class="dropdown-item" href="#"><i-feather name="settings"></i-feather> Settings</a>
                	</div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="container-iner">

            <!-- Inner Part -->
            <router-outlet></router-outlet>
            <!-- End of inner part-->

        </div>
    </div>
</div>




<div *ngIf="!currentUser">
    <router-outlet></router-outlet>
</div>
