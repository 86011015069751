import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { MainChartService } from '../../main-chart.service';
import { Router } from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions'
import { ApicallsService } from 'src/app/_services/apicalls.service';



@Component({
  selector: 'app-candlesticks',
  templateUrl: './candlesticks.component.html',
  styleUrls: ['./candlesticks.component.css']
})
export class CandlesticksComponent implements OnInit {
  @Output() coordinateEvent = new EventEmitter();

  @Input() currentCoin;
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private c: CanvasRenderingContext2D;
  setting: any = [];
  setting_arr = new Object();
  cnd_array;
  general_array;
  total_cnd;
  first_cnd;
  starting_index;
  change_index;
  ending_index;
  increment;
  decrement;
  Factor = 1;
  Base = 1000;




  maximiz:boolean = false;
  minimiz:boolean = true;
  coordinateArray = [];
  Height;
  Width;
  c_Height;
  c_Width;
  graph_height;
  graph_width;
  pline_difference;
  price_length;
  tline_difference;
  time_length;
  truelevel_type;
  y_from_top = 25;
  y_from_bottom = 25;
  x_from_left = 100;
  x_from_right = 20;
  max_value;
  min_value;
  cnd_w;
  cnd_h;
  startdate;
  enddate;
  candle_start_point;
  candle_end_point;
  candle_initial_point;
  candle_final_point;
  ask;
  bid;
  userCoinsArr;
  GLOBALEXCHANGETYPE = localStorage.getItem('GLOBALEXCHANGETYPE')
  constructor(
    private service: MainChartService,
    private apiservice: ApicallsService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
  ) {


    this.apiservice.coinData.subscribe(data => {
      if(typeof data['message'] !== 'undefined'){
        var i = 0;
        this.userCoinsArr = [];
        for (let key = 0; key< data['message'].length; key++) {
          if (data['message'][key].exchange_type == this.GLOBALEXCHANGETYPE) {
            this.userCoinsArr[i++] = data['message'][key];
          }
        }
      }
    });
  }
  counter = 0;
  chartTool: boolean = true;
  showBigCircle: boolean = false;
  showBuyCircle: boolean = false;
  showSellCircle: boolean = false;
  showBuyPriceCircle: boolean = false;
  showSellPriceCircle: boolean = false;
  showStopLossCircle: boolean = false;
  showSwingLines: boolean = true;
  showWallLines: boolean = false;
  showRangesLines: boolean = false;
  showT1Lines: boolean = false;
  showT2Lines: boolean = false;
  showT4Lines: boolean = false;
  showT2BCP15Lines: boolean = false;
  showWhitebackground: boolean = false;
  showTradingViewbackground: boolean = false;



  //....................................................................................................
  //.....................................................................................iiii...........
  //..................................OOOOOOOOO.....................IIII.................iiii...........
  //................................OOOOOOOOOOOOO...................IIII.................iiii....ttt....
  //...............................OOOOOOOOOOOOOOO..................IIII.........................ttt....
  //...............................OOOOOO...OOOOOO..................IIII........................tttt....
  //.nnnn.nnnnnn.....ggggggggggg..gOOOOO.....OOOOOO..nnnn.nnnnnn....IIII..Innnnnnnnnn....iiii..itttttt..
  //.nnnnnnnnnnnn...gggggggggggg..gOOOO.......OOOOO..nnnnnnnnnnnn...IIII..Innnnnnnnnnn...iiii..itttttt..
  //.nnnnnnnnnnnn...gggggggggggg..gOOOO........OOOO..nnnnnnnnnnnn...IIII..Innnnnnnnnnnn..iiii..itttttt..
  //.nnnnnn..nnnnn..ggggg..ggggg..gOOO.........OOOO..nnnnnn..nnnnn..IIII..Innnnn..nnnnn..iiii...tttt....
  //.nnnnn...nnnnn.ngggg....gggg..gOOO.........OOOO..nnnnn...nnnnn..IIII..Innnn...nnnnn..iiii...tttt....
  //.nnnnn...nnnnn.ngggg....gggg..gOOO.........OOOO..nnnnn...nnnnn..IIII..Innnn....nnnn..iiii...tttt....
  //.nnnn....nnnnn.ngggg....gggg..gOOOO........OOOO..nnnn....nnnnn..IIII..Innnn....nnnn..iiii...tttt....
  //.nnnn....nnnnn.ngggg....gggg..gOOOO.......OOOOO..nnnn....nnnnn..IIII..Innnn....nnnn..iiii...tttt....
  //.nnnn....nnnnn.ngggg....gggg..gOOOOO.....OOOOOO..nnnn....nnnnn..IIII..Innnn....nnnn..iiii...tttt....
  //.nnnn....nnnnn..ggggg..ggggg...OOOOOO...OOOOOO...nnnn....nnnnn..IIII..Innnn....nnnn..iiii...ttttt...
  //.nnnn....nnnnn..gggggggggggg...OOOOOOOOOOOOOOO...nnnn....nnnnn..IIII..Innnn....nnnn..iiii...tttttt..
  //.nnnn....nnnnn..gggggggggggg....OOOOOOOOOOOOO....nnnn....nnnnn..IIII..Innnn....nnnn..iiii...tttttt..
  //.nnnn....nnnnn...ggggggggggg......OOOOOOOOO......nnnn....nnnnn..IIII..Innnn....nnnn..iiii....ttttt..
  //........................gggg........................................................................
  //................gggg...ggggg........................................................................
  //................gggggggggggg........................................................................
  //................ggggggggggg.........................................................................
  //.................ggggggggg..........................................................................
  //....................................................................................................
  role;
  adminrole = 'admin';
  userrole = 'user';
  developerrole = 'developer';
  showtoadmin:boolean =false;
  showtouser:boolean = false;
  number;
  href;
  ttime;
  tprice;
  selectedDate;
  color = 'black';

  async ngOnInit() {





    var url = window.location.href;
    var location = decodeURI(url);
    var new_url = new URL(location);
    var date_param = new_url.searchParams.get("date");
    var tradetime_param = new_url.searchParams.get("tradetime");
    var tradeprice_param = new_url.searchParams.get("tradeprice");
    var ttime = (this.router.url).includes("tradetime");
    if(ttime == true){
      this.ttime = tradetime_param;
    }
    var tprice = (this.router.url).includes("tradeprice");
    if(tprice == true){
      this.tprice = tradeprice_param;
    }

    this.number = (this.router.url).includes("date");
    if (this.number == true) {
      this.selectedDate = date_param;
    }



    const perm = JSON.parse(localStorage.getItem('loggedUserName'));
    this.permissionsService.loadPermissions([perm]);


    if (!localStorage.getItem('loggedUserRole')){
      localStorage.removeItem('currentUserNew');
      window.location.reload();
    } else {
      this.role = JSON.parse(localStorage.getItem('loggedUserRole'));
      // console.log(this.role);
    }


    if(this.role == this.adminrole){
      this.showtoadmin = true;
      this.showtouser = false;
    } else if(this.role == this.userrole){
      this.showtoadmin = false;
      this.showtouser = true;
    } else if(this.role == this.developerrole){
      this.showtoadmin = true;
      this.showtouser = false;
    }

    var url = this.router.url
    this.c = this.canvas.nativeElement.getContext('2d');
    var n = url.includes("dailychart");
    if(n == true){
      // daily chart service executed
      await this.service.dailyChartData.subscribe(data => {
        this.cnd_array = data;
        if (this.counter > 0) {
          this.rander();
        }
      });
    } else{
      // main chart service executed
      await this.service.chartData.subscribe(data => {
        this.cnd_array = data;
        if (this.counter > 0) {
          this.rander();
        }
      });
    }

    this.service.candlestickcoordData.subscribe(data => {
      this.coordinateArray = data;
    });

    this.service.canvasData.subscribe(data => {
      this.general_array = data;
      if (this.counter > 0) {
        this.rander();
      }
    });
    if (this.counter == 0) {
      this.rander();
      this.sidebar_visibility_f();
    }
  }

  // sendArray() {
  //   this.coordinateEvent.emit(this.coordinateArray);
  //   console.log('from CandleStick')
  // }
  chartTool_func() {
    this.chartTool = !this.chartTool;
  }
  showCircle_func_buy() {
    this.showBuyCircle = !this.showBuyCircle;
    this.setcomponentstate();
    this.rander();
  }
  showCircle_func_sell() {
    this.showSellCircle = !this.showSellCircle;
    this.setcomponentstate();
    this.rander();
  }
  showCircle_func_big() {
    this.showBigCircle = !this.showBigCircle;
    this.setcomponentstate();
    this.rander();
  }
  showCircle_func_buy_level() {
    this.showBuyPriceCircle = !this.showBuyPriceCircle;
    this.setcomponentstate();
    this.rander();
  }
  showCircle_func_sell_level() {
    this.showSellPriceCircle = !this.showSellPriceCircle;
    this.setcomponentstate();
    this.rander();
  }
  showCircle_func_stop_loss() {
    this.showStopLossCircle = !this.showStopLossCircle;
    this.setcomponentstate();
    this.rander();

  }
  showSwingLines_func() {
    this.showSwingLines = !this.showSwingLines;
    this.setcomponentstate();
    this.rander();
  }
  showWallLines_func() {
    this.showWallLines = !this.showWallLines;
    this.setcomponentstate();
    this.rander();
  }
  showRangeLines_func() {
    this.showRangesLines = !this.showRangesLines;
    this.setcomponentstate();
    this.rander();
  }
  showT1Lines_func() {
    this.showT1Lines = !this.showT1Lines;
    this.setcomponentstate();
    this.rander();
  }
  showT2Lines_func() {
    this.showT2Lines = !this.showT2Lines;
    this.setcomponentstate();
    this.rander();
  }
  showT4Lines_func() {
    this.showT4Lines = !this.showT4Lines;
    this.setcomponentstate();
    this.rander();
  }
  showT2BCP15Lines_func() {
    this.showT2BCP15Lines = !this.showT2BCP15Lines;
    this.setcomponentstate();
    this.rander();
  }

  changeBackgroundcolor() {
    this.showTradingViewbackground = false;
    this.showWhitebackground = !this.showWhitebackground;
    if(this.showWhitebackground == true){
      this.color = 'white'
    } else {
      this.color = 'black'
    }
    this.setcomponentstate();
    this.rander();
  }
  changeBackgroundcolor1() {
    this.showWhitebackground = false;
    this.showTradingViewbackground = !this.showTradingViewbackground;
    if(this.showTradingViewbackground == true){
      this.color = 'trading'
    } else {
      this.color = 'black'
    }
    this.setcomponentstate();
    this.rander();
  }


  //............................................................................
  //..............................................ddddd.........................
  //..............................................ddddd.........................
  //..............................................ddddd.........................
  //..............................................ddddd.........................
  //..............................................ddddd.........................
  //.rrrrrrrr...aaaaaaaa....nnnn.nnnnnn.....ddddddddddd....eeeeeeee...errrrrrr..
  //.rrrrrrrr..aaaaaaaaaa...nnnnnnnnnnnn...dddddddddddd...eeeeeeeeee..errrrrrr..
  //.rrrrrrrr.raaaaaaaaaa...nnnnnnnnnnnn..ndddddddddddd..deeeeeeeeee..errrrrrr..
  //.rrrrrr...raaaa..aaaa...nnnnn..nnnnn..ndddd...ddddd..deee...eeeee.errrrr....
  //.rrrrr...........aaaa...nnnnn...nnnn..nddd....ddddd..deeeeeeeeeee.errrr.....
  //.rrrrr.....aaaaaaaaaa...nnnn....nnnn..nddd....ddddd.ddeeeeeeeeeee.errrr.....
  //.rrrrr....raaaaaaaaaa...nnnn....nnnn..nddd....ddddd.ddeeeeeeeeeee.errrr.....
  //.rrrr.....raaaa..aaaa...nnnn....nnnn..nddd....ddddd.ddeee.........errrr.....
  //.rrrr....rraaa...aaaa...nnnn....nnnn..nddd....ddddd..deee.........errrr.....
  //.rrrr....rraaa..aaaaaa..nnnn....nnnn..ndddd...ddddd..deeee..eeeee.errrr.....
  //.rrrr.....raaaaaaaaaaaa.nnnn....nnnn..ndddddddddddd..deeeeeeeeeee.errrr.....
  //.rrrr.....raaaaaaaaaaaa.nnnn....nnnn...dddddddddddd...eeeeeeeeee..errrr.....
  //.rrrr......aaaaaa.aaaaa.nnnn....nnnn....dddddd.dddd....eeeeeeee...errrr.....
  //............................................................................

  rander() {
    this.getHeight();
    this.getWidth();
    if (localStorage.getItem('setting_arr_candlestick_chart')) {
      this.getcomponentstate();
    } else {
      this.Factor = 1;
    }
    this.c_Height = this.Height;
    this.c_Width = this.Width;
    this.price_length = this.general_array[0].price_length;
    this.time_length = this.general_array[0].time_length;
    this.truelevel_type = this.general_array[0].truelevel_type;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;
    this.Findmax_min();
    this.renderCharts();

  }
  getHeight() {
    this.Height = $(".canvas_candle").height();
  }
  getWidth() {
    this.Width = $(".canvas_candle").width();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.rander();
    });
  }

  renderCharts() {
    this.setCanvasHeightWidth();
    this.priceLineDifference(this.c_Height);
    this.timeLineDifference(this.c_Width);
    this.drawGraph();
    this.counter = 1;
  }
  priceLineDifference(can_h) {
    var total_graph_height = can_h - this.y_from_bottom - this.y_from_top;
    this.graph_height = total_graph_height;
    this.pline_difference = (total_graph_height / this.price_length);
  }

  timeLineDifference(can_w) {
    var total_graph_width = can_w - this.x_from_left - this.x_from_right;
    this.graph_width = total_graph_width;
    this.tline_difference = total_graph_width / this.time_length;
  }
  setCanvasHeightWidth() {
    this.canvas.nativeElement.height = this.Height;
    this.canvas.nativeElement.width = this.Width;
  }

  //.......................................................
  //....GGGGGGGG................................hhh........
  //...GGGGGGGGGG...............................hhh........
  //..GGGGGGGGGGGG..............................hhh........
  //..GGGG....GGGG..rrrrrr..aaaaaa...pppppppp...hhhhhhhh...
  //.GGGG...........rrrrrr.aaaaaaaa..ppppppppp..hhhhhhhhh..
  //.GGGG...........rrrrrr.aaa.aaaa..pppp.ppppp.hhhhhhhhh..
  //.GGGG...GGGGGG..rrrr.....aaaaaa..ppp...pppp.hhhh..hhh..
  //.GGGG...GGGGGG..rrr....aaaaaaaa..ppp...pppp.hhh...hhh..
  //.GGGG......GGG..rrr...raaaaaaaa..ppp...pppp.hhh...hhh..
  //..GGGG.....GGG..rrr...raaa..aaa..pppp..pppp.hhh...hhh..
  //..GGGGGGGGGGGG..rrr...raaa.aaaa..pppp.pppp..hhh...hhh..
  //...GGGGGGGGGGG..rrr...raaaaaaaaaappppppppp..hhh...hhh..
  //....GGGGGGGGG...rrr....aaaaaaaaaapppppppp...hhh...hhh..
  //.................................ppp...................
  //.................................ppp...................
  //.................................ppp...................
  //.................................ppp...................
  //.......................................................
  changearrayValue() {
    this.setcomponentstate();
    this.rander();
  }
  drawGraph() {
    this.drawPrice_Top_Line(this.c_Width, this.c_Height);
    this.drawPriceInnerLines(this.c_Width, this.c_Height);
    this.drawTimeLineTag(this.c_Height);
    this.getCandleWidthHeightInPerc(this.c_Height, this.c_Width);
  }
  //All Bellow Functions are Call in Function drawGraph()

  //Price InnerLines And Price LineTag
  //Price InnerLines


  drawPriceInnerLines(can_w, can_h) {
    var y = can_h - this.y_from_bottom;
    var point_x_perc = this.x_from_left / 100;
    var point_x = point_x_perc * 75;
    var x = this.x_from_left;
    var min = +(this.min_value);
    var max = +(this.max_value);
    for (var i = 0; i <= this.price_length; i++) {

      var diff_value: any = (max - min);
      var value: number = ((diff_value / 100) * i);
      var tag = (value + min).toFixed(8);
      var priceLabel = tag;
      this.c.font = '5px';
      this.c.fillStyle = 'white';
      if(this.showWhitebackground == true){
        this.c.fillStyle = 'black';
      } else if (this.showTradingViewbackground == true){
        this.c.fillStyle = 'white'
      } else {
        this.c.fillStyle = 'white';
      }
      var divisor = 10;
      if (i % divisor === 0) {
        // this.c.beginPath();
        // this.c.moveTo(x, y);
        // this.c.lineTo(can_w - this.x_from_right, y);
        // this.c.lineCap = 'round';
        // this.c.lineWidth = 1;
        // this.c.strokeStyle = 'rgba(255,255,255,0.2)';
        // this.c.stroke();
        this.c.fillText(priceLabel, x - (point_x), y);
      }
      y -= this.pline_difference;
    }
  }

  drawPrice_Top_Line(can_w, can_h) {
    var y = can_h - this.y_from_bottom;
    var x = this.x_from_left;
    var t_graph_height = this.graph_height;// Total Graph Height
    var point_y_perc = t_graph_height / 100;
    var point_y = point_y_perc * 25;// calculation for Label Point Y
    y -= 4 * point_y;
    this.c.beginPath();
    this.c.moveTo(x, y);
    this.c.lineTo(can_w - this.x_from_right, y);
    this.c.lineCap = 'round';
    this.c.lineWidth = 1;
    this.c.strokeStyle = 'rgba(0,0,0,0.4)';
    this.c.stroke();

  }

  drawTimeLineTag(can_h) {
    var x = this.x_from_left + (this.tline_difference / 4);
    var x2 = this.x_from_left;
    var x1 = this.x_from_left;
    var point_y_perc = this.y_from_bottom / 100;
    var point_y = point_y_perc * 75;
    var y2 = + this.y_from_top;
    var y1 = can_h - this.y_from_bottom;
    var y = (can_h - this.y_from_bottom) + point_y;

    for (var j = 0; j < this.time_length; j++) {
      if(j<=168){
      this.c.beginPath();
      this.c.moveTo(x1, y1);
      this.c.lineTo(x2, y2);
      this.c.lineCap = 'round';
      this.c.lineWidth = 1;
      this.c.strokeStyle = 'rgba(0,0,0,0.4)'
      // this.c.strokeStyle = '#010101'
      this.c.stroke();
      }
      x1 += this.tline_difference;
      x2 += this.tline_difference;
      this.change_index = this.starting_index + 1;
      var url = this.router.url
      var n = url.includes("dailychart");
      var timeLabel;
      if(n == true){
         timeLabel = (this.change_index + j) + 'D';
      }
      else {
        timeLabel = (this.change_index + j) + 'H';
      }
      if(j<167){
        this.c.fillText(timeLabel, x, y);
      }
      x += this.tline_difference;
    }
  }



//................................................................................................................................
//.WWW...WWWWW...WWW...................kkk.......... TTTTTTTTTT................................ttt..thh..........................
//.WWWW..WWWWW..WWWW...................kkk.......... TTTTTTTTTT................................ttt..thh..........................
//.WWWW..WWWWW..WWWW.................r.kkk........... TTTTTTTTTT...............................ettt..thh.......................r..
//.WWWW..WWWWW..WWWW..ooooooo..rrrrrrr.kkk..kkkk........TTTT....ooooooo....ggggggggg..eeeeeee.eetttt.thhhhhhhh...eeeeeee..errrrr..
//..WWW..WWWWWW.WWW..ooooooooo.rrrrrrr.kkk.kkkk.........TTTT...Toooooooo..ogggggggg..geeeeeee.eetttt.thhhhhhhh..heeeeeee..errrrr..
//..WWW.WWWWWWW.WWW.ooooo.oooo..rrrrrr.kkkkkkk..........TTTT...Tooo.ooooo.oggg.gggg..geee.eeee.ettt..thhhhhhhh..heee.eeee.errrrr..
//..WWWWWWW.WWWWWWW.oooo...ooo..rrr....kkkkkk...........TTTT...Too...oooo.ogg...ggg..gee...eee.ettt..thhh..hhhh.hee...eee.errr....
//..WWWWWWW.WWWWWWW.oooo...oooo.rrr....kkkkkk...........TTTT..TToo...oooo.ogg...ggg.ggeeeeeeee.ettt..thhh..hhhhhheeeeeeee.errr....
//...WWWWWW.WWWWWW..oooo...oooo.rrr....kkkkkkk..........TTTT..TToo...oooo.ogg...ggg.ggeeeeeeee.ettt..thh...hhhhhheeeeeeee.err.....
//...WWWWWW.WWWWWW..oooo...ooo..rrr....kkkkkkk..........TTTT...Too...oooo.ogg...ggg..gee.......ettt..thh...hhhh.hee.......err.....
//...WWWWW...WWWWW..ooooo.oooo..rrr....kkk.kkkk.........TTTT...Tooo.ooooo.oggg.gggg..geee.eeee.ettt..thh...hhhh.heee.eeee.err.....
//...WWWWW...WWWWW...ooooooooo..rrr....kkk..kkkk........TTTT...Toooooooo..ogggggggg..geeeeeeee.etttttthh...hhhh.heeeeeeee.err.....
//....WWWW...WWWW.....ooooooo...rrr....kkk..kkkk........TTTT....ooooooo....gggggggg...eeeeeee...tttttthh...hhhh..eeeeeee..err.....
//..............................................................................ggg...............................................
//........................................................................oggg.gggg...............................................
//........................................................................ogggggggg...............................................
//.........................................................................ggggggg................................................
//................................................................................................................................


  getCandleWidthHeightInPerc(can_h, can_w) {

    this.coordinateArray = [];
    for (var c = this.starting_index; c < this.ending_index; c++) {
      var close_value = this.cnd_array[c].close;
      var open_value = this.cnd_array[c].open;
      var low_value = this.cnd_array[c].low;
      var high_value = this.cnd_array[c].high;
      var swing_status = this.cnd_array[c].global_swing_parent_status;
      var cnd_close = (((close_value - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var cnd_open = (((open_value - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var cnd_low = (((low_value - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var cnd_high = (((high_value - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var percentiles = this.cnd_array[c].percentile_arr;
      var top_wick_prices = this.cnd_array[c].top_wick_prices;
      var barrier_type = this.cnd_array[c].barrier_type;
      var downwall = this.cnd_array[c].down_big_price;
      var downwallprice = (((downwall - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var downwidth = parseFloat(this.cnd_array[c].down_big_wall_perc);
      var upwall = this.cnd_array[c].up_big_price;
      var upwallprice = (((upwall - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var upwidth = parseFloat(this.cnd_array[c].up_big_wall_perc);


      var range_max_perc = parseFloat(this.cnd_array[c].range_max_qty_perc);
      var ranges = this.cnd_array[c].ranges;

      // console.log(this.cnd_array[c].ranges.length)



      var t1Cot_per_min_price = this.cnd_array[c].t1Cot_per_min_price;
      var t1price_min = (((t1Cot_per_min_price - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var t1Cot_per_max_price = this.cnd_array[c].t1Cot_per_max_price;
      var t1price_max = (((t1Cot_per_max_price - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var t1perc_min = this.cnd_array[c].t1Cot_per_min_perc;
      var t1perc_max = this.cnd_array[c].t1Cot_per_max_perc;


      var t2Cot_per_min_price = this.cnd_array[c].t2Cot_per_min_price;
      var t2price_min = (((t2Cot_per_min_price - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var t2Cot_per_max_price = this.cnd_array[c].t2Cot_per_max_price;
      var t2price_max = (((t2Cot_per_max_price - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var t2perc_min = this.cnd_array[c].t2Cot_per_min_perc;
      var t2perc_max = this.cnd_array[c].t2Cot_per_max_perc;



      var t4Cot_per_min_price = this.cnd_array[c].t4Cot_per_min_price;
      var t4price_min = (((t4Cot_per_min_price - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var t4Cot_per_max_price = this.cnd_array[c].t4Cot_per_max_price;
      var t4price_max = (((t4Cot_per_max_price - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var t4perc_min = this.cnd_array[c].t4Cot_per_min_perc;
      var t4perc_max = this.cnd_array[c].t4Cot_per_max_perc;


      var t2bcp15_ask_bid_price = this.cnd_array[c].t2bcp15_ask_bid_price;
      var t2bcp15price = (((t2bcp15_ask_bid_price - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
      var t2bcp15perc_ask = this.cnd_array[c].t2bcp15_ask_vol_perc;
      var t2bcp15perc_bid = this.cnd_array[c].t2bcp15_bid_vol_perc;


      let s = this.starting_index;
      let e = this.ending_index;
      let starttime = this.cnd_array[s].openTime_human_readible;
      let endtime = this.cnd_array[e-1].openTime_human_readible;
      this.ask = this.cnd_array[c].ask_volume;
      this.bid = this.cnd_array[c].bid_volume;
      if (c == (e - 1)) {
        const candlestart = starttime
        var utcDatestart = new Date(candlestart);
        var st = Date.UTC(utcDatestart.getFullYear(), (utcDatestart.getMonth() + 1), utcDatestart.getDate(), utcDatestart.getHours(), 0, 0);
        this.startdate = utcDatestart.getFullYear() + '-' + (utcDatestart.getMonth() + 1) + '-' + utcDatestart.getDate();

        const candleend = endtime
        var utcDateend = new Date(candleend);
        var et = Date.UTC(utcDateend.getFullYear(), (utcDateend.getMonth() + 1), utcDateend.getDate(), utcDateend.getHours(), 0, 0);
        this.enddate = utcDateend.getFullYear() + '-' + (utcDateend.getMonth() + 1) + '-' + utcDateend.getDate();
      }
      this.drawCandle(can_h, can_w, cnd_close, cnd_open, cnd_low, cnd_high, c, swing_status, percentiles, top_wick_prices, barrier_type, downwallprice, upwallprice,downwidth, upwidth, t1price_min, t1price_max, t1perc_min , t1perc_max, t2price_min, t2price_max, t2perc_min, t2perc_max, t4price_min, t4price_max, t4perc_min, t4perc_max, t2bcp15price, t2bcp15perc_ask, t2bcp15perc_bid, range_max_perc, ranges);
    }
    this.changeValue();
  }
  drawCandle(can_h, can_w, cnd_close, cnd_open, cnd_low, cnd_high, hour_Increment, swing_status, percentiles, top_wick_prices, barrier_type, downwallprice, upwallprice,downwidth, upwidth, t1price_min, t1price_max, t1perc_min , t1perc_max, t2price_min, t2price_max, t2perc_min, t2perc_max, t4price_min, t4price_max, t4perc_min, t4perc_max, t2bcp15price, t2bcp15perc_ask,t2bcp15perc_bid, range_max_perc, ranges) {
    var _h = 0;
    var _w = 0;
    var hour;
    var high = cnd_high;
    var low = cnd_low;
    var open = cnd_open;
    var close = cnd_close;
    // const Datefromlocalstorage = (localStorage.getItem('Highlighted Candle'));
    // const tradetimefromlocalstorage = (localStorage.getItem('Trade Time'));
    if (this.counter == 0 && this.starting_index == 0) {
      hour = hour_Increment;
    } else {
      hour = hour_Increment - this.starting_index;
    }
    _w = this.tline_difference;
    var candle_width_perc = this.tline_difference / 100;
    var candle_l_off = 5 * candle_width_perc;
    var candle_r_off = 5 * candle_width_perc;
    var x = (this.x_from_left) + ((hour * this.tline_difference) + (candle_l_off));
    var color;
    if (close > open) {
      var y = (can_h - this.y_from_bottom) - close;
      _h = close - open;
      // var color;
      if(this.bid > this.ask){
        // color = '#11c15b';
        color = 'green';
        if(this.showWhitebackground == true){
          this.color = 'white'
          color = 'blue'
        }
        else if(this.showTradingViewbackground == true){
          this.color = 'trading'
          color = '#07A8F4'
        }
         else {
          this.color = 'black'
          color = 'green'
        }
      } else {
        color = 'green';
        if(this.showWhitebackground == true){
          this.color = 'white'
          color = 'blue'
        } else if(this.showTradingViewbackground == true){
          this.color = 'trading'
          color = '#07A8F4'
        }  else {
          this.color = 'black'
          color = 'green'
        }
      }
    };
    if (open > close) {
      var y = (can_h - this.y_from_bottom) - open;
      _h = open - close;
      // var color = 'red';
      if(this.ask > this.bid){
        // color = '#ff4d4d';
        color = 'red';
        if(this.showTradingViewbackground == true){
          this.color = 'trading'
          color = '#EC4F30'
        }
      } else {
        color = 'red'
        if(this.showTradingViewbackground == true){
          this.color = 'trading'
          color = '#EC4F30'
        }
      }
    };
    var cnd_w = _w - (candle_l_off + candle_r_off);
    this.cnd_w = cnd_w;
    var cnd_h = _h;
    this.cnd_h = cnd_h;
    this.candle_start_point = x;
    this.candle_end_point = this.candle_start_point + this.cnd_w;

    if (this.chartTool) {
      this.candle_initial_point = (this.graph_height - cnd_low) + 25;
      this.candle_final_point = (this.graph_height - cnd_high) + 25;
    } else {

      this.candle_initial_point = (this.graph_height - cnd_low) + 25;
      this.candle_final_point = (this.graph_height - cnd_high) + 25;
    }
    var openTime_human_readible_ = this.cnd_array[hour_Increment].openTime_human_readible;
    if(openTime_human_readible_ == this.selectedDate){
      // color = 'white';
      this.c.fillStyle = 'rgb(47,79,79,0.3)';
      // this.c.lineWidth = 3;
      this.c.fillRect(x - candle_l_off, 0, cnd_w + candle_r_off, this.graph_height + this.y_from_bottom + this.y_from_top)
    }
    let candle_array_object = new Object();
    candle_array_object['x1'] = this.candle_start_point;
    candle_array_object['x2'] = this.candle_end_point;
    candle_array_object['y1'] = this.candle_initial_point;
    candle_array_object['y2'] = this.candle_final_point;
    candle_array_object['tline_diff'] = this.tline_difference;
    candle_array_object['pline_diff'] = this.pline_difference;
    candle_array_object['fromleft'] = this.x_from_left;
    candle_array_object['fromright'] = can_w - (this.x_from_right);
    candle_array_object['max_value'] = this.max_value;
    candle_array_object['min_value'] = this.min_value;
    candle_array_object['pricestart_point'] = (can_h - this.y_from_bottom);
    candle_array_object['open_time'] = openTime_human_readible_;

    this.coordinateArray.push(candle_array_object);
    // console.log(this.coordinateArray[0]);
    this.c.fillStyle = color;
    this.drawCandleStick(can_h, low, high, hour, cnd_w, candle_l_off);
    this.c.fillRect(x, y, cnd_w, cnd_h);
    if(openTime_human_readible_ == this.ttime){
      // var tradeprice =  +(localStorage.getItem('Trade Price'));
      var PerValue:number = ((this.tprice - this.min_value) / (this.max_value - this.min_value)) * 100;
      var value;
      var element = 1.1
      value = PerValue * this.pline_difference;
      this.drawCircle(can_h, value, x, cnd_w, element, "tradeprice");
    }
    if (this.showBigCircle) {
      // console.log(typeof (top_wick_prices), "====>", top_wick_prices)
      if (typeof (top_wick_prices) === 'string') {
        var top_wick_prices_arr = top_wick_prices.split(',');
        top_wick_prices_arr.forEach(
          element => {
            var key1 = parseFloat(element)
            var PerValue = ((key1 - this.min_value) / (this.max_value - this.min_value)) * 100;
            var value;
            value = PerValue * this.pline_difference;
            this.drawCircle(can_h, value, x, cnd_w, 0.9, "top_wick");
          })
      }
    }
    if (this.showBuyCircle || this.showSellCircle) {

      for (var key in percentiles) {
        var PerValue = ((parseFloat(key) - this.min_value) / (this.max_value - this.min_value)) * 100;
        var value:any = PerValue * this.pline_difference;
        if (this.showBuyCircle) {
          var buy = percentiles[key]['buy'];
          buy.forEach(
            element => {
              if (element != 0) {
                if (parseFloat(element) > 0.8) {
                  element = parseFloat(element) / this.Factor;
                }
                this.drawCircle(can_h, value, x, cnd_w, element, "buy");
              }
            }
          );
        }
        if (this.showSellCircle) {
          var sell = percentiles[key]['sell'];
          sell.forEach(
            element => {
              if (element != 0) {
                if (parseFloat(element) > 0.8) {
                  element = parseFloat(element) / this.Factor;
                }
                this.drawCircle(can_h, value, x, cnd_w, element, "sell");
              }
            }
          );
        }
      }
    }
    var trueLevelType = this.truelevel_type;
    if (this.showBuyPriceCircle || this.showSellPriceCircle || this.showStopLossCircle) {
      for (var row = 1; row <= 20; row++) {
        var truelevelarr = this.cnd_array[hour_Increment].rules_array;
        if (typeof (truelevelarr) !== 'undefined') {
          if (typeof (truelevelarr['level_' + row]) !== 'undefined') {

            buy = truelevelarr['level_' + row].buy_price;
            sell = truelevelarr['level_' + row].sell_price;
            console.log(truelevelarr['level_' + row], '===>>ss');
            var stoploss;
            if (typeof truelevelarr['level_' + row].stoploss == 'undefined') {
              stoploss = 0;
            }
            if (typeof truelevelarr['level_' + row].stoploss !== 'undefined') {
              stoploss = parseFloat(truelevelarr['level_' + row].stoploss);
            }
            var color = truelevelarr['level_' + row].color;
            if (trueLevelType == 'live') {
              if (color == "light-blue" || color == 'blue-light' || color == 'light-red' || color == 'red-light') {
                continue;
              }
            } else if (trueLevelType == 'test') {
              if (color == "blue" || color == 'plum' || color == 'red') {
                continue;
              }
            }

            if (this.showStopLossCircle) {
              var PerValue = ((stoploss - this.min_value) / (this.max_value - this.min_value)) * 100;
              var value;
              var element = 1.1
              value = PerValue * this.pline_difference;
              if (stoploss != 0 && row != 15) {
                this.drawCircle(can_h, value, x, cnd_w, element, "stoploss");
              }
            }

            if (this.showBuyPriceCircle) {

              var PerValue = ((buy - this.min_value) / (this.max_value - this.min_value)) * 100;
              var value;
              var element = 1.1
              value = PerValue * this.pline_difference;

              if (buy != 0 && row != 15) {
                this.drawCircle(can_h, value, x, cnd_w, element, "buyprice");
              }
            }
            if (this.showSellPriceCircle) {
              var PerValue = ((sell - this.min_value) / (this.max_value - this.min_value)) * 100;
              var value;
              var element = 1.1
              value = PerValue * this.pline_difference;
              if (sell != 0 && row != 15) {
                this.drawCircle(can_h, value, x, cnd_w, element, "sellprice");
              }
            }
          }
        }
      }
    }
    if (this.showSwingLines) {
      if (swing_status == "HH" || swing_status == 'LH') {
        this.drawSwingLine(can_h, high, swing_status, barrier_type);
      } else if (swing_status == "LL" || swing_status == "HL") {
        this.drawSwingLine(can_h, low, swing_status, barrier_type);
      }

    }
    if(this.showWallLines){
      var tempArr = [1,2,3,4,5,10];
      if (tempArr.includes(downwidth)){
        this.drawWallLine(can_h, downwallprice, 10/downwidth, "yellow");
      }
      if (tempArr.includes(upwidth)){
        this.drawWallLine(can_h, upwallprice, 10/upwidth, "rgb(209, 79, 105)");
      }
    }
    if(this.showT1Lines){
      var t1minprice = +t1price_min;
      var t1minperc = +t1perc_min
      var t1maxprice = +t1price_max;
      var t1maxperc = +t1perc_max
      var tempArr = [1,2,3,4,5,10];



      if(tempArr.includes(t1minperc)){
        t1minperc = 10/t1minperc
      }
      if (t1minperc > 0 && typeof t1minperc != 'undefined'){
        this.drawT1Line(can_h, t1minprice, t1minperc, "#2a9d8f");
      }



      if(tempArr.includes(t1maxperc)){
        t1maxperc = 10/t1maxperc
      }
      if (t1maxperc > 0 && typeof t1maxperc != 'undefined'){
        this.drawT1Line(can_h, t1maxprice, t1maxperc, "#e9c46a");
      }


    }
    // T1 End
    if(this.showT2Lines){
      var t2minprice = +t2price_min;
      var t2minperc = +t2perc_min
      var t2maxprice = +t2price_max;
      var t2maxperc = +t2perc_max
      var tempArr = [1,2,3,4,5,10];



      if(tempArr.includes(t2minperc)){
        t2minperc = 10/t2minperc
      }
      if (t2minperc > 0 && typeof t2minperc != 'undefined'){
        this.drawT2Line(can_h, t2minprice, t2minperc, "#eac9c1");
      }




      if(tempArr.includes(t2maxperc)){
        t2maxperc = 10/t2maxperc
      }
      if (t2maxperc > 0 && typeof t2maxperc != 'undefined'){
        this.drawT2Line(can_h, t2maxprice, t2maxperc, "#ee6c4d");
      }
    }
    // T2 End
    if(this.showT4Lines){
      var t4minprice = +t4price_min;
      var t4minperc = +t4perc_min;
      var t4maxprice = +t4price_max;
      var t4maxperc = +t4perc_max;
      var tempArr = [1,2,3,4,5,10];

      if(tempArr.includes(t4minperc)){
        t4minperc = 10/t4minperc
      }
      if (t4minperc > 0 && typeof t4minperc != 'undefined'){
        this.drawT4Line(can_h, t4minprice, t4minperc, "#0081a7");
      }



      if(tempArr.includes(t4maxperc)){
        t4maxperc = 10/t4maxperc
      }
      if (t4maxperc > 0 && typeof t4maxperc != 'undefined'){
        this.drawT4Line(can_h, t4maxprice, t4maxperc, "#ffc145");
      }
    }
    // T4 End
    if(this.showRangesLines){

      var rangemaxperc = range_max_perc
      var tempArr = [1,2,3,4,5,10];

      if(tempArr.includes(rangemaxperc)){
        rangemaxperc = 10/rangemaxperc;
        if(typeof ranges !== 'undefined'){
          for (var key in ranges) {
            if (typeof (ranges[key]['sell']) !== 'undefined') {
              var sell_range = ranges[key]['sell'];
            }
            if (typeof (ranges[key]['buy']) !== 'undefined') {
              var buy_range = ranges[key]['buy'];
            }
            if(this.time_length > 100){
              var width_buy = 2;
              var width_sell = 2;
            } else {
              var width_buy = 3;
              var width_sell = 3;
            }
            // var width_buy = 3;
            // var width_sell = 3;
            var color_buy = '#9b5de5';
            var color_sell = '#f15bb5';
            var length_buy = rangemaxperc;//(buy_range/this.Base)*100;
            var length_sell = rangemaxperc;//(sell_range/this.Base)*100;
            var price = (((parseFloat(key) - this.min_value) / (this.max_value - this.min_value)) * 100) * this.pline_difference;
            this.drawRangeLine(can_h, price, width_buy, width_sell, length_buy, length_sell, color_buy, color_sell);
          }
        }
      }
    }
    if(this.showT2BCP15Lines){


      var per_bid = +t2bcp15perc_bid
      var per_ask = +t2bcp15perc_ask;
      var tempArr = [1,2,3,4,5,10];
      if(tempArr.includes(per_ask)){
        per_ask = 10/per_ask
      }
      var width_ask = per_ask;




      if(tempArr.includes(per_bid)){
        per_bid = 10/per_bid
      }
      var width_bid = per_bid;



      var length = 1;
      var color_ask = '#009fb7';
      var color_bid = '#fed766';
      if(width_bid || width_ask > 0){
        this.drawT2BCP15Line(can_h, t2bcp15price, width_ask,width_bid,length, color_ask, color_bid );
      }

    }
    // T2BCP End
  }
  drawWallLine(can_h, price,width, color) {
    for(let i =0;i<this.userCoinsArr.length;i++){
      if(this.userCoinsArr[i].symbol == this.currentCoin){
        var _w = this.tline_difference;
        var candle_width_perc = this.tline_difference / 100;
        var candle_l_off = 5 * candle_width_perc;
        var candle_r_off = 5 * candle_width_perc;
        var x1 = this.candle_start_point;
        var y1 = (can_h - this.y_from_bottom) - price;
        var x2 = x1 + ((1 * this.tline_difference) - (candle_l_off + candle_r_off));
        var y2 = y1;
        this.c.beginPath();
        this.c.moveTo(x1, y1);
        this.c.lineTo(x2, y2);
        this.c.lineCap = "butt";
        this.c.lineWidth = width;
        this.c.strokeStyle = color
        this.c.stroke();
      }
    }
  }
  drawT1Line(can_h, price,width, color) {

    for(let i =0;i<this.userCoinsArr.length;i++){
      if(this.userCoinsArr[i].symbol == this.currentCoin){
        var _w = this.tline_difference;
        var candle_width_perc = this.tline_difference / 100;
        var candle_l_off = 5 * candle_width_perc;
        var candle_r_off = 5 * candle_width_perc;
        var x1 = this.candle_start_point;
        var y1 = (can_h - this.y_from_bottom) - price;
        var x2 = x1 + ((1 * this.tline_difference) - (candle_l_off + candle_r_off));
        var y2 = y1;
        this.c.beginPath();
        this.c.moveTo(x1, y1);
        this.c.lineTo(x2, y2);
        this.c.lineCap = "butt";
        this.c.lineWidth = width;
        this.c.strokeStyle = color
        this.c.stroke();
      }
    }
  }
  drawT2Line(can_h, price,width, color) {
    for(let i =0;i<this.userCoinsArr.length;i++){
      if(this.userCoinsArr[i].symbol == this.currentCoin){
        var _w = this.tline_difference;
        var candle_width_perc = this.tline_difference / 100;
        var candle_l_off = 5 * candle_width_perc;
        var candle_r_off = 5 * candle_width_perc;
        var x1 = this.candle_start_point;
        var y1 = (can_h - this.y_from_bottom) - price;
        var x2 = x1 + ((1 * this.tline_difference) - (candle_l_off + candle_r_off));
        var y2 = y1;
        this.c.beginPath();
        this.c.moveTo(x1, y1);
        this.c.lineTo(x2, y2);
        this.c.lineCap = "butt";
        this.c.lineWidth = width;
        this.c.strokeStyle = color
        this.c.stroke();
      }
    }
  }
  drawT4Line(can_h, price,width, color) {
    for(let i =0;i<this.userCoinsArr.length;i++){
      if(this.userCoinsArr[i].symbol == this.currentCoin){
        var _w = this.tline_difference;
        var candle_width_perc = this.tline_difference / 100;
        var candle_l_off = 5 * candle_width_perc;
        var candle_r_off = 5 * candle_width_perc;
        var x1 = this.candle_start_point;
        var y1 = (can_h - this.y_from_bottom) - price;
        var x2 = x1 + ((1 * this.tline_difference) - (candle_l_off + candle_r_off));
        var y2 = y1;
        this.c.beginPath();
        this.c.moveTo(x1, y1);
        this.c.lineTo(x2, y2);
        this.c.lineCap = "butt";
        this.c.lineWidth = width;
        this.c.strokeStyle = color
        this.c.stroke();
      }
    }
  }
  drawT2BCP15Line(can_h, price,width_ask,width_bid,length, color_ask, color_bid) {
    for(let i =0;i<this.userCoinsArr.length;i++){
      if(this.userCoinsArr[i].symbol == this.currentCoin){
        var _w = this.tline_difference;
        var candle_width_perc = this.tline_difference / 100;
        var candle_l_off = 5 * candle_width_perc;
        var candle_r_off = 5 * candle_width_perc;
        var x1 = this.candle_start_point + (this.cnd_w/2);
        if(width_bid == 0 || typeof width_bid == 'undefined'){
          x1 = this.candle_start_point;
        }
        if(width_ask == 0 || typeof width_ask == 'undefined'){
          x1 = this.candle_start_point;
        }




        var y1 = (can_h - this.y_from_bottom) - price;
        var x2 = x1 - ((length * this.tline_difference/2) - (candle_l_off));
        if(width_bid == 0 || typeof width_bid == 'undefined'){
          x2 = x1 + ((length * this.tline_difference) - (candle_r_off + candle_l_off));
        }
        var y2 = y1;
        var x3 = x1 + ((length * this.tline_difference/2) - (candle_r_off));
        if(width_ask == 0 || typeof width_ask == 'undefined'){
          x3 = x1 + ((length * this.tline_difference) - (candle_r_off + candle_l_off));
        }
        var y3 = y2;

        if(width_ask > 0 && typeof width_ask != 'undefined'){
          this.c.beginPath();
          this.c.moveTo(x1, y1);
          this.c.lineTo(x2, y2);
          this.c.lineCap = "butt";
          this.c.lineWidth = width_ask;
          this.c.strokeStyle = color_ask;
          this.c.stroke();
          this.c.closePath();
        }




        if(width_bid > 0 && typeof width_bid != 'undefined'){
          this.c.beginPath();
          this.c.moveTo(x1, y1);
          this.c.lineTo(x3, y3);
          this.c.lineCap = "butt";
          this.c.lineWidth = width_bid;
          this.c.strokeStyle = color_bid;
          this.c.stroke();
        }
      }
    }

  }
  drawRangeLine(can_h, price, width_buy, width_sell,length_buy, length_sell, color_buy, color_sell) {

    for(let i =0;i<this.userCoinsArr.length;i++){
      if(this.userCoinsArr[i].symbol == this.currentCoin){
        var _w = this.tline_difference;
        var candle_width_perc = this.tline_difference / 100;
        var candle_l_off = 5 * candle_width_perc;
        var candle_r_off = 5 * candle_width_perc;
        var x1 = this.candle_start_point + (this.cnd_w/2);
        var y1 = (can_h - this.y_from_bottom) - price;
        var x2 = x1 - ((length_buy * this.tline_difference/2) - (candle_l_off));
        var y2 = y1;
        var x3 = x1 + ((length_sell * this.tline_difference/2) - (candle_r_off));
        var y3 = y2;
        this.c.beginPath();
        this.c.moveTo(x1, y1);
        this.c.lineTo(x2, y2);
        this.c.lineCap = "butt";
        this.c.lineWidth = width_buy;
        this.c.strokeStyle = color_buy;
        this.c.stroke();
        this.c.closePath();


        this.c.beginPath();
        this.c.moveTo(x1, y1);
        this.c.lineTo(x3, y3);
        this.c.lineCap = "butt";
        this.c.lineWidth = width_sell;
        this.c.strokeStyle = color_sell;
        this.c.stroke();
      }
    }
  }
  drawCandleStick(can_h, low, high, hr, cnd_w, candle_l_off) {
    var start_point = this.x_from_left;
    var x_point = (start_point + (hr * this.tline_difference));
    var y_point = (can_h - this.y_from_bottom);
    this.c.beginPath();
    this.c.moveTo(x_point + candle_l_off + (cnd_w / 2), (y_point - low));
    this.c.lineTo(x_point + candle_l_off + (cnd_w / 2), (y_point - high));
    this.c.strokeStyle = 'white'
    if(this.showWhitebackground == true){
      this.c.strokeStyle = 'black'
    } else {
      this.c.strokeStyle = 'white'
    }
    this.c.lineWidth = 0.7;
    this.c.stroke();
  }
  drawCircle(can_h, price, time, diameter, factor, typeb) {

    for(let i =0;i<this.userCoinsArr.length;i++){
      if(this.userCoinsArr[i].symbol == this.currentCoin){
        var Dot_Size = diameter / 2;
        var DOT__X = time + Dot_Size;
        var DOT__Y = (can_h - this.y_from_bottom) - price;
        var centerX = DOT__X;
        var centerY = DOT__Y;
        var radius = Dot_Size * factor;
        var Circle_color = "";
        //
        if (typeb == 'tradeprice') {
          switch (factor) {

            case (0.7):
              Circle_color = "rgba(255,255,0,0.6)";// rgba(28, 232, 205, 0.5)
              break;
            case (1.1):
              // Circle_color = '#009999';
              Circle_color = 'rgba(255, 255, 255, 0.8)';
              break;
            default:
              Circle_color = "rgba(0, 125, 255,0.15)";
          }
        }
        if (typeb == 'stoploss') {
          switch (factor) {

            case (0.7):
              Circle_color = "rgba(255,255,0,0.6)";// rgba(28, 232, 205, 0.5)
              break;
            case (1.1):
              Circle_color = "rgba(255, 128, 0, 1)";
              break;
            default:
              Circle_color = "rgba(0, 125, 255,0.15)";
          }
        }
        if (typeb == 'buyprice') {
          switch (factor) {

            case (0.7):
              Circle_color = "rgba(255,255,0,0.6)";// rgba(28, 232, 205, 0.5)
              break;
            case (1.1):
              Circle_color = "rgba(255,255,0,1)";
              break;
            default:
              Circle_color = "rgba(0, 125, 255,0.15)";
          }
        }
        if (typeb == 'sellprice') {
          switch (factor) {

            case (0.7):
              Circle_color = "rgba(245, 66, 239,0.5)";
              break;
            case (1.1):
              Circle_color = "rgba(245, 66, 239,0.7)";
              break;
            default:
              Circle_color = "rgba(0, 125, 255,0.15)";
          }
        }
        if (typeb == 'buy') {
          switch (factor) {
            case (0.1):
              Circle_color = "rgba(0, 125, 255,0.45)";
              break;
            case (0.2):
              Circle_color = "rgba(0, 125, 255,0.45)";
              break;
            case (0.3):
              Circle_color = "rgba(0, 125, 255,0.45)";
              break;
            case (0.4):
              Circle_color = "rgba(0, 125, 255,0.30)";
              break;
            case (0.5):
              Circle_color = "rgba(0, 125, 255,0.30)";
              break;
            case (0.6):
              Circle_color = "rgba(0, 125, 255,0.30)";
              break;
            case (0.7):
              Circle_color = "rgba(0, 125, 255,0.15)";
              break;
            case (0.8):
              Circle_color = "rgba(0, 125, 255,0.15)";
              break;
            case (0.9):
              Circle_color = "rgba(0, 125, 255,0.15)";
              break;
            default:
              Circle_color = "rgba(0, 125, 255,0.15)";
          }//Circle-Color end
        } else if (typeb == "sell") {
          switch (factor) {
            case (0.1):
              Circle_color = "rgba(255, 125, 0, 0.45)";
              break;
            case (0.2):
              Circle_color = "rgba(255, 125, 0, 0.45)";
              break;
            case (0.3):
              Circle_color = "rgba(255, 125, 0, 0.45)";
              break;
            case (0.4):
              Circle_color = "rgba(255, 125, 0, 0.30)";
              break;
            case (0.5):
              Circle_color = "rgba(255, 125, 0, 0.30)";
              break;
            case (0.6):
              Circle_color = "rgba(255, 125, 0, 0.30)";
              break;
            case (0.7):
              Circle_color = "rgba(255, 125, 0, 0.15)";
              break;
            case (0.8):
              Circle_color = "rgba(255, 125, 0, 0.15)";
              break;
            case (0.9):
              Circle_color = "rgba(255, 125, 0, 0.15)";
              break;
            default:
              Circle_color = "rgba(255, 125, 0, 0.15)";
          }//Circle-Color end
        } else if (typeb == "top_wick") {
          switch (factor) {
            case (0.1):
              Circle_color = "rgba(255, 255, 255, 0.45)";
              break;
            case (0.2):
              Circle_color = "rgba(255, 255, 255, 0.45)";
              break;
            case (0.3):
              Circle_color = "rgba(255, 255, 255, 0.45)";
              break;
            case (0.4):
              Circle_color = "rgba(255, 255, 255, 0.30)";
              break;
            case (0.5):
              Circle_color = "rgba(255, 255, 255, 0.30)";
              break;
            case (0.6):
              Circle_color = "rgba(255, 255, 255, 0.30)";
              break;
            case (0.7):
              Circle_color = "rgba(255, 255, 255, 0.15)";
              break;
            case (0.8):
              Circle_color = "rgba(255, 255, 255, 0.45)";
              break;
            case (0.9):
              Circle_color = "rgba(255, 255, 255, 0.45)";
              break;
            default:
              Circle_color = "rgba(255,0,0,0)";
          }//Circle-Color end

          if(this.showWhitebackground == true){
            Circle_color = "rgba(0, 0, 0, 0.6)";
          } else {
            Circle_color = "rgba(255, 255, 255, 0.45)";
          }
        }
        //
        this.c.beginPath();
        this.c.arc(centerX, centerY, radius, 0, 2 * Math.PI, true);
        this.c.lineWidth = 0.5;
        this.c.fillStyle = Circle_color;
        this.c.fill();
      }
    }
  }
  drawSwingLine(can_h, price, swing_status, barrier_type) {
    var x1 = this.candle_start_point + (this.cnd_w / 2);
    var y1 = (can_h - this.y_from_bottom) - price;
    var x2 = x1 + (3 * this.tline_difference);
    var y2 = y1;
    var color;


    if (barrier_type == 'very_strong_barrier') {
      // this.c.fillStyle = "#008755";
      color = "#008755";
    } else if (barrier_type == 'weak_barrier') {
      color = "#f00";
    } else {
      color = "#000";
    }

    if (swing_status == "HH") {
      this.c.beginPath();
      this.c.moveTo(x1, y1);
      this.c.lineTo(x2, y2);
      this.c.fillStyle = color;
      this.c.fillText("HH", x1 + 3, y1 - 3);
      this.c.lineWidth = 2;
      this.c.strokeStyle = color//"#000"
      this.c.stroke();
    } else if (swing_status == "HL") {
      this.c.beginPath();
      this.c.moveTo(x1, y1);
      this.c.lineTo(x2, y2);
      this.c.fillStyle = color;
      this.c.fillText("HL", x1 + 3, y1 - 3);
      this.c.lineWidth = 2;
      this.c.strokeStyle = color//"#0606e7"
      this.c.stroke();
    } else if (swing_status == "LH") {
      this.c.beginPath();
      this.c.moveTo(x1, y1);
      this.c.lineTo(x2, y2);
      this.c.fillStyle = color;
      this.c.fillText("LH", x1 + 3, y1 - 3);
      this.c.lineWidth = 2;
      this.c.strokeStyle = color//"#f38600"
      this.c.stroke();
    } else if (swing_status == "LL") {
      this.c.beginPath();
      this.c.moveTo(x1, y1);
      this.c.lineTo(x2, y2);
      this.c.fillStyle = color;
      this.c.fillText("LL", x1 + 3, y1 - 3);
      this.c.lineWidth = 2;
      this.c.strokeStyle = color //"#32cd32"
      this.c.stroke();
    }
  }

//................................................................................................................................
//.WWW...WWWWW...WWW...................kkk.......... TTTTTTTTTT................................ttt..thh..........................
//.WWWW..WWWWW..WWWW...................kkk.......... TTTTTTTTTT................................ttt..thh..........................
//.WWWW..WWWWW..WWWW.................r.kkk........... TTTTTTTTTT...............................ettt..thh.......................r..
//.WWWW..WWWWW..WWWW..ooooooo..rrrrrrr.kkk..kkkk........TTTT....ooooooo....ggggggggg..eeeeeee.eetttt.thhhhhhhh...eeeeeee..errrrr..
//..WWW..WWWWWW.WWW..ooooooooo.rrrrrrr.kkk.kkkk.........TTTT...Toooooooo..ogggggggg..geeeeeee.eetttt.thhhhhhhh..heeeeeee..errrrr..
//..WWW.WWWWWWW.WWW.ooooo.oooo..rrrrrr.kkkkkkk..........TTTT...Tooo.ooooo.oggg.gggg..geee.eeee.ettt..thhhhhhhh..heee.eeee.errrrr..
//..WWWWWWW.WWWWWWW.oooo...ooo..rrr....kkkkkk...........TTTT...Too...oooo.ogg...ggg..gee...eee.ettt..thhh..hhhh.hee...eee.errr....
//..WWWWWWW.WWWWWWW.oooo...oooo.rrr....kkkkkk...........TTTT..TToo...oooo.ogg...ggg.ggeeeeeeee.ettt..thhh..hhhhhheeeeeeee.errr....
//...WWWWWW.WWWWWW..oooo...oooo.rrr....kkkkkkk..........TTTT..TToo...oooo.ogg...ggg.ggeeeeeeee.ettt..thh...hhhhhheeeeeeee.err.....
//...WWWWWW.WWWWWW..oooo...ooo..rrr....kkkkkkk..........TTTT...Too...oooo.ogg...ggg..gee.......ettt..thh...hhhh.hee.......err.....
//...WWWWW...WWWWW..ooooo.oooo..rrr....kkk.kkkk.........TTTT...Tooo.ooooo.oggg.gggg..geee.eeee.ettt..thh...hhhh.heee.eeee.err.....
//...WWWWW...WWWWW...ooooooooo..rrr....kkk..kkkk........TTTT...Toooooooo..ogggggggg..geeeeeeee.etttttthh...hhhh.heeeeeeee.err.....
//....WWWW...WWWW.....ooooooo...rrr....kkk..kkkk........TTTT....ooooooo....gggggggg...eeeeeee...tttttthh...hhhh..eeeeeee..err.....
//..............................................................................ggg...............................................
//........................................................................oggg.gggg...............................................
//........................................................................ogggggggg...............................................
//.........................................................................ggggggg................................................
//................................................................................................................................

  arrayForMaxvalu = [];
  findmaxprice() {
    this.arrayForMaxvalu = [];
    if ((this.time_length > (this.ending_index - this.starting_index)) && (this.time_length > this.cnd_array.length)) {
      this.ending_index = this.cnd_array.length;
    }
    if (this.ending_index > this.cnd_array.length) {
      this.ending_index = this.cnd_array.length;
    }
    for (let i = this.starting_index; i < this.ending_index; i++) {
      let close = this.cnd_array[i].close
      this.arrayForMaxvalu.push(close);
      let open = this.cnd_array[i].open
      this.arrayForMaxvalu.push(open);
      let high = this.cnd_array[i].high
      this.arrayForMaxvalu.push(high);
      let low = this.cnd_array[i].low
      this.arrayForMaxvalu.push(low);
    }
  }
  Findmax_min() {
    this.findmaxprice();
    this.max_value = Math.max(...this.arrayForMaxvalu).toFixed(8);
    this.min_value = Math.min(...this.arrayForMaxvalu).toFixed(8);
  }
  changeValue() {
    var ArrayCandleStick = this.coordinateArray
    this.service.new_candlestickData(ArrayCandleStick);
  }
  setcomponentstate() {
    var setting = [];
    this.setting_arr = new Object();
    this.setting_arr['Factor'] = this.Factor;
    this.setting_arr['Base'] = this.Base;
    this.setting_arr['showBigCircle'] = this.showBigCircle;
    this.setting_arr['showBuyCircle'] = this.showBuyCircle;
    this.setting_arr['showSellCircle'] = this.showSellCircle;
    this.setting_arr['showBuyPriceCircle'] = this.showBuyPriceCircle;
    this.setting_arr['showSellPriceCircle'] = this.showSellPriceCircle;
    this.setting_arr['showStopLossCircle'] = this.showStopLossCircle;
    this.setting_arr['showSwingLines'] = this.showSwingLines;
    this.setting_arr['showWallLines'] = this.showWallLines;
    this.setting_arr['showRangesLines'] = this.showRangesLines;
    this.setting_arr['showT1Lines'] = this.showT1Lines;
    this.setting_arr['showT2Lines'] = this.showT2Lines;
    this.setting_arr['showT4Lines'] = this.showT4Lines;
    this.setting_arr['showT2BCP15Lines'] = this.showT2BCP15Lines;

    setting[0] = this.setting_arr;
    if (typeof Storage != 'undefined') {
      localStorage.removeItem('setting_arr_candlestick_chart');
      localStorage.setItem('setting_arr_candlestick_chart', JSON.stringify(setting));
    }
  }
  getcomponentstate() {
    this.setting_arr = JSON.parse(localStorage.getItem('setting_arr_candlestick_chart'));
    this.Factor = this.setting_arr[0].Factor;
    this.Base = this.setting_arr[0].Base;
    this.showBigCircle = this.setting_arr[0].showBigCircle;
    this.showBuyCircle = this.setting_arr[0].showBuyCircle;
    this.showSellCircle = this.setting_arr[0].showSellCircle;
    this.showBuyPriceCircle = this.setting_arr[0].showBuyPriceCircle;
    this.showSellPriceCircle = this.setting_arr[0].showSellPriceCircle;
    this.showStopLossCircle = this.setting_arr[0].showStopLossCircle;
    this.showSwingLines = this.setting_arr[0].showSwingLines;
    this.showWallLines = this.setting_arr[0].showWallLines;
    this.showRangesLines = this.setting_arr[0].showRangesLines;
    this.showT1Lines = this.setting_arr[0].showT1Lines;
    this.showT2Lines = this.setting_arr[0].showT2Lines;
    this.showT4Lines = this.setting_arr[0].showT4Lines;
    this.showT2BCP15Lines = this.setting_arr[0].showT2BCP15Lines;
  }


//.......................................................................
//........................lllll..........................................
//........................lllll..........................................
//........AAAAAAA.........lllll.................................tttt.....
//........AAAAAAAA........lllll.................................tttt.....
//........AAAAAAAA........lllll.................................tttt.....
//.......AAAAAAAAA........lllll................................ttttt.....
//.......AAAAAAAAAA.......lllll......eeeeeeeee.....rrrrr.rrrrrttttttttt..
//.......AAAAAAAAAA.......lllll.....eeeeeeeeeee....rrrrrrrrrrrttttttttt..
//......AAAAA.AAAAA.......lllll....eeeeeeeeeeeee...rrrrrrrrrrrttttttttt..
//......AAAAA..AAAAA......lllll...eeeeee...eeeee...rrrrrrrrrr..ttttt.....
//.....AAAAAA..AAAAA......lllll...eeeee.....eeeee..rrrrrrr.....ttttt.....
//.....AAAAA...AAAAAA.....lllll...eeeee.....eeeee..rrrrrr......ttttt.....
//.....AAAAA....AAAAA.....lllll...eeeeeeeeeeeeeee..rrrrrr......ttttt.....
//....AAAAAAAAAAAAAAA.....lllll...eeeeeeeeeeeeeee..rrrrrr......ttttt.....
//....AAAAAAAAAAAAAAAA....lllll...eeeeeeeeeeeeeee..rrrrr.......ttttt.....
//....AAAAAAAAAAAAAAAA....lllll...eeeee............rrrrr.......ttttt.....
//...AAAAAAAAAAAAAAAAA....lllll...eeeee............rrrrr.......ttttt.....
//...AAAAA.......AAAAAA...lllll...eeeeee....eeeee..rrrrr.......ttttt.....
//..AAAAAA........AAAAA...lllll...eeeeeee..eeeeee..rrrrr.......tttttt....
//..AAAAAA........AAAAAA..lllll....eeeeeeeeeeeee...rrrrr.......tttttttt..
//..AAAAA.........AAAAAA..lllll.....eeeeeeeeeee....rrrrr........ttttttt..
//.AAAAAA..........AAAAA..lllll......eeeeeeeee.....rrrrr........ttttttt..
//.......................................................................
//..........................................................................................................
//.DDDDDDDDD.........................n'''..ttt....... DDDDDDDD..............ell.............ttt.............
//.DDDDDDDDDD.........................'''..ttt....... DDDDDDDDD.............ell.............ttt.............
//.DDDDDDDDDDD........................'''.'ttt....... DDDDDDDDDD............ell............ettt.............
//.DDD....DDDD...ooooooo...nnnnnnnn...'''''tttt...... DDD...DDDDD..eeeeeee..ell...eeeeeee.eetttt..eeeeeee...
//.DDD.....DDDD.ooooooooo..nnnnnnnnn..'''''tttt...... DDD....DDDD.Deeeeeee..ell..leeeeeee.eetttt.teeeeeee...
//.DDD.....DDDD.oooo.oooo..nnnn.nnnn......'ttt....... DDD....DDDD.Deee.eeee.ell..leee.eeee.ettt..teee.eeee..
//.DDD.....DDDDDooo...oooo.nnnn..nnn......'ttt....... DDD....DDDD.Dee...eee.ell..lee...eee.ettt..tee...eee..
//.DDD.....DDDDDooo...oooo.nnn...nnn......'ttt....... DDD....DDDDDDeeeeeeee.ell.lleeeeeeee.ettt.tteeeeeeee..
//.DDD.....DDDDDooo...oooo.nnn...nnn......'ttt....... DDD....DDDDDDeeeeeeee.ell.lleeeeeeee.ettt.tteeeeeeee..
//.DDD....DDDD.Dooo...oooo.nnn...nnn......'ttt....... DDD...DDDDD.Dee.......ell..lee.......ettt..tee........
//.DDDDDDDDDDD..oooo.oooo..nnn...nnn......'ttt....... DDDDDDDDDD..Deee.eeee.ell..leee.eeee.ettt..teee.eeee..
//.DDDDDDDDDD...ooooooooo..nnn...nnn......'ttttt..... DDDDDDDDD...Deeeeeeee.ell..leeeeeeee.etttttteeeeeeee..
//.DDDDDDDDD.....ooooooo...nnn...nnn.......ttttt..... DDDDDDDD.....eeeeeee..ell...eeeeeee...ttttt.eeeeeee...
//..........................................................................................................
//..........................................................................................................
//....CCCCCCCC....................ddd.................BBBBBBBBBB.............lll............................
//...CCCCCCCCCC...................ddd.................BBBBBBBBBBB............lll............................
//..CCCCCCCCCCCC..................ddd.................BBB...BBBBB............lll............................
//..CCCC....CCCC..ooooooo....dddddddd...eeeeee........BBB....BBBB...eeeeee...lll...ooooooo.ooww..wwww..www..
//.CCCC......C...ooooooooo..ddddddddd..eeeeeeee.......BBB...BBBBB..eeeeeeee..lll..ooooooooo.oww..wwww..www..
//.CCCC..........oooo.oooo.ddddd.dddd..eeee.eeee......BBBBBBBBBB...eeee.eeee.lll..oooo.oooo.oww.wwwwww.www..
//.CCCC.........oooo...oooodddd..dddd.eeee..eeee......BBBBBBBBBBB.eeee..eeee.lll.looo...ooooowwwwwwwwwwwww..
//.CCCC.........oooo...oooodddd...ddd.eeeeeeeeee......BBB...BBBBB.eeeeeeeeee.lll.looo...ooooowwwwwwwwwwww...
//.CCCC......CC.oooo...oooodddd...ddd.eeeeeeeeee......BBB.....BBBBeeeeeeeeee.lll.looo...oooo.wwwwwwwwwwww...
//..CCCC....CCCCoooo...oooodddd..dddd.eeee............BBB.....BBBBeeee.......lll.looo...oooo.wwwww.wwwwww...
//..CCCCCCCCCCCC.oooo.oooo.ddddd.dddd..eeee.eeee......BBB...BBBBBB.eeee.eeee.lll..oooo.oooo..wwwww..wwwww...
//...CCCCCCCCCC..ooooooooo..ddddddddd..eeeeeeee.......BBBBBBBBBBB..eeeeeeee..lll..ooooooooo..wwwww..wwww....
//....CCCCCCCC....ooooooo...ddddddddd...eeeeeee.......BBBBBBBBBB....eeeeeee..lll...ooooooo....wwww..wwww....
//..........................................................................................................




  // maximize(){
  //   this.maximiz = true;
  //   this.minimiz = false;
  //   this.general_array[0].time_length = 168;
  //   this.general_array[0].start_index = 0;
  //   this.general_array[0].end_index = 168;
  //   this.service.new_OthArray(this.general_array);
  //   // setTimeout(() =>{
  //   //   this.rander();
  //   // },3)
  // }
  // minimize(){
  //   this.maximiz = false;
  //   this.minimiz = true;
  //   // setTimeout(() =>{
  //   //   this.rander();
  //   // },3)
  //   this.general_array[0].time_length = 168;
  //   this.general_array[0].start_index = 0;
  //   this.general_array[0].end_index = 168;
  //   this.service.new_OthArray(this.general_array);
  // }
}
