import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { MainChartService } from '../main-chart.service';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from "../window.service";
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { ShareddataService } from '../_services/shareddata.service';
import { ApicallsService } from '../_services/apicalls.service';


@Component({
  selector: 'app-mainchart',
  templateUrl: './mainchart.component.html',
  styleUrls: ['./mainchart.component.css']
})
export class MainchartComponent implements OnInit, OnDestroy {
  public subject:Subject<any> = new Subject();
  ngOnDestroy() {
    this.subject.next();
    this.subject.complete();
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////              Variables           ///////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  number;
  href;

  GLOBALCOIN: string = localStorage.getItem('GLOBALCOIN') != null ? localStorage.getItem('GLOBALCOIN') : 'BTCUSDT';
  // currentCoin: string = this.route.snapshot.paramMap.get('coin');
  currentCoin: string = 'XRPBTC'
  dataLoaderWaiting: boolean = false;
  showAllchart: boolean = false;
  showNewsChart: boolean = false;
  showOtherChart: boolean = false;
  showBigContractorsChart: boolean = false;
  showVolumeIndicatorChart: boolean = false;
  showCandleVolumeChart: boolean = false;
  showSevenlevelChart: boolean = false;
  closeAllChart: boolean = false;


  general_array;
  cnd_array;
  cnd_array_length;
  setting: any = [];
  setting_arr = new Object();
  zone_;
  DP1_
  DP2_
  DP3_
  UP1_
  UP2_
  UP3_
  DP1_perc_
  DP2_perc_
  DP3_perc_
  UP1_perc_
  UP2_perc_
  UP3_perc_
  DP1_BTC_
  DP2_BTC_
  DP3_BTC_
  UP1_BTC_
  UP2_BTC_
  UP3_BTC_
  DP1_perc_BTC_
  DP2_perc_BTC_
  DP3_perc_BTC_
  UP1_perc_BTC_
  UP2_perc_BTC_
  UP3_perc_BTC_
  open_;
  close_;
  high_;
  low_;
  time;
  volume_;
  openTime_human_readible_;
  closeTime_human_readible_;
  active_down_barrier_;
  active_up_barrier_;
  down_barrier_value_daily_;
  up_barrier_value_daily_;
  barrier_avg_move_;
  ask_;
  bid_;
  ask_b_;
  bid_b_;
  buy_;
  sell_;
  total_volume_;
  demandbasecandel_;
  supplybasecandel_;
  swing_;
  L1_buy_price_;
  L2_buy_price_;
  L3_buy_price_;
  L4_buy_price_;
  L5_buy_price_;
  L6_buy_price_;
  L7_buy_price_;
  L8_buy_price_;
  L9_buy_price_;
  L10_buy_price_;
  L11_buy_price_;
  L12_buy_price_;
  L13_buy_price_;
  L14_buy_price_;
  L15_buy_price_;
  L16_buy_price_;
  L17_buy_price_;
  L18_buy_price_;
  L19_buy_price_;
  L20_buy_price_;
  L1_sell_price_;
  L2_sell_price_;
  L3_sell_price_;
  L4_sell_price_;
  L5_sell_price_;
  L6_sell_price_;
  L7_sell_price_;
  L8_sell_price_;
  L9_sell_price_;
  L10_sell_price_;
  L11_sell_price_;
  L12_sell_price_;
  L13_sell_price_;
  L14_sell_price_;
  L15_sell_price_;
  L16_sell_price_;
  L17_sell_price_;
  L18_sell_price_;
  L19_sell_price_;
  L20_sell_price_;
  seven_level_perc_;
  fourteen_level_perc_;
  seven_level_min_vst_perc_;
  seven_level_min_perc_;
  seven_level_min_perc_st_;
  seven_level_max_perc_;
  seven_level_max_perc_st_;
  _1_perc_diff_avg_;
  _2_perc_diff_avg_;
  _3_perc_diff_avg_;
  _1_perc_diff_avg_perc_;
  _2_perc_diff_avg_perc_;
  _3_perc_diff_avg_perc_;
  _1_perc_diff_min_perc_;
  _2_perc_diff_min_perc_;
  _3_perc_diff_min_perc_;
  _1_perc_diff_max_perc_;
  _2_perc_diff_max_perc_;
  _3_perc_diff_max_perc_;
  _1_perc_diff_max_;
  _2_perc_diff_max_;
  _3_perc_diff_max_;
  _1_perc_diff_min_;
  _2_perc_diff_min_;
  _3_perc_diff_min_;
  black_wall_perc_;
  big_contracts_perc_;
  big_buyer_contracts_perc_st_;
  big_buyer_contracts_perc_vst_;
  big_seller_contracts_perc_st_;
  big_seller_contracts_perc_vst_;
  big_buyer_contracts_count_;
  big_seller_contracts_count_;
  big_buyer_contracts_vol_;
  big_seller_contracts_vol_;
  big_buyer_seller_delta_;
  big_buyer_seller_diff_;
  big_buyer_seller_diff_perc_;
  candle_perc_;
  active_down_barrier_perc_;
  active_down_barrier_per_;
  active_down_bar_vol_;
  active_down_bar_5_vol_;
  active_down_bar_date_;
  active_up_barrier_perc_;
  active_up_barrier_per_;
  active_up_bar_vol_;
  active_up_bar_5_vol_;
  active_up_bar_date_;
  candle_st_perc_;
  candle_vst_perc_;
  upper_wick_perc_;
  b_upper_wick_;
  upper_wick_st_perc_;
  upper_wick_vst_perc_;
  lower_wick_perc_;
  b_lower_wick_;
  lower_wick_st_perc_;
  lower_wick_vst_perc_;
  body_perc_;
  black_wall_avg_;
  seven_level_avg_;
  fourteen_level_avg_;
  seven_level_avg_vst_;
  seven_level_avg_st_;
  big_contracts_avg_;
  big_delta_;
  demand_supply_candel_;
  resistance_value_;
  strategy_;
  rejected_candle_;
  move_;
  body_move_;
  upper_wick_move_;
  lower_wick_move_;
  top_wick_;
  candel_trends_;
  daily_candel_trends_;
  dpup_trend_direction_;
  next_close_;
  big_drop_;
  big_raise_;
  big_drop_price_;
  big_raise_price_;
  black_dot_up_ressure_;
  seven_level_avg_diff_perc_;
  fourteen_level_avg_diff_perc_;
  black_dot_pressure_u10_;
  ask_bid_volume_;
  ask_bid_volume_b_;
  ask_bid_delta_b_;
  ask_bid_division_b_;
  ask_count_;
  bid_count_;
  coin_meta_count_;
  volume_comparison_;
  ranges_comparison_;
  script_time_;
  tele_positive_sen_;
  tele_negative_sen_;
  tele_neutral_sen_;
  tele_total_sen_;
  telegram_perc_it_;
  telegram_perc_st_;
  telegram_perc_vst_;
  twit_positive_sen_;
  twit_negative_sen_;
  twit_neutral_sen_;
  twit_total_sen_;
  twitter_perc_it_;
  twitter_perc_st_;
  twitter_perc_vst_;
  news_positive_sen_;
  news_negative_sen_;
  news_neutral_sen_;
  news_total_sen_;
  news_perc_it_;
  news_perc_st_;
  news_perc_vst_;
  ask_volume_;
  bid_volume_;
  sevenlevel_max_;
  sevenlevel_min_;
  fourteenlevel_max_;
  fourteenlevel_min_;
  blackwall_max_;
  blackwall_min_;
  t1_max_;
  t1_min_;
  t2_max_;
  t2_min_;
  t4_max_;
  t4_min_;
  rinkos_rm_;
  rinkos_hk_;
  rinkos_spike_buy_;
  rinkos_spike_sell_;
  rinkos_supply_;
  top_wick_prices_;
  ask_bid_delta_;
  bid_delta_;
  t2bcp_max_;
  t2bcp_min_;


  truelevel_type;
  time_length;
  price_length;
  starting_index;
  ending_index;
  increment: number;
  decrement: number;
  search_date: string;
  candleCoordArray = [];
  truelevelCoordArray = [];
  dkboxCoordArray = [];
  demandsupplyCoordArray = [];
  resistanceCoordArray = [];
  strategyCoordArray = [];
  rejectionCoordArray = [];
  moveCoordArray = [];
  bodymoveCoordArray = [];
  upperwickmoveCoordArray = [];
  lowerwickmoveCoordArray = [];
  topwicksignalCoordArray = [];
  candeltrendsCoordArray = [];
  dailycandeltrendsCoordArray = [];
  dpuptrenddirectionCoordArray = [];
  next_closeCoordArray = [];
  dp1CoordArray = [];
  dp2CoordArray = [];
  dp3CoordArray = [];


  dp1btcCoordArray = [];
  dp2btcCoordArray = [];
  dp3btcCoordArray = [];





  increasingvolumeCoordArray = [];
  blackdotuppressureCoordArray = [];
  sevenlevelavgdiffpercCoordArray = [];
  fourteenlevelavgdiffpercCoordArray = [];
  blackdotpressureu10CoordArray = [];
  askbidvolumeCoordArray = [];
  askbidvolumebCoordArray = [];
  askbidcountCoordArray = [];
  coinmetacountCoordArray = [];
  volumecomparisonCoordArray = [];
  rangescomparisonCoordArray = [];
  scripttimeCoordArray = [];
  sevenlevelPercCoordArray = [];
  fourteenlevelPercCoordArray = [];
  sevenlevelMinVSTPercCoordArray = [];
  sevenlevelMinPercCoordArray = [];
  sevenlevelMinPercSTCoordArray = [];
  sevenlevelMaxPercCoordArray = [];
  sevenlevelMaxPercSTCoordArray = [];
  OrderBook1AvergCoordArray = [];
  OrderBook2AvergCoordArray = [];
  OrderBook3AvergCoordArray = [];
  OrderBook1AvergPercCoordArray = [];
  OrderBook2AvergPercCoordArray = [];
  OrderBook3AvergPercCoordArray = [];
  OrderBook1MinPercCoordArray = [];
  OrderBook2MinPercCoordArray = [];
  OrderBook3MinPercCoordArray = [];
  OrderBook1MaxPercCoordArray = [];
  OrderBook2MaxPercCoordArray = [];
  OrderBook3MaxPercCoordArray = [];
  OrderBook1MinMaxCoordArray = [];
  OrderBook2MinMaxCoordArray = [];
  OrderBook3MinMaxCoordArray = [];
  blackwallPercCoordArray = [];
  bigcontractsPercCoordArray = [];
  bigBuyercontractsPercSTCoordArray = [];
  bigBuyercontractsPercVSTCoordArray = [];
  bigSellercontractsPercSTCoordArray = [];
  bigSellercontractsPercVSTCoordArray = [];
  bigBuyerSellercontractsCountCoordArray = [];
  bigBuyerSellercontractsVolCoordArray = [];
  bigBuyerSellerDeltaCoordArray = [];
  bigBuyerSellerDiffCoordArray = [];
  bigBuyerSellerDiffPercCoordArray = [];
  candlePercCoordArray = [];
  activedownbarrierPercCoordArray = [];
  activeupbarrierPercCoordArray = [];
  candleSTPercCoordArray = [];
  candleVSTPercCoordArray = [];
  twowaybarCoordArray = [];
  blackwallAverageCoordArray = [];
  sevenlevelAverageCoordArray = [];
  fourteenlevelAverageCoordArray = [];
  sevenlevelAverageSTCoordArray = [];
  sevenlevelAverageVSTCoordArray = [];
  bigcontractsAverageCoordArray = [];
  upperwickPercCoordArray = [];
  bupperwickCoordArray = [];
  upperwickSTPercCoordArray = [];
  upperwickVSTPercCoordArray = [];
  lowerwickPercentileCoordArray = [];
  blowerwickCoordArray = [];
  lowerwickSTPercentileCoordArray = [];
  lowerwickVSTPercentileCoordArray = [];
  bodyPercentileCoordArray = [];
  sevenlevelMinMaxCoordArray = [];
  fourteenlevelMinMaxCoordArray = [];
  blackwallMinMaxCoordArray = [];
  bigdeltaCoordArray = [];
  telegramsentimentCoordArray = [];
  telegramITPercentileCoordArray = [];
  telegramSTPercentileCoordArray = [];
  telegramVSTPercentileCoordArray = [];
  twittersentimentCoordArray = [];
  twitterITPercentileCoordArray = [];
  twitterSTPercentileCoordArray = [];
  twitterVSTPercentileCoordArray = [];
  newssentimentCoordArray = [];
  newsITPercentileCoordArray = [];
  newsSTPercentileCoordArray = [];
  newsVSTPercentileCoordArray = [];
  t1CoordArray = [];
  t2CoordArray = [];
  t4CoordArray = [];
  askBidDeltaCoordArray = [];
  askBidDeltabCoordArray = [];
  bidDeltaCoordArray = [];
  askbiddivisionbCoordArray = [];
  t2bcpCoordArray = [];
  lineArray = [];
  pricelineArray = [];
  s_width: number = 0;
  topheader_height;
  maincharthead_height;
  componenthead_height;
  tooltip_height;
  // candlestick_height = 0;
  ondown_x = 0;
  onup_x = 0;
  x_diff;
  top_coord;
  left_coord;
  tooltip_coord;
  scrollTop = 0;
  scroll;
  before_length: any = null;
  after_length: any = null;
  before_candle_width: any = null;
  after_candle_width: any = null;
  ondown_starting;
  ondown_ending;
  ondown_maxprice;
  onup_maxprice;
  ondown_minprice;
  onup_minprice;
  price;
  onenter_maxprice;
  onenter_minprice;
  onkeyup_maxprice;
  onkeyup_minprice;
  onkeydown_maxprice;
  onkeydown_minprice;
  onovercand_maxprice;
  onovercand_minprice;
  showTooltip: boolean = false;
  showDemandSupplyTooltip: boolean = false;
  showResistanceTooltip: boolean = false;
  showStrategyTooltip: boolean = false;
  showRejectionTooltip: boolean = false;
  showMoveTooltip: boolean = false;
  showBodyMoveTooltip: boolean = false;
  showUpperWickMoveTooltip: boolean = false;
  showLowerWickMoveTooltip: boolean = false;
  showTopWickSignalTooltip: boolean = false;
  showCandelTrendsTooltip: boolean = false;
  showDailyCandelTrendsTooltip: boolean = false;
  showDPUPTrendDirectionTooltip: boolean = false;
  showNextCloseTooltip: boolean = false;
  showDP1Tooltip: boolean = false;
  showDP2Tooltip: boolean = false;
  showDP3Tooltip: boolean = false;
  showDP1BTCTooltip: boolean = false;
  showDP2BTCTooltip: boolean = false;
  showDP3BTCTooltip: boolean = false;
  showBigRaiseDropTooltip: boolean = false;
  showBlackDotUpPressureTooltip: boolean = false;
  showSevenLevelAvgDiffPercTooltip: boolean = false;
  showFourteenLevelAvgDiffPercTooltip: boolean = false;
  showBlackDotPressureU10Tooltip: boolean = false;
  showAskBidVolumeTooltip: boolean = false;
  showAskBidVolumeBTooltip: boolean = false;
  showAskBidCountTooltip: boolean = false;
  showCoinMetaCountTooltip: boolean = false;
  showVolumeComparisonTooltip: boolean = false;
  showRangesComparisonTooltip: boolean = false;
  showScriptTimeTooltip: boolean = false;
  showTruelevelTooltip: boolean = false;
  // Nothing in My Mind About this
  showBigDeltaTooltip
  // Nothing in My Mind About this
  showDKBoxeTooltip: boolean = false;
  showSevenlevelPercTooltip: boolean = false;
  showFourteenlevelPercTooltip: boolean = false;
  showSevenlevelMinVSTPercTooltip: boolean = false;
  showSevenlevelMinPercTooltip: boolean = false;
  showSevenlevelMinPercSTTooltip: boolean = false;
  showSevenlevelMaxPercTooltip: boolean = false;
  showSevenlevelMaxPercSTTooltip: boolean = false;
  showBlackwallPercTooltip: boolean = false;
  showBigcontractsPercTooltip: boolean = false;
  showBigBuyercontractsPercSTTooltip: boolean = false;
  showBigBuyercontractsPercVSTTooltip: boolean = false;
  showBigSellercontractsPercSTTooltip: boolean = false;
  showBigSellercontractsPercVSTTooltip: boolean = false;
  showBigBuyerSellercontractsCountTooltip: boolean = false;
  showBigBuyerSellercontractsVolTooltip: boolean = false;
  showBigBuyerSellerDeltaTooltip: boolean = false;
  showBigBuyerSellerDiffTooltip: boolean = false;
  showBigBuyerSellerDiffPercTooltip: boolean = false;
  showCandlePercTooltip: boolean = false;
  showActiveDownBarrierPercTooltip: boolean = false;
  showActiveUpBarrierPercTooltip: boolean = false;
  showCandleSTPercTooltip: boolean = false;
  showCandleVSTPercTooltip: boolean = false;
  showTwoWayBarTooltip: boolean = false;
  showBlackwallAvgTooltip: boolean = false;
  showSevenlevelAvgTooltip: boolean = false;
  showFourteenlevelAvgTooltip: boolean = false;
  showSevenlevelAvgSTTooltip: boolean = false;
  showSevenlevelAvgVSTTooltip: boolean = false;
  showOrderBook1AvergTooltip: boolean = false;
  showOrderBook2AvergTooltip: boolean = false;
  showOrderBook3AvergTooltip: boolean = false;
  showOrderBook1AvergPercTooltip: boolean = false;
  showOrderBook2AvergPercTooltip: boolean = false;
  showOrderBook3AvergPercTooltip: boolean = false;
  showOrderBook1MinPercTooltip: boolean = false;
  showOrderBook2MinPercTooltip: boolean = false;
  showOrderBook3MinPercTooltip: boolean = false;
  showOrderBook1MaxPercTooltip: boolean = false;
  showOrderBook2MaxPercTooltip: boolean = false;
  showOrderBook3MaxPercTooltip: boolean = false;
  showOrderBook1MinMaxTooltip: boolean = false;
  showOrderBook2MinMaxTooltip: boolean = false;
  showOrderBook3MinMaxTooltip: boolean = false;
  showBigcontractsAvgTooltip: boolean = false;
  showUpperwickPercTooltip: boolean = false;
  showBUpperwickTooltip: boolean = false;
  showUpperwickSTPercTooltip: boolean = false;
  showUpperwickVSTPercTooltip: boolean = false;
  showLowerwickPercTooltip: boolean = false;
  showBLowerwickTooltip: boolean = false;
  showLowerwickSTPercTooltip: boolean = false;
  showLowerwickVSTPercTooltip: boolean = false;
  showBodyPercTooltip: boolean = false;
  showSevenlevelMinMaxTooltip: boolean = false;
  showFourteenlevelMinMaxTooltip: boolean = false;
  showBlackwallMinMaxTooltip: boolean = false;
  showTelegramTooltip: boolean = false;
  showTelegramITPerctooltip: boolean = false;
  showTelegramSTPerctooltip: boolean = false;
  showTelegramVSTPerctooltip: boolean = false;
  showTwitterTooltip: boolean = false;
  showTwitterITPerctooltip: boolean = false;
  showTwitterSTPerctooltip: boolean = false;
  showTwitterVSTPerctooltip: boolean = false;
  showNewsTooltip: boolean = false;
  showNewsITPerctooltip: boolean = false;
  showNewsSTPerctooltip: boolean = false;
  showNewsVSTPerctooltip: boolean = false;
  showT1Tooltip: boolean = false;
  showT2Tooltip: boolean = false;
  showT4Tooltip: boolean = false;
  showAskBidDeltaTooltip: boolean = false;
  showAskBidDeltaBTooltip: boolean = false;
  showBidDeltaTooltip: boolean = false;
  showAskBidDivisionBTooltip: boolean = false;
  showT2BCPTooltip: boolean = false;
  show_forward: boolean = true;
  show_backward: boolean = true;
  showCandleSticks: boolean = true;
  showTrueLevels: boolean = false;
  showBlackWallPerc: boolean = false;
  showSevenLevelPerc: boolean = false;
  showFourteenLevelPerc: boolean = false;
  showSevenLevelMinVSTPerc: boolean = false;
  showSevenLevelMinPerc: boolean = false;
  showSevenLevelMinPercST: boolean = false;
  showSevenLevelMaxPerc: boolean = false;
  showSevenLevelMaxPercST: boolean = false;
  showOrderBook1Averg: boolean = false;
  showOrderBook2Averg: boolean = false;
  showOrderBook3Averg: boolean = false;
  showOrderBook1AvergPerc: boolean = false;
  showOrderBook2AvergPerc: boolean = false;
  showOrderBook3AvergPerc: boolean = false;
  showOrderBook1MinPerc: boolean = false;
  showOrderBook2MinPerc: boolean = false;
  showOrderBook3MinPerc: boolean = false;
  showOrderBook1MaxPerc: boolean = false;
  showOrderBook2MaxPerc: boolean = false;
  showOrderBook3MaxPerc: boolean = false;
  showOrderBook1MinMax: boolean = false;
  showOrderBook2MinMax: boolean = false;
  showOrderBook3MinMax: boolean = false;
  showBigContractsPerc: boolean = false;
  showBigBuyerContractsPercST: boolean = false;
  showBigBuyerContractsPercVST: boolean = false;
  showBigSellerContractsPercST: boolean = false;
  showBigSellerContractsPercVST: boolean = false;
  showBigBuyerSellerContractsCount: boolean = false;
  showBigBuyerSellerContractsVol: boolean = false;
  showBigBuyerSellerDelta: boolean = false;
  showBigBuyerSellerDiff: boolean = false;
  showBigBuyerSellerDiffPerc: boolean = false;
  showTwoWayBar: boolean = false;
  showCandlePerc: boolean = false;
  showActiveDownBarrierPerc: boolean = false;
  showActiveUpBarrierPerc: boolean = false;
  showCandleSTPerc: boolean = false;
  showCandleVSTPerc: boolean = false;
  showSevenLevel: boolean = false;
  showFourteenLevelAvg: boolean = false;
  showSevenLevelST: boolean = false;
  showSevenLevelVST: boolean = false;
  showBlackWallAverg: boolean = false;
  showBigContractsAverg: boolean = false;
  showUpperWick: boolean = false;
  showBUpperWick: boolean = false;
  showUpperWickST: boolean = false;
  showUpperWickVST: boolean = false;
  showLowerWick: boolean = false;
  showBLowerWick: boolean = false;
  showLowerWickST: boolean = false;
  showLowerWickVST: boolean = false;
  showBodyPercentile: boolean = false;
  showDemandSupply: boolean = false;
  showResistance: boolean = false;
  showStrategy: boolean = false;
  showRejection: boolean = false;
  showMove: boolean = false;
  showBodyMove: boolean = false;
  showUpperWickMove: boolean = false;
  showLowerWickMove: boolean = false;
  showTopWickSignal: boolean = false;
  showCandelTrends: boolean = false;
  showDailyCandelTrends: boolean = false;
  showDPUPTrendDirection: boolean = false;
  showNextClose: boolean = false;
  showDPBTC: boolean = false;
  showDP: boolean = false;
  showIncreasingVolume: boolean = false;
  showBlackDotUpPressure: boolean = false;
  showSevenLevelAvgDiffPerc: boolean = false;
  showFourteenLevelAvgDiffPerc: boolean = false;
  showBlackDotPressureU10: boolean = false;
  showAskBidVolume: boolean = false;
  showAskBidVolumeB: boolean = false;
  showCoinMetaCount: boolean = false;
  showVolumeComparison: boolean = false;
  showRangesComparison: boolean = false;
  showScriptTime: boolean = false;
  showSevenLevelMinMax: boolean = false;
  showFourteenLevelMinMax: boolean = false;
  showBlackWallMinMax: boolean = false;
  showT3MinMax: boolean = false;
  showT2MinMax: boolean = false;
  showT4MinMax: boolean = false;
  showTelegramSentiment: boolean = false;
  showTelegramITPercentile: boolean = false;
  showTelegramSTPercentile: boolean = false;
  showTelegramVSTPercentile: boolean = false;
  showTwitterSentiment: boolean = false;
  showTwitterITPercentile: boolean = false;
  showTwitterSTPercentile: boolean = false;
  showTwitterVSTPercentile: boolean = false;
  showNewsSentiment: boolean = false;
  showNewsITPercentile: boolean = false;
  showNewsSTPercentile: boolean = false;
  showNewsVSTPercentile: boolean = false;
  showBoxes: boolean = false;
  showAskBidDelta: boolean = false;
  showAskBidCount: boolean = false;
  showAskBidDeltaB: boolean = false;
  showBidDelta: boolean = false;
  showAskBidDivisionB: boolean = false;
  showT2BCP: boolean = false;
  popUpOnCandle: boolean = false;
  popUpOnCandleStick: boolean = false;
  candleScroller: boolean = false;
  filter_all: boolean = false;
  filter_1: boolean = false;
  show_all_chart: boolean = false;
  showSevellevelChart: boolean = false;
  filter_2: boolean = false;
  filter_3: boolean = false;
  filter_4: boolean = false;
  filter_5: boolean = false;
  filter_6: boolean = false;
  filter_7: boolean = false;
  openFilterFooter: boolean = false;
  showPriceLine: boolean = false;
  ChartMenuData = [];
  x_c_gap = 20;
  y_c_gap = 20;
  deduction = 20;
  coord = 0;
  role;
  adminrole = 'admin';
  userrole = 'user';
  developerrole = 'developer';
  showtoadmin: boolean = false;
  showtouser: boolean = false;
  showtodeveloper: boolean = false;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////              Variables End           ///////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////


  GLOBALEXCHANGETYPE = localStorage.getItem('GLOBALEXCHANGETYPE')

  constructor(
    private service: MainChartService,
    private route: ActivatedRoute,
    private router: Router,
    private ShareddataService :ShareddataService,
    private ApicallsService: ApicallsService,
    private permissionsService: NgxPermissionsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {
    ShareddataService.globalValueChanged.subscribe(receiveData => this.globalValueChanged(receiveData));

    this.listCurrentUserCoins()


    this.route.params.subscribe(val => {
      this.ngOnInit();
    });
  }


  private globalValueChanged(receiveData): void {
    if(receiveData['type'] == 'coin'){
        this.GLOBALCOIN = receiveData['coin'];
    }else if(receiveData['type'] == 'exchange'){
        this.GLOBALEXCHANGETYPE = receiveData['exchange'];
    }
  }//End of globalValueChanged


  userCoinsArr
  listCurrentUserCoins(){





    this.ApicallsService.coinData.subscribe(data => {
      if(typeof data['message'] !== 'undefined'){
        var i = 0;
        this.userCoinsArr = [];
        for (let key = 0; key< data['message'].length; key++) {
            if (data['message'][key].exchange_type == this.GLOBALEXCHANGETYPE) {
                this.userCoinsArr[i++] = data['message'][key];
            }
        }
      }
    });

    // this.ApicallsService.listCurrentUserCoins('id', '')
    // .subscribe(
    //     data => {
    //       var i = 0;
    //       this.userCoinsArr = [];
    //       for (let key = 0; key< data['message'].length; key++) {
    //           if (data['message'][key].exchange_type == this.GLOBALEXCHANGETYPE) {
    //               this.userCoinsArr[i++] = data['message'][key];
    //           }
    //       }
    //       if(this.userCoinsArr.length>0){
    //           this.GLOBALCOIN = this.route.snapshot.paramMap.get('coin');
    //       }


    //       console.log(this.userCoinsArr, '==================')

    //     },
    //     error => {
    //         console.log(error)
    //     });
}//End of listCurrentUserCoins


  //ng OnInit Start
  mouseWheelUpFunc() {
    // console.log('upward')
    if (this.candleScroller) {
      if (this.time_length < 169) {
        this.time_length = this.time_length + 4;
        this.lineArray = [];
        if (this.time_length > 168) {
          this.time_length = 168;
          this.starting_index = 0;
          this.ending_index = 168;
        }
        this.changearrayValue();
      }
    }
  }

  mouseWheelDownFunc() {
    // console.log('downward')
    if (this.candleScroller) {
      if (this.time_length >= 27) {
        this.time_length = this.time_length - 4;
        this.lineArray = [];
        this.changearrayValue();
      }
    }
  }
  async ngOnInit() {
    var url = window.location.href;
    var location = decodeURI(url);
    var new_url = new URL(location);
    var date_param = new_url.searchParams.get("date");
    this.number = (this.router.url).includes("date");
    if (this.number == true) {
      var date = date_param;
      const selecteddate = date;
      var utcDate2 = new Date(selecteddate);
      utcDate2.setDate(utcDate2.getDate()-3);
      this.href = (utcDate2.getFullYear() + '-' + (utcDate2.getMonth() + 1) + '-' + utcDate2.getDate() + ' ' + utcDate2.getHours() +':'+'00'+':'+ '00');
    } else {
      this.href = "";
      if(localStorage.getItem('fwd_date')){
        this.href = localStorage.getItem('fwd_date')
      }
    }






    const perm = JSON.parse(localStorage.getItem('loggedUserName'));
    this.permissionsService.loadPermissions([perm]);



    // Add Permission then Load and Then Assign to Role like User, Admin, Umer
    // this.permissionsService.loadPermissions(["canEdit", "canDelete"]);
    // this.rolesService.addRole("UMER", ["canEdit", "canDelete"]);



    /* Current Coin */
    if (!localStorage.getItem('loggedUserRole')) {
      localStorage.removeItem('currentUserNew');
      window.location.reload();
    } else {
      this.role = JSON.parse(localStorage.getItem('loggedUserRole'));
      // console.log(this.role);
    }


    if (this.role == this.adminrole) {
      this.showtoadmin = true;
      this.showtouser = false;
      this.showtodeveloper = false;
    } else if (this.role == this.userrole) {
      this.showtoadmin = false;
      this.showtouser = true;
      this.showtodeveloper = false;
    } else if (this.role == this.developerrole) {
      this.showtoadmin = false;
      this.showtouser = false;
      this.showtodeveloper = true;
    }
    this.currentCoin = this.route.snapshot.paramMap.get('coin');
    if (this.currentCoin == null) {
      this.currentCoin = "XRPBTC";
    }
    /* Reload Page... */
    if (!localStorage.getItem('refresh_flag')) {
      localStorage.setItem('refresh_flag', 'true');
      localStorage.setItem('currentCoin', this.currentCoin);
      window.location.reload();
    }

    this.dataLoaderWaiting = true
    this.showCandleSticks = false;
    this.hide_all_filters();
    this.service.canvasData.subscribe(data => {
      this.general_array = data;
    });
    let back_date = "";
    let fwd_date = this.href;
    if(this.href == ""){
      if(localStorage.getItem('back_date')){
        back_date = localStorage.getItem('back_date')
      }
    }
    console.log(fwd_date, back_date)
    // this.service.getCandleChart1(this.currentCoin, back_date, fwd_date);
    // await this.service.getCandleChart(this.currentCoin, back_date, fwd_date).subscribe(data => {
    var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
      this.cnd_array = data;
      console.log('Array', this.cnd_array)
      this.time_length = this.general_array[0].time_length;
      this.truelevel_type = this.general_array[0].truelevel_type;
      this.price_length = this.general_array[0].price_length;
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.cnd_array_length = this.cnd_array.length;
      this.increment = this.general_array[0].increment;
      this.decrement = this.general_array[0].decrement;

      this.dataLoaderWaiting = false
      if (localStorage.getItem('setting_arrr_main_chart')) {
        this.getcomponentstate();
      } else {
        this.popUpOnCandle = true;
        this.popUpOnCandleStick = true;
        this.candleScroller = false;
        this.showCandleSticks = true;
        this.showTrueLevels = true;
        this.hide_all_filters();
        this.setcomponentstate();
      }
    // });
    // service for candlesticks
    this.service.candlestickcoordData.subscribe(data => {
      this.candleCoordArray = data;
    });
    // service for truelevels
    this.service.truelevelcoordData.subscribe(data => {
      this.truelevelCoordArray = data;
    });
    // service for dkboxes
    this.service.dkboxcoordData.subscribe(data => {
      this.dkboxCoordArray = data;
    });
    //service for demand supply
    this.service.demandsupplycoordData.subscribe(data => {
      this.demandsupplyCoordArray = data;
    });
    //service for resistance
    this.service.resistancecoordData.subscribe(data => {
      this.resistanceCoordArray = data;
    });
    //service for strategy
    this.service.strategycoordData.subscribe(data => {
      this.strategyCoordArray = data;
    });
    //service for rejection
    this.service.rejectioncoordData.subscribe(data => {
      this.rejectionCoordArray = data;
    });
    //service for move
    this.service.movecoordData.subscribe(data => {
      this.moveCoordArray = data;
    });
    //service for body move
    this.service.bodymovecoordData.subscribe(data => {
      this.bodymoveCoordArray = data;
    });
    //service for upperwick move
    this.service.upperwickmovecoordData.subscribe(data => {
      this.upperwickmoveCoordArray = data;
    });
    //service for lowerwick move
    this.service.lowerwickmovecoordData.subscribe(data => {
      this.lowerwickmoveCoordArray = data;
    });
    //service for topwick
    this.service.topwicksignalcoordData.subscribe(data => {
      this.topwicksignalCoordArray = data;
    });
    //service for candletrends
    this.service.candeltrendscoordData.subscribe(data => {
      this.candeltrendsCoordArray = data;
    });
    //service for dailycandeltrends
    this.service.dailycandeltrendscoordData.subscribe(data => {
      this.dailycandeltrendsCoordArray = data;
    });
    //service for dpuptrenddirection
    this.service.dpuptrenddirectioncoordData.subscribe(data => {
      this.dpuptrenddirectionCoordArray = data;
    });
    //service for next_close
    this.service.next_closecoordData.subscribe(data => {
      this.next_closeCoordArray = data;
    });
    //service for dp1
    this.service.dp_up1coordData.subscribe(data => {
      this.dp1CoordArray = data;
    });
    //service for dp2
    this.service.dp_up2coordData.subscribe(data => {
      this.dp2CoordArray = data;
    });
    //service for dp3
    this.service.dp_up3coordData.subscribe(data => {
      this.dp3CoordArray = data;
    });



    //service for dp1_btc
    this.service.dp_up1_btccoordData.subscribe(data => {
      this.dp1btcCoordArray = data;
    });
    //service for dp2_btc
    this.service.dp_up2_btccoordData.subscribe(data => {
      this.dp2btcCoordArray = data;
    });
    //service for dp3_btc
    this.service.dp_up3_btccoordData.subscribe(data => {
      this.dp3btcCoordArray = data;
    });



    //service for raise drop
    this.service.increasingcoordData.subscribe(data => {
      this.increasingvolumeCoordArray = data;
    });
    //service for black dot up pressure
    this.service.blackdotupcoordData.subscribe(data => {
      this.blackdotuppressureCoordArray = data;
    });
    //service for sevenlevelavgdiffperc
    this.service.sevenlevelavgdiffperccoordData.subscribe(data => {
      this.sevenlevelavgdiffpercCoordArray = data;
    });
    //service for fourteenlevelavgdiffperc
    this.service.fourteenlevelavgdiffperccoordData.subscribe(data => {
      this.fourteenlevelavgdiffpercCoordArray = data;
    });
    //service for black dot pressure u10
    this.service.blackdotpressure_u10coordData.subscribe(data => {
      this.blackdotpressureu10CoordArray = data;
    });
    //service for ask bid volume
    this.service.askbidvolumecoordData.subscribe(data => {
      this.askbidvolumeCoordArray = data;
    });
    //service for ask bid volumeb
    this.service.askbidvolumebcoordData.subscribe(data => {
      this.askbidvolumebCoordArray = data;
    });
    //service for ask bid count
    this.service.askbidcountcoordData.subscribe(data => {
      this.askbidcountCoordArray = data;
    });
    //service for coinmetacount
    this.service.coinmetacountcoordData.subscribe(data => {
      this.coinmetacountCoordArray = data;
    });
    //service for volume comparison
    this.service.volumecomparisoncoordData.subscribe(data => {
      this.volumecomparisonCoordArray = data;
    });
    //service for ranges comparison
    this.service.rangescomparisoncoordData.subscribe(data => {
      this.rangescomparisonCoordArray = data;
    });
    //service for script time
    this.service.scripttimecoordData.subscribe(data => {
      this.scripttimeCoordArray = data;
    });
    // service for black wall percentile
    this.service.blackwallperccoordData.subscribe(data => {
      this.blackwallPercCoordArray = data;
    });
    //service for seven level Percentile
    this.service.sevenlevelperccoordData.subscribe(data => {
      this.sevenlevelPercCoordArray = data;
    });
    //service for fourteen level Percentile
    this.service.fourteenlevelperccoordData.subscribe(data => {
      this.fourteenlevelPercCoordArray = data;
    });
    //service for seven level min VST Percentile
    this.service.sevenlevelminvstperccoordData.subscribe(data => {
      this.sevenlevelMinVSTPercCoordArray = data;
    });
    //service for seven level min Percentile
    this.service.sevenlevelminperccoordData.subscribe(data => {
      this.sevenlevelMinPercCoordArray = data;
    });
    //service for seven level min Percentile ST
    this.service.sevenlevelminpercstcoordData.subscribe(data => {
      this.sevenlevelMinPercSTCoordArray = data;
    });
    //service for seven level max Percentile
    this.service.sevenlevelmaxperccoordData.subscribe(data => {
      this.sevenlevelMaxPercCoordArray = data;
    });
    //service for seven level max Percentile ST
    this.service.sevenlevelmaxpercstcoordData.subscribe(data => {
      this.sevenlevelMaxPercSTCoordArray = data;
    });
    //service for Order Book 1 Avg
    this.service.orderbook_1_perc_diff_avgcoordData.subscribe(data => {
      this.OrderBook1AvergCoordArray = data;
    });
    //service for Order Book 2 Avg
    this.service.orderbook_2_perc_diff_avgcoordData.subscribe(data => {
      this.OrderBook2AvergCoordArray = data;
    });
    //service for Order Book 3 Avg
    this.service.orderbook_3_perc_diff_avgcoordData.subscribe(data => {
      this.OrderBook3AvergCoordArray = data;
    });
    //service for Order Book 1 Avg Perc
    this.service.orderbook_1_perc_diff_avg_perccoordData.subscribe(data => {
      this.OrderBook1AvergPercCoordArray = data;
    });
    //service for Order Book 2 Avg Perc
    this.service.orderbook_2_perc_diff_avg_perccoordData.subscribe(data => {
      this.OrderBook2AvergPercCoordArray = data;
    });
    //service for Order Book 3 Avg Perc
    this.service.orderbook_3_perc_diff_avg_perccoordData.subscribe(data => {
      this.OrderBook3AvergPercCoordArray = data;
    });
    //service for Order Book 1 Min Perc
    this.service.orderbook1minperccoordData.subscribe(data => {
      this.OrderBook1MinPercCoordArray = data;
    });
    //service for Order Book 2 Min Perc
    this.service.orderbook2minperccoordData.subscribe(data => {
      this.OrderBook2MinPercCoordArray = data;
    });
    //service for Order Book 3 Min Perc
    this.service.orderbook3minperccoordData.subscribe(data => {
      this.OrderBook3MinPercCoordArray = data;
    });
    //service for Order Book 1 Max Perc
    this.service.orderbook1maxperccoordData.subscribe(data => {
      this.OrderBook1MaxPercCoordArray = data;
    });
    //service for Order Book 2 Max Perc
    this.service.orderbook2maxperccoordData.subscribe(data => {
      this.OrderBook2MaxPercCoordArray = data;
    });
    //service for Order Book 3 Max Perc
    this.service.orderbook3maxperccoordData.subscribe(data => {
      this.OrderBook3MaxPercCoordArray = data;
    });
    //service for Order Book 1 Min Max
    this.service.orderbook1minmaxcoordData.subscribe(data => {
      this.OrderBook1MinMaxCoordArray = data;
    });
    //service for Order Book 2 Min Max
    this.service.orderbook2minmaxcoordData.subscribe(data => {
      this.OrderBook2MinMaxCoordArray = data;
    });
    //service for Order Book 3 Min Max
    this.service.orderbook3minmaxcoordData.subscribe(data => {
      this.OrderBook3MinMaxCoordArray = data;
    });
    //service for bigcontracts percentile
    this.service.bigcontractsperccoordData.subscribe(data => {
      this.bigcontractsPercCoordArray = data;
    });
    //service for bigbuyercontracts percentile vst
    this.service.bigbuyercontractspercvstcoordData.subscribe(data => {
      this.bigBuyercontractsPercVSTCoordArray = data;
    });
    //service for bigbuyercontracts percentile st
    this.service.bigbuyercontractspercstcoordData.subscribe(data => {
      this.bigBuyercontractsPercSTCoordArray = data;
    });
    //service for bigsellercontracts percentile vst
    this.service.bigsellercontractspercvstcoordData.subscribe(data => {
      this.bigSellercontractsPercVSTCoordArray = data;
    });
    //service for bigsellercontracts percentile st
    this.service.bigsellercontractspercstcoordData.subscribe(data => {
      this.bigSellercontractsPercSTCoordArray = data;
    });
    //service for bigsellercontracts Count
    this.service.bigbuyersellercontractscountcoordData.subscribe(data => {
      this.bigBuyerSellercontractsCountCoordArray = data;
    });
    //service for bigsellercontracts Vol
    this.service.bigbuyersellercontractsvolcoordData.subscribe(data => {
      this.bigBuyerSellercontractsVolCoordArray = data;
    });
    //service for big buyer seller delta
    this.service.bigbuyersellerdeltacoordData.subscribe(data => {
      this.bigBuyerSellerDeltaCoordArray = data;
    });
    //service for big buyer seller diff
    this.service.bigbuyersellerdiffcoordData.subscribe(data => {
      this.bigBuyerSellerDiffCoordArray = data;
    });
    //service for big buyer seller diff perc
    this.service.bigbuyersellerdiffperccoordData.subscribe(data => {
      this.bigBuyerSellerDiffPercCoordArray = data;
    });
    //service for candle percentile
    this.service.candleperccoordData.subscribe(data => {
      this.candlePercCoordArray = data;
    });
    //service for activedownbarrier percentile
    this.service.activedownbarrierperccoordData.subscribe(data => {
      this.activedownbarrierPercCoordArray = data;
    });
    //service for activeupbarrier percentile
    this.service.activeupbarrierperccoordData.subscribe(data => {
      this.activeupbarrierPercCoordArray = data;
    });
    //service for candle ST percentile
    this.service.candlestperccoordData.subscribe(data => {
      this.candleSTPercCoordArray = data;
    });
    //service for candle VST percentile
    this.service.candlevstperccoordData.subscribe(data => {
      this.candleVSTPercCoordArray = data;
    });
    //service for Two Way Bar
    this.service.twowaybarccoordData.subscribe(data => {
      this.twowaybarCoordArray = data;
    });
    //service for Blackwall average
    this.service.blackwallavgcoordData.subscribe(data => {
      this.blackwallAverageCoordArray = data;
    });
    //service for sevenlevel average
    this.service.sevenlevelavgcoordData.subscribe(data => {
      this.sevenlevelAverageCoordArray = data;
    });
    //service for fourteenlevel average
    this.service.fourteenlevelavgcoordData.subscribe(data => {
      this.fourteenlevelAverageCoordArray = data;
    });
    //service for sevenlevel average vst
    this.service.sevenlevelavgvstcoordData.subscribe(data => {
      this.sevenlevelAverageVSTCoordArray = data;
    });
    //service for sevenlevel average st
    this.service.sevenlevelavgstcoordData.subscribe(data => {
      this.sevenlevelAverageSTCoordArray = data;
    });
    //service for bigcontracts average
    this.service.bigcontractsavgcoordData.subscribe(data => {
      this.bigcontractsAverageCoordArray = data;
    });
    //service for upperwick percentile
    this.service.upperwickperccoordData.subscribe(data => {
      this.upperwickPercCoordArray = data;
    });
    //service for bupperwick
    this.service.bupperwickcoordData.subscribe(data => {
      this.bupperwickCoordArray = data;
    });
    //service for upperwick st percentile
    this.service.upperwickstperccoordData.subscribe(data => {
      this.upperwickSTPercCoordArray = data;
    });
    //service for upperwick vst percentile
    this.service.upperwickvstperccoordData.subscribe(data => {
      this.upperwickVSTPercCoordArray = data;
    });
    //service for lowerwick percentile
    this.service.lowerwickperccoordData.subscribe(data => {
      this.lowerwickPercentileCoordArray = data;
    });
    //service for blowerwick
    this.service.blowerwickcoordData.subscribe(data => {
      this.blowerwickCoordArray = data;
    });
    //service for lowerwick st percentile
    this.service.lowerwickstperccoordData.subscribe(data => {
      this.lowerwickSTPercentileCoordArray = data;
    });
    //service for lowerwick vst percentile
    this.service.lowerwickvstperccoordData.subscribe(data => {
      this.lowerwickVSTPercentileCoordArray = data;
    });
    //service for Body percentile
    this.service.bodyperccoordData.subscribe(data => {
      this.bodyPercentileCoordArray = data;
    });
    //service for SevenLevel MinMax
    this.service.sevenlevelminmaxcoordData.subscribe(data => {
      this.sevenlevelMinMaxCoordArray = data;
    });
    //service for fourteenLevel MinMax
    this.service.fourteenlevelminmaxcoordData.subscribe(data => {
      this.fourteenlevelMinMaxCoordArray = data;
    });
    //service for Blackwall MinMax
    this.service.blackwallminmaxcoordData.subscribe(data => {
      this.blackwallMinMaxCoordArray = data;
    });
    //service for bigdelta
    this.service.bigdeltacoordData.subscribe(data => {
      this.bigdeltaCoordArray = data;
    });
    //service for telegram
    this.service.telegramcoordData.subscribe(data => {
      this.telegramsentimentCoordArray = data;
    });
    //service for telegramit
    this.service.telegramitperccoordData.subscribe(data => {
      this.telegramITPercentileCoordArray = data;
    });
    //service for telegramst
    this.service.telegramstperccoordData.subscribe(data => {
      this.telegramSTPercentileCoordArray = data;
    });
    //service for telegramvst
    this.service.telegramvstperccoordData.subscribe(data => {
      this.telegramVSTPercentileCoordArray = data;
    });
    //service for twitter
    this.service.twittercoordData.subscribe(data => {
      this.twittersentimentCoordArray = data;
    });
    //service for twitterit
    this.service.twitteritperccoordData.subscribe(data => {
      this.twitterITPercentileCoordArray = data;
    });
    //service for twitterst
    this.service.twitterstperccoordData.subscribe(data => {
      this.twitterSTPercentileCoordArray = data;
    });
    //service for twittervst
    this.service.twittervstperccoordData.subscribe(data => {
      this.twitterVSTPercentileCoordArray = data;
    });
    //service for news
    this.service.newscoordData.subscribe(data => {
      this.newssentimentCoordArray = data;
    });
    //service for newsit
    this.service.newsitperccoordData.subscribe(data => {
      this.newsITPercentileCoordArray = data;
    });
    //service for newsst
    this.service.newsstperccoordData.subscribe(data => {
      this.newsSTPercentileCoordArray = data;
    });
    //service for newsvst
    this.service.newsvstperccoordData.subscribe(data => {
      this.newsVSTPercentileCoordArray = data;
    });
    //service for t1
    this.service.t1coordData.subscribe(data => {
      this.t1CoordArray = data;
    });
    //service for t2
    this.service.t2coordData.subscribe(data => {
      this.t2CoordArray = data;
    });
    //service for t4
    this.service.t4coordData.subscribe(data => {
      this.t4CoordArray = data;
    });
    //service for askbiddelta
    this.service.askbiddeltacoordData.subscribe(data => {
      this.askBidDeltaCoordArray = data;
    });
    //service for askbiddeltab
    this.service.askbiddeltabcoordData.subscribe(data => {
      this.askBidDeltabCoordArray = data;
    });
    //service for biddelta
    this.service.biddeltacoordData.subscribe(data => {
      this.bidDeltaCoordArray = data;
    });
    //service for askbiddivisionb
    this.service.askbiddivisionbcoordData.subscribe(data => {
      this.askbiddivisionbCoordArray = data;
    });
    //service for t2bcpaskbidvol
    this.service.t2bcpaskbidvolcoordData.subscribe(data => {
      this.t2bcpCoordArray = data;
    });
  }//OnInit End here



  async show_backward_c() {
    this.setcomponentstate();
    this.dataLoaderWaiting = true
    this.hide_all_filters();
    this.showCandleSticks = false;
    this.service.canvasData.subscribe(data => {
      this.general_array = data;
    });
    let back_date = this.cnd_array[0].openTime_human_readible;
    let fwd_date = "";
    localStorage.setItem('back_date',back_date)
    this.removeAllLines();
    var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
    this.cnd_array = data;
    this.time_length = this.general_array[0].time_length;
    this.truelevel_type = this.general_array[0].truelevel_type;
    this.price_length = this.general_array[0].price_length;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.cnd_array_length = this.cnd_array.length;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;

    this.dataLoaderWaiting = false;
    if (localStorage.getItem('setting_arrr_main_chart')) {
      this.getcomponentstate();
    } else {
      this.showCandleSticks = true;
      this.hide_all_filters();
    }
  }
  async show_forward_f() {
    var utcDate1 = new Date();
    var utcdate = Date.UTC(utcDate1.getUTCFullYear(), (utcDate1.getUTCMonth() + 1), utcDate1.getUTCDate(), utcDate1.getUTCHours(), 0, 0);
    const candle167 = this.cnd_array[167].openTime_human_readible;
    var utcDate2 = new Date(candle167);
    var date = Date.UTC(utcDate2.getFullYear(), (utcDate2.getMonth() + 1), utcDate2.getDate(), utcDate2.getHours(), 0, 0);


    // alert(utcDate1.getUTCFullYear() + '-' + (utcDate1.getUTCMonth() + 1) + '-' + utcDate1.getUTCDate() + '-' + utcDate1.getUTCHours() + 'fdsfdsfdss' + utcDate2.getFullYear() + '/' + (utcDate2.getMonth() + 1) + '/' + utcDate2.getDate() + '/' + utcDate2.getHours())
    if ((utcDate1.getUTCFullYear() == utcDate2.getFullYear())) {
      if(utcDate1.getUTCMonth() + 1 == utcDate2.getMonth() + 1){
        if (utcDate1.getUTCDate() >= utcDate2.getDate()) {
          if (utcDate2.getHours() > utcDate1.getUTCHours() - 1) {
            this.setcomponentstate();
            this.dataLoaderWaiting = true
            this.hide_all_filters();
            this.showCandleSticks = false;
            this.lineArray = [];
            this.service.canvasData.subscribe(data => {
              this.general_array = data;
            });
            let back_date = "";
            let fwd_date = this.cnd_array[this.cnd_array.length - 1].openTime_human_readible;
            localStorage.setItem('fwd_date',fwd_date)
            var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
            this.cnd_array = data;
            this.time_length = this.general_array[0].time_length;
            this.price_length = this.general_array[0].price_length;
            this.starting_index = this.general_array[0].start_index;
            this.ending_index = this.general_array[0].end_index;
            this.cnd_array_length = this.cnd_array.length;
            this.increment = this.general_array[0].increment;
            this.decrement = this.general_array[0].decrement;

            this.dataLoaderWaiting = false
            if (localStorage.getItem('setting_arrr_main_chart')) {

              this.getcomponentstate();
            } else {
              this.showCandleSticks = true;
              this.hide_all_filters();
            }
          } else {
            //Notification
          }
        }
      }else if(utcDate1.getUTCMonth() + 1 > utcDate2.getMonth() + 1){
        if ((utcDate1.getUTCDate() >= utcDate2.getDate()) ||  (utcDate1.getUTCDate() <= utcDate2.getDate())) {
          if (utcDate2.getHours() > utcDate1.getUTCHours() - 1 || utcDate2.getHours() <= utcDate1.getUTCHours() - 1) {
            this.setcomponentstate();
            this.dataLoaderWaiting = true
            this.hide_all_filters();
            this.showCandleSticks = false;
            this.lineArray = [];
            this.service.canvasData.subscribe(data => {
              this.general_array = data;
            });
            let back_date = "";
            let fwd_date = this.cnd_array[this.cnd_array.length - 1].openTime_human_readible;
            localStorage.setItem('fwd_date',fwd_date)
            var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
            this.cnd_array = data;
            this.time_length = this.general_array[0].time_length;
            this.price_length = this.general_array[0].price_length;
            this.starting_index = this.general_array[0].start_index;
            this.ending_index = this.general_array[0].end_index;
            this.cnd_array_length = this.cnd_array.length;
            this.increment = this.general_array[0].increment;
            this.decrement = this.general_array[0].decrement;

            this.dataLoaderWaiting = false
            if (localStorage.getItem('setting_arrr_main_chart')) {

              this.getcomponentstate();
            } else {
              this.showCandleSticks = true;
              this.hide_all_filters();
            }
          } else {
            //Notification
          }
        }
      }
    } else if((utcDate1.getUTCFullYear() > utcDate2.getFullYear())){
      if(utcDate1.getUTCMonth() + 1 == utcDate2.getMonth() + 1){
        if (utcDate1.getUTCDate() >= utcDate2.getDate() || utcDate1.getUTCDate() < utcDate2.getDate()) {
          if ((utcDate2.getHours() > utcDate1.getUTCHours() - 1) || (utcDate2.getHours() <= utcDate1.getUTCHours() - 1)) {
            this.setcomponentstate();
            this.dataLoaderWaiting = true
            this.hide_all_filters();
            this.showCandleSticks = false;
            this.lineArray = [];
            this.service.canvasData.subscribe(data => {
              this.general_array = data;
            });
            let back_date = "";
            let fwd_date = this.cnd_array[this.cnd_array.length - 1].openTime_human_readible;
            localStorage.setItem('fwd_date',fwd_date)
            var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
            this.cnd_array = data;
            this.time_length = this.general_array[0].time_length;
            this.price_length = this.general_array[0].price_length;
            this.starting_index = this.general_array[0].start_index;
            this.ending_index = this.general_array[0].end_index;
            this.cnd_array_length = this.cnd_array.length;
            this.increment = this.general_array[0].increment;
            this.decrement = this.general_array[0].decrement;

            this.dataLoaderWaiting = false
            if (localStorage.getItem('setting_arrr_main_chart')) {

              this.getcomponentstate();
            } else {
              this.showCandleSticks = true;
              this.hide_all_filters();
            }
          } else {
            //Notification
          }
        }
      }else if((utcDate1.getUTCMonth() + 1 > utcDate2.getMonth() + 1) || (utcDate1.getUTCMonth() + 1 < utcDate2.getMonth() + 1)){
        if ((utcDate1.getUTCDate() >= utcDate2.getDate()) ||  (utcDate1.getUTCDate() <= utcDate2.getDate())) {
          if (utcDate2.getHours() > utcDate1.getUTCHours() - 1 || utcDate2.getHours() <= utcDate1.getUTCHours() - 1) {
            this.setcomponentstate();
            this.dataLoaderWaiting = true
            this.hide_all_filters();
            this.showCandleSticks = false;
            this.lineArray = [];
            this.service.canvasData.subscribe(data => {
              this.general_array = data;
            });
            let back_date = "";
            let fwd_date = this.cnd_array[this.cnd_array.length - 1].openTime_human_readible;
            localStorage.setItem('fwd_date',fwd_date)
            var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
            this.cnd_array = data;
            this.time_length = this.general_array[0].time_length;
            this.price_length = this.general_array[0].price_length;
            this.starting_index = this.general_array[0].start_index;
            this.ending_index = this.general_array[0].end_index;
            this.cnd_array_length = this.cnd_array.length;
            this.increment = this.general_array[0].increment;
            this.decrement = this.general_array[0].decrement;

            this.dataLoaderWaiting = false
            if (localStorage.getItem('setting_arrr_main_chart')) {

              this.getcomponentstate();
            } else {
              this.showCandleSticks = true;
              this.hide_all_filters();
            }
          } else {
            //Notification
          }
        }
      }
    }
  }
  async date_search_candle() {



    var utcDate1 = new Date();
    var utcdate = Date.UTC(utcDate1.getUTCFullYear(), (utcDate1.getUTCMonth() + 1), utcDate1.getUTCDate(), utcDate1.getUTCHours(), 0, 0);
    var utcDate2 = new Date(this.search_date);
    var date = Date.UTC(utcDate2.getFullYear(), (utcDate2.getMonth() + 1), utcDate2.getDate(), utcDate2.getHours(), 0, 0);

    // alert(utcDate1.getUTCFullYear() + '-' + (utcDate1.getUTCMonth() + 1) + '-' + utcDate1.getUTCDate() + '-' + utcDate1.getUTCHours() + 'fdsfdsfdss' + utcDate2.getFullYear() + '/' + (utcDate2.getMonth() + 1) + '/' + utcDate2.getDate() + '/' + utcDate2.getHours())


    if ((utcDate1.getUTCFullYear() == utcDate2.getFullYear())) {
      if(utcDate1.getUTCMonth() + 1 == utcDate2.getMonth() + 1){
        if (utcDate1.getUTCDate() >= utcDate2.getDate()) {
          this.setcomponentstate();
          this.dataLoaderWaiting = true
          this.hide_all_filters();
          this.showCandleSticks = false;
          this.lineArray = [];
          this.service.canvasData.subscribe(data => {
            this.general_array = data;
          });
          let back_date = "";
          let fwd_date = this.search_date;
          localStorage.setItem('fwd_date',fwd_date)
          var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
          this.cnd_array = data;
          this.time_length = this.general_array[0].time_length;
          this.price_length = this.general_array[0].price_length;
          this.starting_index = this.general_array[0].start_index;
          this.ending_index = this.general_array[0].end_index;
          this.cnd_array_length = this.cnd_array.length;
          this.increment = this.general_array[0].increment;
          this.decrement = this.general_array[0].decrement;

          this.dataLoaderWaiting = false

          if (localStorage.getItem('setting_arrr_main_chart')) {

            this.getcomponentstate();
          } else {
            this.showCandleSticks = true;
            this.hide_all_filters();
          }
        }
      }else if(utcDate1.getUTCMonth() + 1 > utcDate2.getMonth() + 1){
        if (utcDate1.getUTCDate() > utcDate2.getDate() || utcDate1.getUTCDate() <= utcDate2.getDate()) {
          this.setcomponentstate();
          this.dataLoaderWaiting = true
          this.hide_all_filters();
          this.showCandleSticks = false;
          this.lineArray = [];
          this.service.canvasData.subscribe(data => {
            this.general_array = data;
          });
          let back_date = "";
          let fwd_date = this.search_date;
          localStorage.setItem('fwd_date',fwd_date)
          var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
          this.cnd_array = data;
          this.time_length = this.general_array[0].time_length;
          this.price_length = this.general_array[0].price_length;
          this.starting_index = this.general_array[0].start_index;
          this.ending_index = this.general_array[0].end_index;
          this.cnd_array_length = this.cnd_array.length;
          this.increment = this.general_array[0].increment;
          this.decrement = this.general_array[0].decrement;

          this.dataLoaderWaiting = false

          if (localStorage.getItem('setting_arrr_main_chart')) {

            this.getcomponentstate();
          } else {
            this.showCandleSticks = true;
            this.hide_all_filters();
          }
        }
      }
    }else if((utcDate1.getUTCFullYear() > utcDate2.getFullYear())) {
        if(utcDate1.getUTCMonth() + 1 == utcDate2.getMonth() + 1){
          if (utcDate1.getUTCDate() >= utcDate2.getDate() || utcDate1.getUTCDate() < utcDate2.getDate()) {
            this.setcomponentstate();
            this.dataLoaderWaiting = true
            this.hide_all_filters();
            this.showCandleSticks = false;
            this.lineArray = [];
            this.service.canvasData.subscribe(data => {
              this.general_array = data;
            });
            let back_date = "";
            let fwd_date = this.search_date;
            localStorage.setItem('fwd_date',fwd_date)
            var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
            this.cnd_array = data;
            this.time_length = this.general_array[0].time_length;
            this.price_length = this.general_array[0].price_length;
            this.starting_index = this.general_array[0].start_index;
            this.ending_index = this.general_array[0].end_index;
            this.cnd_array_length = this.cnd_array.length;
            this.increment = this.general_array[0].increment;
            this.decrement = this.general_array[0].decrement;

            this.dataLoaderWaiting = false

            if (localStorage.getItem('setting_arrr_main_chart')) {

              this.getcomponentstate();
            } else {
              this.showCandleSticks = true;
              this.hide_all_filters();
            }
          }
        }else if(utcDate1.getUTCMonth() + 1 > utcDate2.getMonth() + 1 || utcDate1.getUTCMonth() + 1 <= utcDate2.getMonth() + 1){
          if (utcDate1.getUTCDate() > utcDate2.getDate() || utcDate1.getUTCDate() <= utcDate2.getDate()) {
            this.setcomponentstate();
            this.dataLoaderWaiting = true
            this.hide_all_filters();
            this.showCandleSticks = false;
            this.lineArray = [];
            this.service.canvasData.subscribe(data => {
              this.general_array = data;
            });
            let back_date = "";
            let fwd_date = this.search_date;
            localStorage.setItem('fwd_date',fwd_date)
            var data = await this.service.getCandleChart(this.currentCoin, back_date, fwd_date);
            this.cnd_array = data;
            this.time_length = this.general_array[0].time_length;
            this.price_length = this.general_array[0].price_length;
            this.starting_index = this.general_array[0].start_index;
            this.ending_index = this.general_array[0].end_index;
            this.cnd_array_length = this.cnd_array.length;
            this.increment = this.general_array[0].increment;
            this.decrement = this.general_array[0].decrement;

            this.dataLoaderWaiting = false

            if (localStorage.getItem('setting_arrr_main_chart')) {

              this.getcomponentstate();
            } else {
              this.showCandleSticks = true;
              this.hide_all_filters();
            }
          }
        }
    }
  }
  show_Today_f(){
    localStorage.removeItem('fwd_date')
    localStorage.removeItem('back_date')
    this.ngOnInit();
  }

  hide_all_filters() {
    this.showTrueLevels = false;
    this.showBlackWallPerc = false;
    this.showSevenLevelPerc = false;
    this.showFourteenLevelPerc = false;
    this.showSevenLevelMinVSTPerc = false;
    this.showSevenLevelMinPerc = false;
    this.showSevenLevelMinPercST = false;
    this.showSevenLevelMaxPerc = false;
    this.showSevenLevelMaxPercST = false;
    this.showOrderBook1Averg = false;
    this.showOrderBook2Averg = false;
    this.showOrderBook3Averg = false;
    this.showOrderBook1AvergPerc = false;
    this.showOrderBook2AvergPerc = false;
    this.showOrderBook3AvergPerc = false;
    this.showOrderBook1MinPerc = false;
    this.showOrderBook2MinPerc = false;
    this.showOrderBook3MinPerc = false;
    this.showOrderBook1MaxPerc = false;
    this.showOrderBook2MaxPerc = false;
    this.showOrderBook3MaxPerc = false;
    this.showOrderBook1MinMax = false;
    this.showOrderBook2MinMax = false;
    this.showOrderBook3MinMax = false;
    this.showBigContractsPerc = false;
    this.showBigBuyerContractsPercST = false;
    this.showBigBuyerContractsPercVST = false;
    this.showBigSellerContractsPercST = false;
    this.showBigSellerContractsPercVST = false;
    this.showBigBuyerSellerContractsCount = false;
    this.showBigBuyerSellerContractsVol = false;
    this.showBigBuyerSellerDelta = false;
    this.showBigBuyerSellerDiff = false;
    this.showBigBuyerSellerDiffPerc = false;
    this.showTwoWayBar = false;
    this.showCandlePerc = false;
    this.showActiveDownBarrierPerc = false;
    this.showActiveUpBarrierPerc = false;
    this.showCandleSTPerc = false;
    this.showCandleVSTPerc = false;
    this.showSevenLevel = false;
    this.showFourteenLevelAvg = false;
    this.showSevenLevelST = false;
    this.showSevenLevelVST = false;
    this.showBlackWallAverg = false;
    this.showBigContractsAverg = false;
    this.showUpperWick = false;
    this.showBUpperWick = false;
    this.showUpperWickST = false;
    this.showUpperWickVST = false;
    this.showLowerWick = false;
    this.showBLowerWick = false;
    this.showLowerWickST = false;
    this.showLowerWickVST = false;
    this.showBodyPercentile = false;
    this.showDemandSupply = false;
    this.showResistance = false;
    this.showStrategy = false;
    this.showRejection = false;
    this.showMove = false;
    this.showBodyMove = false;
    this.showUpperWickMove = false;
    this.showLowerWickMove = false;
    this.showTopWickSignal = false;
    this.showCandelTrends = false;
    this.showDailyCandelTrends = false;
    this.showDPUPTrendDirection = false;
    this.showNextClose = false;
    this.showDP = false;
    this.showDPBTC = false;
    this.showIncreasingVolume = false;
    this.showBlackDotUpPressure = false;
    this.showSevenLevelAvgDiffPerc = false;
    this.showFourteenLevelAvgDiffPerc = false;
    this.showBlackDotPressureU10 = false;
    this.showAskBidVolume = false;
    this.showAskBidVolumeB = false;
    this.showAskBidCount = false;
    this.showCoinMetaCount = false;
    this.showVolumeComparison = false;
    this.showRangesComparison = false;
    this.showScriptTime = false;
    this.showSevenLevelMinMax = false;
    this.showFourteenLevelMinMax = false;
    this.showBlackWallMinMax = false;
    this.showT3MinMax = false;
    this.showT2MinMax = false;
    this.showT4MinMax = false;
    this.showTelegramSentiment = false;
    this.showTelegramITPercentile = false;
    this.showTelegramSTPercentile = false;
    this.showTelegramVSTPercentile = false;
    this.showTwitterSentiment = false;
    this.showTwitterITPercentile = false;
    this.showTwitterSTPercentile = false;
    this.showTwitterVSTPercentile = false;
    this.showNewsSentiment = false;
    this.showNewsITPercentile = false;
    this.showNewsSTPercentile = false;
    this.showNewsVSTPercentile = false;
    this.showBoxes = false;
    this.showAskBidDelta = false;
    this.showAskBidDeltaB = false;
    this.showBidDelta = false;
    this.showAskBidDivisionB = false;
    this.showT2BCP = false;





    this.openFilterFooter = false;
    this.filter_1 = false;
    this.show_all_chart = false;
    this.showSevellevelChart = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
  }
  show_all_charts() {
    this.showCandleSticks = true;
    this.showTrueLevels = true;
    this.showBlackWallPerc = true;
    this.showSevenLevelPerc = true;
    this.showFourteenLevelPerc = true;
    this.showSevenLevelMinVSTPerc = true;
    this.showSevenLevelMinPerc = true;
    this.showSevenLevelMinPercST = true;
    this.showSevenLevelMaxPerc = true;
    this.showSevenLevelMaxPercST = true;
    this.showOrderBook1Averg = true;
    this.showOrderBook2Averg = true;
    this.showOrderBook3Averg = true;
    this.showOrderBook1AvergPerc = true;
    this.showOrderBook2AvergPerc = true;
    this.showOrderBook3AvergPerc = true;
    this.showOrderBook1MinPerc = true;
    this.showOrderBook2MinPerc = true;
    this.showOrderBook3MinPerc = true;
    this.showOrderBook1MaxPerc = true;
    this.showOrderBook2MaxPerc = true;
    this.showOrderBook3MaxPerc = true;
    this.showOrderBook1MinMax = true;
    this.showOrderBook2MinMax = true;
    this.showOrderBook3MinMax = true;
    this.showBigContractsPerc = true;
    this.showBigBuyerContractsPercST = true;
    this.showBigBuyerContractsPercVST = true;
    this.showBigSellerContractsPercST = true;
    this.showBigSellerContractsPercVST = true;
    this.showBigBuyerSellerContractsCount = true;
    this.showBigBuyerSellerContractsVol = true;
    this.showBigBuyerSellerDelta = true;
    this.showBigBuyerSellerDiff = true;
    this.showBigBuyerSellerDiffPerc = true;
    this.showTwoWayBar = true;
    this.showCandlePerc = true;
    this.showActiveDownBarrierPerc = true;
    this.showActiveUpBarrierPerc = true;
    this.showCandleSTPerc = true;
    this.showCandleVSTPerc = true;
    this.showSevenLevel = true;
    this.showFourteenLevelAvg = true;
    this.showSevenLevelST = true;
    this.showSevenLevelVST = true;
    this.showBlackWallAverg = true;
    this.showBigContractsAverg = true;
    this.showUpperWick = true;
    this.showBUpperWick = true;
    this.showUpperWickST = true;
    this.showUpperWickVST = true;
    this.showLowerWick = true;
    this.showBLowerWick = true;
    this.showLowerWickST = true;
    this.showLowerWickVST = true;
    this.showBodyPercentile = true;
    this.showDemandSupply = true;
    this.showResistance = true;
    this.showStrategy = true;
    this.showRejection = true;
    this.showMove = true
    this.showBodyMove = true;
    this.showUpperWickMove = true;
    this.showLowerWickMove = true;
    this.showTopWickSignal = true;
    this.showCandelTrends = true;
    this.showDailyCandelTrends = true;
    this.showDPUPTrendDirection = true;
    this.showNextClose = true;
    this.showDP = true;
    this.showDPBTC = true;
    this.showIncreasingVolume = true;
    this.showBlackDotUpPressure = true;
    this.showSevenLevelAvgDiffPerc = true;
    this.showFourteenLevelAvgDiffPerc = true;
    this.showBlackDotPressureU10 = true;
    this.showAskBidVolume = true;
    this.showAskBidVolumeB = true;
    this.showAskBidCount = true;
    this.showCoinMetaCount = true;
    this.showVolumeComparison = true;
    this.showRangesComparison = true;
    this.showScriptTime = true;
    this.showSevenLevelMinMax = true;
    this.showFourteenLevelMinMax = true;
    this.showBlackWallMinMax = true;
    this.showT3MinMax = true;
    this.showT2MinMax = true;
    this.showT4MinMax = true;
    this.showTelegramSentiment = true;
    this.showTelegramITPercentile = true;
    this.showTelegramSTPercentile = true;
    this.showTelegramVSTPercentile = true;
    this.showTwitterSentiment = true;
    this.showTwitterITPercentile = true;
    this.showTwitterSTPercentile = true;
    this.showTwitterVSTPercentile = true;
    this.showNewsSentiment = true;
    this.showNewsITPercentile = true;
    this.showNewsSTPercentile = true;
    this.showNewsVSTPercentile = true;
    this.showBoxes = true;
    this.showBidDelta = true;
    this.showAskBidDivisionB = true;
    this.showAskBidDelta = true;
    this.showAskBidDeltaB = true;
    this.showT2BCP = true;


    this.showAllchart =true;
    this.closeAllChart = false;
    this.filter_5 = false;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.setcomponentstate();
  }
  closeAllCharts(){
    this.showCandleSticks = true;
    this.showSevenLevelPerc = false;
    this.showFourteenLevelPerc = false;
    this.showSevenLevelMinVSTPerc = false;
    this.showSevenLevelMinPerc = false;
    this.showSevenLevelMinPercST = false;
    this.showSevenLevelMaxPerc = false;
    this.showSevenLevelMaxPercST = false;
    this.showSevenLevel = false;
    this.showFourteenLevelAvg = false;
    this.showSevenLevelST = false;
    this.showSevenLevelVST = false;
    this.showSevenLevelMinMax = false;
    this.showFourteenLevelMinMax = false;
    this.showSevenLevelAvgDiffPerc = false;
    this.showFourteenLevelAvgDiffPerc = false;
    this.showOrderBook1Averg = false;
    this.showOrderBook2Averg = false;
    this.showOrderBook3Averg = false;
    this.showOrderBook1AvergPerc = false;
    this.showOrderBook2AvergPerc = false;
    this.showOrderBook3AvergPerc = false;
    this.showOrderBook1MinPerc = false;
    this.showOrderBook2MinPerc = false;
    this.showOrderBook3MinPerc = false;
    this.showOrderBook1MaxPerc = false;
    this.showOrderBook2MaxPerc = false;
    this.showOrderBook3MaxPerc = false;
    this.showOrderBook1MinMax = false;
    this.showOrderBook2MinMax = false;
    this.showOrderBook3MinMax = false;
    this.showTrueLevels = false;
    this.showBlackWallPerc = false;
    this.showBigContractsPerc = false;
    this.showBigBuyerContractsPercST = false;
    this.showBigBuyerContractsPercVST = false;
    this.showBigSellerContractsPercST = false;
    this.showBigSellerContractsPercVST = false;
    this.showBigBuyerSellerContractsCount = false;
    this.showBigBuyerSellerContractsVol = false;
    this.showBigBuyerSellerDelta = false;
    this.showBigBuyerSellerDiff = false;
    this.showBigBuyerSellerDiffPerc = false;
    this.showTwoWayBar = false;
    this.showCandlePerc = false;
    this.showActiveDownBarrierPerc = false;
    this.showActiveUpBarrierPerc = false;
    this.showCandleSTPerc = false;
    this.showCandleVSTPerc = false;
    this.showBlackWallAverg = false;
    this.showBigContractsAverg = false;
    this.showUpperWick = false;
    this.showBUpperWick = false;
    this.showUpperWickST = false;
    this.showUpperWickVST = false;
    this.showLowerWick = false;
    this.showBLowerWick = false;
    this.showLowerWickST = false;
    this.showLowerWickVST = false;
    this.showBodyPercentile = false;
    this.showDemandSupply = false;
    this.showResistance = false;
    this.showStrategy = false;
    this.showRejection = false;
    this.showMove = false
    this.showBodyMove = false;
    this.showUpperWickMove = false;
    this.showLowerWickMove = false;
    this.showTopWickSignal = false;
    this.showCandelTrends = false;
    this.showDailyCandelTrends = false;
    this.showDPUPTrendDirection = false;
    this.showNextClose = false;
    this.showDP = false;
    this.showDPBTC = false;
    this.showIncreasingVolume = false;
    this.showBlackDotUpPressure = false;
    this.showBlackDotPressureU10 = false;
    this.showAskBidVolume = false;
    this.showAskBidVolumeB = false;
    this.showAskBidCount = false;
    this.showCoinMetaCount = false;
    this.showVolumeComparison = false;
    this.showRangesComparison = false;
    this.showScriptTime = false;
    this.showBlackWallMinMax = false;
    this.showT3MinMax = false;
    this.showT2MinMax = false;
    this.showT4MinMax = false;
    this.showTelegramSentiment = false;
    this.showTelegramITPercentile = false;
    this.showTelegramSTPercentile = false;
    this.showTelegramVSTPercentile = false;
    this.showTwitterSentiment = false;
    this.showTwitterITPercentile = false;
    this.showTwitterSTPercentile = false;
    this.showTwitterVSTPercentile = false;
    this.showNewsSentiment = false;
    this.showNewsITPercentile = false;
    this.showNewsSTPercentile = false;
    this.showNewsVSTPercentile = false;
    this.showBoxes = false;
    this.showBidDelta = false;
    this.showAskBidDivisionB = false;
    this.showAskBidDelta = false;
    this.showAskBidDeltaB = false;
    this.showT2BCP = false;




    this.filter_5 = false;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart = false;
    this.closeAllChart = true;
    this.setcomponentstate();
  }
  open_filter_footer_func(){
    this.openFilterFooter = !this.openFilterFooter;
    this.setcomponentstate();
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////              Update Value in General Array           //////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////



  SetTimeLength(length){
    this.time_length = length
    if(length <= 168){
      this.starting_index = 1
      this.ending_index = 168
    }
    if(length > 168){
      this.starting_index = 1
      this.ending_index = 200
    }
    this.changearrayValue();
  }
  changearrayValue() {
    if (this.general_array[0].time_length != this.time_length) {
      if (this.time_length > this.ending_index) {
        this.ending_index = this.time_length;
      } else {
        this.ending_index = this.ending_index;
      }
      this.starting_index = this.ending_index - this.time_length;
      if (this.starting_index < 0) {
        this.starting_index = 0;
      }
      this.general_array[0].start_index = this.starting_index;
      this.general_array[0].end_index = this.ending_index;
      this.general_array[0].time_length = this.time_length;
    }
    if (this.general_array[0].price_length != this.price_length) {
      this.general_array[0].price_length = this.price_length;
    }
    if (this.general_array[0].start_index != this.starting_index) {
      this.general_array[0].start_index = this.starting_index;
    }
    if (this.general_array[0].end_index != this.ending_index) {
      this.general_array[0].end_index = this.ending_index;
    }
    if (this.general_array[0].truelevel_type != this.truelevel_type) {
      this.general_array[0].truelevel_type = this.truelevel_type;
    }
    this.setcomponentstate();
    this.service.new_OthArray(this.general_array);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////           Update Value in General Array End          //////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /* ********************************** */
  /*   Set Component Filter States              */
  /* ********************************** */
  setcomponentstate() {
    var setting = [];
    this.setting_arr = new Object();
    this.setting_arr['showtimelength'] = this.time_length;
    this.setting_arr['showendindex'] = this.ending_index;
    this.setting_arr['showstartindex'] = this.starting_index;
    this.setting_arr['showtrueleveltype'] = this.truelevel_type;
    this.setting_arr['showCandleSticks'] = this.showCandleSticks;
    this.setting_arr['showTrueLevels'] = this.showTrueLevels;
    this.setting_arr['showBlackWallPerc'] = this.showBlackWallPerc;
    this.setting_arr['showSevenLevelPerc'] = this.showSevenLevelPerc;
    this.setting_arr['showFourteenLevelPerc'] = this.showFourteenLevelPerc;
    this.setting_arr['showSevenLevelMinVSTPerc'] = this.showSevenLevelMinVSTPerc;
    this.setting_arr['showSevenLevelMinPerc'] = this.showSevenLevelMinPerc;
    this.setting_arr['showSevenLevelMinPercST'] = this.showSevenLevelMinPercST;
    this.setting_arr['showSevenLevelMaxPerc'] = this.showSevenLevelMaxPerc;
    this.setting_arr['showSevenLevelMaxPercST'] = this.showSevenLevelMaxPercST;
    this.setting_arr['showOrderBook1Averg'] = this.showOrderBook1Averg;
    this.setting_arr['showOrderBook2Averg'] = this.showOrderBook2Averg;
    this.setting_arr['showOrderBook3Averg'] = this.showOrderBook3Averg;
    this.setting_arr['showOrderBook1AvergPerc'] = this.showOrderBook1AvergPerc;
    this.setting_arr['showOrderBook2AvergPerc'] = this.showOrderBook2AvergPerc;
    this.setting_arr['showOrderBook3AvergPerc'] = this.showOrderBook3AvergPerc;
    this.setting_arr['showOrderBook1MinPerc'] = this.showOrderBook1MinPerc;
    this.setting_arr['showOrderBook2MinPerc'] = this.showOrderBook2MinPerc;
    this.setting_arr['showOrderBook3MinPerc'] = this.showOrderBook3MinPerc;
    this.setting_arr['showOrderBook1MaxPerc'] = this.showOrderBook1MaxPerc;
    this.setting_arr['showOrderBook2MaxPerc'] = this.showOrderBook2MaxPerc;
    this.setting_arr['showOrderBook3MaxPerc'] = this.showOrderBook3MaxPerc;
    this.setting_arr['showOrderBook1MinMax'] = this.showOrderBook1MinMax;
    this.setting_arr['showOrderBook2MinMax'] = this.showOrderBook2MinMax;
    this.setting_arr['showOrderBook3MinMax'] = this.showOrderBook3MinMax;
    this.setting_arr['showBigContractsPerc'] = this.showBigContractsPerc;
    this.setting_arr['showBigBuyerContractsPercVST'] = this.showBigBuyerContractsPercVST;
    this.setting_arr['showBigSellerContractsPercVST'] = this.showBigSellerContractsPercVST;
    this.setting_arr['showBigBuyerSellerContractsCount'] = this.showBigBuyerSellerContractsCount;
    this.setting_arr['showBigBuyerSellerContractsVol'] = this.showBigBuyerSellerContractsVol;
    this.setting_arr['showBigBuyerSellerDelta'] = this.showBigBuyerSellerDelta;
    this.setting_arr['showBigBuyerSellerDiff'] = this.showBigBuyerSellerDiff;
    this.setting_arr['showBigBuyerSellerDiffPerc'] = this.showBigBuyerSellerDiffPerc;
    this.setting_arr['showBigBuyerContractsPercST'] = this.showBigBuyerContractsPercST;
    this.setting_arr['showBigSellerContractsPercST'] = this.showBigSellerContractsPercST;
    this.setting_arr['showTwoWayBar'] = this.showTwoWayBar;
    this.setting_arr['showCandlePerc'] = this.showCandlePerc;
    this.setting_arr['showActiveDownBarrierPerc'] = this.showActiveDownBarrierPerc;
    this.setting_arr['showActiveUpBarrierPerc'] = this.showActiveUpBarrierPerc;
    this.setting_arr['showCandleSTPerc'] = this.showCandleSTPerc;
    this.setting_arr['showCandleVSTPerc'] = this.showCandleVSTPerc;
    this.setting_arr['showSevenLevel'] = this.showSevenLevel;
    this.setting_arr['showFourteenLevelAvg'] = this.showFourteenLevelAvg;
    this.setting_arr['showSevenLevelVST'] = this.showSevenLevelVST;
    this.setting_arr['showSevenLevelST'] = this.showSevenLevelST;
    this.setting_arr['showBlackWallAverg'] = this.showBlackWallAverg;
    this.setting_arr['showBigContractsAverg'] = this.showBigContractsAverg;
    this.setting_arr['showUpperWick'] = this.showUpperWick;
    this.setting_arr['showBUpperWick'] = this.showBUpperWick;
    this.setting_arr['showUpperWickST'] = this.showUpperWickST;
    this.setting_arr['showUpperWickVST'] = this.showUpperWickVST;
    this.setting_arr['showLowerWick'] = this.showLowerWick;
    this.setting_arr['showBLowerWick'] = this.showBLowerWick;
    this.setting_arr['showLowerWickST'] = this.showLowerWickST;
    this.setting_arr['showLowerWickVST'] = this.showLowerWickVST;
    this.setting_arr['showBodyPercentile'] = this.showBodyPercentile;
    this.setting_arr['showDemandSupply'] = this.showDemandSupply;
    this.setting_arr['showResistance'] = this.showResistance;
    this.setting_arr['showStrategy'] = this.showStrategy;
    this.setting_arr['showRejection'] = this.showRejection;
    this.setting_arr['showMove'] = this.showMove;
    this.setting_arr['showBodyMove'] = this.showBodyMove;
    this.setting_arr['showUpperWickMove'] = this.showUpperWickMove;
    this.setting_arr['showLowerWickMove'] = this.showLowerWickMove;
    this.setting_arr['showTopWickSignal'] = this.showTopWickSignal;
    this.setting_arr['showCandelTrends'] = this.showCandelTrends;
    this.setting_arr['showDailyCandelTrends'] = this.showDailyCandelTrends;
    this.setting_arr['showDPUPTrendDirection'] = this.showDPUPTrendDirection;
    this.setting_arr['showNextClose'] = this.showNextClose;
    this.setting_arr['showDP'] = this.showDP;
    this.setting_arr['showDPBTC'] = this.showDPBTC;
    this.setting_arr['showIncreasingVolume'] = this.showIncreasingVolume;
    this.setting_arr['showBlackDotUpPressure'] = this.showBlackDotUpPressure;
    this.setting_arr['showSevenLevelAvgDiffPerc'] = this.showSevenLevelAvgDiffPerc;
    this.setting_arr['showFourteenLevelAvgDiffPerc'] = this.showFourteenLevelAvgDiffPerc;
    this.setting_arr['showBlackDotPressureU10'] = this.showBlackDotPressureU10;
    this.setting_arr['showAskBidVolume'] = this.showAskBidVolume;
    this.setting_arr['showAskBidVolumeB'] = this.showAskBidVolumeB;
    this.setting_arr['showAskBidCount'] = this.showAskBidCount;
    this.setting_arr['showCoinMetaCount'] = this.showCoinMetaCount;
    this.setting_arr['showVolumeComparison'] = this.showVolumeComparison;
    this.setting_arr['showRangesComparison'] = this.showRangesComparison;
    this.setting_arr['showScriptTime'] = this.showScriptTime;
    this.setting_arr['showSevenLevelMinMax'] = this.showSevenLevelMinMax;
    this.setting_arr['showFourteenLevelMinMax'] = this.showFourteenLevelMinMax;
    this.setting_arr['showBlackWallMinMax'] = this.showBlackWallMinMax;
    this.setting_arr['showT3MinMax'] = this.showT3MinMax;
    this.setting_arr['showT2MinMax'] = this.showT2MinMax;
    this.setting_arr['showT4MinMax'] = this.showT4MinMax;
    this.setting_arr['showTelegramSentiment'] = this.showTelegramSentiment;
    this.setting_arr['showTwitterSentiment'] = this.showTwitterSentiment;
    this.setting_arr['showNewsSentiment'] = this.showNewsSentiment;
    this.setting_arr['showTelegramIT'] = this.showTelegramITPercentile;
    this.setting_arr['showTelegramST'] = this.showTelegramSTPercentile;
    this.setting_arr['showTelegramVST'] = this.showTelegramVSTPercentile;
    this.setting_arr['showTwitterIT'] = this.showTwitterITPercentile;
    this.setting_arr['showTwitterST'] = this.showTwitterSTPercentile;
    this.setting_arr['showTwitterVST'] = this.showTwitterVSTPercentile;
    this.setting_arr['showNewsIT'] = this.showNewsITPercentile;
    this.setting_arr['showNewsST'] = this.showNewsSTPercentile;
    this.setting_arr['showNewsVST'] = this.showNewsVSTPercentile;
    this.setting_arr['showBoxes'] = this.showBoxes;
    this.setting_arr['showAskBidDelta'] = this.showAskBidDelta;
    this.setting_arr['showAskBidDeltaB'] = this.showAskBidDeltaB;
    this.setting_arr['showBidDelta'] = this.showBidDelta;
    this.setting_arr['showAskBidDivisionB'] = this.showAskBidDivisionB;
    this.setting_arr['showT2BCP'] = this.showT2BCP;
    this.setting_arr['PopUpOnCandle'] = this.popUpOnCandle;
    this.setting_arr['popUpOnCandleStick'] = this.popUpOnCandleStick;
    this.setting_arr['candleScroller'] = this.candleScroller;
    this.setting_arr['openFilterFooter'] = this.openFilterFooter;
    this.setting_arr['show_all_chart']= this.show_all_chart;
    this.setting_arr['showSevellevelChart']= this.showSevellevelChart;
    this.setting_arr['filter_1']= this.filter_1;
    this.setting_arr['filter_2']= this.filter_2;
    this.setting_arr['filter_3']= this.filter_3;
    this.setting_arr['filter_4']= this.filter_4;
    this.setting_arr['filter_5']= this.filter_5;
    this.setting_arr['filter_6']= this.filter_6;
    this.setting_arr['filter_7']= this.filter_7;
    this.setting_arr['showAllchart']= this.showAllchart;
    this.setting_arr['closeAllChart']= this.closeAllChart;
    setting[0] = this.setting_arr;
    if (typeof Storage != 'undefined') {
      localStorage.removeItem('setting_arrr_main_chart');
      localStorage.setItem('setting_arrr_main_chart', JSON.stringify(setting));
    }
  }
  /* ********************************** */
  /*   Get Component Filter States              */
  /* ********************************** */
  getcomponentstate() {
    this.setting_arr = JSON.parse(localStorage.getItem('setting_arrr_main_chart'));
    if ((this.setting_arr[0].showendindex > 0) && (this.setting_arr[0].showstartindex >= 0)) {
      this.time_length = this.setting_arr[0].showtimelength;
      this.ending_index = this.setting_arr[0].showendindex;
      this.starting_index = this.setting_arr[0].showstartindex;
    }
    this.truelevel_type = this.setting_arr[0].showtrueleveltype;
    this.showCandleSticks = true;
    this.showTrueLevels = this.setting_arr[0].showTrueLevels;
    this.showBlackWallPerc = this.setting_arr[0].showBlackWallPerc;
    this.showSevenLevelPerc = this.setting_arr[0].showSevenLevelPerc;
    this.showFourteenLevelPerc = this.setting_arr[0].showFourteenLevelPerc;
    this.showSevenLevelMinVSTPerc = this.setting_arr[0].showSevenLevelMinVSTPerc;
    this.showSevenLevelMaxPercST = this.setting_arr[0].showSevenLevelMaxPercST;
    this.showSevenLevelMaxPerc = this.setting_arr[0].showSevenLevelMaxPerc;
    this.showSevenLevelMinPercST = this.setting_arr[0].showSevenLevelMinPercST;
    this.showSevenLevelMinPerc = this.setting_arr[0].showSevenLevelMinPerc;
    this.showOrderBook1Averg = this.setting_arr[0].showOrderBook1Averg;
    this.showOrderBook2Averg = this.setting_arr[0].showOrderBook2Averg;
    this.showOrderBook3Averg = this.setting_arr[0].showOrderBook3Averg;
    this.showOrderBook1AvergPerc = this.setting_arr[0].showOrderBook1AvergPerc;
    this.showOrderBook2AvergPerc = this.setting_arr[0].showOrderBook2AvergPerc;
    this.showOrderBook3AvergPerc = this.setting_arr[0].showOrderBook3AvergPerc;
    this.showOrderBook1MinPerc = this.setting_arr[0].showOrderBook1MinPerc;
    this.showOrderBook2MinPerc = this.setting_arr[0].showOrderBook2MinPerc;
    this.showOrderBook3MinPerc = this.setting_arr[0].showOrderBook3MinPerc;
    this.showOrderBook1MaxPerc = this.setting_arr[0].showOrderBook1MaxPerc;
    this.showOrderBook2MaxPerc = this.setting_arr[0].showOrderBook2MaxPerc;
    this.showOrderBook3MaxPerc = this.setting_arr[0].showOrderBook3MaxPerc;
    this.showOrderBook1MinMax = this.setting_arr[0].showOrderBook1MinMax;
    this.showOrderBook2MinMax = this.setting_arr[0].showOrderBook2MinMax;
    this.showOrderBook3MinMax = this.setting_arr[0].showOrderBook3MinMax;
    this.showBigBuyerContractsPercVST = this.setting_arr[0].showBigBuyerContractsPercVST;
    this.showBigBuyerContractsPercST = this.setting_arr[0].showBigBuyerContractsPercST;
    this.showBigSellerContractsPercST = this.setting_arr[0].showBigSellerContractsPercST;
    this.showBigSellerContractsPercVST = this.setting_arr[0].showBigSellerContractsPercVST;
    this.showBigBuyerSellerContractsCount = this.setting_arr[0].showBigBuyerSellerContractsCount;
    this.showBigBuyerSellerContractsVol = this.setting_arr[0].showBigBuyerSellerContractsVol;
    this.showBigBuyerSellerDelta = this.setting_arr[0].showBigBuyerSellerDelta;
    this.showBigBuyerSellerDiff = this.setting_arr[0].showBigBuyerSellerDiff;
    this.showBigBuyerSellerDiffPerc = this.setting_arr[0].showBigBuyerSellerDiffPerc;
    this.showBigContractsPerc = this.setting_arr[0].showBigContractsPerc;
    this.showTwoWayBar = this.setting_arr[0].showTwoWayBar;
    this.showCandlePerc = this.setting_arr[0].showCandlePerc;
    this.showActiveDownBarrierPerc = this.setting_arr[0].showActiveDownBarrierPerc;
    this.showActiveUpBarrierPerc = this.setting_arr[0].showActiveUpBarrierPerc;
    this.showCandleSTPerc = this.setting_arr[0].showCandleSTPerc;
    this.showCandleVSTPerc = this.setting_arr[0].showCandleVSTPerc;
    this.showSevenLevel = this.setting_arr[0].showSevenLevel;
    this.showFourteenLevelAvg = this.setting_arr[0].showFourteenLevelAvg;
    this.showSevenLevelST = this.setting_arr[0].showSevenLevelST;
    this.showSevenLevelVST = this.setting_arr[0].showSevenLevelVST;
    this.showBlackWallAverg = this.setting_arr[0].showBlackWallAverg;
    this.showBigContractsAverg = this.setting_arr[0].showBigContractsAverg;
    this.showUpperWick = this.setting_arr[0].showUpperWick;
    this.showBUpperWick = this.setting_arr[0].showBUpperWick;
    this.showUpperWickST = this.setting_arr[0].showUpperWickST;
    this.showUpperWickVST = this.setting_arr[0].showUpperWickVST;
    this.showLowerWick = this.setting_arr[0].showLowerWick;
    this.showBLowerWick = this.setting_arr[0].showBLowerWick;
    this.showLowerWickST = this.setting_arr[0].showLowerWickST;
    this.showLowerWickVST = this.setting_arr[0].showLowerWickVST;
    this.showBodyPercentile = this.setting_arr[0].showBodyPercentile;
    this.showDemandSupply = this.setting_arr[0].showDemandSupply;
    this.showResistance = this.setting_arr[0].showResistance;
    this.showStrategy = this.setting_arr[0].showStrategy;
    this.showRejection = this.setting_arr[0].showRejection;
    this.showMove = this.setting_arr[0].showMove;
    this.showBodyMove = this.setting_arr[0].showBodyMove;
    this.showUpperWickMove = this.setting_arr[0].showUpperWickMove;
    this.showLowerWickMove = this.setting_arr[0].showLowerWickMove;
    this.showTopWickSignal = this.setting_arr[0].showTopWickSignal;
    this.showCandelTrends = this.setting_arr[0].showCandelTrends;
    this.showDailyCandelTrends = this.setting_arr[0].showDailyCandelTrends;
    this.showDPUPTrendDirection = this.setting_arr[0].showDPUPTrendDirection;
    this.showNextClose = this.setting_arr[0].showNextClose;
    this.showDPBTC = this.setting_arr[0].showDPBTC;
    this.showDP = this.setting_arr[0].showDP;
    this.showIncreasingVolume = this.setting_arr[0].showIncreasingVolume;
    this.showBlackDotUpPressure = this.setting_arr[0].showBlackDotUpPressure;
    this.showSevenLevelAvgDiffPerc = this.setting_arr[0].showSevenLevelAvgDiffPerc;
    this.showFourteenLevelAvgDiffPerc = this.setting_arr[0].showFourteenLevelAvgDiffPerc;
    this.showBlackDotPressureU10 = this.setting_arr[0].showBlackDotPressureU10;
    this.showAskBidVolume = this.setting_arr[0].showAskBidVolume;
    this.showAskBidVolumeB = this.setting_arr[0].showAskBidVolumeB;
    this.showAskBidCount = this.setting_arr[0].showAskBidCount;
    this.showCoinMetaCount = this.setting_arr[0].showCoinMetaCount;
    this.showVolumeComparison = this.setting_arr[0].showVolumeComparison;
    this.showRangesComparison = this.setting_arr[0].showRangesComparison;
    this.showScriptTime = this.setting_arr[0].showScriptTime;
    this.showSevenLevelMinMax = this.setting_arr[0].showSevenLevelMinMax;
    this.showFourteenLevelMinMax = this.setting_arr[0].showFourteenLevelMinMax;
    this.showBlackWallMinMax = this.setting_arr[0].showBlackWallMinMax;
    this.showT3MinMax = this.setting_arr[0].showT3MinMax;
    this.showT2MinMax = this.setting_arr[0].showT2MinMax;
    this.showT4MinMax = this.setting_arr[0].showT4MinMax;
    this.showTelegramSentiment = this.setting_arr[0].showTelegramSentiment;
    this.showTwitterSentiment = this.setting_arr[0].showTwitterSentiment;
    this.showNewsSentiment = this.setting_arr[0].showNewsSentiment;
    this.showTelegramITPercentile = this.setting_arr[0].showTelegramIT;
    this.showTelegramSTPercentile = this.setting_arr[0].showTelegramST;
    this.showTelegramVSTPercentile = this.setting_arr[0].showTelegramVST;
    this.showTwitterITPercentile = this.setting_arr[0].showTwitterIT;
    this.showTwitterSTPercentile = this.setting_arr[0].showTwitterST;
    this.showTwitterVSTPercentile = this.setting_arr[0].showTwitterVST;
    this.showNewsITPercentile = this.setting_arr[0].showNewsIT;
    this.showNewsSTPercentile = this.setting_arr[0].showNewsST;
    this.showNewsVSTPercentile = this.setting_arr[0].showNewsVST;
    this.showAskBidDelta = this.setting_arr[0].showAskBidDelta;
    this.showAskBidDeltaB = this.setting_arr[0].showAskBidDeltaB;
    this.showBidDelta = this.setting_arr[0].showBidDelta;
    this.showAskBidDivisionB = this.setting_arr[0].showAskBidDivisionB;
    this.showT2BCP = this.setting_arr[0].showT2BCP;
    this.showBoxes = this.setting_arr[0].showBoxes;
    this.popUpOnCandle = this.setting_arr[0].PopUpOnCandle;
    this.popUpOnCandleStick = this.setting_arr[0].popUpOnCandleStick;
    this.candleScroller = this.setting_arr[0].candleScroller;
    this.openFilterFooter = typeof this.setting_arr[0].openFilterFooter == 'undefined' ? false : this.setting_arr[0].openFilterFooter;
    this.show_all_chart = this.setting_arr[0].show_all_chart;
    this.showSevellevelChart = this.setting_arr[0].showSevellevelChart;
    this.filter_1 = this.setting_arr[0].filter_1;
    this.filter_2 = this.setting_arr[0].filter_2;
    this.filter_3 = this.setting_arr[0].filter_3;
    this.filter_4 = this.setting_arr[0].filter_4;
    this.filter_5 = this.setting_arr[0].filter_5;
    this.filter_6 = this.setting_arr[0].filter_6;
    this.filter_7 = this.setting_arr[0].filter_7;
    this.showAllchart = this.setting_arr[0].showAllchart;
    this.closeAllChart = this.setting_arr[0].closeAllChart;




    this.changearrayValue();
  }

  RemovesettingLocalStorage() {
    localStorage.removeItem('setting_arrr_main_chart');
    localStorage.removeItem('back_date')
    localStorage.removeItem('fwd_date')
    this.window.location.reload();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
  }
  divScroll(e) {
    this.scrollTop = e.srcElement.scrollTop;
  }
  onMouseScroll(e) {
    // console.log('scroll')

  }
  // gettopHeight() {
  //   this.topheader_height = $(".top-header").height();
  // }
  // getHeight() {
  //   this.maincharthead_height = $(".main-chart-head").height();
  // }
  gettooltipHeight() {
    this.tooltip_height = $(".tooltip-box").height();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.getWidth();
    });
  }
  getWidth() {
    this.s_width = 0;
    var sideBar: number = ($(".sidebar").width());
    if ($("body").hasClass('remove-sidebar')) {
      sideBar = 0;
    }
    this.s_width = this.s_width + sideBar;
  }
  clickcandlestickToogle() {
    $("body").on('click', '.cuix-tt-show', () => {
      this.getCandleStickHeadHeight();
    });
  }//when head shown
  clickcandlestickToogle1() {
    $("body").on('click', '.cuix-tt-icon', () => {
      this.getCandleStickHeadHeight();
    });
  }//when head not shown
  getCandleStickHeadHeight() {
    this.componenthead_height = 0;
    var height = $(".chartuix-head").height();
    var head_height = (typeof height == 'undefined') ? 0 : height;
    this.componenthead_height = this.componenthead_height + head_height;
  }



  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////   Filter Checks          ////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  filter_all_show_func() {
    this.show_all_chart = true;
    this.showSevellevelChart = true;
    this.filter_1 = true;
    this.filter_2 = true;
    this.filter_3 = true;
    this.filter_4 = true;
    this.filter_5 = true;
    this.filter_6 = true;
    this.filter_7 = true;
    this.showAllchart =true;
    this.closeAllChart = true;
    this.setcomponentstate();
  }
  filter_all_hide_func() {
    this.openFilterFooter = false;
    this.filter_1 = false;
    this.show_all_chart = false;
    this.showSevellevelChart = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }
  filter_1_func() {
    this.filter_1 = true;
    this.show_all_chart = true;
    this.showSevellevelChart = true;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }
  filter_2_func() {
    this.filter_2 = true;
    this.filter_1 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }
  filter_3_func() {
    this.filter_3 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }
  filter_4_func() {
    this.filter_4 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }
  filter_5_func() {
    this.filter_5 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_6 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }
  filter_6_func() {
    this.filter_6 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_7 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }
  filter_7_func() {
    this.filter_7 = true;
    this.filter_1 = false;
    this.filter_2 = false;
    this.filter_3 = false;
    this.filter_4 = false;
    this.filter_5 = false;
    this.filter_6 = false;
    this.showAllchart =false;
    this.closeAllChart = false;
    this.setcomponentstate();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////   Filter Checks  End        ////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  mouseEnterComponent(e) {
    this.showTooltip = false;
    this.showDemandSupplyTooltip = false;
    this.showResistanceTooltip = false;
    this.showStrategyTooltip = false;
    this.showRejectionTooltip = false;
    this.showMoveTooltip = false;
    this.showBodyMoveTooltip = false;
    this.showUpperWickMoveTooltip = false;
    this.showLowerWickMoveTooltip = false;
    this.showTopWickSignalTooltip = false;
    this.showCandelTrendsTooltip = false;
    this.showDailyCandelTrendsTooltip = false;
    this.showDPUPTrendDirectionTooltip = false;
    this.showNextCloseTooltip = false;
    this.showDP1Tooltip = false;
    this.showDP2Tooltip = false;
    this.showDP3Tooltip = false;
    this.showDP1BTCTooltip = false;
    this.showDP2BTCTooltip = false;
    this.showDP3BTCTooltip = false;
    this.showBigRaiseDropTooltip = false;
    this.showBlackDotUpPressureTooltip = false;
    this.showSevenLevelAvgDiffPercTooltip = false;
    this.showFourteenLevelAvgDiffPercTooltip = false;
    this.showBlackDotPressureU10Tooltip = false;
    this.showAskBidVolumeTooltip = false;
    this.showAskBidVolumeBTooltip = false;
    this.showCoinMetaCountTooltip = false;
    this.showVolumeComparisonTooltip = false;
    this.showRangesComparisonTooltip = false;
    this.showScriptTimeTooltip = false;
    this.showTruelevelTooltip = false;
    this.showDKBoxeTooltip = false;
    this.showSevenlevelPercTooltip = false;
    this.showFourteenlevelPercTooltip = false;
    this.showSevenlevelMinVSTPercTooltip = false;
    this.showSevenlevelMinPercSTTooltip = false;
    this.showSevenlevelMinPercTooltip = false;
    this.showSevenlevelMaxPercSTTooltip = false;
    this.showSevenlevelMaxPercTooltip = false;



    this.showOrderBook1AvergTooltip = false;
    this.showOrderBook2AvergTooltip = false;
    this.showOrderBook3AvergTooltip = false;
    this.showOrderBook1AvergPercTooltip = false;
    this.showOrderBook2AvergPercTooltip = false;
    this.showOrderBook3AvergPercTooltip = false;
    this.showOrderBook1MinPercTooltip = false;
    this.showOrderBook2MinPercTooltip = false;
    this.showOrderBook3MinPercTooltip = false;
    this.showOrderBook1MaxPercTooltip = false;
    this.showOrderBook2MaxPercTooltip = false;
    this.showOrderBook3MaxPercTooltip = false;
    this.showOrderBook1MinMaxTooltip = false;
    this.showOrderBook2MinMaxTooltip = false;
    this.showOrderBook3MinMaxTooltip = false;




    this.showBlackwallPercTooltip = false;
    this.showBigcontractsPercTooltip = false;
    this.showBigBuyercontractsPercSTTooltip = false;
    this.showBigBuyercontractsPercVSTTooltip = false;
    this.showBigSellercontractsPercSTTooltip = false;
    this.showBigSellercontractsPercVSTTooltip = false;
    this.showBigBuyerSellercontractsCountTooltip = false;
    this.showBigBuyerSellercontractsVolTooltip = false;
    this.showBigBuyerSellerDeltaTooltip = false;
    this.showBigBuyerSellerDiffTooltip = false;
    this.showBigBuyerSellerDiffPercTooltip = false;
    this.showCandlePercTooltip = false;
    this.showActiveDownBarrierPercTooltip = false;
    this.showActiveUpBarrierPercTooltip = false;
    this.showCandleSTPercTooltip = false;
    this.showCandleVSTPercTooltip = false;
    this.showTwoWayBarTooltip = false;
    this.showBlackwallAvgTooltip = false;
    this.showSevenlevelAvgTooltip = false;
    this.showFourteenlevelAvgTooltip = false;
    this.showSevenlevelAvgSTTooltip = false;
    this.showSevenlevelAvgVSTTooltip = false;
    this.showBigcontractsAvgTooltip = false;
    this.showUpperwickPercTooltip = false;
    this.showBUpperwickTooltip = false;
    this.showUpperwickSTPercTooltip = false;
    this.showUpperwickVSTPercTooltip = false;
    this.showLowerwickPercTooltip = false;
    this.showBLowerwickTooltip = false;
    this.showLowerwickSTPercTooltip = false;
    this.showLowerwickVSTPercTooltip = false;
    this.showBodyPercTooltip = false;
    this.showSevenlevelMinMaxTooltip = false;
    this.showFourteenlevelMinMaxTooltip = false;
    this.showBlackwallMinMaxTooltip = false;
    this.showTelegramTooltip = false;
    this.showTelegramITPerctooltip = false;
    this.showTelegramSTPerctooltip = false;
    this.showTelegramVSTPerctooltip = false;
    this.showTwitterTooltip = false;
    this.showTwitterITPerctooltip = false;
    this.showTwitterSTPerctooltip = false;
    this.showTwitterVSTPerctooltip = false;
    this.showNewsTooltip = false;
    this.showNewsITPerctooltip = false;
    this.showNewsSTPerctooltip = false;
    this.showNewsVSTPerctooltip = false;
    this.showT1Tooltip = false;
    this.showT2Tooltip = false;
    this.showT4Tooltip = false;
    this.showAskBidDeltaTooltip = false;
    this.showAskBidDeltaBTooltip = false;
    this.showAskBidCountTooltip = false;
    this.showBidDeltaTooltip = false;
    this.showAskBidDivisionBTooltip = false;
    this.showT2BCPTooltip = false;
  }
  mouseMoveOnTruelevels(e) {
    var truelevelspositionfrom = document.querySelector(".truelevels-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - truelevelspositionfrom.top) - 6;
    var trueleveltop = e.offsetY;
    this.left_coord = (e.clientX - truelevelspositionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.truelevelCoordArray.length > 1) {
        if ((this.left_coord > this.truelevelCoordArray[a].x1 && this.left_coord <= this.truelevelCoordArray[a].x2)) {
          if ((trueleveltop <= (this.truelevelCoordArray[a].y1 + 4) && trueleveltop > this.truelevelCoordArray[a].y2 + 6)) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }


            this.L1_buy_price_ = this.cnd_array[i].rules_array['level_' + 1].buy_price;
            this.L2_buy_price_ = this.cnd_array[i].rules_array['level_' + 2].buy_price;
            this.L3_buy_price_ = this.cnd_array[i].rules_array['level_' + 3].buy_price;
            this.L4_buy_price_ = this.cnd_array[i].rules_array['level_' + 4].buy_price;
            this.L5_buy_price_ = this.cnd_array[i].rules_array['level_' + 5].buy_price;
            this.L6_buy_price_ = this.cnd_array[i].rules_array['level_' + 6].buy_price;
            this.L7_buy_price_ = this.cnd_array[i].rules_array['level_' + 7].buy_price;
            this.L8_buy_price_ = this.cnd_array[i].rules_array['level_' + 8].buy_price;
            this.L9_buy_price_ = this.cnd_array[i].rules_array['level_' + 9].buy_price;
            this.L10_buy_price_ = this.cnd_array[i].rules_array['level_' + 10].buy_price;
            this.L11_buy_price_ = this.cnd_array[i].rules_array['level_' + 11].buy_price;
            this.L12_buy_price_ = this.cnd_array[i].rules_array['level_' + 12].buy_price;
            this.L13_buy_price_ = this.cnd_array[i].rules_array['level_' + 13].buy_price;
            this.L14_buy_price_ = this.cnd_array[i].rules_array['level_' + 14].buy_price;
            this.L15_buy_price_ = this.cnd_array[i].rules_array['level_' + 15].buy_price;
            this.L1_sell_price_ = this.cnd_array[i].rules_array['level_' + 1].sell_price;
            this.L2_sell_price_ = this.cnd_array[i].rules_array['level_' + 2].sell_price;
            this.L3_sell_price_ = this.cnd_array[i].rules_array['level_' + 3].sell_price;
            this.L4_sell_price_ = this.cnd_array[i].rules_array['level_' + 4].sell_price;
            this.L5_sell_price_ = this.cnd_array[i].rules_array['level_' + 5].sell_price;
            this.L6_sell_price_ = this.cnd_array[i].rules_array['level_' + 6].sell_price;
            this.L7_sell_price_ = this.cnd_array[i].rules_array['level_' + 7].sell_price;
            this.L8_sell_price_ = this.cnd_array[i].rules_array['level_' + 8].sell_price;
            this.L9_sell_price_ = this.cnd_array[i].rules_array['level_' + 9].sell_price;
            this.L10_sell_price_ = this.cnd_array[i].rules_array['level_' + 10].sell_price;
            this.L11_sell_price_ = this.cnd_array[i].rules_array['level_' + 11].sell_price;
            this.L12_sell_price_ = this.cnd_array[i].rules_array['level_' + 12].sell_price;
            this.L13_sell_price_ = this.cnd_array[i].rules_array['level_' + 13].sell_price;
            this.L14_sell_price_ = this.cnd_array[i].rules_array['level_' + 14].sell_price;
            this.L15_sell_price_ = this.cnd_array[i].rules_array['level_' + 15].sell_price;
            if (typeof (this.cnd_array[i].rules_array['level_' + 16]) !== 'undefined') {
              this.L16_sell_price_ = this.cnd_array[i].rules_array['level_' + 16].sell_price;
              this.L16_buy_price_ = this.cnd_array[i].rules_array['level_' + 16].buy_price;
            }
            if (typeof (this.cnd_array[i].rules_array['level_' + 17]) !== 'undefined') {
              this.L17_sell_price_ = this.cnd_array[i].rules_array['level_' + 17].sell_price;
              this.L17_buy_price_ = this.cnd_array[i].rules_array['level_' + 17].buy_price;
            }
            if (typeof (this.cnd_array[i].rules_array['level_' + 18]) !== 'undefined') {
              this.L18_sell_price_ = this.cnd_array[i].rules_array['level_' + 18].sell_price;
              this.L18_buy_price_ = this.cnd_array[i].rules_array['level_' + 18].buy_price;
            }
            if (typeof (this.cnd_array[i].rules_array['level_' + 19]) !== 'undefined') {
              this.L19_sell_price_ = this.cnd_array[i].rules_array['level_' + 19].sell_price;
              this.L19_buy_price_ = this.cnd_array[i].rules_array['level_' + 19].buy_price;
            }
            if (typeof (this.cnd_array[i].rules_array['level_' + 20]) !== 'undefined') {
              this.L20_sell_price_ = this.cnd_array[i].rules_array['level_' + 20].sell_price;
              this.L20_buy_price_ = this.cnd_array[i].rules_array['level_' + 20].buy_price;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTruelevelTooltip = true
          }
          else {
            this.showTruelevelTooltip = false
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnCandels(e) {
    var positionfrom = document.querySelector(".candlesticks-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = true;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.candleCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.candleCoordArray[a].x1 && this.left_coord <= this.candleCoordArray[a].x2)) {
            if ((this.top_coord <= (this.candleCoordArray[a].y1 + (this.componenthead_height + 30)) && this.top_coord > (this.candleCoordArray[a].y2 + (this.componenthead_height + 30)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 260;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 260;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 260;
                } else {
                  this.deduction = 20;
                }
              }
              this.close_ = parseFloat(this.cnd_array[i].close).toFixed(8);
              this.open_ = parseFloat(this.cnd_array[i].open).toFixed(8);
              this.high_ = parseFloat(this.cnd_array[i].high).toFixed(8);
              this.low_ = parseFloat(this.cnd_array[i].low).toFixed(8);
              this.volume_ = this.cnd_array[i].volume;
              this.ask_ = this.cnd_array[i].ask_volume;
              this.bid_ = this.cnd_array[i].bid_volume;
              this.total_volume_ = this.cnd_array[i].total_volume;
              this.ask_b_ = this.cnd_array[i].ask_volume_b;
              this.bid_b_ = this.cnd_array[i].bid_volume_b;

              this.DP1_ = (typeof this.cnd_array[i].DP1 !== 'undefined') ? this.cnd_array[i].DP1 : 0;
              this.DP2_ = (typeof this.cnd_array[i].DP2 !== 'undefined') ? this.cnd_array[i].DP2 : 0;
              this.DP3_ = (typeof this.cnd_array[i].DP3 !== 'undefined') ? this.cnd_array[i].DP3 : 0;
              this.UP1_ = (typeof this.cnd_array[i].UP1 !== 'undefined') ? this.cnd_array[i].UP1 : 0;
              this.UP2_ = (typeof this.cnd_array[i].UP2 !== 'undefined') ? this.cnd_array[i].UP2 : 0;
              this.UP3_ = (typeof this.cnd_array[i].UP3 !== 'undefined') ? this.cnd_array[i].UP3 : 0;

              var demandbase = this.cnd_array[i].demand_base_candel;
              if (demandbase == null) {
                this.demandbasecandel_ = "null";
              } else {
                this.demandbasecandel_ = demandbase;
              }
              var supplybase = this.cnd_array[i].supply_base_candel;
              if (supplybase == null) {
                this.supplybasecandel_ = "null";
              } else {
                this.supplybasecandel_ = supplybase;
              }
              this.swing_ = this.cnd_array[i].global_swing_status;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.closeTime_human_readible_ = this.cnd_array[i].closeTime_human_readible;
              this.active_down_barrier_ = this.cnd_array[i].active_down_barrier;
              this.active_up_barrier_ = this.cnd_array[i].active_up_barrier;



              this.down_barrier_value_daily_ = this.cnd_array[i].down_barrier_value_daily;
              this.up_barrier_value_daily_ = this.cnd_array[i].up_barrier_value_daily;
              this.barrier_avg_move_ = this.cnd_array[i].barrier_avg_move;


              this.top_wick_prices_ = this.cnd_array[i].top_wick_prices;
              this.showTooltip = true;
            } else {
              this.showTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          } else {
            this.showTooltip = false;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.candleCoordArray[a].x1 && this.left_coord <= this.candleCoordArray[a].x2)) {
            if ((this.top_coord <= this.candleCoordArray[a].y1 && this.top_coord > this.candleCoordArray[a].y2)) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 260;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 260;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 260;
                } else {
                  this.deduction = 20;
                }
              }
              this.close_ = this.cnd_array[i].close;
              this.open_ = this.cnd_array[i].open;
              this.high_ = this.cnd_array[i].high;
              this.low_ = this.cnd_array[i].low;
              this.volume_ = this.cnd_array[i].volume;
              this.ask_ = this.cnd_array[i].ask_volume;
              this.bid_ = this.cnd_array[i].bid_volume;
              this.total_volume_ = this.cnd_array[i].total_volume;
              var demandbase = this.cnd_array[i].demand_base_candel;
              if (demandbase == null) {
                this.demandbasecandel_ = "null";
              } else {
                this.demandbasecandel_ = demandbase;
              }
              var supplybase = this.cnd_array[i].supply_base_candel;
              if (supplybase == null) {
                this.supplybasecandel_ = "null";
              } else {
                this.supplybasecandel_ = supplybase;
              }
              this.swing_ = this.cnd_array[i].global_swing_status;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.closeTime_human_readible_ = this.cnd_array[i].closeTime_human_readible;
              this.active_down_barrier_ = this.cnd_array[i].active_down_barrier;
              this.active_up_barrier_ = this.cnd_array[i].active_up_barrier;
              this.down_barrier_value_daily_ = this.cnd_array[i].down_barrier_value_daily;
              this.up_barrier_value_daily_ = this.cnd_array[i].up_barrier_value_daily;
              this.barrier_avg_move_ = this.cnd_array[i].barrier_avg_move;
              this.top_wick_prices_ = this.cnd_array[i].top_wick_prices;
              this.showTooltip = true;
              break;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          } else {
            this.showTooltip = false;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnBoxes(e) {
    var positionfrom = document.querySelector(".boxes-selector").getBoundingClientRect();
    //Set Top Top not Set in this Function
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      // if (this.dkboxCoordArray.length > 1) {
      //   var height_deduction = this.dkboxes_height + this.demandsupply_height + this.rejection_height + this.move_height + this.top_wick_height + this.big_raise_drop_height + this.black_dot_up_pressure_height + this.blackwallperc_height + this.sevenlevelperc_height + this.bigcontractsperc_height + this.candleperc_height + this.twowaybars_height + this.blackwallavg_height + this.sevenlevelavg_height + this.bigcontractsavg_height + this.upperwick_height + this.lowerwick_height + this.bodyperc_height + this.sevenlevelminmax_height + this.blackwallminmax_height + this.bigdelta_height + this.telegram_height + this.twitter_height + this.news_height + this.t1cot_height + this.t2cot_height + this.t4cot_height;
      //   if (this.componenthead_height > 0) {
      //     if ((this.left_coord > this.dkboxCoordArray[a].x1 && this.left_coord <= this.dkboxCoordArray[a].x2) && (this.top_coord <= (this.dkboxCoordArray[a].y1 + ((this.total_height - height_deduction) + 30)) && this.top_coord > (this.dkboxCoordArray[a].y2 + ((this.total_height - height_deduction) + 30)))) {
      //       this.rinkos_rm_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_rm;
      //       this.rinkos_hk_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_hk;
      //       this.rinkos_spike_buy_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_spike_buy;
      //       this.rinkos_spike_sell_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_spike_sell;
      //       this.rinkos_supply_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_get_supply;
      //       this.showDKBoxeTooltip = true
      //       break;
      //     }
      //     else {
      //       this.showDKBoxeTooltip = false
      //     }
      //   }
      //   if (this.componenthead_height == 0) {
      //     if ((this.left_coord > this.dkboxCoordArray[a].x1 && this.left_coord <= this.dkboxCoordArray[a].x2) && (this.top_coord <= (((this.dkboxCoordArray[a].y1) + (this.total_height - height_deduction)) ) && this.top_coord > (((this.dkboxCoordArray[a].y2) + (this.total_height - height_deduction))))) {
      //       this.rinkos_rm_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_rm;
      //       this.rinkos_hk_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_hk;
      //       this.rinkos_spike_buy_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_spike_buy;
      //       this.rinkos_spike_sell_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_spike_sell;
      //       this.rinkos_supply_ = this.cnd_array[i].rinkos_data.candle_rinkos_data_get_supply;
      //       this.showDKBoxeTooltip = true
      //       break;
      //     }
      //     else {
      //       this.showDKBoxeTooltip = false
      //     }
      //   }
      // }
    }
  }
  mouseMoveOnDemandSupply(e) {
    var positionfrom = document.querySelector(".demandsupply-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var demandsupplytop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.demandsupplyCoordArray.length > 1) {
        if ((this.left_coord > this.demandsupplyCoordArray[a].x1 && this.left_coord <= this.demandsupplyCoordArray[a].x2)) {
          if ((demandsupplytop <= ((this.demandsupplyCoordArray[a].y1 + 6)) && demandsupplytop > ((this.demandsupplyCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.demand_supply_candel_ = this.cnd_array[i].candle_type;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDemandSupplyTooltip = true;
          } else {
            this.showDemandSupplyTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnResistance(e) {
    var positionfrom = document.querySelector(".resistance-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var resistancetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.resistanceCoordArray.length > 1) {
        if ((this.left_coord > this.resistanceCoordArray[a].x1 && this.left_coord <= this.resistanceCoordArray[a].x2)) {
          if ((resistancetop <= ((this.resistanceCoordArray[a].y1 + 6)) && resistancetop > ((this.resistanceCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.resistance_value_ = this.cnd_array[i].resistance_value;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showResistanceTooltip = true;
          } else {
            this.showResistanceTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnStrategy(e) {
    var positionfrom = document.querySelector(".strategy-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var strategytop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.strategyCoordArray.length > 1) {
        if ((this.left_coord > this.strategyCoordArray[a].x1 && this.left_coord <= this.strategyCoordArray[a].x2)) {
          if ((strategytop <= ((this.strategyCoordArray[a].y1 + 6)) && strategytop > ((this.strategyCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.strategy_ = this.cnd_array[i].strategy;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showStrategyTooltip = true;
          } else {
            this.showStrategyTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnRejection(e) {
    var positionfrom = document.querySelector(".rejection-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var rejectiontop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.rejectionCoordArray.length > 1) {
        if ((this.left_coord > this.rejectionCoordArray[a].x1 && this.left_coord <= this.rejectionCoordArray[a].x2)) {
          if ((rejectiontop <= ((this.rejectionCoordArray[a].y1 + 6)) && rejectiontop > ((this.rejectionCoordArray[a].y2 + 6)))) {
          if (this.time_length == 25) {
            if (i >= this.ending_index - 2) {
              this.deduction = - 220;
            } else {
              this.deduction = 20;
            }
          } else if (this.time_length > 50 && this.time_length <= 100) {
            if (i > this.ending_index - 5) {
              this.deduction = - 220;
            } else {
              this.deduction = 20;
            }
          } else if (this.time_length > 100) {
            if (i > this.ending_index - 7) {
              this.deduction = - 220;
            } else {
              this.deduction = 20;
            }
          }
          this.rejected_candle_ = this.cnd_array[i].rejected_candle;
          this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
          this.showRejectionTooltip = true;
          } else {
            this.showRejectionTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnMove(e) {
    var positionfrom = document.querySelector(".move-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var movetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.moveCoordArray.length > 1) {
        if ((this.left_coord > this.moveCoordArray[a].x1 && this.left_coord <= this.moveCoordArray[a].x2)) {
          if ((movetop <= ((this.moveCoordArray[a].y1 + 6)) && movetop > ((this.moveCoordArray[a].y2 + 6)))) {
          if (this.time_length == 25) {
            if (i >= this.ending_index - 2) {
              this.deduction = - 220;
            } else {
              this.deduction = 20;
            }
          } else if (this.time_length > 50 && this.time_length <= 100) {
            if (i > this.ending_index - 5) {
              this.deduction = - 220;
            } else {
              this.deduction = 20;
            }
          } else if (this.time_length > 100) {
            if (i > this.ending_index - 7) {
              this.deduction = - 220;
            } else {
              this.deduction = 20;
            }
          }
          this.move_ = this.cnd_array[i].per_move;
          this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
          this.showMoveTooltip = true;
          } else {
            this.showMoveTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBodyMove(e) {
    var positionfrom = document.querySelector(".bodymove-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bodymovetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bodymoveCoordArray.length > 1) {
        if ((this.left_coord > this.bodymoveCoordArray[a].x1 && this.left_coord <= this.bodymoveCoordArray[a].x2)) {
          if ((bodymovetop <= ((this.bodymoveCoordArray[a].y1 + 6)) && bodymovetop > ((this.bodymoveCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.body_move_ = this.cnd_array[i].body_per_move;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBodyMoveTooltip = true;
          } else {
            this.showBodyMoveTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnUpperWickMove(e) {
    var positionfrom = document.querySelector(".upperwickmove-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var upperwickmovetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.upperwickmoveCoordArray.length > 1) {
        if ((this.left_coord > this.upperwickmoveCoordArray[a].x1 && this.left_coord <= this.upperwickmoveCoordArray[a].x2)) {
          if ((upperwickmovetop <= ((this.upperwickmoveCoordArray[a].y1 + 6)) && upperwickmovetop > ((this.upperwickmoveCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.upper_wick_move_ = this.cnd_array[i].upper_wick_per_move;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showUpperWickMoveTooltip = true;
          } else {
            this.showUpperWickMoveTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnLowerWickMove(e) {
    var positionfrom = document.querySelector(".lowerwickmove-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var lowerwickmovetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.lowerwickmoveCoordArray.length > 1) {
        if ((this.left_coord > this.lowerwickmoveCoordArray[a].x1 && this.left_coord <= this.lowerwickmoveCoordArray[a].x2)) {
          if ((lowerwickmovetop <= ((this.lowerwickmoveCoordArray[a].y1 + 6)) && lowerwickmovetop > ((this.lowerwickmoveCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.lower_wick_move_ = this.cnd_array[i].lower_wick_per_move;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showLowerWickMoveTooltip = true;
          } else {
            this.showLowerWickMoveTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTopWickSignal(e) {
    var positionfrom = document.querySelector(".topwicksignal-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var topwicksignaltop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.topwicksignalCoordArray.length > 1) {
        if ((this.left_coord > this.topwicksignalCoordArray[a].x1 && this.left_coord <= this.topwicksignalCoordArray[a].x2)) {
          if ((topwicksignaltop <= ((this.topwicksignalCoordArray[a].y1 + 6)) && topwicksignaltop > ((this.topwicksignalCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.top_wick_ = this.cnd_array[i].top_wick_aggregate;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTopWickSignalTooltip = true;
          } else {
            this.showTopWickSignalTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnCandelTrends(e) {
    var positionfrom = document.querySelector(".candeltrends-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var candeltrendstop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.candeltrendsCoordArray.length > 1) {
        if ((this.left_coord > this.candeltrendsCoordArray[a].x1 && this.left_coord <= this.candeltrendsCoordArray[a].x2)) {
          if ((candeltrendstop <= ((this.candeltrendsCoordArray[a].y1 + 6)) && candeltrendstop > ((this.candeltrendsCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.candel_trends_ = this.cnd_array[i].candel_trends;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showCandelTrendsTooltip = true;
          } else {
            this.showCandelTrendsTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDailyCandelTrends(e) {
    var positionfrom = document.querySelector(".dailycandeltrends-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dailycandeltrendstop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dailycandeltrendsCoordArray.length > 1) {
        if ((this.left_coord > this.dailycandeltrendsCoordArray[a].x1 && this.left_coord <= this.dailycandeltrendsCoordArray[a].x2)) {
          if ((dailycandeltrendstop <= ((this.dailycandeltrendsCoordArray[a].y1 + 6)) && dailycandeltrendstop > ((this.dailycandeltrendsCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.daily_candel_trends_ = this.cnd_array[i].daily_trend;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDailyCandelTrendsTooltip = true;
          } else {
            this.showDailyCandelTrendsTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDPUPTrendDirection(e) {
    // if(this.popUpOnCandle){
    var positionfrom = document.querySelector(".dpuptrenddirection-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dpuptrenddirectiontop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dpuptrenddirectionCoordArray.length > 1) {
        if (this.componenthead_height > 0) {
          if ((this.left_coord > this.dpuptrenddirectionCoordArray[a].x1 && this.left_coord <= this.dpuptrenddirectionCoordArray[a].x2)) {
            if ((dpuptrenddirectiontop <= ((this.dpuptrenddirectionCoordArray[a].y1 + 6)) && dpuptrenddirectiontop > ((this.dpuptrenddirectionCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.dpup_trend_direction_ = this.cnd_array[i].daily_dpup_trend_direction;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDPUPTrendDirectionTooltip = true;
              // break;
            } else {
              this.showDPUPTrendDirectionTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
        if (this.componenthead_height == 0) {
          if ((this.left_coord > this.dpuptrenddirectionCoordArray[a].x1 && this.left_coord <= this.dpuptrenddirectionCoordArray[a].x2)) {
            if ((dpuptrenddirectiontop <= ((this.dpuptrenddirectionCoordArray[a].y1 + 6)) && dpuptrenddirectiontop > ((this.dpuptrenddirectionCoordArray[a].y2 + 6)))) {
              if (this.time_length == 25) {
                if (i >= this.ending_index - 12) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 50 && this.time_length <= 100) {
                if (i > this.ending_index - 30) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              } else if (this.time_length > 100) {
                if (i > this.ending_index - 84) {
                  this.deduction = - 220;
                } else {
                  this.deduction = 20;
                }
              }
              this.dpup_trend_direction_ = this.cnd_array[i].daily_dpup_trend_direction;
              this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
              this.showDPUPTrendDirectionTooltip = true;
              // break;
            } else {
              this.showDPUPTrendDirectionTooltip = false;
            }
            this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
            break;
          }
        }
      }
    }
    // }
  }
  mouseMoveOnNextClose(e) {
    var positionfrom = document.querySelector(".next_close-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var next_closetop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.next_closeCoordArray.length > 1) {
        if ((this.left_coord > this.next_closeCoordArray[a].x1 && this.left_coord <= this.next_closeCoordArray[a].x2)) {
          if ((next_closetop <= ((this.next_closeCoordArray[a].y1 + 6)) && next_closetop > ((this.next_closeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.next_close_ = this.cnd_array[i].next_close;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showNextCloseTooltip = true;
            break;
          } else {
            this.showNextCloseTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnIncreasingVolume(e) {
    var positionfrom = document.querySelector(".increasingvolume-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var increasingvolumetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.increasingvolumeCoordArray.length > 1) {
        if ((this.left_coord > this.increasingvolumeCoordArray[a].x1 && this.left_coord <= this.increasingvolumeCoordArray[a].x2)) {
          if ((increasingvolumetop <= ((this.increasingvolumeCoordArray[a].y1 + 6)) && increasingvolumetop > ((this.increasingvolumeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_drop_ = this.cnd_array[i].big_drop_pull_back;
            this.big_raise_ = this.cnd_array[i].big_raise_pull_back;
            this.big_drop_price_ = (typeof this.cnd_array[i].big_drop_pull_back_price == 'undefined') ? 0 : this.cnd_array[i].big_drop_pull_back_price;
            this.big_raise_price_ = (typeof this.cnd_array[i].big_raise_pull_back_price == 'undefined') ? 0 : this.cnd_array[i].big_raise_pull_back_price;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigRaiseDropTooltip = true;
          } else {
            this.showBigRaiseDropTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBlackDotUpPressure(e) {
    var positionfrom = document.querySelector(".blackdotuppressure-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var blackdotuptop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.blackdotuppressureCoordArray.length > 1) {
        if ((this.left_coord > this.blackdotuppressureCoordArray[a].x1 && this.left_coord <= this.blackdotuppressureCoordArray[a].x2)) {
          if ((blackdotuptop <= ((this.blackdotuppressureCoordArray[a].y1 + 6)) && blackdotuptop > ((this.blackdotuppressureCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.black_dot_up_ressure_ = this.cnd_array[i].black_dot_up_pressure;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBlackDotUpPressureTooltip = true;
          } else {
            this.showBlackDotUpPressureTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelAvgDiffPerc(e) {
    var positionfrom = document.querySelector(".sevenlevelavgdiffperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelavgdiffperctop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelavgdiffpercCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelavgdiffpercCoordArray[a].x1 && this.left_coord <= this.sevenlevelavgdiffpercCoordArray[a].x2)) {
          if ((sevenlevelavgdiffperctop <= ((this.sevenlevelavgdiffpercCoordArray[a].y1 + 6)) && sevenlevelavgdiffperctop > ((this.sevenlevelavgdiffpercCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_avg_diff_perc_ = this.cnd_array[i].seven_level_avg_diff_p;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenLevelAvgDiffPercTooltip = true;
          } else {
            this.showSevenLevelAvgDiffPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook1Average(e) {
    var positionfrom = document.querySelector(".orderbook1avg-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook1avgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook1AvergCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook1AvergCoordArray[a].x1 && this.left_coord <= this.OrderBook1AvergCoordArray[a].x2)) {
          if ((orderbook1avgtop <= ((this.OrderBook1AvergCoordArray[a].y1 + 4)) && orderbook1avgtop > ((this.OrderBook1AvergCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._1_perc_diff_avg_ = parseFloat(this.cnd_array[i]._1_perc_diff_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook1AvergTooltip = true;
          } else {
            this.showOrderBook1AvergTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook2Average(e) {
    var positionfrom = document.querySelector(".orderbook2avg-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook2avgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook2AvergCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook2AvergCoordArray[a].x1 && this.left_coord <= this.OrderBook2AvergCoordArray[a].x2)) {
          if ((orderbook2avgtop <= ((this.OrderBook2AvergCoordArray[a].y1 + 4)) && orderbook2avgtop > ((this.OrderBook2AvergCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._2_perc_diff_avg_ = parseFloat(this.cnd_array[i]._2_perc_diff_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook2AvergTooltip = true;
          } else {
            this.showOrderBook2AvergTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook3Average(e) {
    var positionfrom = document.querySelector(".orderbook3avg-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook3avgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook3AvergCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook3AvergCoordArray[a].x1 && this.left_coord <= this.OrderBook3AvergCoordArray[a].x2)) {
          if ((orderbook3avgtop <= ((this.OrderBook3AvergCoordArray[a].y1 + 4)) && orderbook3avgtop > ((this.OrderBook3AvergCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._3_perc_diff_avg_ = parseFloat(this.cnd_array[i]._3_perc_diff_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook3AvergTooltip = true;
          } else {
            this.showOrderBook3AvergTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook1AveragePerc(e) {
    var positionfrom = document.querySelector(".orderbook1avgperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook1avgperctop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook1AvergPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook1AvergPercCoordArray[a].x1 && this.left_coord <= this.OrderBook1AvergPercCoordArray[a].x2)) {
          if ((orderbook1avgperctop <= ((this.OrderBook1AvergPercCoordArray[a].y1 + 6)) && orderbook1avgperctop > ((this.OrderBook1AvergPercCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._1_perc_diff_avg_perc_ = this.cnd_array[i]._1_perc_diff_avg_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook1AvergPercTooltip = true;
          } else {
            this.showOrderBook1AvergPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook2AveragePerc(e) {
    var positionfrom = document.querySelector(".orderbook2avgperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook2avgperctop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook2AvergPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook2AvergPercCoordArray[a].x1 && this.left_coord <= this.OrderBook2AvergPercCoordArray[a].x2)) {
          if ((orderbook2avgperctop <= ((this.OrderBook2AvergPercCoordArray[a].y1 + 6)) && orderbook2avgperctop > ((this.OrderBook2AvergPercCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._2_perc_diff_avg_perc_ = this.cnd_array[i]._2_perc_diff_avg_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook2AvergPercTooltip = true;
          } else {
            this.showOrderBook2AvergPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook3AveragePerc(e) {
    var positionfrom = document.querySelector(".orderbook3avgperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook3avgperctop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook3AvergPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook3AvergPercCoordArray[a].x1 && this.left_coord <= this.OrderBook3AvergPercCoordArray[a].x2)) {
          if ((orderbook3avgperctop <= ((this.OrderBook3AvergPercCoordArray[a].y1 + 6)) && orderbook3avgperctop > ((this.OrderBook3AvergPercCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._3_perc_diff_avg_perc_ = this.cnd_array[i]._3_perc_diff_avg_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook3AvergPercTooltip = true;
          } else {
            this.showOrderBook3AvergPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook1MinPerc(e) {
    var positionfrom = document.querySelector(".orderbook1minperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook1minpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook1MinPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook1MinPercCoordArray[a].x1 && this.left_coord <= this.OrderBook1MinPercCoordArray[a].x2)) {
          if ((orderbook1minpercentiletop <= ((this.OrderBook1MinPercCoordArray[a].y1 + 4)) && orderbook1minpercentiletop > ((this.OrderBook1MinPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._1_perc_diff_min_perc_ = this.cnd_array[i]._1_perc_diff_min_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook1MinPercTooltip = true;
          } else {
            this.showOrderBook1MinPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook2MinPerc(e) {
    var positionfrom = document.querySelector(".orderbook2minperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook2minpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook2MinPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook2MinPercCoordArray[a].x1 && this.left_coord <= this.OrderBook2MinPercCoordArray[a].x2)) {
          if ((orderbook2minpercentiletop <= ((this.OrderBook2MinPercCoordArray[a].y1 + 4)) && orderbook2minpercentiletop > ((this.OrderBook2MinPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._2_perc_diff_min_perc_ = this.cnd_array[i]._2_perc_diff_min_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook2MinPercTooltip = true;
          } else {
            this.showOrderBook2MinPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook3MinPerc(e) {
    var positionfrom = document.querySelector(".orderbook3minperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook3minpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook3MinPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook3MinPercCoordArray[a].x1 && this.left_coord <= this.OrderBook3MinPercCoordArray[a].x2)) {
          if ((orderbook3minpercentiletop <= ((this.OrderBook3MinPercCoordArray[a].y1 + 4)) && orderbook3minpercentiletop > ((this.OrderBook3MinPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._3_perc_diff_min_perc_ = this.cnd_array[i]._3_perc_diff_min_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook3MinPercTooltip = true;
          } else {
            this.showOrderBook3MinPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook1MaxPerc(e) {
    var positionfrom = document.querySelector(".orderbook1maxperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook1maxpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook1MaxPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook1MaxPercCoordArray[a].x1 && this.left_coord <= this.OrderBook1MaxPercCoordArray[a].x2)) {
          if ((orderbook1maxpercentiletop <= ((this.OrderBook1MaxPercCoordArray[a].y1 + 4)) && orderbook1maxpercentiletop > ((this.OrderBook1MaxPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._1_perc_diff_max_perc_ = this.cnd_array[i]._1_perc_diff_max_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook1MaxPercTooltip = true;
          } else {
            this.showOrderBook1MaxPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook2MaxPerc(e) {
    var positionfrom = document.querySelector(".orderbook2maxperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook2maxpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook2MaxPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook2MaxPercCoordArray[a].x1 && this.left_coord <= this.OrderBook2MaxPercCoordArray[a].x2)) {
          if ((orderbook2maxpercentiletop <= ((this.OrderBook2MaxPercCoordArray[a].y1 + 4)) && orderbook2maxpercentiletop > ((this.OrderBook2MaxPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._2_perc_diff_max_perc_ = this.cnd_array[i]._2_perc_diff_max_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook2MaxPercTooltip = true;
          } else {
            this.showOrderBook2MaxPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook3MaxPerc(e) {
    var positionfrom = document.querySelector(".orderbook3maxperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook3maxpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook3MaxPercCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook3MaxPercCoordArray[a].x1 && this.left_coord <= this.OrderBook3MaxPercCoordArray[a].x2)) {
          if ((orderbook3maxpercentiletop <= ((this.OrderBook3MaxPercCoordArray[a].y1 + 4)) && orderbook3maxpercentiletop > ((this.OrderBook3MaxPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._3_perc_diff_max_perc_ = this.cnd_array[i]._3_perc_diff_max_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook3MaxPercTooltip = true;
          } else {
            this.showOrderBook3MaxPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook1MinMax(e) {
    var positionfrom = document.querySelector(".orderbook1minmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook1minmaxtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook1MinMaxCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook1MinMaxCoordArray[a].x1 && this.left_coord <= this.OrderBook1MinMaxCoordArray[a].x2)) {
          if ((orderbook1minmaxtop <= ((this.OrderBook1MinMaxCoordArray[a].y1 + 4)) && orderbook1minmaxtop > ((this.OrderBook1MinMaxCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._1_perc_diff_max_ = this.cnd_array[i]._1_perc_diff_max;
            this._1_perc_diff_min_ = this.cnd_array[i]._1_perc_diff_min;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook1MinMaxTooltip = true;
          } else {
            this.showOrderBook1MinMaxTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook2MinMax(e) {
    var positionfrom = document.querySelector(".orderbook2minmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook2minmaxtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook2MinMaxCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook2MinMaxCoordArray[a].x1 && this.left_coord <= this.OrderBook2MinMaxCoordArray[a].x2)) {
          if ((orderbook2minmaxtop <= ((this.OrderBook2MinMaxCoordArray[a].y1 + 4)) && orderbook2minmaxtop > ((this.OrderBook2MinMaxCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._2_perc_diff_max_ = this.cnd_array[i]._2_perc_diff_max;
            this._2_perc_diff_min_ = this.cnd_array[i]._2_perc_diff_min;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook2MinMaxTooltip = true;
          } else {
            this.showOrderBook2MinMaxTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnOrderBook3MinMax(e) {
    var positionfrom = document.querySelector(".orderbook3minmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var orderbook3minmaxtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.OrderBook3MinMaxCoordArray.length > 1) {
        if ((this.left_coord > this.OrderBook3MinMaxCoordArray[a].x1 && this.left_coord <= this.OrderBook3MinMaxCoordArray[a].x2)) {
          if ((orderbook3minmaxtop <= ((this.OrderBook3MinMaxCoordArray[a].y1 + 4)) && orderbook3minmaxtop > ((this.OrderBook3MinMaxCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this._3_perc_diff_max_ = this.cnd_array[i]._3_perc_diff_max;
            this._3_perc_diff_min_ = this.cnd_array[i]._3_perc_diff_min;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showOrderBook3MinMaxTooltip = true;
          } else {
            this.showOrderBook3MinMaxTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnFourteenLevelAvgDiffPerc(e) {
    var positionfrom = document.querySelector(".fourteenlevelavgdiffperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var fourteenlevelavgdiffperctop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.fourteenlevelavgdiffpercCoordArray.length > 1) {
        if ((this.left_coord > this.fourteenlevelavgdiffpercCoordArray[a].x1 && this.left_coord <= this.fourteenlevelavgdiffpercCoordArray[a].x2)) {
          if ((fourteenlevelavgdiffperctop <= ((this.fourteenlevelavgdiffpercCoordArray[a].y1 + 6)) && fourteenlevelavgdiffperctop > ((this.fourteenlevelavgdiffpercCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.fourteen_level_avg_diff_perc_ = typeof this.cnd_array[i].fourteen_level_avg_diff_p == 'undefined' ? 0 : (this.cnd_array[i].fourteen_level_avg_diff_p == null ? 0 : (this.cnd_array[i].fourteen_level_avg_diff_p).toFixed(8));
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showFourteenLevelAvgDiffPercTooltip = true;
          } else {
            this.showFourteenLevelAvgDiffPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDP1(e) {
    var positionfrom = document.querySelector(".dp1-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp1top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp1CoordArray.length > 1) {
        if ((this.left_coord > this.dp1CoordArray[a].x1 && this.left_coord <= this.dp1CoordArray[a].x2)) {
          if ((dp1top <= ((this.dp1CoordArray[a].y1 + 6)) && dp1top > ((this.dp1CoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.DP1_perc_ = this.cnd_array[i].DP1_perc;
            this.DP1_ =  (Math.abs(this.cnd_array[i].DP1)).toFixed(2);
            this.UP1_ = (Math.abs(this.cnd_array[i].UP1)).toFixed(2)
            this.UP1_perc_ = this.cnd_array[i].UP1_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDP1Tooltip = true;
          } else {
            this.showDP1Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDP1BTC(e) {
    var positionfrom = document.querySelector(".dp1_btc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp1btctop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp1btcCoordArray.length > 1) {
        if ((this.left_coord > this.dp1btcCoordArray[a].x1 && this.left_coord <= this.dp1btcCoordArray[a].x2)) {
          if ((dp1btctop <= ((this.dp1btcCoordArray[a].y1 + 6)) && dp1btctop > ((this.dp1btcCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.DP1_perc_BTC_ = this.cnd_array[i].DP1_perc_btc;
            this.DP1_BTC_ =  (Math.abs(this.cnd_array[i].DP1_btc)).toFixed(2);
            this.UP1_BTC_ = (Math.abs(this.cnd_array[i].UP1_btc)).toFixed(2)
            this.UP1_perc_BTC_ = this.cnd_array[i].UP1_perc_btc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDP1BTCTooltip = true;
          } else {
            this.showDP1BTCTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDP2(e) {
    var positionfrom = document.querySelector(".dp2-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp2top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp2CoordArray.length > 1) {
        if ((this.left_coord > this.dp2CoordArray[a].x1 && this.left_coord <= this.dp2CoordArray[a].x2)) {
          if ((dp2top <= ((this.dp2CoordArray[a].y1 + 6)) && dp2top > ((this.dp2CoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.DP2_perc_ = this.cnd_array[i].DP2_perc;
            this.DP2_ =  (Math.abs(this.cnd_array[i].DP2)).toFixed(2);
            this.UP2_ = (Math.abs(this.cnd_array[i].UP2)).toFixed(2);
            this.UP2_perc_ = this.cnd_array[i].UP2_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDP2Tooltip = true;
          } else {
            this.showDP2Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDP2BTC(e) {
    var positionfrom = document.querySelector(".dp2_btc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp2btctop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp2btcCoordArray.length > 1) {
        if ((this.left_coord > this.dp2btcCoordArray[a].x1 && this.left_coord <= this.dp2btcCoordArray[a].x2)) {
          if ((dp2btctop <= ((this.dp2btcCoordArray[a].y1 + 6)) && dp2btctop > ((this.dp2btcCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.DP2_perc_BTC_ = this.cnd_array[i].DP2_perc_btc;
            this.DP2_BTC_ =  (Math.abs(this.cnd_array[i].DP2_btc)).toFixed(2);
            this.UP2_BTC_ = (Math.abs(this.cnd_array[i].UP2_btc)).toFixed(2);
            this.UP2_perc_BTC_ = this.cnd_array[i].UP2_perc_btc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDP2BTCTooltip = true;
          } else {
            this.showDP2BTCTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDP3(e) {
    var positionfrom = document.querySelector(".dp3-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp3top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp3CoordArray.length > 1) {
        if ((this.left_coord > this.dp3CoordArray[a].x1 && this.left_coord <= this.dp3CoordArray[a].x2)) {
          if ((dp3top <= ((this.dp3CoordArray[a].y1 + 6)) && dp3top > ((this.dp3CoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.DP3_perc_ = this.cnd_array[i].DP3_perc;
            this.DP3_ =  (Math.abs(this.cnd_array[i].DP3)).toFixed(2);
            this.UP3_ = (Math.abs(this.cnd_array[i].UP3)).toFixed(2);
            // this.DP3_perc_ = this.cnd_array[i].DP3_perc;
            this.UP3_perc_ = this.cnd_array[i].UP3_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDP3Tooltip = true;
          } else {
            this.showDP3Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnDP3BTC(e) {
    var positionfrom = document.querySelector(".dp3_btc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var dp3btctop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.dp3btcCoordArray.length > 1) {
        if ((this.left_coord > this.dp3btcCoordArray[a].x1 && this.left_coord <= this.dp3btcCoordArray[a].x2)) {
          if ((dp3btctop <= ((this.dp3btcCoordArray[a].y1 + 6)) && dp3btctop > ((this.dp3btcCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.DP3_perc_BTC_ = this.cnd_array[i].DP3_perc_btc;
            this.DP3_BTC_ =  (Math.abs(this.cnd_array[i].DP3_btc)).toFixed(2);
            this.UP3_BTC_ = (Math.abs(this.cnd_array[i].UP3_btc)).toFixed(2);
            this.UP3_perc_BTC_ = this.cnd_array[i].UP3_perc_btc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showDP3BTCTooltip = true;
          } else {
            this.showDP3BTCTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelPercentile(e) {
    var positionfrom = document.querySelector(".sevenlevelpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelPercCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelPercCoordArray[a].x1 && this.left_coord <= this.sevenlevelPercCoordArray[a].x2)) {
          if ((sevenlevelpercentiletop <= ((this.sevenlevelPercCoordArray[a].y1 + 4)) && sevenlevelpercentiletop > ((this.sevenlevelPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_perc_ = this.cnd_array[i].seven_level_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelPercTooltip = true;
          } else {
            this.showSevenlevelPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnFourteenLevelPercentile(e) {
    var positionfrom = document.querySelector(".fourteenlevelpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var fourteenlevelpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.fourteenlevelPercCoordArray.length > 1) {
        if ((this.left_coord > this.fourteenlevelPercCoordArray[a].x1 && this.left_coord <= this.fourteenlevelPercCoordArray[a].x2)) {
          if ((fourteenlevelpercentiletop <= ((this.fourteenlevelPercCoordArray[a].y1 + 4)) && fourteenlevelpercentiletop > ((this.fourteenlevelPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.fourteen_level_perc_ = this.cnd_array[i].fourteen_level_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showFourteenlevelPercTooltip = true;
          } else {
            this.showFourteenlevelPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelMinVSTPercentile(e) {
    var positionfrom = document.querySelector(".sevenlevelminvstpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelminvstpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelMinVSTPercCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelMinVSTPercCoordArray[a].x1 && this.left_coord <= this.sevenlevelMinVSTPercCoordArray[a].x2)) {
          if ((sevenlevelminvstpercentiletop <= ((this.sevenlevelMinVSTPercCoordArray[a].y1 + 4)) && sevenlevelminvstpercentiletop > ((this.sevenlevelMinVSTPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_min_vst_perc_ = this.cnd_array[i].seven_level_min_perc_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelMinVSTPercTooltip = true;
          } else {
            this.showSevenlevelMinVSTPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelMinPercentile(e) {
    var positionfrom = document.querySelector(".sevenlevelminpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelminpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelMinPercCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelMinPercCoordArray[a].x1 && this.left_coord <= this.sevenlevelMinPercCoordArray[a].x2)) {
          if ((sevenlevelminpercentiletop <= ((this.sevenlevelMinPercCoordArray[a].y1 + 4)) && sevenlevelminpercentiletop > ((this.sevenlevelMinPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_min_perc_ = this.cnd_array[i].seven_level_min_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelMinPercTooltip = true;
          } else {
            this.showSevenlevelMinPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelMinPercentileST(e) {
    var positionfrom = document.querySelector(".sevenlevelminpercentilest-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelminpercentilesttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelMinPercSTCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelMinPercSTCoordArray[a].x1 && this.left_coord <= this.sevenlevelMinPercSTCoordArray[a].x2)) {
          if ((sevenlevelminpercentilesttop <= ((this.sevenlevelMinPercSTCoordArray[a].y1 + 4)) && sevenlevelminpercentilesttop > ((this.sevenlevelMinPercSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_min_perc_st_ = this.cnd_array[i].seven_level_min_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelMinPercSTTooltip = true;
          } else {
            this.showSevenlevelMinPercSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelMaxPercentile(e) {
    var positionfrom = document.querySelector(".sevenlevelmaxpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelmaxpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelMaxPercCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelMaxPercCoordArray[a].x1 && this.left_coord <= this.sevenlevelMaxPercCoordArray[a].x2)) {
          if ((sevenlevelmaxpercentiletop <= ((this.sevenlevelMaxPercCoordArray[a].y1 + 4)) && sevenlevelmaxpercentiletop > ((this.sevenlevelMaxPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_max_perc_ = this.cnd_array[i].seven_level_max_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelMaxPercTooltip = true;
          } else {
            this.showSevenlevelMaxPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelMaxPercentileST(e) {
    var positionfrom = document.querySelector(".sevenlevelmaxpercentilest-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelmaxpercentilesttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelMaxPercSTCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelMaxPercSTCoordArray[a].x1 && this.left_coord <= this.sevenlevelMaxPercSTCoordArray[a].x2)) {
          if ((sevenlevelmaxpercentilesttop <= ((this.sevenlevelMaxPercSTCoordArray[a].y1 + 4)) && sevenlevelmaxpercentilesttop > ((this.sevenlevelMaxPercSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_max_perc_st_ = this.cnd_array[i].seven_level_max_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelMaxPercSTTooltip = true;
          } else {
            this.showSevenlevelMaxPercSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevel(e) {
    var positionfrom = document.querySelector(".sevenlevel-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelavgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelAverageCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelAverageCoordArray[a].x1 && this.left_coord <= this.sevenlevelAverageCoordArray[a].x2)) {
          if ((sevenlevelavgtop <= ((this.sevenlevelAverageCoordArray[a].y1 + 4)) && sevenlevelavgtop > ((this.sevenlevelAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_avg_ = parseFloat(this.cnd_array[i].seven_level_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelAvgTooltip = true;
          } else {
            this.showSevenlevelAvgTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnFourteenLevelAvg(e) {
    var positionfrom = document.querySelector(".fourteenlevelavg-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var fourteenlevelavgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.fourteenlevelAverageCoordArray.length > 1) {
        if ((this.left_coord > this.fourteenlevelAverageCoordArray[a].x1 && this.left_coord <= this.fourteenlevelAverageCoordArray[a].x2)) {
          if ((fourteenlevelavgtop <= ((this.fourteenlevelAverageCoordArray[a].y1 + 4)) && fourteenlevelavgtop > ((this.fourteenlevelAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.fourteen_level_avg_ = parseFloat(this.cnd_array[i].fourteen_level_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showFourteenlevelAvgTooltip = true;
          } else {
            this.showFourteenlevelAvgTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelST(e) {
    var positionfrom = document.querySelector(".sevenlevelst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelavgsttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelAverageSTCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelAverageSTCoordArray[a].x1 && this.left_coord <= this.sevenlevelAverageSTCoordArray[a].x2)) {
          if ((sevenlevelavgsttop <= ((this.sevenlevelAverageSTCoordArray[a].y1 + 4)) && sevenlevelavgsttop > ((this.sevenlevelAverageSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_avg_st_ = this.cnd_array[i].seven_level_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelAvgSTTooltip = true;
          } else {
            this.showSevenlevelAvgSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelVST(e) {
    var positionfrom = document.querySelector(".sevenlevelvst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelavgvsttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelAverageVSTCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelAverageVSTCoordArray[a].x1 && this.left_coord <= this.sevenlevelAverageVSTCoordArray[a].x2)) {
          if ((sevenlevelavgvsttop <= ((this.sevenlevelAverageVSTCoordArray[a].y1 + 4)) && sevenlevelavgvsttop > ((this.sevenlevelAverageVSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.seven_level_avg_vst_ = this.cnd_array[i].seven_level_perc_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelAvgVSTTooltip = true;
          } else {
            this.showSevenlevelAvgVSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnSevenLevelMinMax(e) {
    var positionfrom = document.querySelector(".sevenlevelminmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var sevenlevelminmaxtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.sevenlevelMinMaxCoordArray.length > 1) {
        if ((this.left_coord > this.sevenlevelMinMaxCoordArray[a].x1 && this.left_coord <= this.sevenlevelMinMaxCoordArray[a].x2)) {
          if ((sevenlevelminmaxtop <= ((this.sevenlevelMinMaxCoordArray[a].y1 + 4)) && sevenlevelminmaxtop > ((this.sevenlevelMinMaxCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.sevenlevel_max_ = this.cnd_array[i].seven_level_max;
            this.sevenlevel_min_ = this.cnd_array[i].seven_level_min;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showSevenlevelMinMaxTooltip = true;
          } else {
            this.showSevenlevelMinMaxTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnFourteenLevelMinMax(e) {
    var positionfrom = document.querySelector(".fourteenlevelminmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var fourteenlevelminmaxtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.fourteenlevelMinMaxCoordArray.length > 1) {
        if ((this.left_coord > this.fourteenlevelMinMaxCoordArray[a].x1 && this.left_coord <= this.fourteenlevelMinMaxCoordArray[a].x2)) {
          if ((fourteenlevelminmaxtop <= ((this.fourteenlevelMinMaxCoordArray[a].y1 + 4)) && fourteenlevelminmaxtop > ((this.fourteenlevelMinMaxCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.fourteenlevel_max_ = this.cnd_array[i].fourteen_level_max;
            this.fourteenlevel_min_ = this.cnd_array[i].fourteen_level_min;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showFourteenlevelMinMaxTooltip = true;
          } else {
            this.showFourteenlevelMinMaxTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBlackDotPressureU10(e) {
    var positionfrom = document.querySelector(".blackdotpressureu10-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var blackdotpressureu10top = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.blackdotpressureu10CoordArray.length > 1) {
        if ((this.left_coord > this.blackdotpressureu10CoordArray[a].x1 && this.left_coord <= this.blackdotpressureu10CoordArray[a].x2)) {
          if ((blackdotpressureu10top <= ((this.blackdotpressureu10CoordArray[a].y1 + 6)) && blackdotpressureu10top > ((this.blackdotpressureu10CoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.black_dot_pressure_u10_ = this.cnd_array[i].black_dot_up_pressure_U10;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBlackDotPressureU10Tooltip = true;
          } else {
            this.showBlackDotPressureU10Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnAskBidVolume(e) {
    var positionfrom = document.querySelector(".askbidvolume-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbidvolumetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbidvolumeCoordArray.length > 1) {
        if ((this.left_coord > this.askbidvolumeCoordArray[a].x1 && this.left_coord <= this.askbidvolumeCoordArray[a].x2)) {
          if ((askbidvolumetop <= ((this.askbidvolumeCoordArray[a].y1 + 6)) && askbidvolumetop > ((this.askbidvolumeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_bid_volume_ = this.cnd_array[i].total_volume;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidVolumeTooltip = true;
          } else {
            this.showAskBidVolumeTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnAskBidVolumeB(e) {
    var positionfrom = document.querySelector(".askbidvolumeb-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbidvolumebtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbidvolumebCoordArray.length > 1) {
        if ((this.left_coord > this.askbidvolumebCoordArray[a].x1 && this.left_coord <= this.askbidvolumebCoordArray[a].x2)) {
          if ((askbidvolumebtop <= ((this.askbidvolumebCoordArray[a].y1 + 6)) && askbidvolumebtop > ((this.askbidvolumebCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_bid_volume_b_ = this.cnd_array[i].total_volume_b;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidVolumeBTooltip = true;
          } else {
            this.showAskBidVolumeBTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnAskBidDelta(e) {
    var positionfrom = document.querySelector(".askbiddelta-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbiddeltatop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askBidDeltaCoordArray.length > 1) {
        if ((this.left_coord > this.askBidDeltaCoordArray[a].x1 && this.left_coord <= this.askBidDeltaCoordArray[a].x2)) {
          if ((askbiddeltatop <= ((this.askBidDeltaCoordArray[a].y1 + 4)) && askbiddeltatop > ((this.askBidDeltaCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            if (typeof (this.cnd_array[i].ask_bid_diff) !== 'undefined') {
              this.ask_bid_delta_ = this.cnd_array[i].ask_bid_diff;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDeltaTooltip = true;
          } else {
            this.showAskBidDeltaTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnAskBidDeltaB(e) {
    var positionfrom = document.querySelector(".askbiddeltab-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbiddeltabtop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askBidDeltabCoordArray.length > 1) {
        if ((this.left_coord > this.askBidDeltabCoordArray[a].x1 && this.left_coord <= this.askBidDeltabCoordArray[a].x2)) {
          if ((askbiddeltabtop <= ((this.askBidDeltabCoordArray[a].y1 + 4)) && askbiddeltabtop > ((this.askBidDeltabCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }


            if (typeof (this.cnd_array[i].ask_bid_diff_b) !== 'undefined') {
              this.ask_bid_delta_b_ = this.cnd_array[i].ask_bid_diff_b;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDeltaBTooltip = true;
          } else {
            this.showAskBidDeltaBTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBidDelta(e) {
    var positionfrom = document.querySelector(".biddelta-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var biddeltatop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bidDeltaCoordArray.length > 1) {
        if ((this.left_coord > this.bidDeltaCoordArray[a].x1 && this.left_coord <= this.bidDeltaCoordArray[a].x2)) {
          if ((biddeltatop <= ((this.bidDeltaCoordArray[a].y1 + 4)) && biddeltatop > ((this.bidDeltaCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            let ask = parseFloat(this.cnd_array[i].ask_volume);
            let bid = parseFloat(this.cnd_array[i].bid_volume);
            this.bid_delta_ = (ask > bid ? ask / bid : bid / ask).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBidDeltaTooltip = true;
          } else {
            this.showBidDeltaTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnAskBidDivisionB(e) {
    var positionfrom = document.querySelector(".askbiddivisionb-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbiddivisionbtop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbiddivisionbCoordArray.length > 1) {
        if ((this.left_coord > this.askbiddivisionbCoordArray[a].x1 && this.left_coord <= this.askbiddivisionbCoordArray[a].x2)) {
          if ((askbiddivisionbtop <= ((this.askbiddivisionbCoordArray[a].y1 + 4)) && askbiddivisionbtop > ((this.askbiddivisionbCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            let ask = parseFloat(this.cnd_array[i].ask_volume_b);
            let bid = parseFloat(this.cnd_array[i].bid_volume_b);
            this.ask_bid_division_b_ = (ask > bid ? ask / bid : bid / ask).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidDivisionBTooltip = true;
          } else {
            this.showAskBidDivisionBTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnAskBidCount(e) {
    var positionfrom = document.querySelector(".askbidcount-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var askbidcounttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.askbidcountCoordArray.length > 1) {
        if ((this.left_coord > this.askbidcountCoordArray[a].x1 && this.left_coord <= this.askbidcountCoordArray[a].x2)) {
          if ((askbidcounttop <= ((this.askbidcountCoordArray[a].y1 + 4)) && askbidcounttop > ((this.askbidcountCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_count_ = this.cnd_array[i].ask_count;
            this.bid_count_ = this.cnd_array[i].bid_count;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showAskBidCountTooltip = true;
          } else {
            this.showAskBidCountTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnVolumeComparison(e) {
    var positionfrom = document.querySelector(".volumecomparison-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var volumecomparisontop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.volumecomparisonCoordArray.length > 1) {
        if ((this.left_coord > this.volumecomparisonCoordArray[a].x1 && this.left_coord <= this.volumecomparisonCoordArray[a].x2)) {
          if ((volumecomparisontop <= ((this.volumecomparisonCoordArray[a].y1 + 6)) && volumecomparisontop > ((this.volumecomparisonCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.volume_comparison_ = ((parseFloat(this.cnd_array[i].total_volume) / parseFloat(this.cnd_array[i].volume)) * 100).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showVolumeComparisonTooltip = true;
          } else {
            this.showVolumeComparisonTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnRangesComparison(e) {
    var positionfrom = document.querySelector(".rangescomparison-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var rangescomparisontop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.rangescomparisonCoordArray.length > 1) {
        if ((this.left_coord > this.rangescomparisonCoordArray[a].x1 && this.left_coord <= this.rangescomparisonCoordArray[a].x2)) {
          if ((rangescomparisontop <= ((this.rangescomparisonCoordArray[a].y1 + 6)) && rangescomparisontop > ((this.rangescomparisonCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ranges_comparison_ = ((parseFloat(this.cnd_array[i].ranges_volume) / parseFloat(this.cnd_array[i].volume)) * 100).toFixed(2);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showRangesComparisonTooltip = true;
          } else {
            this.showRangesComparisonTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnScriptTime(e) {
    var positionfrom = document.querySelector(".scripttime-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var scripttimetop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.scripttimeCoordArray.length > 1) {
        if ((this.left_coord > this.scripttimeCoordArray[a].x1 && this.left_coord <= this.scripttimeCoordArray[a].x2)) {
          if ((scripttimetop <= ((this.scripttimeCoordArray[a].y1 + 6)) && scripttimetop > ((this.scripttimeCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            if (typeof (this.cnd_array[i].scriptCloseTime) !== 'undefined') {
              this.script_time_ = (((parseFloat(this.cnd_array[i].scriptCloseTime)) - (parseFloat(this.cnd_array[i].closeTime)) / 1000) / 60).toFixed(0);
            } else {
              this.script_time_ = '';
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showScriptTimeTooltip = true;
          } else {
            this.showScriptTimeTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnCoinMetaCount(e) {
    var positionfrom = document.querySelector(".coinmetacount-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var coinmetacounttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.coinmetacountCoordArray.length > 1) {
        if ((this.left_coord > this.coinmetacountCoordArray[a].x1 && this.left_coord <= this.coinmetacountCoordArray[a].x2)) {
          if ((coinmetacounttop <= ((this.coinmetacountCoordArray[a].y1 + 6)) && coinmetacounttop > ((this.coinmetacountCoordArray[a].y2 + 6)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.coin_meta_count_ = this.cnd_array[i].coin_meta_counts;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showCoinMetaCountTooltip = true;
          } else {
            this.showCoinMetaCountTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBlackWallPercentile(e) {
    var positionfrom = document.querySelector(".blackwallpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var blackwallpercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.blackwallPercCoordArray.length > 1) {
        if ((this.left_coord > this.blackwallPercCoordArray[a].x1 && this.left_coord <= this.blackwallPercCoordArray[a].x2)) {
          if ((blackwallpercentiletop <= ((this.blackwallPercCoordArray[a].y1 + 4)) && blackwallpercentiletop > ((this.blackwallPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.black_wall_perc_ = this.cnd_array[i].black_wall_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBlackwallPercTooltip = true;
          } else {
            this.showBlackwallPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigContractsPercentile(e) {
    var positionfrom = document.querySelector(".bigcontractspercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigcontractspercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigcontractsPercCoordArray.length > 1) {
        if ((this.left_coord > this.bigcontractsPercCoordArray[a].x1 && this.left_coord <= this.bigcontractsPercCoordArray[a].x2)) {
          if ((bigcontractspercentiletop <= ((this.bigcontractsPercCoordArray[a].y1 + 4)) && bigcontractspercentiletop > ((this.bigcontractsPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_contracts_perc_ = this.cnd_array[i].big_contracts_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigcontractsPercTooltip = true;
          } else {
            this.showBigcontractsPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigBuyerContractsPercentileST(e) {
    var positionfrom = document.querySelector(".bigbuyercontractspercentilest-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyercontractspercentilesttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyercontractsPercSTCoordArray.length > 1) {
        if ((this.left_coord > this.bigBuyercontractsPercSTCoordArray[a].x1 && this.left_coord <= this.bigBuyercontractsPercSTCoordArray[a].x2)) {
          if ((bigbuyercontractspercentilesttop <= ((this.bigBuyercontractsPercSTCoordArray[a].y1 + 4)) && bigbuyercontractspercentilesttop > ((this.bigBuyercontractsPercSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_buyer_contracts_perc_st_ = this.cnd_array[i].big_buyers_contracts_1_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigBuyercontractsPercSTTooltip = true;
          } else {
            this.showBigBuyercontractsPercSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigBuyerContractsPercentileVST(e) {
    var positionfrom = document.querySelector(".bigbuyercontractspercentilevst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyercontractspercentilevsttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyercontractsPercVSTCoordArray.length > 1) {
        if ((this.left_coord > this.bigBuyercontractsPercVSTCoordArray[a].x1 && this.left_coord <= this.bigBuyercontractsPercVSTCoordArray[a].x2)) {
          if ((bigbuyercontractspercentilevsttop <= ((this.bigBuyercontractsPercVSTCoordArray[a].y1 + 4)) && bigbuyercontractspercentilevsttop > ((this.bigBuyercontractsPercVSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_buyer_contracts_perc_vst_ = this.cnd_array[i].big_buyers_contracts_1_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigBuyercontractsPercVSTTooltip = true;
          } else {
            this.showBigBuyercontractsPercVSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigSellerContractsPercentileST(e) {
    var positionfrom = document.querySelector(".bigsellercontractspercentilest-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigsellercontractspercentilesttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigSellercontractsPercSTCoordArray.length > 1) {
        if ((this.left_coord > this.bigSellercontractsPercSTCoordArray[a].x1 && this.left_coord <= this.bigSellercontractsPercSTCoordArray[a].x2)) {
          if ((bigsellercontractspercentilesttop <= ((this.bigSellercontractsPercSTCoordArray[a].y1 + 4)) && bigsellercontractspercentilesttop > ((this.bigSellercontractsPercSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_seller_contracts_perc_st_ = this.cnd_array[i].big_sellers_contracts_1_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigSellercontractsPercSTTooltip = true;
          } else {
            this.showBigSellercontractsPercSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigSellerContractsPercentileVST(e) {
    var positionfrom = document.querySelector(".bigsellercontractspercentilevst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigsellercontractspercentilevsttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigSellercontractsPercVSTCoordArray.length > 1) {
        if ((this.left_coord > this.bigSellercontractsPercVSTCoordArray[a].x1 && this.left_coord <= this.bigSellercontractsPercVSTCoordArray[a].x2)) {
          if ((bigsellercontractspercentilevsttop <= ((this.bigSellercontractsPercVSTCoordArray[a].y1 + 4)) && bigsellercontractspercentilevsttop > ((this.bigSellercontractsPercVSTCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_seller_contracts_perc_vst_ = this.cnd_array[i].big_sellers_contracts_1_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigSellercontractsPercVSTTooltip = true;
          } else {
            this.showBigSellercontractsPercVSTTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigBuyerSellerContractsCount(e) {
    var positionfrom = document.querySelector(".bigbuyersellercontractscount-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellercontractscounttop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellercontractsCountCoordArray.length > 1) {
        if ((this.left_coord > this.bigBuyerSellercontractsCountCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellercontractsCountCoordArray[a].x2)) {
          if ((bigbuyersellercontractscounttop <= ((this.bigBuyerSellercontractsCountCoordArray[a].y1 + 4)) && bigbuyersellercontractscounttop > ((this.bigBuyerSellercontractsCountCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_buyer_contracts_count_ = (typeof this.cnd_array[i].bigBuyerContractCount_h !== 'undefined') ? this.cnd_array[i].bigBuyerContractCount_h : 0;
            this.big_seller_contracts_count_ = (typeof this.cnd_array[i].bigSellerContractCount_h !== 'undefined') ? this.cnd_array[i].bigSellerContractCount_h : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigBuyerSellercontractsCountTooltip = true;
          } else {
            this.showBigBuyerSellercontractsCountTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigBuyerSellerContractsVol(e) {
    var positionfrom = document.querySelector(".bigbuyersellercontractsvol-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellercontractsvoltop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellercontractsVolCoordArray.length > 1) {
        if ((this.left_coord > this.bigBuyerSellercontractsVolCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellercontractsVolCoordArray[a].x2)) {
          if ((bigbuyersellercontractsvoltop <= ((this.bigBuyerSellercontractsVolCoordArray[a].y1 + 4)) && bigbuyersellercontractsvoltop > ((this.bigBuyerSellercontractsVolCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_buyer_contracts_vol_ = (typeof this.cnd_array[i].bigBuyerContractVol_h !== 'undefined') ? this.cnd_array[i].bigBuyerContractVol_h : 0;
            this.big_seller_contracts_vol_ = (typeof this.cnd_array[i].bigSellerContractVol_h !== 'undefined') ? this.cnd_array[i].bigSellerContractVol_h : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigBuyerSellercontractsVolTooltip = true;
          } else {
            this.showBigBuyerSellercontractsVolTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigBuyerSellerDelta(e) {
    var positionfrom = document.querySelector(".bigbuyersellerdelta-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellerdeltatop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellerDeltaCoordArray.length > 1) {
        if ((this.left_coord > this.bigBuyerSellerDeltaCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellerDeltaCoordArray[a].x2)) {
          if ((bigbuyersellerdeltatop <= ((this.bigBuyerSellerDeltaCoordArray[a].y1 + 4)) && bigbuyersellerdeltatop > ((this.bigBuyerSellerDeltaCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_buyer_seller_delta_ = (typeof this.cnd_array[i].bigBuyerSellerDelta_h !== 'undefined') ? parseFloat(this.cnd_array[i].bigBuyerSellerDelta_h).toFixed(2) : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigBuyerSellerDeltaTooltip = true;
          } else {
            this.showBigBuyerSellerDeltaTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigBuyerSellerDiff(e) {
    var positionfrom = document.querySelector(".bigbuyersellerdiff-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellerdifftop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellerDiffCoordArray.length > 1) {
        if ((this.left_coord > this.bigBuyerSellerDiffCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellerDiffCoordArray[a].x2)) {
          if ((bigbuyersellerdifftop <= ((this.bigBuyerSellerDiffCoordArray[a].y1 + 4)) && bigbuyersellerdifftop > ((this.bigBuyerSellerDiffCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_buyer_seller_diff_ = (typeof this.cnd_array[i].bigBuyerSellerDiff_h !== 'undefined') ? parseFloat(this.cnd_array[i].bigBuyerSellerDiff_h).toFixed(2) : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigBuyerSellerDiffTooltip = true;
          } else {
            this.showBigBuyerSellerDiffTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigBuyerSellerDiffPerc(e) {
    var positionfrom = document.querySelector(".bigbuyersellerdiffperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigbuyersellerdiffperctop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigBuyerSellerDiffPercCoordArray.length > 1) {
        if ((this.left_coord > this.bigBuyerSellerDiffPercCoordArray[a].x1 && this.left_coord <= this.bigBuyerSellerDiffPercCoordArray[a].x2)) {
          if ((bigbuyersellerdiffperctop <= ((this.bigBuyerSellerDiffPercCoordArray[a].y1 + 4)) && bigbuyersellerdiffperctop > ((this.bigBuyerSellerDiffPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_buyer_seller_diff_perc_ = (typeof this.cnd_array[i].bigBuyerSellerDiff_perc_st !== 'undefined') ? parseFloat(this.cnd_array[i].bigBuyerSellerDiff_perc_st).toFixed(2) : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigBuyerSellerDiffPercTooltip = true;
          } else {
            this.showBigBuyerSellerDiffPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnCandlePercentile(e) {
    var positionfrom = document.querySelector(".candlepercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var candlepercentiletop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.candlePercCoordArray.length > 1) {
        if ((this.left_coord > this.candlePercCoordArray[a].x1 && this.left_coord <= this.candlePercCoordArray[a].x2)) {
          if ((candlepercentiletop <= ((this.candlePercCoordArray[a].y1 + 4)) && candlepercentiletop > ((this.candlePercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.candle_perc_ = this.cnd_array[i].total_volume_percentile;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showCandlePercTooltip = true;
          } else {
            this.showCandlePercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnActiveDownBarrierPercentile(e) {
    var positionfrom = document.querySelector(".activedownbarrierpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var activedownbarrierpercentiletop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.activedownbarrierPercCoordArray.length > 1) {
        if ((this.left_coord > this.activedownbarrierPercCoordArray[a].x1 && this.left_coord <= this.activedownbarrierPercCoordArray[a].x2)) {
          if ((activedownbarrierpercentiletop <= ((this.activedownbarrierPercCoordArray[a].y1 + 4)) && activedownbarrierpercentiletop > ((this.activedownbarrierPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.active_down_barrier_per_ = this.cnd_array[i].active_down_barrier_per;
            this.active_down_barrier_ = this.cnd_array[i].active_down_barrier;
            this.active_down_bar_vol_ = this.cnd_array[i].active_down_bar_vol;
            this.active_down_bar_5_vol_ = this.cnd_array[i].active_down_bar_5_vol;
            this.active_down_bar_date_ = this.cnd_array[i].active_down_bar_date;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showActiveDownBarrierPercTooltip = true;
          } else {
            this.showActiveDownBarrierPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnActiveUpBarrierPercentile(e) {
    var positionfrom = document.querySelector(".activeupbarrierpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var activeupbarrierpercentiletop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.activeupbarrierPercCoordArray.length > 1) {
        if ((this.left_coord > this.activeupbarrierPercCoordArray[a].x1 && this.left_coord <= this.activeupbarrierPercCoordArray[a].x2)) {
          if ((activeupbarrierpercentiletop <= ((this.activeupbarrierPercCoordArray[a].y1 + 4)) && activeupbarrierpercentiletop > ((this.activeupbarrierPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.active_up_barrier_per_ = this.cnd_array[i].active_up_barrier_per;
            this.active_up_barrier_ = this.cnd_array[i].active_up_barrier;
            this.active_up_bar_vol_ = this.cnd_array[i].active_up_bar_vol;
            this.active_up_bar_5_vol_ = this.cnd_array[i].active_up_bar_5_vol;
            this.active_up_bar_date_ = this.cnd_array[i].active_up_bar_date;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showActiveUpBarrierPercTooltip = true;
          } else {
            this.showActiveUpBarrierPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnCandleSTPercentile(e) {
    var positionfrom = document.querySelector(".candlestpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var candlestpercentiletop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.candleSTPercCoordArray.length > 1) {
        if ((this.left_coord > this.candleSTPercCoordArray[a].x1 && this.left_coord <= this.candleSTPercCoordArray[a].x2)) {
          if ((candlestpercentiletop <= ((this.candleSTPercCoordArray[a].y1 + 4)) && candlestpercentiletop > ((this.candleSTPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.candle_st_perc_ = this.cnd_array[i].total_volume_percentile_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showCandleSTPercTooltip = true;
          } else {
            this.showCandleSTPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnCandleVSTPercentile(e) {
    var positionfrom = document.querySelector(".candlevstpercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var candlevstpercentiletop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.candleVSTPercCoordArray.length > 1) {
        if ((this.left_coord > this.candleVSTPercCoordArray[a].x1 && this.left_coord <= this.candleVSTPercCoordArray[a].x2)) {
          if ((candlevstpercentiletop <= ((this.candleVSTPercCoordArray[a].y1 + 4)) && candlevstpercentiletop > ((this.candleVSTPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.candle_vst_perc_ = this.cnd_array[i].total_volume_percentile_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showCandleVSTPercTooltip = true;
          } else {
            this.showCandleVSTPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTwoWayBar(e) {
    var positionfrom = document.querySelector(".twowaybar-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var twowaybartop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.twowaybarCoordArray.length > 1) {
        if ((this.left_coord > this.twowaybarCoordArray[a].x1 && this.left_coord <= this.twowaybarCoordArray[a].x2)) {
          if ((twowaybartop <= ((this.twowaybarCoordArray[a].y1 + 4)) && twowaybartop > ((this.twowaybarCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.ask_volume_ = this.cnd_array[i].ask_volume;
            this.bid_volume_ = this.cnd_array[i].bid_volume;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTwoWayBarTooltip = true;
          } else {
            this.showTwoWayBarTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBlackWallAverage(e) {
    var positionfrom = document.querySelector(".blackwallavg-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var blackwallavgtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.blackwallAverageCoordArray.length > 1) {
        if ((this.left_coord > this.blackwallAverageCoordArray[a].x1 && this.left_coord <= this.blackwallAverageCoordArray[a].x2)) {
          if ((blackwallavgtop <= ((this.blackwallAverageCoordArray[a].y1 + 4)) && blackwallavgtop > ((this.blackwallAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.black_wall_avg_ = parseFloat(this.cnd_array[i].black_wall_avg).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBlackwallAvgTooltip = true;
          } else {
            this.showBlackwallAvgTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBigContractsAverage(e) {
    var positionfrom = document.querySelector(".bigcontractsavg-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bigcontractsavgtop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bigcontractsAverageCoordArray.length > 1) {
        if ((this.left_coord > this.bigcontractsAverageCoordArray[a].x1 && this.left_coord <= this.bigcontractsAverageCoordArray[a].x2)) {
          if ((bigcontractsavgtop <= ((this.bigcontractsAverageCoordArray[a].y1 + 4)) && bigcontractsavgtop > ((this.bigcontractsAverageCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.big_contracts_avg_ = parseFloat(this.cnd_array[i].big_contracts).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBigcontractsAvgTooltip = true;
          } else {
            this.showBigcontractsAvgTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnUpperwick(e) {
    var positionfrom = document.querySelector(".upperwick-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var upperwicktop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.upperwickPercCoordArray.length > 1) {
        if ((this.left_coord > this.upperwickPercCoordArray[a].x1 && this.left_coord <= this.upperwickPercCoordArray[a].x2)) {
          if ((upperwicktop <= ((this.upperwickPercCoordArray[a].y1 + 4)) && upperwicktop > ((this.upperwickPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.upper_wick_perc_ = this.cnd_array[i].upper_1_wick_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showUpperwickPercTooltip = true;
          } else {
            this.showUpperwickPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBUpperwick(e) {
    var positionfrom = document.querySelector(".bupperwick-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bupperwicktop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bupperwickCoordArray.length > 1) {
        if ((this.left_coord > this.bupperwickCoordArray[a].x1 && this.left_coord <= this.bupperwickCoordArray[a].x2)) {
          if ((bupperwicktop <= ((this.bupperwickCoordArray[a].y1 + 4)) && bupperwicktop > ((this.bupperwickCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.b_upper_wick_ = this.cnd_array[i].upper_wick_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBUpperwickTooltip = true;
          } else {
            this.showBUpperwickTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnUpperwickST(e) {
    var positionfrom = document.querySelector(".upperwickst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var upperwicksttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.upperwickSTPercCoordArray.length > 1) {
        if ((this.left_coord > this.upperwickSTPercCoordArray[a].x1 && this.left_coord <= this.upperwickSTPercCoordArray[a].x2)) {
          if ((upperwicksttop <= ((this.upperwickSTPercCoordArray[a].y1 + 4)) && upperwicksttop > ((this.upperwickSTPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.upper_wick_st_perc_ = this.cnd_array[i].upper_1_wick_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showUpperwickSTPercTooltip = true;
          } else {
            this.showUpperwickSTPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnUpperwickVST(e) {
    var positionfrom = document.querySelector(".upperwickvst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var upperwickvsttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.upperwickVSTPercCoordArray.length > 1) {
        if ((this.left_coord > this.upperwickVSTPercCoordArray[a].x1 && this.left_coord <= this.upperwickVSTPercCoordArray[a].x2)) {
          if ((upperwickvsttop <= ((this.upperwickVSTPercCoordArray[a].y1 + 4)) && upperwickvsttop > ((this.upperwickVSTPercCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.upper_wick_vst_perc_ = this.cnd_array[i].upper_1_wick_perc_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showUpperwickVSTPercTooltip = true;
          } else {
            this.showUpperwickVSTPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnLowerwick(e) {
    var positionfrom = document.querySelector(".lowerwick-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var lowerwicktop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.lowerwickPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.lowerwickPercentileCoordArray[a].x1 && this.left_coord <= this.lowerwickPercentileCoordArray[a].x2)) {
          if ((lowerwicktop <= ((this.lowerwickPercentileCoordArray[a].y1 + 4)) && lowerwicktop > ((this.lowerwickPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.lower_wick_perc_ = this.cnd_array[i].lower_1_wick_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showLowerwickPercTooltip = true;
          } else {
            this.showLowerwickPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBLowerwick(e) {
    var positionfrom = document.querySelector(".blowerwick-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var blowerwicktop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.blowerwickCoordArray.length > 1) {
        if ((this.left_coord > this.blowerwickCoordArray[a].x1 && this.left_coord <= this.blowerwickCoordArray[a].x2)) {
          if ((blowerwicktop <= ((this.blowerwickCoordArray[a].y1 + 4)) && blowerwicktop > ((this.blowerwickCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.b_lower_wick_ = this.cnd_array[i].lower_wick_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBLowerwickTooltip = true;
          } else {
            this.showBLowerwickTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnLowerwickST(e) {
    var positionfrom = document.querySelector(".lowerwickst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var lowerwicksttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.lowerwickSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.lowerwickSTPercentileCoordArray[a].x1 && this.left_coord <= this.lowerwickSTPercentileCoordArray[a].x2)) {
          if ((lowerwicksttop <= ((this.lowerwickSTPercentileCoordArray[a].y1 + 4)) && lowerwicksttop > ((this.lowerwickSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.lower_wick_st_perc_ = this.cnd_array[i].lower_1_wick_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showLowerwickSTPercTooltip = true;
          } else {
            this.showLowerwickSTPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnLowerwickVST(e) {
    var positionfrom = document.querySelector(".lowerwickvst-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var lowerwickvsttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.lowerwickVSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.lowerwickVSTPercentileCoordArray[a].x1 && this.left_coord <= this.lowerwickVSTPercentileCoordArray[a].x2)) {
          if ((lowerwickvsttop <= ((this.lowerwickVSTPercentileCoordArray[a].y1 + 4)) && lowerwickvsttop > ((this.lowerwickVSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.lower_wick_vst_perc_ = this.cnd_array[i].lower_1_wick_perc_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showLowerwickVSTPercTooltip = true;
          } else {
            this.showLowerwickVSTPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBodyPercentile(e) {
    var positionfrom = document.querySelector(".bodypercentile-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var bodypercentiletop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.bodyPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.bodyPercentileCoordArray[a].x1 && this.left_coord <= this.bodyPercentileCoordArray[a].x2)) {
          if ((bodypercentiletop <= ((this.bodyPercentileCoordArray[a].y1 + 4)) && bodypercentiletop > ((this.bodyPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.body_perc_ = this.cnd_array[i].body_1_perc;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBodyPercTooltip = true;
          } else {
            this.showBodyPercTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnBlackWallMinMax(e) {
    var positionfrom = document.querySelector(".blackwallminmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var blackwallminmaxtop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.blackwallMinMaxCoordArray.length > 1) {
        if ((this.left_coord > this.blackwallMinMaxCoordArray[a].x1 && this.left_coord <= this.blackwallMinMaxCoordArray[a].x2)) {
          if ((blackwallminmaxtop <= ((this.blackwallMinMaxCoordArray[a].y1 + 4)) && blackwallminmaxtop > ((this.blackwallMinMaxCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.blackwall_max_ = this.cnd_array[i].black_wall_max;
            this.blackwall_min_ = this.cnd_array[i].black_wall_min;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showBlackwallMinMaxTooltip = true;
          } else {
            this.showBlackwallMinMaxTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTelegram(e) {
    var positionfrom = document.querySelector(".telegram-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var telegramtop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.telegramsentimentCoordArray.length > 1) {
        if ((this.left_coord > this.telegramsentimentCoordArray[a].x1 && this.left_coord <= this.telegramsentimentCoordArray[a].x2)) {
          if ((telegramtop <= ((this.telegramsentimentCoordArray[a].y1 + 4)) && telegramtop > ((this.telegramsentimentCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            var tele_sent = this.cnd_array[i]['rinkos_data'].telegram_sentiment_counts;
            this.tele_positive_sen_ = (parseFloat(tele_sent.positive)) ? parseFloat(tele_sent.positive) : 0;
            this.tele_negative_sen_ = (parseFloat(tele_sent.negative)) ? parseFloat(tele_sent.negative) : 0;
            this.tele_neutral_sen_ = (parseFloat(tele_sent.neutral)) ? parseFloat(tele_sent.neutral) : 0;
            this.tele_total_sen_ = (parseFloat(tele_sent.total)) ? parseFloat(tele_sent.total) : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTelegramTooltip = true;
          } else {
            this.showTelegramTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTwitter(e) {
    var positionfrom = document.querySelector(".twitter-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var twittertop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.twittersentimentCoordArray.length > 1) {
        if ((this.left_coord > this.twittersentimentCoordArray[a].x1 && this.left_coord <= this.twittersentimentCoordArray[a].x2)) {
          if ((twittertop <= ((this.twittersentimentCoordArray[a].y1 + 4)) && twittertop > ((this.twittersentimentCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            var twit_sent = this.cnd_array[i]['rinkos_data'].twitter_sentiment_counts;
            this.twit_positive_sen_ = (parseFloat(twit_sent.positive)) ? parseFloat(twit_sent.positive) : 0;
            this.twit_negative_sen_ = (parseFloat(twit_sent.negative)) ? parseFloat(twit_sent.negative) : 0;
            this.twit_neutral_sen_ = (parseFloat(twit_sent.neutral)) ? parseFloat(twit_sent.neutral) : 0;
            this.twit_total_sen_ = (parseFloat(twit_sent.total)) ? parseFloat(twit_sent.total) : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTwitterTooltip = true;
          } else {
            this.showTwitterTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnNews(e) {
    var positionfrom = document.querySelector(".news-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var newstop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.newssentimentCoordArray.length > 1) {
        if ((this.left_coord > this.newssentimentCoordArray[a].x1 && this.left_coord <= this.newssentimentCoordArray[a].x2)) {
          if ((newstop <= ((this.newssentimentCoordArray[a].y1 + 4)) && newstop > ((this.newssentimentCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            var news_sent = this.cnd_array[i]['rinkos_data'].news_sentiment_counts;
            this.news_positive_sen_ = (parseFloat(news_sent.positive)) ? parseFloat(news_sent.positive) : 0;
            this.news_negative_sen_ = (parseFloat(news_sent.negative)) ? parseFloat(news_sent.negative) : 0;
            this.news_neutral_sen_ = (parseFloat(news_sent.neutral)) ? parseFloat(news_sent.neutral) : 0;
            this.news_total_sen_ = (parseFloat(news_sent.total)) ? parseFloat(news_sent.total) : 0;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showNewsTooltip = true;
          } else {
            this.showNewsTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnNewsITPercentile(e) {
    var positionfrom = document.querySelector(".newsitperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var newsittop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.newsITPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.newsITPercentileCoordArray[a].x1 && this.left_coord <= this.newsITPercentileCoordArray[a].x2)) {
          if ((newsittop <= ((this.newsITPercentileCoordArray[a].y1 + 4)) && newsittop > ((this.newsITPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.news_perc_it_ = this.cnd_array[i].new_perc_lt;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showNewsITPerctooltip = true;
          } else {
            this.showNewsITPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnNewsSTPercentile(e) {
    var positionfrom = document.querySelector(".newsstperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var newssttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.newsSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.newsSTPercentileCoordArray[a].x1 && this.left_coord <= this.newsSTPercentileCoordArray[a].x2)) {
          if ((newssttop <= ((this.newsSTPercentileCoordArray[a].y1 + 4)) && newssttop > ((this.newsSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.news_perc_st_ = this.cnd_array[i].new_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showNewsSTPerctooltip = true;
          } else {
            this.showNewsSTPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnNewsVSTPercentile(e) {
    var positionfrom = document.querySelector(".newsvstperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var newsvsttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.newsVSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.newsVSTPercentileCoordArray[a].x1 && this.left_coord <= this.newsVSTPercentileCoordArray[a].x2)) {
          if ((newsvsttop <= ((this.newsVSTPercentileCoordArray[a].y1 + 4)) && newsvsttop > ((this.newsVSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.news_perc_vst_ = this.cnd_array[i].new_perc_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showNewsVSTPerctooltip = true;
          } else {
            this.showNewsVSTPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTwitterITPercentile(e) {
    var positionfrom = document.querySelector(".twitteritperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var twitterittop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.twitterITPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.twitterITPercentileCoordArray[a].x1 && this.left_coord <= this.twitterITPercentileCoordArray[a].x2)) {
          if ((twitterittop <= ((this.twitterITPercentileCoordArray[a].y1 + 4)) && twitterittop > ((this.twitterITPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.twitter_perc_it_ = this.cnd_array[i].twitter_per_lt;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTwitterITPerctooltip = true;
          } else {
            this.showTwitterITPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTwitterSTPercentile(e) {
    var positionfrom = document.querySelector(".twitterstperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var twittersttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.twitterSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.twitterSTPercentileCoordArray[a].x1 && this.left_coord <= this.twitterSTPercentileCoordArray[a].x2)) {
          if ((twittersttop <= ((this.twitterSTPercentileCoordArray[a].y1 + 4)) && twittersttop > ((this.twitterSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.twitter_perc_st_ = this.cnd_array[i].twitter_per_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTwitterSTPerctooltip = true;
          } else {
            this.showTwitterSTPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTwitterVSTPercentile(e) {
    var positionfrom = document.querySelector(".twittervstperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var twittervsttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.twitterVSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.twitterVSTPercentileCoordArray[a].x1 && this.left_coord <= this.twitterVSTPercentileCoordArray[a].x2)) {
          if ((twittervsttop <= ((this.twitterVSTPercentileCoordArray[a].y1 + 4)) && twittervsttop > ((this.twitterVSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.twitter_perc_vst_ = this.cnd_array[i].twitter_per_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTwitterVSTPerctooltip = true;
          } else {
            this.showTwitterVSTPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTelegramITPercentile(e) {
    var positionfrom = document.querySelector(".telegramitperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var telegramvsttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.telegramITPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.telegramITPercentileCoordArray[a].x1 && this.left_coord <= this.telegramITPercentileCoordArray[a].x2)) {
          if ((telegramvsttop <= ((this.telegramITPercentileCoordArray[a].y1 + 4)) && telegramvsttop > ((this.telegramITPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.telegram_perc_it_ = this.cnd_array[i].telegram_perc_lt;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTelegramITPerctooltip = true;
          } else {
            this.showTelegramITPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTelegramSTPercentile(e) {
    var positionfrom = document.querySelector(".telegramstperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var telegramsttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.telegramSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.telegramSTPercentileCoordArray[a].x1 && this.left_coord <= this.telegramSTPercentileCoordArray[a].x2)) {
          if ((telegramsttop <= ((this.telegramSTPercentileCoordArray[a].y1 + 4)) && telegramsttop > ((this.telegramSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.telegram_perc_st_ = this.cnd_array[i].telegram_perc_st;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTelegramSTPerctooltip = true;
          } else {
            this.showTelegramSTPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnTelegramVSTPercentile(e) {
    var positionfrom = document.querySelector(".telegramvstperc-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var telegramvsttop = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.telegramVSTPercentileCoordArray.length > 1) {
        if ((this.left_coord > this.telegramVSTPercentileCoordArray[a].x1 && this.left_coord <= this.telegramVSTPercentileCoordArray[a].x2)) {
          if ((telegramvsttop <= ((this.telegramVSTPercentileCoordArray[a].y1 + 4)) && telegramvsttop > ((this.telegramVSTPercentileCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.telegram_perc_vst_ = this.cnd_array[i].telegram_perc_vst;
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showTelegramVSTPerctooltip = true;
          } else {
            this.showTelegramVSTPerctooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnT3(e) {
    var positionfrom = document.querySelector(".t3minmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var T3top = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.t1CoordArray.length > 1) {
        if ((this.left_coord > this.t1CoordArray[a].x1 && this.left_coord <= this.t1CoordArray[a].x2)) {
          if ((T3top <= ((this.t1CoordArray[a].y1 + 4)) && T3top > ((this.t1CoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.t1_max_ = parseFloat(this.cnd_array[i].t1Cot_per_max).toFixed(4);
            this.t1_min_ = parseFloat(this.cnd_array[i].t1Cot_per_min).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showT1Tooltip = true;
          } else {
            this.showT1Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnT2(e) {
    var positionfrom = document.querySelector(".t2minmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var T2top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.t2CoordArray.length > 1) {
        if ((this.left_coord > this.t2CoordArray[a].x1 && this.left_coord <= this.t2CoordArray[a].x2)) {
          if ((T2top <= ((this.t2CoordArray[a].y1 + 4)) && T2top > ((this.t2CoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.t2_max_ = parseFloat(this.cnd_array[i].t2Cot_per_max).toFixed(4);
            this.t2_min_ = parseFloat(this.cnd_array[i].t2Cot_per_min).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showT2Tooltip = true;
          } else {
            this.showT2Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnT4(e) {
    var positionfrom = document.querySelector(".t4minmax-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var T4top = e.offsetY;
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.t4CoordArray.length > 1) {
        if ((this.left_coord > this.t4CoordArray[a].x1 && this.left_coord <= this.t4CoordArray[a].x2)) {
          if ((T4top <= ((this.t4CoordArray[a].y1 + 4)) && T4top > ((this.t4CoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }
            this.t4_max_ = parseFloat(this.cnd_array[i].t4Cot_per_max).toFixed(4);
            this.t4_min_ = parseFloat(this.cnd_array[i].t4Cot_per_min).toFixed(4);
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showT4Tooltip = true;
          } else {
            this.showT4Tooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }
  mouseMoveOnT2BCP(e) {
    var positionfrom = document.querySelector(".t2bcp-selector").getBoundingClientRect();
    this.top_coord = (e.clientY - positionfrom.top) - 6;
    var t2bcptop = e.offsetY
    this.left_coord = (e.clientX - positionfrom.left) - 15;
    this.tooltip_coord = this.top_coord - (this.tooltip_height + 35);
    this.showPriceLine = false;
    for (var i = this.starting_index; i < this.ending_index; i++) {
      if (this.starting_index > 0) {
        var a = i - this.starting_index;
      } else {
        a = i;
      }
      if (this.t2bcpCoordArray.length > 1) {
        if ((this.left_coord > this.t2bcpCoordArray[a].x1 && this.left_coord <= this.t2bcpCoordArray[a].x2)) {
          if ((t2bcptop <= ((this.t2bcpCoordArray[a].y1 + 4)) && t2bcptop > ((this.t2bcpCoordArray[a].y2 + 4)))) {
            if (this.time_length == 25) {
              if (i >= this.ending_index - 12) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 50 && this.time_length <= 100) {
              if (i > this.ending_index - 30) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            } else if (this.time_length > 100) {
              if (i > this.ending_index - 84) {
                this.deduction = - 220;
              } else {
                this.deduction = 20;
              }
            }

            if (typeof this.cnd_array[i].t2bcp15_ask_vol !== 'undefined' && typeof this.cnd_array[i].t2bcp15_bid_vol !== 'undefined') {
              this.t2bcp_max_ = (this.cnd_array[i].t2bcp15_ask_vol).toFixed(2);
              this.t2bcp_min_ = (this.cnd_array[i].t2bcp15_bid_vol).toFixed(2);
            } else {
              this.t2bcp_max_ = 0;
              this.t2bcp_min_ = 0;
            }
            this.openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
            this.showT2BCPTooltip = true;
          } else {
            this.showT2BCPTooltip = false;
          }
          this.openTime_human_readible_ = this.candleCoordArray[a].open_time;
          break;
        }
      }
    }
  }


  pricecheck() {
    var min, max;
    for (var i = 0; i <= this.price_length; i++) {
      if (this.ending_index < 168) {
        max = this.onup_maxprice;
        if (isNaN(max)) {
          max = this.onovercand_maxprice
        }
        min = this.onup_minprice;
        if (isNaN(min)) {
          min = this.onovercand_minprice
        }
      } else {
        max = this.onenter_maxprice;
        min = this.onenter_minprice;
      }
      var diff = max - min;
      var value_diff: number = ((diff / 100) * i);
      var value1 = parseFloat(min + value_diff).toFixed(8);
      this.top_coord = parseFloat(this.top_coord).toFixed(0);
      var valueCandlestickHead = ((this.candleCoordArray[0].pricestart_point + (this.componenthead_height + 30)) - ((this.candleCoordArray[0].pline_diff) * i)).toFixed(0);
      var valueCandlestickWithoutHead = (this.candleCoordArray[0].pricestart_point - ((this.candleCoordArray[0].pline_diff) * i)).toFixed(0);
      if (this.componenthead_height > 0) {
        if (this.top_coord == valueCandlestickHead) {
          // if(this.top_coord == (this.candleCoordArray[0].pricestart_point + (this.componenthead_height + 30)) - ((this.candleCoordArray[0].pline_diff)*i)){
          this.price = value1;
          break;
        }
      }
      if (this.componenthead_height == 0) {
        if (this.top_coord == valueCandlestickWithoutHead) {
          // if(this.top_coord == this.candleCoordArray[0].pricestart_point - ((this.candleCoordArray[0].pline_diff)*i)){
          this.price = value1;
          break;
        }
      }
    }
    // price check end
  }


  mouseOverOnCandels(e) {
    this.onovercand_maxprice = typeof parseFloat(this.candleCoordArray[0].max_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].max_value);
    this.onovercand_minprice = typeof parseFloat(this.candleCoordArray[0].min_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].min_value);
  }
  mouseMove(e) {
    this.gettooltipHeight();
    this.getCandleStickHeadHeight();
    this.pricecheck();
  }
  mousedown(e) {
    if ((this.componenthead_height > 0 && this.top_coord > 80) || (this.componenthead_height == 0 && this.top_coord > 25)) {
      this.ondown_x = e.clientX;
      this.ondown_starting = this.starting_index;
      this.ondown_ending = this.ending_index;
      this.ondown_maxprice = parseFloat(this.candleCoordArray[0].max_value);
      this.ondown_minprice = parseFloat(this.candleCoordArray[0].min_value);
    }
  }
  mouseenter(e) {
    this.onenter_maxprice = typeof parseFloat(this.candleCoordArray[0].max_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].max_value);
    this.onenter_minprice = typeof parseFloat(this.candleCoordArray[0].min_value) == 'undefined' ? 0 : parseFloat(this.candleCoordArray[0].min_value);
  }
  mouseleave(e) {

  }
  mouseup(e) {
    if ((this.componenthead_height > 0 && this.top_coord > 80) || (this.componenthead_height == 0 && this.top_coord > 25)) {
      this.onup_x = e.clientX;
      this.increment_decrement();
      this.onup_maxprice = parseFloat(this.candleCoordArray[0].max_value);
      this.onup_minprice = parseFloat(this.candleCoordArray[0].min_value);
      if (this.x_diff == 0) {
      } else {
        this.line_append_updated();
        this.updatePricelineArray();
        this.line_append_updated_horizontal();
      }
    }
  }
  dblclick() {
    this.line_append()
  }
  // By Raja Kamran
  keyReleased: boolean = true;
  onKeyDown(e) {
    if (this.keyReleased) {
      this.before_candle_width = this.candleCoordArray[0].tline_diff
      this.before_length = this.time_length;
      this.onkeydown_maxprice = parseFloat(this.candleCoordArray[0].max_value);
      this.onkeydown_minprice = parseFloat(this.candleCoordArray[0].min_value);
      // console.log('pressed');
      this.keyReleased = false;
    }
  }
  onKeyUp(e) {
    if (!this.keyReleased) {
      setTimeout(() => {
        this.after_length = this.time_length;
        this.after_candle_width = this.candleCoordArray[0].tline_diff;
        this.onkeyup_maxprice = parseFloat(this.candleCoordArray[0].max_value);
        this.onkeyup_minprice = parseFloat(this.candleCoordArray[0].min_value);
      }, 1);

      setTimeout(() => {
        for (let i = 0; i < this.lineArray.length; i++) {
          if (this.after_length != this.before_length) {

            // this.lineArray[i].fromleft = (((this.lineArray[i].fromleft - this.candleCoordArray[0].fromleft) * this.after_candle_width) / this.before_candle_width) + this.candleCoordArray[0].fromleft;
            this.lineArray[i].fromleft = (((this.lineArray[i].fromleft - this.candleCoordArray[0].fromright) * this.after_candle_width) / this.before_candle_width) + this.candleCoordArray[0].fromright;
          }
        }
        if (this.lineArray.length > 0) {
          this.line_append_updated_horizontal();
          this.updatePricelineArray();
        }
      }, 2);
      this.keyReleased = true;
    }
  }
  // End Here From Raja Kamran




  line_append() {
    var lineArray_object = new Object();
    var left = this.left_coord;
    var top = this.top_coord;
    if ((this.componenthead_height > 0 && this.top_coord > 80) || (this.componenthead_height == 0 && this.top_coord > 25)) {
      lineArray_object['fromleft'] = left;
      lineArray_object['fromtop'] = top;
      lineArray_object['time'] = this.openTime_human_readible_;
      lineArray_object['price'] = this.price;
      this.lineArray.push(lineArray_object);
    }

  }
  line_append_updated() {

    for (var i = 0; i < this.lineArray.length; i++) {
      var ver_diff = this.onup_x - this.ondown_x;
      if (this.starting_index == 0) {
        this.decrement = this.ondown_starting - this.starting_index;
      }
      if (this.ending_index == this.cnd_array.length) {
        this.increment = this.ondown_ending - this.ending_index;
      }
      if (ver_diff > 0) {
        this.lineArray[i].fromleft = this.lineArray[i].fromleft + (this.decrement * this.candleCoordArray[0].tline_diff);
      } else if (ver_diff < 0) {
        this.lineArray[i].fromleft = this.lineArray[i].fromleft + (this.increment * this.candleCoordArray[0].tline_diff);
      }
    }
  }//vartical
  updatePricelineArray() {
    this.pricelineArray = [];
    var upmax
    var upmin
    for (var i = 0; i < this.price_length; i++) {
      var diff;
      if ((this.onkeyup_minprice != this.onkeydown_minprice) || (this.onkeyup_maxprice != this.onkeydown_maxprice)) {
        upmax = this.onkeyup_maxprice
        upmin = this.onkeyup_minprice;
      }
      else {
        upmax = this.onup_maxprice
        upmin = this.onup_minprice;
      }
      diff = upmax - upmin;
      var value = (diff / 100) * i;
      var tag = parseFloat(upmin + value).toFixed(8);
      if (this.componenthead_height > 0) {
        let selected_price_coord = (this.candleCoordArray[0].pricestart_point + (this.componenthead_height + 30)) - (this.candleCoordArray[0].pline_diff) * i;
        var price_object = new Object();
        price_object['price'] = tag;
        price_object['coord'] = selected_price_coord;
        this.pricelineArray.push(price_object);
      }
      if (this.componenthead_height == 0) {
        let selected_price_coord = (this.candleCoordArray[0].pricestart_point) - (this.candleCoordArray[0].pline_diff) * i;
        var price_object = new Object();
        price_object['price'] = tag;
        price_object['coord'] = selected_price_coord;
        this.pricelineArray.push(price_object);
      }
    }
  }//horozontal
  line_append_updated_horizontal() {
    this.coord = -1000;
    setTimeout(() => {
      if (this.lineArray.length > 0) {
        for (let i = 0; i < this.lineArray.length; i++) {
          if ((this.onup_maxprice != this.ondown_maxprice) || (this.onup_minprice != this.ondown_minprice) || (this.onkeyup_minprice != this.onkeydown_minprice) || (this.onkeyup_maxprice != this.onkeydown_maxprice)) {
            let coord_arr = this.pricelineArray.find(x => x.price >= this.lineArray[i].price);
            if (typeof coord_arr != 'undefined') {
              if (typeof coord_arr.coord != 'undefined') {
                this.coord = coord_arr.coord;
                this.lineArray[i].fromtop = this.coord;
              } else {
                this.lineArray[i].fromtop = this.coord;
              }
            } else {
              this.lineArray[i].fromtop = this.coord;
            }
          }
        }
      }
    }, 2);
  }//horizontal
  removeline(index) {
    this.lineArray.splice(index, 1);
  }
  removeAllLines() {
    this.lineArray = []
  }
  increment_decrement() {
    this.x_diff = this.onup_x - this.ondown_x;
    var x_diff = this.x_diff;
    if (x_diff > 0) {
      this.decrement = +((x_diff / this.candleCoordArray[0].tline_diff).toFixed(0));
      this.backward();
    }
    if (x_diff < 0) {
      this.increment = +((x_diff / this.candleCoordArray[0].tline_diff).toFixed(0));
      this.forward();
    }

  }
  forward() {
    if (this.ending_index < this.cnd_array.length) {
      this.starting_index = this.starting_index - this.increment;
      this.ending_index = this.ending_index - this.increment;
      if (this.ending_index > this.cnd_array.length || this.ending_index == this.cnd_array.length) {
        this.ending_index = this.cnd_array.length;
        this.starting_index = this.ending_index - this.time_length;
      }
      this.changearrayValue();
    } else {
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.changearrayValue();
    }
  }
  backward() {
    if (this.starting_index > 0) {
      this.starting_index = this.starting_index - this.decrement;
      this.ending_index = this.ending_index - this.decrement;
      if (this.starting_index < 0 || this.starting_index == 0) {
        this.starting_index = 0;
        this.ending_index = this.starting_index + this.time_length;
      }
      this.changearrayValue();
    } else {
      this.starting_index = this.general_array[0].start_index;
      this.ending_index = this.general_array[0].end_index;
      this.changearrayValue();
    }
  }

}
