import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './_guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { AddUserComponent } from './add-user/add-user.component';


import { DailyChartComponent } from './daily-chart/daily-chart.component';
import { MainchartComponent } from './mainchart/mainchart.component';


const routes: Routes = [
{path: '',component: HomeComponent,canActivate: [AuthGuard] },
{ path: 'login', component: LoginComponent },
{ path: 'add-user', component: AddUserComponent },
{ path: 'mainchart2/:coin', component: MainchartComponent, canActivate: [AuthGuard] },
{ path: 'dailychart/:coin', component: DailyChartComponent, canActivate: [AuthGuard] },
// otherwise redirect to home
{ path: '**', redirectTo: '' }
];



@NgModule({
    imports: [
      RouterModule.forRoot(
        routes,
        {
          enableTracing: false, // <-- debugging purposes only
        }
      )
    ],
    exports: [
      RouterModule
    ]
  })
export class AppRoutingModule { }
