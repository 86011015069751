import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatherModule } from 'angular-feather';
import { Circle,Minus,Edit, Tag,ArrowDown,ArrowDownRight,ArrowDownLeft,ArrowLeft,Home ,TrendingDown,Cpu,User,Menu,Activity,AlertOctagon,Minimize2,Minimize,ChevronDown,ChevronUp,Bell,Maximize2,Maximize,BarChart2,TrendingUp, Pocket,Unlock,LogOut,Settings, Aperture, BatteryCharging, Battery} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  Circle,Minus,Edit, Tag,ArrowDown,ArrowDownRight,ArrowDownLeft,ArrowLeft,Home,TrendingDown,Cpu,User,Menu,Activity,AlertOctagon,Minimize2,Minimize,ChevronDown,ChevronUp,Bell,Maximize2,Maximize,BarChart2,TrendingUp, Pocket,Unlock,LogOut,Settings, Aperture, BatteryCharging, Battery
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
