import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { MainChartService } from '../../main-chart.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-seven-level-min-max',
  templateUrl: './seven-level-min-max.component.html',
  styleUrls: []
})
export class SevenLevelMinMaxComponent implements OnInit {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private c: CanvasRenderingContext2D;
  cnd_array;
  general_array;
  total_cnd;
  first_cnd;
  starting_index;
  change_index;
  ending_index;
  increment;
  decrement;





  coordinateArray;
  sevenlevelminmax_start_point;
  sevenlevelminmax_end_point;
  sevenlevelminmax_initial_point;
  sevenlevelminmax_final_point;
  Height;
  Width;
  c_Height;
  c_Width;
  graph_height;
  graph_width;
  pline_difference;
  price_length;
  tline_difference;
  time_length;
  y_from_top = 5;
  y_from_bottom = 5;
  x_from_left = 100;
  x_from_right = 20;
  max_value;
  min_value;
  selectedDate;
  number;


  constructor(
    private service: MainChartService,
    private router: Router
  ) { }
  counter = 0;
  chartTool: boolean = false;




  ngOnInit() {





    var url = window.location.href;
    var location = decodeURI(url);
    var new_url = new URL(location);
    var date_param = new_url.searchParams.get("date");
    var tradetime_param = new_url.searchParams.get("tradetime");
    var tradeprice_param = new_url.searchParams.get("tradeprice");
    console.log(date_param, tradetime_param,tradeprice_param);

    this.number = (this.router.url).includes("date");
    if (this.number == true) {
      this.selectedDate = date_param;
    }





    this.c = this.canvas.nativeElement.getContext('2d');
    this.service.chartData.subscribe(data => {
      this.cnd_array = data
    });
    this.service.sevenlevelminmaxcoordData.subscribe(data => {
      this.coordinateArray = data;
    });
    this.total_cnd = this.cnd_array.length;
    this.first_cnd = this.cnd_array[0];

    this.service.canvasData.subscribe(data => {
      this.general_array = data;
      if (this.counter > 0) {
        this.rander();
      }
    });
    if (this.counter == 0) {
      this.rander();
      this.sidebar_visibility_f();
    }
  }



  ///Other Functions Not Related to Canvas or Graph
  chartTool_func() {
    this.chartTool = !this.chartTool;
  }/// Not to delete
  rander() {
    this.getWidth();
    this.getHeight();
    this.c_Height = this.Height;
    this.c_Width = this.Width
    this.price_length = this.general_array[0].price_length;
    this.time_length = this.general_array[0].time_length;
    this.starting_index = this.general_array[0].start_index;
    this.ending_index = this.general_array[0].end_index;
    this.increment = this.general_array[0].increment;
    this.decrement = this.general_array[0].decrement;
    this.renderCharts();
  }
  getHeight() {
    this.Height = $(".canvas_sevenlevelminmax").height();
  }
  getWidth() {
    this.Width = $(".canvas_sevenlevelminmax").width();
  }
  sidebar_visibility_f() {
    $("body").on('click', '.sidebar-closer', () => {
      this.rander();
    });
  }




  renderCharts() {
    setTimeout(() => {
      this.setCanvasHeightWidth();
      this.priceLineDifference(this.c_Height);
      this.timeLineDifference(this.c_Width);
      this.drawGraph();
      this.counter = 1;
    }, 1);
  }
  priceLineDifference(can_h) {
    var total_graph_height = can_h - this.y_from_bottom - this.y_from_top;
    this.graph_height = total_graph_height;
    this.pline_difference = (total_graph_height / this.price_length);
  }
  timeLineDifference(can_w) {
    var total_graph_width = can_w - this.x_from_left - this.x_from_right;
    this.graph_width = total_graph_width;
    this.tline_difference = total_graph_width / this.time_length;
  }
  setCanvasHeightWidth() {
    this.canvas.nativeElement.height = this.Height;
    this.canvas.nativeElement.width = this.Width;
  }





  drawGraph() {
    this.drawPriceLine_Tag(this.c_Height);
    this.drawCandle(this.c_Height);
  }
  //All Bellow Functions are Call in Function drawGraph()


  drawPriceLine_Tag(can_h) {
    var y = can_h - this.y_from_bottom;
    var x = this.x_from_left;
    var t_graph_height = this.graph_height;// Total Graph Height
    var point_y_perc = t_graph_height / 100;
    var point_y = point_y_perc * 50;// calculation for Label Point Y
    var point_x_perc = this.x_from_left / 100;
    var point_x = point_x_perc * 75;
    var tag = 'SevenLevel';
    var tag2 = 'MinMax';
    this.c.fillStyle = 'white';
    this.c.fillText(tag, x - (point_x), y - point_y);
    this.c.fillText(tag2, x - (point_x), (y - point_y) + 15);
  }


  drawCandle(can_h) {
    var _h = 0;
    var _w = 0;
    _w = this.tline_difference;
    _h = 22
    var candle_width_perc = this.tline_difference / 100;
    var candle_l_off = 5 * candle_width_perc;
    var candle_r_off = 5 * candle_width_perc;
    var x = (this.x_from_left) + (+ (candle_l_off));
    var t_graph_height = this.graph_height;// Total Graph Height
    var point_y_perc = t_graph_height / 100;
    var y = (can_h - this.y_from_bottom);
    var cnd_w = _w - (candle_l_off + candle_r_off);
    var cnd_h = _h;
    var total_vol_arr = []
    var max_vol = 0
    if ((this.time_length > (this.ending_index - this.starting_index)) && (this.time_length > this.cnd_array.length)) {
      this.ending_index = this.cnd_array.length;
    }
    if (this.ending_index > this.cnd_array.length) {
      this.ending_index = this.cnd_array.length;
    }
    this.coordinateArray = [];
    for (var i = this.starting_index; i < this.ending_index; i++) {
      let ask_vol = parseFloat(this.cnd_array[i].seven_level_max);
      let bid_vol = parseFloat(this.cnd_array[i].seven_level_min);

      var base_arr1 = this.cnd_array.slice(this.starting_index, this.ending_index).map(
        x => (Math.abs(typeof x.seven_level_max !== 'undefined' ? x.seven_level_max : 0))
      );
      var base_arr2 = this.cnd_array.slice(this.starting_index, this.ending_index).map(
        x=>(Math.abs(typeof x.seven_level_min !== 'undefined' ? x.seven_level_min : 0))
      );
      var base_arr = base_arr1.concat(base_arr2);

      let base = parseFloat(Math.max.apply(null, base_arr));

      let ask_vol_per = 50 * (ask_vol/base);
      let bid_vol_per = 50 * (bid_vol/base);
      // let ask_vol_per = 5 * ask_vol;
      // let bid_vol_per = 5 * bid_vol;

      if(ask_vol_per > 50) {
         ask_vol_per = 50;
      }
      if(bid_vol_per > 50 || bid_vol_per < -50) {
        bid_vol_per = 50;
      }
      ask_vol_per = (ask_vol_per * point_y_perc);
      bid_vol_per = bid_vol_per * point_y_perc;


       // const Datefromlocalstorage =(localStorage.getItem('Highlighted Candle'));
       var openTime_human_readible_ = this.cnd_array[i].openTime_human_readible;
       if(openTime_human_readible_ == this.selectedDate){
         this.c.fillStyle = 'rgb(47,79,79,0.3)';
         this.c.fillRect(x - candle_l_off, 0, cnd_w + candle_r_off, this.graph_height + this.y_from_bottom + this.y_from_top);
       }

      this.c.fillStyle = 'green';
      this.c.fillRect(x, (y / 2) - Math.abs(ask_vol_per), cnd_w, Math.abs(ask_vol_per));
      this.c.fillStyle = "red";


      this.sevenlevelminmax_start_point = x;
      this.sevenlevelminmax_end_point = this.sevenlevelminmax_start_point + cnd_w;
      this.sevenlevelminmax_initial_point = ((y / 2) + Math.abs(bid_vol_per));
      this.sevenlevelminmax_final_point = ((y / 2) - Math.abs(ask_vol_per));


      let sevenlevelminmax_array_object = new Object();
      sevenlevelminmax_array_object['x1'] = this.sevenlevelminmax_start_point;
      sevenlevelminmax_array_object['x2'] = this.sevenlevelminmax_end_point;
      sevenlevelminmax_array_object['y1'] = this.sevenlevelminmax_initial_point;
      sevenlevelminmax_array_object['y2'] = this.sevenlevelminmax_final_point;

      this.coordinateArray.push(sevenlevelminmax_array_object);



      this.c.fillRect(x, (y / 2), cnd_w, Math.abs(bid_vol_per));
      x += this.tline_difference;
    }
    this.changeValue()
  }
  changeValue(){
    var ArraySevenlevelMinMax = this.coordinateArray
    this.service.new_sevenlevelminmaxData(ArraySevenlevelMinMax);
  }
}
